export default {
  ["k_time"]: "Time",
  ["k_open"]: "Open",
  ["k_hight"]: "High",
  ["k_low"]: "Low",
  ["k_close"]: "Receive",
  ["k_quote_amount"]: "Change",
  ["k_quote_change"]: "Change%",
  ["k_volume"]: "Volume",
  ["exchange_1m"]: "1 Punkt",
  ["exchange_5m"]: "5 Punkte",
  ["exchange_15m"]: "15 Punkte",
  ["exchange_30m"]: "30 Punkte",
  ["exchange_60m"]: "60 Punkte",
  ["exchange_more"]: "Mehr",
  ["exchange_4h"]: "4 Stunden",
  ["exchange_day"]: "Tag",
  ["exchange_month"]: "Mond",
  ["exchange_week"]: "Woche",
  ["exchange_year"]: "Jahr",
  ["tabbar.home"]: "Titelseite",
  ["tabbar.trade"]: "Zitate",
  ["tabbar.hosting"]: "Finanzverwaltung",
  ["tabbar.exchange"]: "handeln",
  ["tabbar.account"]: "Vermögenswerte",
  ["common.confirm"]: "bestätigen",
  ["index.number"]: "Gleichgewicht",
  ["index.Total revenue"]: "Gesamteinnahmen",
  ["index.Trading"]: "Starten Sie AI Expert Advisor",
  ["index.intelligentive trading"]: "Starten Sie jetzt einen intelligenten quantitativen Handel",
  ["host.detail"]: "Einzelheiten",
  ["host.todayProfit"]: "Ergebnis heute",
  ["host.totalProfit"]: "Erwartete Einnahmen",
  ["host.Order quantity"]: "Bestellmenge",
  ["host.Strategic"]: "Produkte für strategische Aktivitäten",
  ["host.escrow"]: "Treuhandbetrag",
  ["host.max"]: "maximal",
  ["host.determine"]: "Sicher",
  ["host.balance"]: "Verfügbares Guthaben",
  ["host.Deposit"]: "Geld einzahlen",
  ["host.quota"]: "Grenze",
  ["host.years"]: "gesamte annualisierte Rate",
  ["host.expect"]: "erwarteter Gewinn",
  ["host.profit"]: "Yu'e Bao",
  ["host.lockup"]: "Bergbau abstecken",
  ["host.liquidated"]: "Vorfälligkeitsentschädigung",
  ["host.redeem"]: "Erlösung",
  ["host.isitredeemed"]: "Es gibt eine Strafe für die vorzeitige Rückzahlung, ist es eine Rückzahlung?",
  ["host.consignmentOrder"]: "anvertrauter Auftrag",
  ["host.purchase"]: "kaufen",
  ["exchange.bond"]: "freie Marge",
  ["exchange.optionNo"]: "Ausgabe",
  ["exchange.endTime"]: "Abschluss dieser Ausgabe",
  ["exchange.Forecast"]: "Prognosezeitraum",
  ["enter the amount"]: "Bitte geben Sie den Betrag ein",
  ["Insufficient wallet balance"]: "Unzureichendes Wallet-Guthaben zum Kauf",
  ["minimum purchase amount is"]: "Der Mindestkaufbetrag beträgt",
  ["Purchase successful"]: "erfolgreicher Kauf",
  ["exchange.orderConifrm"]: "Bestellbestätigung",
  ["exchange.balance"]: "Verfügbares Guthaben",
  ["exchange.custormAmount"]: "benutzerdefinierte Menge",
  ["exchange.input"]: "Bitte eingeben",
  ["exchange.confirm"]: "Sicher",
  ["exchange.minute"]: "Minute",
  ["exchange.curentrust"]: "aktuelles Mandat",
  ["exchange.hisentrust"]: "Historischer Auftrag",
  ["exchange.noneEntrust"]: "Noch kein Mandat",
  ["exchange.wait"]: "auf Öffnung warten",
  ["exchange.opened"]: "offen",
  ["exchange.rescinded"]: "Widerrufen",
  ["exchange.type"]: "Typ",
  ["exchange.finashTime"]: "Geschäftsschluss",
  ["exchange.openingQuantity"]: "Anzahl offener Stellen",
  ["exchange.awards"]: "Anzahl Auszeichnungen",
  ["exchange.charge"]: "Positionseröffnungsgebühr",
  ["exchange.openPrice"]: "Eröffnungskurs",
  ["exchange.close"]: "Schlusskurs",
  ["exchange.prediction"]: "Positionsrichtung",
  ["exchange.pump"]: "Pumpen",
  ["hello"]: "Hallo",
  ["welcome"]: "Willkommen zu",
  ["change success"]: "Erfolgreich geändert",
  ["set success"]: "erfolgreich eingestellt",
  ["confirm"]: "Sicher",
  ["confirm1"]: "Sicher",
  ["confirm2"]: "Sicher",
  ["cancel"]: "Absagen",
  ["copySuccess"]: "Erfolgreich kopieren",
  ["please enter"]: "Bitte eingeben",
  ["no data"]: "Keine Daten",
  ["all"]: "alle",
  ["processing"]: "wird bearbeitet",
  ["credited"]: "gutgeschrieben",
  ["fail"]: "scheitern",
  ["timeout failed"]: "Zeitüberschreitung fehlgeschlagen",
  ["amount"]: "Menge",
  ["state"]: "Zustand",
  ["time"]: "Zeit",
  ["pwd_password"]: "Passwort",
  ["pwd_Enter password"]: "Bitte Passwort eingeben",
  ["pwd_old"]: "Altes Passwort",
  ["pwd_new"]: "Neues Passwort",
  ["pwd_new again"]: "Passwort bestätigen",
  ["pwd_Enter old password"]: "Bitte altes Passwort eingeben",
  ["pwd_Enter new password"]: "Bitte geben Sie ein neues Passwort ein",
  ["pwd_Enter new password again"]: "Bitte neues Passwort erneut eingeben",
  ["pwd_set"]: "Passwort festlegen",
  ["pwd_not empty"]: "Passwort darf nicht leer sein",
  ["pwd_length"]: "Die Passwortlänge darf nicht weniger als 6 Zeichen betragen",
  ["pwd_old not empty"]: "Altes Passwort darf nicht leer sein",
  ["pwd_new not empty"]: "Neues Passwort darf nicht leer sein",
  ["pwd_inconsistent twice"]: "Die zwei Passwörter stimmen nicht überein",
  ["home_msg"]: "Technologie ist die gemeinsame Seele derer, die sie entwickeln",
  ["home_recharge"]: "Schnelle Einzahlung",
  ["home_exchange"]: "Optionshandel",
  ["home_mining"]: "Bergbau abstecken",
  ["home_service"]: "Onlineservice",
  ["home_increaseList"]: "Liste der Gewinner",
  ["home_turnover"]: "Volumen",
  ["home_currencyName"]: "Name",
  ["home_newPrice"]: "Letzter Preis",
  ["home_vol24"]: "24-Stunden-Handelsvolumen",
  ["home_upDown"]: "Angebotsänderung",
  ["home_warrant"]: "Mainstream-Währung",
  ["trade_tab1"]: "Optional",
  ["trade_tab2"]: "Coin-M Futures",
  ["trade_tab3"]: "Exchange",
  ["trade_tab4"]: "Vertragsgeschäft",
  ["trade_tab5"]: "USDⓈ-M",
  ["trade_tab6"]: "zweiter Vertrag",
  ["trade.empty"]: "Keine Daten",
  ["edit_choice"]: "Die Wahl des Herausgebers",
  ["add_optional"]: "Optional hinzufügen",
  ["save"]: "sparen",
  ["select_all"]: "Wählen Sie Alle",
  ["delete"]: "löschen",
  ["please_choose"]: "bitte auswählen",
  ["sidebar_share"]: "Ich möchte teilen",
  ["sidebar_help"]: "Hilfezentrum",
  ["sidebar_service"]: "Onlineservice",
  ["exchange_selCurrency"]: "Währung wählen",
  ["exchange_searchText"]: "Bitte geben Sie das Suchwort ein",
  ["exchange_rule"]: "Regel",
  ["exchange_bond"]: "freie Marge",
  ["exchange_optionNo"]: "Ausgabe",
  ["exchange_endTime"]: "Abschluss dieser Ausgabe",
  ["exchange_forecast"]: "Prognosezeitraum",
  ["exchange_minute"]: "Minute",
  ["exchange_curentrust"]: "aktuelles Mandat",
  ["exchange_noneEntrust"]: "Noch kein Mandat",
  ["exchange_bullish"]: "bullisch",
  ["exchange_bearish"]: "bärisch",
  ["exchange_orderConifrm"]: "Bestellbestätigung",
  ["exchange_balance"]: "Verfügbares Guthaben",
  ["exchange_custormAmount"]: "benutzerdefinierte Menge",
  ["host_profit"]: "Yu'e Bao",
  ["host_lockup"]: "Bergbau abstecken",
  ["host_consignmentOrder"]: "anvertrauter Auftrag",
  ["host_purchase"]: "kaufen",
  ["mining_purchase"]: "kaufen",
  ["host_todayProfit"]: "Ergebnis heute",
  ["host_totalProfit"]: "Erwartete Einnahmen",
  ["host_limit"]: "Anlagegrenze",
  ["host_income"]: "Einkommen",
  ["host_funds in custody"]: "Gelder in Verwahrung",
  ["host_entrusted order"]: "anvertrauter Auftrag",
  ["host_expected earnings for today"]: "Erwarteter Gewinn für heute",
  ["host_cumulative income"]: "kumuliertes Einkommen",
  ["host_order in commission"]: "in Kommission bestellen",
  ["host_day"]: "Himmel",
  ["host_single limit"]: "einzelne Grenze",
  ["host_daily rate of return"]: "tägliche Rendite",
  ["host_cycle"]: "Kreislauf",
  ["host_processing"]: "wird bearbeitet",
  ["host_completed"]: "abgeschlossen",
  ["host.redeemed"]: "eingelöst",
  ["host_histotyProfit"]: "historische Einnahmen",
  ["host_illustrate"]: "veranschaulichen",
  ["host_order details"]: "Bestelldetails",
  ["host_single benefit"]: "einzigen Vorteil",
  ["host_single day income"]: "eintägiges Einkommen",
  ["host_total revenue"]: "Gesamteinnahmen",
  ["host_earnings Details"]: "Ergebnisdetails",
  ["host_paid"]: "Bezahlt",
  ["host_pay miner fees"]: "Miner-Gebühren zahlen",
  ["host_user output"]: "Benutzerausgabe",
  ["host_address"]: "die Anschrift",
  ["host_quantity"]: "Anzahl",
  ["host_time"]: "Zeit",
  ["host_account Balance"]: "Kontostand",
  ["account_total assets equivalent"]: "Gesamtvermögen entspricht",
  ["account_deposit"]: "Hinterlegung",
  ["account_withdraw"]: "Abheben",
  ["account_exchange"]: "Austausch",
  ["account_funding Account"]: "Finanzierungskonto",
  ["account_hide small coins"]: "Kleine Münzen verstecken",
  ["account_available"]: "verfügbar",
  ["account_processing"]: "wird bearbeitet",
  ["account_folded"]: "gefaltet",
  ["recharge"]: "aufladen",
  ["recharge_currency"]: "Währung aufladen",
  ["recharge_scan code transfer"]: "Code scannen und aufladen",
  ["recharge_amount"]: "Aufladebetrag",
  ["recharge_upload"]: "Screenshot der Zahlungsdetails hochladen",
  ["recharge_record"]: "Rekord aufladen",
  ["recharge_enter amount"]: "Bitte geben Sie den Aufladebetrag ein",
  ["recharge_less zero"]: "Der Aufladebetrag darf nicht kleiner als 0 sein",
  ["recharge_upload screenshot"]: "Bitte Screenshot hochladen",
  ["recharge_try again later"]: "Bild wird hochgeladen, versuchen Sie es später erneut",
  ["recharge_successful"]: "erfolgreiches Aufladen",
  ["recharge_Details"]: "Aufladedetails",
  ["recharge_currency2"]: "Währung",
  ["recharge_address"]: "Adresse aufladen",
  ["recharge_order number"]: "Bestellnummer",
  ["loading"]: "Wird geladen…",
  ["investment amount"]: "Anlagebetrag",
  ["expire date"]: "Ablaufdatum",
  ["order number"]: "Bestellnummer",
  ["start date"]: "Anfangsdatum",
  ["end date"]: "Endtermin",
  ["cash back"]: "Bargeld zurück",
  ["order"]: "Befehl",
  ["swap"]: "Austausch",
  ["swap_currency exchange"]: "Geldwechsel",
  ["swap_please enter exchange amount"]: "Bitte geben Sie den Wechselbetrag ein",
  ["swap_exchange quantity"]: "Austauschmenge",
  ["swap_up to"]: "bis zu",
  ["swap_redeem all"]: "Alle einlösen",
  ["swap_exchange rate"]: "Tauschrate",
  ["swap_available"]: "Verfügbar",
  ["withdraw"]: "abheben",
  ["withdrawal_currency"]: "Auszahlungswährung",
  ["withdrawal_Amount_Available"]: "Verfügbarer Betrag",
  ["withdrawal_Please_enter_amount"]: "Bitte geben Sie den Auszahlungsbetrag ein",
  ["withdrawal_remove_all"]: "alles entfernen",
  ["withdrawal_address"]: "Auszahlungsadresse",
  ["withdrawal_Please_enter_address"]: "Bitte geben Sie die Wallet-Adresse ein",
  ["withdrawal_password"]: "Widerrufspasswort",
  ["withdrawal_Please_enter_password"]: "Bitte geben Sie das Auszahlungspasswort ein",
  ["withdrawal_handling_fee"]: "Bearbeitungsgebühr",
  ["withdrawal_Reminder_text"]: "Erinnerung: Für die Auszahlung wird ein Teil der Bearbeitungsgebühr berechnet, die Sie innerhalb von 24 Stunden nach der Auszahlung erhalten. Bei Fragen wenden Sie sich bitte an den Kundendienst",
  ["withdrawal_under_review"]: "unter Überprüfung",
  ["withdrawal_success"]: "Auszahlungserfolg",
  ["withdrawal_examination_passed"]: "Prüfung bestanden",
  ["withdrawal_paying_on_behalf"]: "Bezahlen im Namen",
  ["withdrawal_payment_overtime"]: "Bezahlung von Überstunden",
  ["withdrawal_record"]: "Aufzeichnung der Auszahlungen",
  ["enter the bet amount"]: "Bitte geben Sie den Einsatzbetrag ein",
  ["minimum bet amount"]: "Der Mindesteinsatz beträgt",
  ["bet up to"]: "Der maximale Einsatzbetrag ist",
  ["number is being updated"]: "Aktualisierungsproblem, bitte versuchen Sie es später erneut",
  ["checkout success"]: "erfolgreich bestellt",
  ["exchange amount cannot be blank"]: "Umtauschbetrag darf nicht leer sein",
  ["exchange amount must be greater than 0"]: "Umtauschbetrag muss größer als 0 sein",
  ["successfully redeemed"]: "Sie haben den Austausch erfolgreich eingereicht, bitte warten Sie geduldig",
  ["enter the withdrawal amount"]: "Bitte geben Sie den Auszahlungsbetrag ein",
  ["amount cannot be less than 0"]: "Der Auszahlungsbetrag darf nicht kleiner als 0 sein",
  ["enter the withdrawal address"]: "Bitte geben Sie die Auszahlungsadresse ein",
  ["enter the withdrawal password"]: "Bitte geben Sie das Auszahlungspasswort ein",
  ["successful application"]: "Erfolgreiche Bewerbung",
  ["set the password first"]: "Bitte legen Sie zuerst ein Passwort fest",
  ["Withdrawal details"]: "Auszahlungsdetails",
  ["Withdrawal quantity"]: "Auszahlungsbetrag",
  ["Withdrawal address"]: "Auszahlungsadresse",
  ["share_invite"]: "Laden Sie Freunde zur Nutzung ein",
  ["share_link"]: "Einen Link teilen",
  ["share"]: "Teilen",
  ["add_success"]: "Erfolgreich hinzugefügt",
  ["mining.question1"]: "Was ist Staking-Mining? Woher kommen die Einnahmen?",
  ["mining.answer1"]: "Staking Mining ist ein Werkzeugprodukt, das vom Unternehmen entwickelt wurde, um Benutzern dabei zu helfen, sich schnell in der Kette zu verpflichten, Belohnungen zu erhalten. Durch das Staking digitaler Assets im Blockchain-Netzwerk und den Erhalt von Belohnungen basierend auf dem POS-Mechanismus (Proof of Stake, d. h. Proof of Stake). Bei diesem Mechanismus vertrauen Benutzer digitale Assets Nodes an, und Nodes üben die Rechte aus, Blöcke zu produzieren, Transaktionen zu verpacken usw. auf der Blockchain und Belohnungen zu erhalten. Die Benutzer teilen die von den Knoten erhaltenen Belohnungen proportional zur Anzahl der gesperrten Positionen.",
  ["mining.question2"]: "Was ist Referenz-Annualisierung? Wie man rechnet?",
  ["mining.answer2"]: "Die annualisierte Referenzrate ist die annualisierte Referenzrendite des von Ihnen abonnierten Produkts, die dynamisch gemäß den tatsächlichen Einnahmen in der Kette aktualisiert wird, nur zu Ihrer Information. Referenz-Annualisierung = On-Chain-Umsatz/On-Chain-Sperrmenge/On-Chain-Sperrzeit*365*100 %.",
  ["mining.question3"]: "Was ist die erwartete Rendite? Wie man rechnet?",
  ["mining.answer3"]: "Erwarteter Umsatz ist der täglich erwartete Umsatz, der basierend auf Ihrer Abonnementmenge und Ihrer Produktreferenz auf Jahresbasis berechnet wird, und dient nur zu Ihrer Information. Täglich erwartete Rendite = Anzahl der Abonnements * Referenz auf Jahresbasis / 365.",
  ["mining.question4"]: "Ab wann fallen Zinsen an?",
  ["mining.answer4"]: "Für Zeichnungen am Tag T werden die Zinsen ab 00:00 Uhr am Tag T+1 berechnet.",
  ["mining.question5"]: "Wann werden die Leistungen verteilt?",
  ["mining.answer5"]: "Zeichnung am T-Tag, Zinsen werden am T+1-Tag generiert und die Erträge werden zusammen mit dem Kapital nach Fälligkeit ausgeschüttet.",
  ["mining.question6"]: "Kann ich Laufzeitprodukte vorzeitig einlösen? Was ist die Auswirkung?",
  ["mining.answer6"]: "Sie können Ihre Bestellung jederzeit einlösen, aber wenn Sie das Laufzeitprodukt im Voraus einlösen, verlieren Sie einen Teil der bereits aus der Bestellung erzielten Einnahmen, und die ausgegebenen Einnahmen werden zum Zeitpunkt der Einlösung von Ihrem Kapital abgezogen.",
  ["mining.question7"]: "Muss ich das Produkt nach Ablauf der Laufzeit manuell einlösen?",
  ["mining.answer7"]: "Automatische Einlösung nach Ablauf des Laufzeitprodukts.",
  ["mining.question8"]: "1. Wie trete ich bei?",
  ["mining.answer8"]: "Um am zerstörungsfreien Liquiditätsschürfen teilzunehmen, müssen Sie die ETH als Miner-Gebühr bezahlen. Eine Kryptowährungs-Wallet-Adresse muss nur einmal beansprucht werden, und die Mining-Erlaubnis wird nach Erfolg automatisch geöffnet.",
  ["mining.question9"]: "2. Wie hebe ich Geld ab?",
  ["mining.answer9"]: "Sie können die täglich generierten Coins in USDT umwandeln und dann eine Auszahlung veranlassen. USDT-Auszahlungen werden automatisch an die Wallet-Adresse gesendet, die Sie dem Knoten hinzugefügt haben, andere Adressen werden nicht unterstützt.",
  ["mining.question10"]: "3. Wie wird das Einkommen berechnet?",
  ["mining.answer10"]: "Wenn Sie erfolgreich beitreten, beginnt der Smart Contract mit der Berechnung Ihrer Adresse über den Knoten und mit der Berechnung des Einkommens.",
  ["show_more"]: "Mehr sehen",
  ["promble"]: "häufiges Problem",
  ["what_yeb"]: "Was ist Yu'e Bao?",
  ["what_yeb_answer"]: "Yubibao ist ein Produkt, das vom Unternehmen entwickelt wurde, um Benutzern zu helfen, die über ungenutzte digitale Assets verfügen, und Benutzern, die sich Geld leihen müssen. Es hat die Eigenschaften von sofortiger Ein- und Auszahlung, stündlicher Zinsberechnung und Unterstützung für die Anpassung des Zinssatzes. Nachdem der Benutzer die digitalen Assets an Yubibao übertragen hat, bestimmt das System zu jeder Stunde, ob die Ausleihe gemäß dem vom Benutzer festgelegten Kreditzins erfolgreich ist. Nachdem die Ausleihe erfolgreich war, können die Zinsen für die Stunde erhalten werden. Andernfalls kann die Zinsen können nicht erhalten werden, und Sie müssen auf das nächste Mal warten.Eine Stunde Matching-Ergebnisse. Yubibao stützt sich auf ein strenges Risikokontrollsystem und garantiert die Sicherheit der Benutzertransaktionen vollständig.",
  ["what_lixi"]: "Wie werden Zinsen berechnet?",
  ["what_lixi_answer"]: "Zinsen (pro Tag) = Kreditbetrag * Jährliche Marktdarlehensrate / 365/24 * 85 %",
  ["rule_yeb_title1"]: "Was ist Yu'e Bao?",
  ["rule_yeb_cont1"]: "Yubibao ist ein Produkt, das vom Unternehmen entwickelt wurde, um Benutzern zu helfen, die über ungenutzte digitale Assets verfügen, und Benutzern, die sich Geld leihen müssen. Es hat die Eigenschaften von sofortiger Ein- und Auszahlung, stündlicher Zinsberechnung und Unterstützung für die Anpassung des Zinssatzes. Nachdem der Benutzer die digitalen Assets an Yubibao übertragen hat, bestimmt das System zu jeder Stunde, ob die Ausleihe gemäß dem vom Benutzer festgelegten Kreditzins erfolgreich ist. Nachdem die Ausleihe erfolgreich war, können die Zinsen für die Stunde erhalten werden. Andernfalls kann die Zinsen können nicht erhalten werden, und Sie müssen auf das nächste Mal warten.Eine Stunde Matching-Ergebnisse. Yubibao stützt sich auf ein strenges Risikokontrollsystem und garantiert die Sicherheit der Benutzertransaktionen vollständig.",
  ["rule_yeb_note1"]: "*Bitte beachten Sie: Historische Renditen sind kein Hinweis auf zukünftige Renditen. Wir versprechen nicht, den Kapitalbetrag und die Zinsen in Währung, physischen Objekten, Aktien oder anderen Formen von Vermögenserträgen innerhalb eines bestimmten Zeitraums zurückzuzahlen.",
  ["rule_yeb_title2"]: "Produktvorteile",
  ["rule_yeb_til2"]: "Robust",
  ["rule_yeb_cont2"]: "Yu'ebao stützt sich auf ein strenges Risikokontrollsystem und garantiert die Sicherheit der Vermögenswerte der Benutzer vollständig und ermöglicht es ihnen, ihre Gewinne unbesorgt zu genießen.",
  ["rule_yeb_title3"]: "Hinweise zur Anlage in verzinsliche Vermögenswerte",
  ["rule_yeb_cont3"]: "Nachdem Benutzer ihre Vermögenswerte an Yubibao übertragen haben, werden die gekauften digitalen Vermögenswerte an Benutzer des gehebelten Handels verliehen.",
  ["rule_yeb_title4"]: "Einkommensnachweis",
  ["rule_yeb_til4"]: "Einkommensregel",
  ["rule_yeb_cont4"]: "Wenn die Auktion erfolgreich ist, werden die Einnahmen des Vortages verzinst und am nächsten Tag um 02:00 Uhr abgerechnet.",
  ["rule_yeb_title5"]: "Beschreibung der Einkommenszusammensetzung",
  ["rule_yeb_cont5"]: "15 % der Kreditzinsen des Benutzers werden als Risikomarge verwendet, und 85 % werden dem Kreditbenutzer zugewiesen, d. h. die Zinsen, die der Kreditbenutzer erhalten kann, betragen: Kreditkapital * Aktuelle annualisierte Kreditvergabe / 365 / 24 * 85 %",
  ["rule_yeb_title6"]: "Transaktion Beschreibung",
  ["rule_yeb_cont61"]: "Unterstützen Sie das Abonnement jederzeit, beginnen Sie mit dem Bieten in der Stunde nach dem Abonnement",
  ["rule_yeb_cont62"]: "Zinsanpassungsregeln: Der Markt bietet stündlich gemäß der Kreditnachfrage, gemäß der „minimalen Darlehensannualisierung“ von niedrig nach hoch, und der höchste Wert der Auktion wird als „aktuelle Darlehensannualisierung“ verwendet.",
  ["rule_yeb_cont63"]: "Wenn die minimale Annualisierung des Darlehens < die Annualisierung des aktuellen Darlehens ist, wird die Annualisierung des aktuellen Darlehens für eine erfolgreiche Kreditvergabe verwendet",
  ["rule_yeb_cont64"]: "Wenn die minimale Annualisierung des Darlehens > die aktuelle Annualisierung des Darlehens ist, scheitert das Darlehen und es fallen keine Zinsen an",
  ["rule_yeb_cont65"]: "Wenn die minimale Annualisierung des Darlehens gleich der aktuellen Annualisierung des Darlehens ist, kann es teilweise verliehen werden oder das Darlehen kann scheitern",
  ["rule_yeb_title7"]: "Einlösungsregeln",
  ["rule_yeb_til7"]: "Automatische Rückzahlung bei Fälligkeit",
  ["rule_wk_title1"]: "Was ist Staking-Mining?",
  ["rule_wk_cont1"]: "Staking Mining ist ein Werkzeugprodukt, das vom Unternehmen entwickelt wurde, um Benutzern dabei zu helfen, sich schnell in der Kette zu verpflichten, Belohnungen zu erhalten. Durch das Staking digitaler Assets im Blockchain-Netzwerk und den Erhalt von Belohnungen basierend auf dem POS-Mechanismus (Proof of Stake, d. h. Proof of Stake). Bei diesem Mechanismus vertrauen Benutzer digitale Assets Nodes an, und Nodes üben die Rechte aus, Blöcke zu produzieren, Transaktionen zu verpacken usw. auf der Blockchain und Belohnungen zu erhalten. Die Benutzer teilen die von den Knoten erhaltenen Belohnungen proportional zur Anzahl der gesperrten Positionen.",
  ["rule_wk_note1"]: "*Bitte beachten Sie: Historische Renditen sind kein Hinweis auf zukünftige Renditen. Wir versprechen nicht, den Kapitalbetrag und die Zinsen in Währung, physischen Objekten, Aktien oder anderen Formen von Vermögenserträgen innerhalb eines bestimmten Zeitraums zurückzuzahlen.",
  ["rule_wk_title2"]: "Produktvorteile",
  ["rule_wk_til2"]: "Robust",
  ["rule_wk_cont2"]: "Staking Mining kann relativ stabile Belohnungen von Dritten mit verschiedenen Produktzeiträumen erhalten.",
  ["rule_wk_title3"]: "Hinweise zur Anlage in verzinsliche Vermögenswerte",
  ["rule_wk_cont3"]: "Staking Mining bedeutet, Ihre digitalen Assets auf der Blockchain zu verpfänden, um den Betrieb des Blockchain-Netzwerks zu unterstützen und entsprechende Belohnungen zu erhalten.",
  ["rule_wk_title4"]: "Einkommensnachweis",
  ["rule_wk_cont4"]: "Nach erfolgreicher Zeichnung am Tag T beginnt die Verzinsung um 00:00 Uhr am Tag T+1 und die Verzinsung erfolgt um 02:00 Uhr.",
  ["rule_wk_title5"]: "Transaktion Beschreibung",
  ["rule_wk_til5"]: "Regel kaufen",
  ["rule_wk_cont5"]: "Support-Abonnement jederzeit.",
  ["rule_wk_title6"]: "Einlösungsregeln",
  ["rule_wk_cont61"]: "Die Einlösung wird jederzeit unterstützt, und die Wartezeit für die Einlösung verschiedener Projekte ist unterschiedlich.",
  ["rule_wk_cont62"]: "Ist die Laufzeit noch nicht abgelaufen, wird sie vorzeitig getilgt und ein Teil der gezahlten Zinsen abgezogen.",
  ["rule_wk_cont63"]: "Einlösung nicht unterstützt",
  ["rule_wk_title7"]: "Risikowarnung",
  ["rule_wk_cont7"]: "Bei vorzeitiger Einlösung von regulären Produkten zieht das System einen Teil der bereits erzielten Einnahmen aus der Bestellung ab.",
  ["rule_fzywk_title1"]: "Liquiditätsabbau",
  ["rule_fzywk_cont1"]: "Liquidity Mining ist eine Vermögensverwaltungsfunktion, die vom Unternehmen entwickelt wurde, um Benutzer bei der Verwaltung digitaler Vermögenswerte und der Erzielung langfristiger und effizienter Renditen zu unterstützen. Der Benutzer klickt (bezahlt die Miner-Gebühr) und zahlt eine bestimmte Miner-Gebühr, um sofort Einnahmen zu erzielen. Der Benutzer muss nur einmal bezahlen, um einen dauerhaften Effekt zu erzielen, und es ist nicht erforderlich, in Zukunft erneut zu klicken, um zu zahlen. Das Einkommensverhältnis hängt davon ab auf das Wallet-Guthaben des Benutzers. Je höher das Guthaben, desto größer die Einkommensquote.",
  ["rule_fzywk_note"]: "Hinweis: (Nicht-Ausleihe) Verdienstdetails werden viermal täglich alle 6 Stunden gesendet. Sie können die detaillierten Verdienste über die Verdienstdetails anzeigen.",
  ["rule_fzywk_note1"]: "Hinweis: (Dieses Produkt ist derzeit ein Wohlfahrtsentwässerungsprodukt mit begrenztem Kontingent. In Zukunft wird den Benutzern die Bewertung digitaler Assets hinzugefügt, und dieses Produkt wird nur für qualifizierte Benutzer geöffnet, also gilt: Wer zuerst kommt, mahlt zuerst).",
  ["rule_fzywk_title2"]: "Produktvorteile",
  ["rule_fzywk_til2"]: "Robuste Vorteile",
  ["rule_fzywk_cont2"]: "Langfristige Vorteile können erzielt werden, ohne Geld zu verleihen, die Sicherheit des Benutzervermögens zu gewährleisten und Vorteile zu genießen, ohne Verluste zu riskieren.",
  ["rule_fzywk_title3"]: "Einkommensnachweis",
  ["rule_fzywk_til3"]: "Einkommensregel",
  ["rule_fzywk_cont3"]: "Es wird sofort nach erfolgreicher Zahlungsautorisierung wirksam und das Einkommen wird jeden Tag innerhalb eines festgelegten Zeitraums verteilt.Der Einkommenszyklus des Benutzers beträgt 6 Stunden, und innerhalb von 24 Stunden können insgesamt 4 Einkommen erzielt werden.",
  ["rule_fzywk_til3_1"]: "Getriebeeinkommensverhältnis",
  ["rule_fzywk_tab1"]: "Ausrüstung",
  ["rule_fzywk_tab2"]: "Anzahl",
  ["rule_fzywk_tab3"]: "Rendite",
  ["rule_fzywk_tab4"]: "Einnahmeeinheit",
  ["unlimited"]: "unbegrenzt",
  ["verified"]: "Verifiziert",
  ["verified_tips"]: "Um ein sicheres Kontoerlebnis zu gewährleisten, binden Sie bitte Ihre personenbezogenen Daten",
  ["verified_name"]: "Name",
  ["verified_input_name"]: "Bitte geben Sie Ihren Namen ein",
  ["verified_card"]: "amtliches Kennzeichen",
  ["verified_input_card"]: "Bitte geben Sie die ID-Nummer ein",
  ["photo_front"]: "Ausweisfoto",
  ["clearly_visible"]: "(Bitte achten Sie darauf, dass die Dokumente gut sichtbar sind)",
  ["front_photo"]: "Laden Sie die Vorderseite des Ausweisfotos hoch",
  ["reverse_photo"]: "Laden Sie die Rückseite Ihres Ausweisfotos hoch",
  ["standard"]: "Standard",
  ["missing"]: "fehlen",
  ["vague"]: "Vage",
  ["strong flash"]: "starker Blitz",
  ["confirm_submit"]: "Bestätigen und absenden",
  ["need to change"]: "Wenn Sie die Bindung ändern müssen, bitte",
  ["Contact Customer Service"]: "Wenden Sie sich an den Kundendienst",
  ["authentication succeeded"]: "Authentifizierung erfolgreich",
  ["please_information"]: "Bitte vervollständigen Sie die Angaben",
  ["only_symbol"]: "Schauen Sie sich einfach das aktuelle Handelspaar an",
  ["options"]: "Optionen",
  ["occupy"]: "Besetzen",
  ["bb_buy"]: "Kaufen",
  ["bb_sell"]: "verkaufen",
  ["bb_count"]: "Anzahl",
  ["bb_current_best_price"]: "Handeln Sie zum aktuellen Bestpreis",
  ["bb_turnover"]: "Umsatz",
  ["bb_Limit order"]: "Limit-Order",
  ["bb_market order"]: "Marktordnung",
  ["bb_successful operation"]: "Erfolgreiche Operation",
  ["bb_operation failed"]: "Operation fehlgeschlagen",
  ["bb_Hide other trading pairs"]: "Andere Handelspaare ausblenden",
  ["bb_price"]: "Preis",
  ["bb_volume"]: "Volumen",
  ["bb_default"]: "Ursprünglich",
  ["bb_buy order"]: "Bestellung kaufen",
  ["bb_sell order"]: "Auftrag verkaufen",
  ["bb_commission price"]: "Provisionspreis",
  ["bb_commission amount"]: "Provisionsbetrag",
  ["bb_Cancellation"]: "Stornierung",
  ["bb_total turnover"]: "Gesamtumsatz",
  ["bb_Average transaction price"]: "Durchschnittlicher Transaktionspreis",
  ["bb_Undo succeeded"]: "Widerrufen erfolgreich",
  ["bb_Undo failed"]: "Rückgängig machen fehlgeschlagen",
  ["bb_Deal done"]: "Geschäft abgeschlossen",
  ["bb_Revoked"]: "Widerrufen",
  ["bb_depth map"]: "Tiefenkarte",
  ["bb_actual price"]: "aktueller Preis",
  ["bb_to buy order"]: "Bestellung kaufen",
  ["bb_to sell order"]: "Auftrag verkaufen",
  ["bb_direction"]: "Richtung",
  ["bb_clinch"]: "ein Geschäft machen",
  ["user_login"]: "Benutzer-Anmeldung",
  ["password_placeholder"]: "Bitte Passwort eingeben",
  ["code_placeholder"]: "Bitte geben Sie den Bestätigungscode ein",
  ["login"]: "Einloggen",
  ["no account"]: "Sie haben kein Konto?",
  ["to register"]: "zu registrieren",
  ["Forgot password"]: "Passwort vergessen?",
  ["user register"]: "Benutzer Registration",
  ["username"]: "Nutzername",
  ["repassword_placeholder"]: "Bitte geben Sie das Passwort erneut ein",
  ["register"]: "registrieren",
  ["have account"]: "Sie haben bereits ein Konto?",
  ["to login"]: "gehen Sie zum Anmelden",
  ["address_placeholder"]: "Bitte geben Sie die Wallet-Adresse ein",
  ["password does not match"]: "Die zwei Passwörter stimmen nicht überein",
  ["username_msg"]: "Bitte geben sie einen Benutzernamen ein",
  ["ua_msg"]: "Bitte geben Sie den Benutzernamen/die Wallet-Adresse ein",
  ["register_failed"]: "Registrierung fehlgeschlagen",
  ["login_failed"]: "Login fehlgeschlagen",
  ["invalid_address"]: "Ungültige Adresse, bitte im Wallet öffnen und autorisieren",
  ["exchange_hisentrust"]: "Historischer Auftrag",
  ["sidebar_bindPassword"]: "Richten Sie ein Fondspasswort ein",
  ["sidebar_changePassword"]: "Ändern Sie das Fondskennwort",
  ["sidebar_setLoginPassword"]: "Login-Passwort festlegen",
  ["sidebar_changeLoginPassword"]: "Login-Passwort ändern",
  ["add_address"]: "Adresse binden",
  ["paste"]: "Einfügen",
  ["No amount available"]: "Kein Betrag verfügbar",
  ["Please commission amount"]: "Bitte geben Sie den Provisionsbetrag ein",
  ["country"]: "Staatsbürgerschaftsland",
  ["verified_input_country"]: "Bitte geben Sie die Nationalität ein",
  ["Please select a chain"]: "Bitte wählen Sie eine Kette aus",
  ["login_out"]: "austragen",
  ["login_out_sure"]: "Möchten Sie sich wirklich abmelden?",
  ["login_out_success"]: "erfolgreich beenden",
  ["login_out_faild"]: "Beenden fehlgeschlagen",
  ["login_out_cancel"]: "Ausfahrt abbrechen",
  ["real_name_withdrawal"]: "Bitte vervollständigen Sie die Überprüfung des Echtnamens so schnell wie möglich, bevor Sie abheben",
  ["going_long"]: "geh lang",
  ["open_empty"]: "offen",
  ["can_open_empty"]: "öffenbar",
  ["cash_deposit"]: "Rand",
  ["fund_transfer"]: "Überweisung",
  ["from"]: "aus",
  ["arrive"]: "ankommen",
  ["fund_account"]: "Finanzierungskonto",
  ["transaction_account"]: "Transaktionskonto",
  ["transfer_all"]: "alles übertragen",
  ["up_to"]: "bis zu",
  ["contract_account"]: "Vertragskonto",
  ["spot_account"]: "Spot-Konto",
  ["transfer_amount"]: "Bitte geben Sie den Überweisungsbetrag ein",
  ["max_transfer"]: "Überweisung bis",
  ["current_position"]: "aktuelle Position",
  ["minimum_transfer"]: "Der Mindestüberweisungsbetrag beträgt",
  ["sell"]: "Verkaufen",
  ["buy"]: "kaufen",
  ["null"]: "Null",
  ["income"]: "Einkommen",
  ["yield_rate"]: "Rendite",
  ["open_interest"]: "offenes Interesse",
  ["position_collateral_assets"]: "Sicherheitenwerte positionieren",
  ["average_opening_price"]: "Durchschnittlicher Eröffnungspreis",
  ["expected_strong_parity"]: "Erwartete starke Parität",
  ["guaranteed_asset_ratio"]: "Garantierte Vermögensquote",
  ["close_position"]: "Position schließen",
  ["adjustment_margin"]: "Anpassungsmarge",
  ["stop_profit"]: "Gewinn mitnehmen",
  ["stop_loss"]: "Verlust stoppen",
  ["profit_loss"]: "Take-Profit und Stop-Loss",
  ["many"]: "viele",
  ["cancel_bulk"]: "Stornierung in großen Mengen",
  ["trigger_price"]: "Auslösepreis",
  ["mark"]: "Kennzeichen",
  ["order_price"]: "Bestellpreis",
  ["total_entrustment"]: "Gesamtbetrag der Betrauung",
  ["latest_price"]: "Letzter Transaktionspreis",
  ["profit_price"]: "Take-Profit-Triggerpreis",
  ["loss_price"]: "Stop-Loss-Auslösepreis",
  ["profit_order"]: "Take-Profit-Order",
  ["loss_order"]: "Stop-Loss-Order",
  ["profit_tips_1"]: "Marktpreis zu",
  ["profit_tips_2"]: "Die Take-Profit-Order wird ausgelöst, wenn die Transaktion abgeschlossen ist, und der Gewinn wird voraussichtlich nach der Transaktion erzielt",
  ["profit_tips_3"]: "Wenn die Stop-Loss-Order ausgelöst wird, der erwartete Verlust nach der Transaktion",
  ["order_count"]: "Anzahl",
  ["market_price"]: "Marktpreis",
  ["limit_price"]: "Preis begrenzen",
  ["margin_call"]: "Nachschussaufforderung",
  ["margin_reduction"]: "Margenreduzierung",
  ["sustainable"]: "nachhaltig",
  ["increase_most"]: "höchstens zunehmen",
  ["reduce_most"]: "höchstens reduzieren",
  ["after_price"]: "Liquidationspreis nach Zugabe",
  ["add_lever"]: "Die zusätzliche Hebelwirkung",
  ["sub_price"]: "Reduzierter Liquidationspreis",
  ["sub_lever"]: "Reduzierte Hebelwirkung",
  ["commission_detail"]: "Einzelheiten zur Kommission",
  ["to_trade"]: "handeln",
  ["order_status"]: "Bestellstatus",
  ["delegate_type"]: "Delegattyp",
  ["volume"]: "Volumen",
  ["delegate_value"]: "Wert delegieren",
  ["commission_time"]: "Kommissionszeit",
  ["currency_least_purchase"]: "Mindestabnahme erforderlich",
  ["quick_close"]: "Schnell schließen",
  ["closing_price"]: "Schlusskurs",
  ["completely_sold"]: "Komplett verkauft",
  ["not_contract_transactions"]: "Die aktuelle Währung unterstützt keine Vertragstransaktionen",
  ["coinbase_h_l_hot"]: "Beliebte Währungen",
  ["coinbase_h_market"]: "Währungskurse",
  ["coinbase_h_coin"]: "Währung",
  ["coinbase_h_vol"]: "Handelsvolumen",
  ["coinbase_h_price"]: "Preis",
  ["coinbase_h_quote"]: "Angebotsänderung",
  ["coinbase_h_more"]: "Mehr sehen",
  ["coinbase_h_pt"]: "Von Benutzern vertrauenswürdige Handelsplattform für Kryptowährungen",
  ["coinbase_h_tit1"]: "Benutzersicherheitsfonds (SAFU)",
  ["coinbase_h_conn1"]: "Speichern Sie 10 % aller Transaktionsgebühren im Safe Asset Fund, um einen Teilschutz für Benutzergelder zu bieten",
  ["coinbase_h_tit2"]: "Personalisierte Zugangskontrolle",
  ["coinbase_h_conn2"]: "Die erweiterte Zugriffskontrolle schränkt den Zugriff auf Geräte und Adressen mit persönlichen Konten ein, sodass Benutzer beruhigt sein können",
  ["coinbase_h_tit3"]: "Erweiterte Datenverschlüsselung",
  ["coinbase_h_conn3"]: "Persönliche Transaktionsdaten werden durch Ende-zu-Ende-Verschlüsselung gesichert, und nur die Person kann auf persönliche Informationen zugreifen",
  ["coinbase_hq_title"]: "verwandte Frage",
  ["coinbase_hq_tit1"]: "1. Was ist Defi?",
  ["coinbase_hq_conn1"]: "Der vollständige Name von DeFi lautet Decentralized Finance, auch bekannt alsOffene Finanzen, fast allesDeFiProjekte werden alle auf der Ethereum-Blockchain durchgeführt. Ethereum ist eine globale Open-Source-Plattform für dezentrale Anwendungen. Auf Ethereum können Sie Code schreiben, um digitale Assets zu verwalten und Programme ohne geografische Einschränkungen auszuführen.",
  ["coinbase_hq_tit2"]: "2. Was sind die Vorteile der dezentralen Finanzierung?",
  ["coinbase_hq_conn2"]: "Die dezentrale Finanzierung nutzt die Schlüsselprinzipien der Blockchain, um die finanzielle Sicherheit und Transparenz zu verbessern, Liquiditäts- und Wachstumschancen freizusetzen und ein integriertes und standardisiertes Wirtschaftssystem zu unterstützen. Hochgradig programmierbare Smart Contracts automatisieren und unterstützen die Erstellung neuer Finanzinstrumente und digitaler Assets. Die manipulationssichere Datenorchestrierung über die dezentrale Blockchain-Architektur verbessert die Sicherheit und Überprüfbarkeit.",
  ["coinbase_hq_tit3"]: "3. Wie führt Defi Transaktionen aus?",
  ["coinbase_hq_conn3"]: "Defi unterstützt derzeit unbefristete Verträge, Lieferverträge und den quantitativen Handel mit Aluminium. Unbefristete Verträge sind eine Art „innovative“ Futures. Ein Vertrag, ein traditioneller Vertrag, hat ein Ablaufdatum, aber ein unbefristeter Vertrag hat kein Lieferdatum und kann für immer gehalten werden, daher wird er als unbefristeter Vertrag bezeichnet. Manueller quantitativer Handel bezieht sich auf die Verwendung bestehender Computertechnologie, um eine große Datenmenge in kurzer Zeit zu analysieren und dann automatisch über ein vorgefertigtes Handelsmodell zu handeln, um die Handelseffizienz zu verbessern. Dieser Computer wird verwendet, um Spreads an großen Börsen zu handeln.",
  ["coinbase_hq_tit4"]: "4. Warum Coinbase für den Handel wählen?",
  ["coinbase_hq_conn4"]: "Coinbase arbeitet in einem stark regulierten Umfeld und unternimmt zusätzliche Schritte, um sicherzustellen, dass Kundendaten geschützt sind, und nicht einmal die eigenen Data Scientists und Ingenieure von Coinbase haben freien Zugriff auf diese Daten. Jeder Code, der auf Coinbase-Produktionsservern läuft, muss einer Codeüberprüfung durch mehrere Gruppen unterzogen werden, bevor er in Produktion geht. „Eines unserer Grundprinzipien ist Sicherheit an erster Stelle, da digitale Assets, die auf unserer Plattform gespeichert sind, unseren Kunden gehören.",
  ["coinbase_hq_tit5"]: "5. Die Vision von Pangea Capital Management für seine Partnerschaft mit Coinbase.",
  ["coinbase_hq_conn5"]: "2019 Pankontinentales Kapitalmanagement. Finanzierung von 230 Millionen US-Dollar für den Eintritt in den Coinbase-Währungsmarkt, Übernahme der Führung beim Aufbau einer strategischen Partnerschaft mit Coinbase, der größten Coinbase-Währungsbörse in den Vereinigten Staaten, in Bezug auf Blockchain, und Zusammenarbeit mit Coinabse, um eingehende Untersuchungen zur dezentralen Defi-Finanzierung durchzuführen und bauen eine unabhängige Blockchain-Technologie und dezentrale Anwendungen auf. Führen Sie einen dezentralen Austausch von Coinbase-Währungen durch. Auftragsprüfungsstelle",
  ["coinbase_ho_tit1"]: "Vertragsprüfer",
  ["coinbase_ho_tit2"]: "Spot/Hebel/Kontrakt",
  ["coinbase_ho_tit3"]: "Staking Mining, DeFi usw.",
  ["coinbase_ho_tit4"]: "kurz davor, mit dem Handel zu beginnen",
  ["coinbase_ho_tit5"]: "Dezentrale Austauschplattform",
  ["coinbase_ho_tit6"]: "Handel jetzt",
  ["coinbase_ho_tit7"]: "Partner",
  ["coinbase_ho_tit8"]: "Geldbörse verbinden",
  ["coinbase_ho_tit9"]: "Ihr Browser unterstützt die Wallet-Erweiterung nicht",
  ["coinbase_hs_tit1"]: "Nutzungsbedingungen",
  ["coinbase_hs_tit2"]: "weißes Papier",
  ["coinbase_hs_tit3"]: "E-Mail-Authentifikation",
  ["coinbase_he_tit1"]: "E-Mail-Bindung",
  ["coinbase_he_tit2"]: "Post",
  ["coinbase_he_tit3"]: "Verifizierungs-Schlüssel",
  ["coinbase_he_tit4"]: "Senden Sie den Bestätigungscode",
  ["coinbase_he_tit5"]: "Bitte geben Sie Ihre E-Mail ein",
  ["coinbase_he_tit6"]: "Erneut senden",
  ["tokpiedex_he_tit3"]: "Verifizierungs-Schlüssel",
  ["opening time"]: "Öffnungszeit",
  ["view_desc"]: "Beschreibung ansehen",
  ["host_non-collateralized mining"]: "Mining definieren",
  ["air_activity"]: "Airdrop-Aktivität",
  ["total_output"]: "Gesamtleistung",
  ["valid_node"]: "gültiger Knoten",
  ["participant"]: "Teilnehmer",
  ["user_benefit"]: "Benutzervorteile",
  ["participate_mining"]: "Nehmen Sie am Bergbau teil",
  ["take_over"]: "übernehmen",
  ["divide"]: "teilen",
  ["balance_reaches"]: "Wenn das Wallet-Guthaben erreicht ist",
  ["you_get"]: "Sie erhalten",
  ["mining_pool"]: "Belohnungen aus Mining-Pools",
  ["valid_time"]: "Gültige Uhrzeit erhalten:",
  ["air_activity_help_1"]: "Ein Smart Contract ist ein Computerprotokoll, das entwickelt wurde, um einen Vertrag auf informative Weise zu verbreiten, zu verifizieren oder auszuführen. Intelligente Verträge ermöglichen nachvollziehbare und irreversible vertrauenswürdige Transaktionen ohne Dritte. Das Konzept der Smart Contracts wurde erstmals 1995 von Nick Szabo vorgeschlagen. Der Zweck von Smart Contracts besteht darin, eine sichere Methode bereitzustellen, die traditionellen Verträgen überlegen ist und andere mit Verträgen verbundene Transaktionskosten reduziert. Smart Contracts laufen völlig autonom, ohne menschliches Eingreifen, und sind fair und gerecht.",
  ["air_activity_help_2"]: "Airdrops laufen autonom mit Ethereum Smart Contracts, und nicht jeder Benutzer ist berechtigt, an Airdrops teilzunehmen. Wenn Sie eine Airdrop-Aufgabe für einen Smart Contract erhalten, müssen Sie nur die Aufgabenbedingungen erfüllen, um die Belohnung zu erhalten.",
  ["air_activity_help_3"]: "Die Standards von Airdrop-Missionen sind unterschiedlich und die erhaltenen Belohnungen sind unterschiedlich.",
  ["submit_success"]: "erfolgreich eingereicht",
  ["verified_wait"]: "Informationen werden überprüft",
  ["air_activity_msg_1"]: "Vielen Dank für Ihre Teilnahme, die ETH-Belohnung wurde Ihrem Kontoguthaben gutgeschrieben",
  ["promotion_center"]: "Förderzentrum",
  ["invite_friende"]: "Lade Freunde ein, um gemeinsam Münzen zu verdienen",
  ["invite_friends_commission"]: "Freunde einladen, sofort aufladen und Provision erhalten",
  ["promotion_rule"]: "Werberegeln anzeigen",
  ["my_promotion"]: "meine Beförderung",
  ["total_promotions"]: "Gesamtzahl der Werbeaktionen",
  ["commission_amount"]: "Provisionsbetrag",
  ["generation_p"]: "Generation",
  ["second_generation_p"]: "Nummer der zweiten Generation",
  ["three_generations_p"]: "Drei Generationen",
  ["invitation_code_y"]: "Ihr Einladungscode",
  ["sponsored_link"]: "gesponserte Links",
  ["click_share"]: "Zum Teilen klicken",
  ["commission_details"]: "Provisionsdetails",
  ["generation"]: "Generation",
  ["second_generation"]: "zweite Generation",
  ["three_generations"]: "drei Generationen",
  ["user_ID"]: "Benutzeridentifikation",
  ["rebate_amount"]: "Rabattbetrag",
  ["registration_time"]: "Anmeldezeit",
  ["QRcode_register"]: "Scannen Sie den QR-Code, um sich zu registrieren",
  ["click_save"]: "klicken Sie auf Speichern",
  ["share_amount"]: "Menge",
  ["invitation_code"]: "Einladungscode (optional)",
  ["option_buy_up"]: "aufkaufen",
  ["option_buy_short"]: "kurz kaufen",
  ["option_countdown"]: "Countdown",
  ["option_purchase_price"]: "Kaufpreis",
  ["option_profitability"]: "Rentabilität",
  ["option_minimum_amount"]: "Mindestbetrag",
  ["option_profit_loss"]: "Geschätzter Gewinn und Verlust",
  ["option_latest_price"]: "letzter Preis",
  ["option_now_price"]: "derzeitiger Preis",
  ["option_profit_loss_1"]: "Gewinn-und Verlust",
  ["option_profit_loss_2"]: "Mindestkaufbetrag",
  ["option_profit_loss_3"]: "Der Endpreis unterliegt der Systemabrechnung.",
  ["host_single_limit_1"]: "Quote",
  ["record_tip"]: "Hinweis",
  ["record_tip_1"]: "Sehr geehrter Kunde, Ihre verfügbaren Einkäufe sind aufgebraucht, wenden Sie sich bitte an den Kundendienst",
  ["available_time"]: "Verfügbare Zeiten",
  ["about_us"]: "über uns",
  ["home_tip_agree"]: "Mit der Registrierung stimme ich zu",
  ["home_tip_terms"]: "Nutzungsbedingungen",
  ["home_tip_agreement"]: "Datenschutz-Bestimmungen",
  ["home_tip_score"]: "Kreditwürdigkeit",
  ["home_tip_service_email"]: "Offizielle Kundendienst-E-Mail",
  ["home_tip_and"]: "und",
  ["quick_label_1"]: "Fällige Abrechnung abgeschlossen",
  ["quick_label_2"]: "derzeitiger Preis",
  ["quick_label_3"]: "Kreislauf",
  ["quick_label_5"]: "Anzahl",
  ["quick_label_6"]: "Preis",
  ["quick_label_7"]: "erwarteter Gewinn",
  ["coinbase2_buy_tip"]: "Bitte kontaktieren Sie den Kundendienst, um zu kaufen",
  ["upload_file_error"]: "Die Dateigröße überschreitet 1,5 M und kann nicht hochgeladen werden. Bitte ändern Sie das hochzuladende Bild",
  ["tokpiedex_home_recharge"]: "Schnelle Einzahlung",
  ["tokpiedex_trade_tab6"]: "zweiter Vertrag",
  ["defi_host_non-collateralized mining"]: "Mining definieren",
  ["defi_host_lockup"]: "Bergbau abstecken",
  ["defi_participate_mining"]: "Nehmen Sie am Bergbau teil",
  ["defi_host_non-collateralized"]: "Mining definieren",
  ["defi_h_pt"]: "Von Benutzern vertrauenswürdige Handelsplattform für Kryptowährungen",
  ["defi_hq_tit4"]: "4. Warum Coinsaving für den Handel wählen?",
  ["defi_hq_tit5"]: "5. Die Vision von Pangea Capital Management für seine Partnerschaft mit Coinsaving.",
  ["defi_hq_conn4"]: "Coinsaving arbeitet in einem stark regulierten Umfeld und unternimmt zusätzliche Schritte, um sicherzustellen, dass Kundendaten geschützt sind, und nicht einmal die eigenen Data Scientists und Ingenieure von Coinsaving haben freien Zugriff auf diese Daten. Jeder Code, der auf Coinsaving-Produktionsservern läuft, muss einer Codeüberprüfung durch mehrere Gruppen unterzogen werden, bevor er in Produktion geht. „Eines unserer Grundprinzipien ist Sicherheit an erster Stelle, da digitale Assets, die auf unserer Plattform gespeichert sind, unseren Kunden gehören.",
  ["defi_hq_conn5"]: "2019 Pankontinentales Kapitalmanagement. Finanzierung von 230 Millionen US-Dollar für den Eintritt in den Coinsaving-Währungsmarkt, Übernahme der Führung beim Aufbau einer strategischen Partnerschaft mit Coinsaving, der größten Coinsaving-Währungsbörse in den Vereinigten Staaten, in Bezug auf Blockchain, und Zusammenarbeit mit Coinabse, um eingehende Untersuchungen zur dezentralen Defi-Finanzierung durchzuführen und bauen eine unabhängige Blockchain-Technologie und dezentrale Anwendungen auf. Führen Sie einen dezentralen Austausch von Coinsaving-Währungen durch. Auftragsprüfungsstelle",
  ["rachange_link_huobi"]: "Huobi offizieller Aufladekanal",
  ["rachange_link_binance"]: "Binance Offizieller Einzahlungskanal",
  ["rachange_link_coinbase"]: "Coinbase offizieller Aufladekanal",
  ["rachange_link_crypto"]: "Crypto offizieller Aufladekanal",
  ["recharge_hash"]: "Transaktions-ID",
  ["recharge_hash_placeholder"]: "Bitte geben Sie die Transaktions-ID ein",
  ["symbol_closed"]: "Abgeschlossen",
  ["email_placeholder"]: "Bitte geben Sie Ihre E-Mail ein",
  ["email_title"]: "Post",
  ["email_code"]: "Verifizierungs-Schlüssel",
  ["email register"]: "E-Mail Registrierung",
  ["email login"]: "E-Mail-Anmeldung",
  ["email_code_placeholder"]: "Bitte geben Sie den E-Mail-Bestätigungscode ein",
  ["use_email_change"]: "Verwenden Sie E-Mail zum Ändern",
  ["use_password_change"]: "Verwenden Sie zum Ändern das ursprüngliche Kennwort",
  ["tip_change"]: "Hinweis: Wenn die Mailbox nicht gebunden ist oder die Mailbox verloren geht, bitte",
  ["forgot_password"]: "Passwort vergessen",
  ["i_know"]: "Ich verstehe",
  ["regulatory_permission"]: "Finanzielle Lizenz",
  ["regulatory_conn_1"]: "Die PLATFORM_TITLE Group unterliegt an vielen Orten der Welt der strengen Aufsicht maßgeblicher Finanzinstitute, einschließlich der Australian Securities and Investments Commission (Regulierungsnummer: 001296201) und der Canadian Financial Market Conduct Authority (Regulierungsnummer: M19578081). PLATFORM_TITLE hält sich vollständig an die strengen Finanzvorschriften der Australian Securities and Investments Commission und der Canadian Financial Market Conduct Authority. Unter der Aufsicht von Regulierungsbehörden garantiert PLATFORM_TITLE die absolute Transparenz aller seiner Transaktionen und ist einer der zuverlässigsten Finanzderivatehändler der Welt.",
  ["regulatory_conn_2"]: "Versign ist der globale Netzwerksicherheitspartner der PLATFORM_TITLE Capital Group.Das Gateway verwendet den branchenweit höchsten Standard fortschrittlicher Verschlüsselungstechnologie mit bis zu 256 Bit, um die Online-Sicherheit von Kunden und die normale Durchführung großer Online-Transaktionen zu gewährleisten, damit Kunden sicher und zuverlässig arbeiten können Abwicklung von Transaktionen auf der offiziellen Website von PLATFORM_TITLE Ein- und Auszahlungen und Änderung von Kundeninformationen und anderen Verfahren. Verisign ist ein ausgezeichneter Anbieter von Basisdiensten, der Dienste bereitstellt, die den reibungslosen Ablauf von Hunderten von Millionen von Online-Transaktionen jeden Tag sicherstellen können. Verisign ist der maßgebliche Verzeichnisanbieter für alle .com-, net-, .cc- und .tv-Domänennamen mit dem weltweit größten unabhängigen SS7-Netzwerk. Jeden Tag überwacht Verisign weltweit mehr als 1,5 Milliarden Sicherheitsprotokolle und sichert mehr als 500.000 Webserver.",
  ["regulatory_conn_3"]: "Die anerkannte und preisgekrönte Analysemethode von Trading Central verwendet bewährte technische Indikatoren. Ganz gleich, ob es sich um Intraday-Trading, Swing-Trading oder langfristige Investitionen handelt, Trading Central kann Anlegern Diagramme und mathematische Analysen zur Verfügung stellen, die den Anforderungen verschiedener Anlagestile entsprechen.",
  ["regulatory_conn_4"]: "In mehr als 30 Ländern auf der ganzen Welt haben sich mehr als 100 führende globale Finanzinstitute dafür entschieden, die von Trading Central bereitgestellte Anlageforschung und technische Analyse zu nutzen.",
  ["regulatory_conn_5"]: "TRADING CENTRAL ist auch ein akkreditiertes Mitglied von drei Verbänden unabhängiger Forschungsanbieter (IRP): Investorside, European IRP und Asia IRP. TRADING CENTRAL bietet unabhängiges Finanzresearch, das sich an den Interessen der Anleger ausrichtet und frei von Interessenkonflikten im Investmentbanking ist.",
  ["username_placeholder"]: "Benutzername/Wallet-Adresse/E-Mail",
  ["air_title"]: "Belohnungen für Mining-Pool-Aktivitäten",
  ["air_content"]: "Mining-Pool-Aktivitätsbelohnung Nr.: 951 ERC-20 Smart Pledge Contract, Sie können ihn jetzt erhalten, wenn Sie teilnehmen",
  ["pulling_text"]: "Zum Aktualisieren nach unten ziehen...",
  ["loosing_text"]: "Zum Aktualisieren loslassen...",
  ["pledge_amount"]: "Smarte Finanzierungssumme",
  ["deposit_amount"]: "Einzahlungsbetrag erforderlich",
  ["tokpiedex_home_increaseList"]: "Liste der Gewinner",
  ["tokpiedex_home_turnover"]: "Volumen",
  ["tokpiedex_trade_tab1"]: "Optional",
  ["tokpiedex_home_service"]: "Onlineservice",
  ["cwg_host_non-collateralized mining"]: "Mining definieren",
  ["help_loan"]: "Darlehen helfen",
  ["loan_tip"]: "Nach der Plattformprüfung können Sie einen Kredit von der Plattform beantragen!",
  ["loan_amount"]: "voraussichtliche Kredithöhe",
  ["repayment_cycle"]: "Rückzahlungszyklus",
  ["daily_rate"]: "Tagesrate",
  ["repayment"]: "Rückzahlung",
  ["interest"]: "Interesse",
  ["repayment_principal_maturity"]: "Eine Rückzahlung fällig",
  ["lending_institution"]: "Kreditinstitut",
  ["credit_loan"]: "Kreditdarlehen (bitte darauf achten, dass das Bild gut sichtbar ist)",
  ["upload_passport_photo"]: "Laden Sie ein Passfoto hoch",
  ["approval"]: "Genehmigung",
  ["approval_failed"]: "Genehmigung fehlgeschlagen",
  ["reps"]: "Rückzahlung",
  ["overdue"]: "überfällig",
  ["loan_money"]: "Darlehensbetrag",
  ["repayment_date"]: "Rückzahlungsdatum",
  ["mechanism"]: "Kreditinstitut",
  ["loan_record"]: "Kreditaufnahme",
  ["primary_certification"]: "Primärzertifizierung",
  ["advanced_certification"]: "Fortgeschrittene Zertifizierung",
  ["certificate_type"]: "Art des Zertifikats",
  ["example"]: "Beispiel",
  ["passport"]: "Reisepass",
  ["id_card"]: "Ausweis",
  ["driver_license"]: "Führerschein",
  ["show_passport_photo"]: "Ausweisfoto",
  ["show_front_photo"]: "Ausweisfoto",
  ["show_reverse_photo"]: "Ausweisfoto",
  ["minimum_borrowing"]: "minimale Kreditaufnahme",
  ["copy_link"]: "Kopieren",
  ["to_be_confirmed"]: "zu bestätigen",
  ["notify"]: "benachrichtigen",
  ["account_frozen"]: "Das Konto wurde eingefroren, bitte wenden Sie sich an den Kundendienst.",
  ["mining_pool_rewards"]: "Mining-Pool-Belohnungen",
  ["output"]: "Ausgang",
  ["required"]: "Aufladen erforderlich",
  ["reward"]: "vergeben",
  ["popup_miniing_title"]: "Bewerben Sie sich für Mining-Pool-Prämien",
  ["popup_miniing_conn1"]: "ERC-20 Smart Contract",
  ["popup_miniing_conn2"]: "Der ERC-20 Smart Contract Mining Pool steht kurz vor dem Start des nächsten ETH-Sharing-Events. Bewerben Sie sich jetzt um mitzumachen. Nach Beginn des Events können Liquiditätsanbieter mehrere Belohnungen teilen",
  ["popup_miniing_btn"]: "Bewerben Sie sich für eine Auszeichnung",
  ["my_account"]: "mein Konto",
  ["mining_status"]: "Zustand",
  ["wallet_balance"]: "Wallet-Guthaben",
  ["activity"]: "Aktivität",
  ["additional_rewards"]: "Zusätzliche Belohnungen",
  ["pledge"]: "versprechen",
  "loan_swiper1": "Ihre finanziellen Probleme sind das, was wir lösen möchten.",
  "loan_swiper2": "Keine Notwendigkeit für Sicherheiten oder Bürgen, schnellste Auszahlung in 3 Stunden!",
  "now_loan": "Jetzt Kredit beantragen",
  "loan_tip1": "Solange Sie Trust, Coinbase, MetaMask, Math Wallet oder BitKeep Digital Wallets verwenden und zwischen 18 und 60 Jahren alt sind, können Sie sich bei uns bewerben.",
  "loan_tip2": "Persönliche Darlehen in USDT (USD) mit einem jährlichen Zinssatz von nur",
  "loan_tip3": "% und bis zu",
  "loan_tip4": "Ratenzahlungen. Egal, ob Sie einen Kredit für Notausgaben, Hausreparaturen, Geschäftskapital oder die Konsolidierung von Schulden benötigen, unsere persönlichen Darlehensdienste können Ihnen in fast jeder Situation helfen.",
  "max_loan_amount": "Höchstdarlehensbetrag",
  "rate_as_low_to": "Zinssatz ab",
  "max_period": "Bis zu",
  "open_an_account": "Konto eröffnen",
  "loan_record": "Kreditakte",
  "personal_data": "Persönliche Daten",
  "go": "Starten",
  "complete": "Abschließen",
  "not_filled_in": "Nicht ausgefüllt",
  "job_info": "Berufsinformationen",
  "beneficiary_info": "Begünstigtendaten",
  "low_exchange_rate": "Niedriger Wechselkurs",
  "quick_arrival": "Schnelle Auszahlung",
  "mortgage_free": "Ohne Hypothek",
  "user_loan": "Benutzerdarlehen",
  "address": "Adresse",
  "successful_loan_amount": "Erfolgreicher Darlehensbetrag",
  "company_qualification": "Unternehmensqualifikation",
  "view": "Ansehen",
  "common_questions": "Häufig gestellte Fragen",
  "loan_question1": "Wie schütze ich mich vor Betrug!",
  "loan_answer1": "Die am häufigsten gemeldete Betrugskategorie ist die Identitätsdiebstahl, wobei Vorauszahlungsbetrug am häufigsten ist. Betrüger versprechen oft hohe Kredite oder Kreditkarten, um arglose Verbraucher zu verschiedenen Vorauszahlungen zu verleiten. Gängige Ausreden für Vorauszahlungen sind Einlagen, Steuern, Garantien, Geheimhaltungsgebühren, Mitgliedschaftsgebühren, Plattformgebühren, Materialkosten, Arbeitskosten, Entfrierungsgebühren, Verwaltungsgebühren und Bearbeitungsgebühren, unter anderem. Hinweis: Kein seriöses Kreditunternehmen oder Bank wird von Verbrauchern verlangen, Gebühren im Voraus zu zahlen, bevor das Kreditgeld oder die Kreditkarten ankommen. Achten Sie darauf, keine Kredite oder Kreditkarten zu akzeptieren, die von Ihnen verlangen, Gebühren im Voraus zu zahlen, um nicht betrogen zu werden!",
  "loan_question2": "Was ist ein persönliches Darlehen?",
  "loan_answer2": "Ein persönliches Darlehen ermöglicht es Ihnen, Geld von einem Kreditgeber für nahezu jeden legitimen Zweck zu leihen, in der Regel mit einer festen Laufzeit, einem festen Zinssatz und einem regelmäßigen monatlichen Rückzahlungsplan.",
  "loan_question3": "Schritte im Antragsprozess für persönliche Darlehen",
  "loan_answer3": "Kreditantragsteller können den Antragsprozess in nur zwei Schritten abschließen. 1: Wählen Sie den gewünschten Darlehensbetrag, registrieren Sie sich und geben Sie Ihre persönlichen Informationen ein. 2: Authentifizierung und digitale Brieftaschenunterschrift, Überprüfung und Validierung Ihrer Brieftasche. Unternehmensüberprüfungsschritte: 1: Umfassende Analyse und Bewertung der Informationen des Antragstellers. 2: Überprüfung der Kreditwürdigkeit des Antragstellers und seiner Rückzahlungsfähigkeit. 3: Überprüfung und Entscheidung über die Verringerung oder Erhöhung des Darlehensbetrags. 4: Vertragsunterzeichnung und Auszahlung des Darlehens. 5: Der Kreditgeber tilgt das Darlehen und die Zinsen.",
  "loan_question4": "Wie lange dauert es, ein persönliches Darlehen nach Antragstellung zu erhalten?",
  "loan_answer4": "Die meisten Benutzerbewertungen dauern nur 2 Stunden, und die USDT-Fonds werden in der Regel innerhalb einer Stunde nach der Überprüfung in Ihrer Brieftasche gutgeschrieben. (Normale Benutzer erhalten das Darlehen in der Regel in weniger als 3 Stunden, von der Antragstellung bis zur Auszahlung.)",
  "loan_question5": "Bin ich für ein persönliches Darlehen berechtigt?",
  "loan_answer5": "Um für ein Loan-Persönliches Darlehen in Frage zu kommen, müssen Sie lediglich ein gesunder Erwachsener im Alter von 18 bis 60 Jahren mit Ihrer eigenen digitalen Geldbörse sein. Ihr Darlehensantrag wird auf Grundlage mehrerer Faktoren bewertet, einschließlich der von Ihnen zum Zeitpunkt der Antragstellung bereitgestellten Informationen, Ihrer Kreditwürdigkeit und Ihrer Rückzahlungsfähigkeit. In Bezug auf den minimalen Zinssatz von 3 %, solange dies Ihr erster Antrag ist, Ihre Kreditwürdigkeit normal ist und Ihre Kredithistorie gut ist, sind Sie bereit.",
  "loan_question6": "Kreditvereinbarung!",
  "loan_answer6": "Die meisten Benutzerbewertungen dauern nur 2 Stunden, und das USDT-Darlehen wird innerhalb einer Stunde nach der Überprüfung in Ihrer Brieftasche gutgeschrieben (normale Benutzer erhalten das Darlehen in der Regel in weniger als 3 Stunden, von der Antragstellung bis zur Auszahlung).",
  "loan_question7": "Muss ich Gebühren bezahlen, bevor ich das Darlehen erhalte?",
  "loan_answer7": "Keine Vorauszahlungen erforderlich. (Kein seriöses Kreditunternehmen oder Bank wird von Ihnen verlangen, Gebühren im Voraus zu zahlen, bevor Sie die Kreditmittel erhalten. Einige Hochzins-Kleinkreditunternehmen ziehen die entsprechenden Gebühren direkt vom Darlehenskapital ab. Beachten Sie, dass, wenn Gebühren im Voraus an das Kreditunternehmen gezahlt werden müssen, es sich auf jeden Fall um Betrüger handelt !!!)",
  "loan_question8": "Warum leihen wir in Form von Kryptowährung USDT statt USD?",
  "loan_answer8": "1: Der Wert von USDT entspricht dem Wert des US-Dollars, wobei 1 USDT 1 USD entspricht. 2: Da unser Unternehmen weltweit tätig ist, sind Kryptowährungen universell, effizient, schnell, bequem und bieten Echtzeitzugang zu Geldern. Außerdem können Sie USDT auf fast allen Kryptowährungsplattformen leicht in Ihre lokale Währung umtauschen und auf Ihr Bankkonto überweisen. 3: Es gibt nur wenige grenzüberschreitende persönliche Kreditkanäle, und die Genehmigung ist in der Regel aufgrund der vielen Gesetze in verschiedenen Ländern, sowie Probleme im Zusammenhang mit der Besteuerung, der Währungsaufwertung und -abwertung und den Bedingungen und Beschränkungen der Banken in verschiedenen Ländern schwierig. 4: Die Verwendung von Kryptowährungen ist weltweit weit verbreitet, und in vielen Ländern können Kryptowährungen für verschiedene Zahlungen verwendet werden, einschließlich Einkäufen und Hotels. 5: Die Sicherheit von Kryptowährungen ist sehr stabil und zuverlässig und übertrifft sogar das Bankensystem.",
  "loan_question9": "Wie erfolgt die Rückzahlung?",
  "loan_answer9": "Der persönliche Darlehensvertrag, den wir mit Ihnen abschließen, legt fest, dass Ihre signierte digitale Geldbörse den Betrag, den Sie am Rückzahlungstag zurückzahlen müssen, automatisch abbucht. Sie müssen lediglich den für die Rückzahlung am Rückzahlungstag vorgesehenen USDT-Betrag reservieren.",
  "loan_question10": "Kann ich Teilzeit als Verkäufer für Ihr Unternehmen arbeiten?",
  "loan_answer10": "1: Ja. Da wir eine weltweite Benutzerbasis bedienen, stellen wir kontinuierlich Teilzeit-Promoter ein. 2: Teilzeit-Promoter erhalten kein Grundgehalt oder andere Vergünstigungen; Ihr Gewinn kommt nur von einer einmaligen Provision von 2 % auf den Kreditbetrag der Kunden, die Sie einladen.",
  "our_advantages": "Unsere Vorteile",
  "high_quota": "Hoher Kreditrahmen",
  "big_company": "Großes Unternehmen",
  "without_guarantee": "Ohne Sicherheit",
  "fast_arrival": "Schnelle Auszahlung",
  "convenient_and_fast": "Bequem und schnell",
  "lend_money": "Geld leihen",
  "verify_amount": "Betrag überprüfen",
  "verify_money": "Überprüfungsbetrag",
  "wallet_balance": "Wallet-Guthaben",
  "need_to_add_amount": "Betrag hinzufügen",
  "loan_term_month": "Kreditlaufzeit in Monaten",
  "loan_amount_and_details": "Kreditbetrag und Details",
  "total_interest_rate": "Gesamtzinsrate",
  "monthly_interest": "Monatszinsen",
  "total_interest": "Gesamtzinsen",
  "loan_start_and_end_dates": "Start- und Enddaten des Darlehens",
  "date_of_first_renovation": "Datum der ersten Renovierung",
  "loan_term": "Kreditlaufzeit",
  "loan_agree": "Ich habe die",
  "loan_agreement": "Kredit- und Bürgschaftsvertrag",
  "loan_confirm": "Vereinbarung akzeptieren und bestätigen",
  "verify_title": "Informationen eingeben",
  "enter_name": "Geben Sie Ihren Namen ein",
  "enter_phone": "Geben Sie Ihre Telefonnummer ein",
  "enter_age": "Geben Sie Ihr Alter ein",
  "select_gender": "Geschlecht auswählen",
  "enter_email": "Geben Sie Ihre E-Mail-Adresse ein",
  "enter_current_address": "Geben Sie Ihre aktuelle Adresse ein",
  "option_marital_status": "Familienstand auswählen",
  "option_education_attainment": "Bildungsabschluss auswählen",
  "front_of_documents": "Vorderseite des Ausweises",
  "back_of_documents": "Rückseite des Ausweises",
  "confirm_personal_infomation": "Persönliche Informationen bestätigen",
  "work_infomation": "Berufsinformationen",
  "option_type_of_job": "Art des Jobs auswählen",
  "option_total_working_years": "Gesamtberufsjahre auswählen",
  "option_monthly_income": "Monatliches Einkommen auswählen",
  "monthly_household_income": "Monatliches Haushaltseinkommen",
  "confirm_work_information": "Berufsinformationen bestätigen",
  "beneficiary_information": "Begünstigteninformationen",
  "beneficiary_address_trc20": "Begünstigtenadresse (TRC20)",
  "confirm_beneficiary_information": "Begünstigteninformationen bestätigen",
  "confirm": "Bestätigen",
  "cancel": "Abbrechen",
  "loan_title": "Darlehen",
  ["message_center"]: "Nachrichtenzentrum",
  "activity-top-title": "ETH Airdrop-Belohnungen",
"activity-top-big-title": "1 Million ETH Airdrop-Belohnungen kommen",
"activity-top-tip": "Vervollständigen Sie einfache Aufgaben auf der Mining-Pool-Knotenseite Ihres DeFi-Renditeprojekts, um an einer riesigen Airdrop-Belohnung teilzuhaben.",
"activity-end": "Aktivität beendet",
"activity-join": "Aktivität beitreten",
"activity-v3-provide": "Dieser Service wird von {net} bereitgestellt",
  "activity-v3-sub": "Nehmen Sie an der Aktivität teil und erhalten Sie {awardNumber} {currencyType} Belohnungen!",
  "activity-v3-support-title": "Unterstützte Netzwerke",
  "activity-v3-all-support": "Alle Netzwerke",
  "activity-v3-token": "Verwandte Token",
  "activity-v3-read": "Ich habe gelesen und stimme zu",
  "activity-v3-statement": "Risikohinweis",
  "activity-v3-no-remind": "Nicht mehr erinnern",
  "activity-v3-btn": "Am {awardNumber} {currencyType} Airdrop teilnehmen",
  "safety-title": "Sicherheitsüberprüfung",
  "safety-tip": "Zur Sicherheit Ihres Kontos erfordert dieser Vorgang eine Schiebereglerüberprüfung.",
  "slider-text": "nach rechts schieben",
  "slider-success": "erfolgreich",
  'grade': 'Grad'
}