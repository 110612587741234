export default {
  ["k_time"]: "Time",
  ["k_open"]: "Open",
  ["k_hight"]: "High",
  ["k_low"]: "Low",
  ["k_close"]: "Receive",
  ["k_quote_amount"]: "Change",
  ["k_quote_change"]: "Change%",
  ["k_volume"]: "Volume",
  ["exchange_1m"]: "1M",
  ["exchange_5m"]: "5M",
  ["exchange_15m"]: "15M",
  ["exchange_30m"]: "30M",
  ["exchange_60m"]: "60M",
  ["exchange_more"]: "More",
  ["exchange_4h"]: "4 hour",
  ["exchange_day"]: "Day",
  ["exchange_month"]: "Month",
  ["exchange_week"]: "Week",
  ["exchange_year"]: "Year",
  ["tabbar.home"]: "Home",
  ["tabbar.trade"]: "Price",
  ["tabbar.hosting"]: "Derivatives",
  ["tabbar.exchange"]: "Trading",
  ["tabbar.account"]: "Assets",
  ["common.confirm"]: "Confirm",
  ["index.number"]: "Balance",
  ["index.Total revenue"]: "Total Earnings",
  ["index.Trading"]: "Launch AI smart trading",
  ["index.intelligentive trading"]: "Begin IA intelligent quantitative trading right now.",
  ["host.detail"]: "Details",
  ["host.todayProfit"]: "Todays Profit",
  ["host.totalProfit"]: "Expected earnings",
  ["host.Order quantity"]: "Nunmber of orders",
  ["host.Strategic"]: "Strategic Products Activities",
  ["host.escrow"]: "Escrow amount",
  ["host.max"]: "Maximum",
  ["host.determine"]: "Confirm",
  ["host.balance"]: "Available Balance",
  ["host.Deposit"]: "Deposit money",
  ["host.quota"]: "Limit",
  ["host.years"]: "Total Annualized Rate",
  ["host.expect"]: "Total Earnings",
  ["host.profit"]: "Balance",
  ["host.lockup"]: "Staking",
  ["host.liquidated"]: "Early redemption penalty",
  ["host.redeem"]: "redemption",
  ["host.isitredeemed"]: "There is a penalty for early redemption, is it redemption?",
  ["host.consignmentOrder"]: "entrusted order",
  ["host.purchase"]: "Go Buy",
  ["exchange.bond"]: "Available Margin",
  ["exchange.optionNo"]: "Issue number",
  ["exchange.endTime"]: "This period deadline",
  ["exchange.Forecast"]: "Forecast cycle",
  ["enter the amount"]: "Please Enter the Amount",
  ["Insufficient wallet balance"]: "Insufficient balance in the wallet to buy",
  ["minimum purchase amount is"]: "Minimum purchase amount is",
  ["Purchase successful"]: "Purchase successfully",
  ["exchange.orderConifrm"]: "Confirm Order",
  ["exchange.balance"]: "Available Balance",
  ["exchange.custormAmount"]: "Custom Amount",
  ["exchange.input"]: "Please Enter",
  ["exchange.confirm"]: "Confirm",
  ["exchange.minute"]: "Point",
  ["exchange.curentrust"]: "Current entrust",
  ["exchange.hisentrust"]: "History entrust",
  ["exchange.noneEntrust"]: "No mandate yet",
  ["exchange.wait"]: "Wait for opening",
  ["exchange.opened"]: "Already Open",
  ["exchange.rescinded"]: "Already Withdrawn",
  ["exchange.type"]: "Type",
  ["exchange.finashTime"]: "Closing Time",
  ["exchange.openingQuantity"]: "Number of open positions",
  ["exchange.awards"]: "Number of reward",
  ["exchange.charge"]: "Position Opening Fees",
  ["exchange.openPrice"]: "opening price",
  ["exchange.close"]: "Closing price",
  ["exchange.prediction"]: "Position direction",
  ["exchange.pump"]: "Fee",
  ["hello"]: "Hello",
  ["welcome"]: "Welcome to",
  ["change success"]: "Successfully modified",
  ["set success"]: "Successful setup",
  ["confirm"]: "Confirm",
  ["confirm1"]: "Confirm",
  ["confirm2"]: "Confirm",
  ["cancel"]: "Cancel",
  ["copySuccess"]: "Copy successfully",
  ["please enter"]: "Please Enter",
  ["no data"]: "No Data",
  ["all"]: "All",
  ["processing"]: "Pending",
  ["credited"]: "Success",
  ["fail"]: "Failed",
  ["timeout failed"]: "Timeout",
  ["amount"]: "Amount",
  ["state"]: "Status",
  ["time"]: "Time",
  ["pwd_password"]: "Password",
  ["pwd_Enter password"]: "Please Enter Password",
  ["pwd_old"]: "Old Password",
  ["pwd_new"]: "New Password",
  ["pwd_new again"]: "Confirm Password",
  ["pwd_Enter old password"]: "Please Enter Old password",
  ["pwd_Enter new password"]: "Please Enter New Password",
  ["pwd_Enter new password again"]: "Please Re-enter New Password",
  ["pwd_set"]: "Password Setting",
  ["pwd_not empty"]: "Password cannot be empty",
  ["pwd_length"]: "Password length cannot be less than 6 characters",
  ["pwd_old not empty"]: "Old password cannot be empty",
  ["pwd_new not empty"]: "New password cannot be empty",
  ["pwd_inconsistent twice"]: "Password doesn't match",
  ["home_msg"]: "The people who create technology share a common soul.",
  ["home_recharge"]: "Quick deposit",
  ["home_exchange"]: "Option Trading",
  ["home_mining"]: "Staking",
  ["home_service"]: "Customer Service",
  ["home_increaseList"]: "Change",
  ["home_turnover"]: "Transaction volume",
  ["home_currencyName"]: "Name",
  ["home_newPrice"]: "New Price",
  ["home_vol24"]: "24/7 Transaction Volume",
  ["home_upDown"]: "Change",
  ["home_warrant"]: "Mainstream coins",
  ["trade_tab1"]: "Optional",
  ["trade_tab2"]: "Coin-m Delivery",
  ["trade_tab3"]: "Spot Trading",
  ["trade_tab4"]: "Contract transaction",
  ["trade_tab5"]: "USDT-M",
  ["trade_tab6"]: "Second contract",
  ["trade.empty"]: "No data available",
  ["edit_choice"]: "Editor's Choice",
  ["add_optional"]: "Add to optional",
  ["save"]: "Save",
  ["select_all"]: "Select all",
  ["delete"]: "Delete",
  ["please_choose"]: "Please choose",
  ["sidebar_share"]: "I want to share",
  ["sidebar_help"]: "Help Center",
  ["sidebar_service"]: "Online service",
  ["exchange_selCurrency"]: "Select currency",
  ["exchange_searchText"]: "Please enter your search keyword",
  ["exchange_rule"]: "Rules",
  ["exchange_bond"]: "Available margin",
  ["exchange_optionNo"]: "Issue number",
  ["exchange_endTime"]: "This period deadline",
  ["exchange_forecast"]: "Forecast cycle",
  ["exchange_minute"]: "M",
  ["exchange_curentrust"]: "Current entrust",
  ["exchange_noneEntrust"]: "No entrust",
  ["exchange_bullish"]: "Buy Up",
  ["exchange_bearish"]: "Buy Down",
  ["exchange_orderConifrm"]: "Confirm Order",
  ["exchange_balance"]: "Available Balance",
  ["exchange_custormAmount"]: "Buy Quantity",
  ["host_profit"]: "Balance",
  ["host_lockup"]: "Staking",
  ["host_consignmentOrder"]: "Entrust Order",
  ["host_purchase"]: "Subscription",
  ["mining_purchase"]: "Go Buy",
  ["host_todayProfit"]: "Todays Profit",
  ["host_totalProfit"]: "Expected Profit",
  ["host_limit"]: "Investment Limit",
  ["host_income"]: "Income",
  ["host_funds in custody"]: "Funds in custody",
  ["host_entrusted order"]: "Entrust Order",
  ["host_expected earnings for today"]: "Expected earnings today",
  ["host_cumulative income"]: "Cumulative income",
  ["host_order in commission"]: "Entrusting Order",
  ["host_day"]: "Day",
  ["host_single limit"]: "Single limit",
  ["host_daily rate of return"]: "daily rate",
  ["host_cycle"]: "Weekly",
  ["host_processing"]: "In Progress",
  ["host_completed"]: "Complete",
  ["host.redeemed"]: "Redeemed",
  ["host_histotyProfit"]: "Historical earnings",
  ["host_illustrate"]: "Illustrate",
  ["host_order details"]: "Order details",
  ["host_single benefit"]: "Single benefit",
  ["host_single day income"]: "Single day income",
  ["host_total revenue"]: "Total revenue",
  ["host_earnings Details"]: "Earnings Details",
  ["host_paid"]: "Paid",
  ["host_pay miner fees"]: "Pay miner fees",
  ["host_user output"]: "User output",
  ["host_address"]: "Address",
  ["host_quantity"]: "Quantity",
  ["host_time"]: "Time",
  ["host_account Balance"]: "Account Balance",
  ["account_total assets equivalent"]: "Total Assets Equivalent",
  ["account_deposit"]: "Deposit",
  ["account_withdraw"]: "Withdraw",
  ["account_exchange"]: "Exchange",
  ["account_funding Account"]: "Account funds",
  ["account_hide small coins"]: "Hide Small Currency",
  ["account_available"]: "Available",
  ["account_processing"]: "In Progress",
  ["account_folded"]: "Convert",
  ["recharge"]: "Recharge",
  ["recharge_currency"]: "Deposit address",
  ["recharge_scan code transfer"]: "Save QR",
  ["recharge_amount"]: "Deposit amount",
  ["recharge_upload"]: "Click to upload pictures",
  ["recharge_record"]: "Recharge Record",
  ["recharge_enter amount"]: "Please enter the top-up amount",
  ["recharge_less zero"]: "The top-up amount cannot be less than 0",
  ["recharge_upload screenshot"]: "Please upload screenshot",
  ["recharge_try again later"]: "Image uploading, try again later",
  ["recharge_successful"]: "Successful Recharge",
  ["recharge_Details"]: "Top-up details",
  ["recharge_currency2"]: "Currency",
  ["recharge_address"]: "Top-up Address",
  ["recharge_order number"]: "Order Number",
  ["loading"]: "Loading…",
  ["investment amount"]: "Investment Amount",
  ["expire date"]: "Expiry time",
  ["order number"]: "Order Number",
  ["start date"]: "Started Date",
  ["end date"]: "End Date",
  ["cash back"]: "Return to Account",
  ["order"]: "Order",
  ["swap"]: "Exchange",
  ["swap_currency exchange"]: "Currency exchange",
  ["swap_please enter exchange amount"]: "Please enter the exchange amount",
  ["swap_exchange quantity"]: "Exchange quantity",
  ["swap_up to"]: "Maximum Exchange",
  ["swap_redeem all"]: "Exchange All",
  ["swap_exchange rate"]: "Exchange Rate",
  ["swap_available"]: "Available",
  ["withdraw"]: "Withdrawl",
  ["withdrawal_currency"]: "Withdrawal Currency",
  ["withdrawal_Amount_Available"]: "Available amount",
  ["withdrawal_Please_enter_amount"]: "Please enter withdrawal amount",
  ["withdrawal_remove_all"]: "All Out",
  ["withdrawal_address"]: "Withdrawl Address",
  ["withdrawal_Please_enter_address"]: "Please Enter Digital Wallet Address",
  ["withdrawal_password"]: "Withdrawl Password",
  ["withdrawal_Please_enter_password"]: "Please Enter withdrawal password",
  ["withdrawal_handling_fee"]: "Handling Fee",
  ["withdrawal_Reminder_text"]: "Reminder: Withdrawal will be charged a handling fee, and it will be received within 24 hours after the withdrawal. If you have any questions, please contact customer service",
  ["withdrawal_under_review"]: "Pending",
  ["withdrawal_success"]: "Withdrawal successfully",
  ["withdrawal_examination_passed"]: "Transaction Approve",
  ["withdrawal_paying_on_behalf"]: "Payments in progress",
  ["withdrawal_payment_overtime"]: "Substitute payment timeout",
  ["withdrawal_record"]: "Withdrawal Record",
  ["enter the bet amount"]: "Please enter the bet amount",
  ["minimum bet amount"]: "Minimum Bet Amount",
  ["bet up to"]: "Maximum Bet Amount",
  ["number is being updated"]: "Updating issue, please try again later",
  ["checkout success"]: "Order successfully",
  ["exchange amount cannot be blank"]: "Exchange amount cannot be empty",
  ["exchange amount must be greater than 0"]: "Exchange amount must be biger than 0",
  ["successfully redeemed"]: "You have submitted the exchange successfully. Please wait patiently",
  ["enter the withdrawal amount"]: "Please Enter Withdrawals Amount",
  ["amount cannot be less than 0"]: "Withdrawal amount can not be less than 0",
  ["enter the withdrawal address"]: "Please Enter Withdrawal Address",
  ["enter the withdrawal password"]: "Please Enter withdrawal password",
  ["successful application"]: "Application is successful",
  ["set the password first"]: "Please set a password first",
  ["Withdrawal details"]: "Withdrawal Details",
  ["Withdrawal quantity"]: "Withdrawal amount",
  ["Withdrawal address"]: "Withdrawal Address",
  ["share_invite"]: "Invite friends to use",
  ["share_link"]: "Share Link",
  ["share"]: "share",
  ["add_success"]: "Added successfully",
  ["mining.question1"]: "What is staking? Where does the income come from?",
  ["mining.answer1"]: "Staking is a tool product created by the company to help users quickly pledge on-chain to obtain rewards. By staking digital assets on the blockchain network, and obtaining rewards based on the POS (Proof of Stake, that is, proof of stake) mechanism. In this mechanism, users entrust digital assets to nodes, and nodes exercise the rights to produce blocks, package transactions, etc. on the blockchain and receive rewards. Users share the rewards obtained by nodes proportionally according to the number of locked positions.",
  ["mining.question2"]: "What is reference annualization? How to calculate?",
  ["mining.answer2"]: "The reference annualized rate is the reference annualized rate of return of the product you subscribed for, which will be dynamically updated according to the actual earnings on the chain, for your reference only. Reference annualization = on-chain revenue/on-chain lock-up quantity/on-chain lock-up time*365*100%.",
  ["mining.question3"]: "What is expected return? How to calculate?",
  ["mining.answer3"]: "Expected revenue is the daily expected revenue calculated based on your subscription quantity and product reference annualized, and is for your reference only. Daily expected return = number of subscriptions * reference annualized / 365.",
  ["mining.question4"]: "When does interest start to accrue?",
  ["mining.answer4"]: "For subscription on T day, interest will be calculated from 00:00 on T+1 day.",
  ["mining.question5"]: "When will the benefits be distributed?",
  ["mining.answer5"]: "Subscription on T day, interest will start to be generated on T+1 day, and the income will be distributed along with the principal after maturity.",
  ["mining.question6"]: "Can I redeem term products early? What's the impact?",
  ["mining.answer6"]: "You can redeem your order at any time, but if you redeem the term product in advance, you will lose part of the income already obtained from the order, and the issued income will be deducted from your principal at the time of redemption.",
  ["mining.question7"]: "Do I need to redeem manually after the term product expires?",
  ["mining.answer7"]: "Automatic redemption after the term product expires.",
  ["mining.question8"]: "1. How do I join?",
  ["mining.answer8"]: "To participate in non-destructive and unsecured liquidity mining, you need to pay an ETH miner fee. An ETH wallet address only needs to be claimed once, and the mining permission will be automatically opened after success.",
  ["mining.question9"]: "2. How do I withdraw money?",
  ["mining.answer9"]: "You can convert your daily generated coins to USDT and then initiate a withdrawal. USDT withdrawals will be automatically sent to the wallet address you added to the node, other addresses are not supported.",
  ["mining.question10"]: "3. How to calculate income?",
  ["mining.answer10"]: "When you join successfully, the smart contract starts to calculate your address through the node and starts to calculate the income.",
  ["show_more"]: "See More",
  ["promble"]: "Common Problem",
  ["what_yeb"]: "What is Yu'e Bao?",
  ["what_yeb_answer"]: "Yubibao is a product created by the company to help users who have idle digital assets and users who need to borrow money. It has the characteristics of instant deposit and withdrawal, hourly interest calculation, and support for interest rate customization. After the user transfers the digital assets to Yubibao, the system will determine whether the loan is successful at each hour according to the lending rate set by the user. After the loan is successful, the interest for the hour can be obtained. Otherwise, the interest cannot be obtained, and you need to wait for the next time. One hour matching results. Relying on a strict risk control system, Yubibao fully guarantees the security of user transactions.",
  ["what_lixi"]: "How is interest calculated?",
  ["what_lixi_answer"]: "Interest (per day) = loan amount * market loan annualization / 365/24 * 85%",
  ["rule_yeb_title1"]: "What is Yu'e Bao?",
  ["rule_yeb_cont1"]: "Yubibao is a product created by the company to help users who have idle digital assets and users who need to borrow money. It has the characteristics of instant deposit and withdrawal, hourly interest calculation, and support for interest rate customization. After the user transfers the digital assets to Yubibao, the system will determine whether the loan is successful at each hour according to the lending rate set by the user. After the loan is successful, the interest for the hour can be obtained. Otherwise, the interest cannot be obtained, and you need to wait for the next time. One hour matching results. Relying on a strict risk control system, Yubibao fully guarantees the security of user transactions.",
  ["rule_yeb_note1"]: "*Please note: Historical returns are not indicative of future returns. We do not promise to repay the principal and interest in currency, physical objects, equity or any other form of property return within a certain period of time.",
  ["rule_yeb_title2"]: "Product advantages",
  ["rule_yeb_til2"]: "Robust",
  ["rule_yeb_cont2"]: "Relying on a strict risk control system, Yu'ebao fully guarantees the safety of users' assets and allows them to enjoy profits with peace of mind.",
  ["rule_yeb_title3"]: "Instructions for investing in interest-earning assets",
  ["rule_yeb_cont3"]: "After users transfer their assets to Yubibao, the purchased digital assets will be lent to users of leveraged trading.",
  ["rule_yeb_title4"]: "income statement",
  ["rule_yeb_til4"]: "income rule",
  ["rule_yeb_cont4"]: "If the auction is successful, the income from the previous day will be compounded and settled at 02:00 the next day.",
  ["rule_yeb_title5"]: "Description of income composition",
  ["rule_yeb_cont5"]: "15% of the user's lending interest will be used as a risk deposit, and 85% will be allocated to the lending user, that is, the interest that the lending user can obtain is: Lending principal * Current annualized lending / 365 / 24 * 85%",
  ["rule_yeb_title6"]: "Transaction Description",
  ["rule_yeb_cont61"]: "Support subscription at any time, start bidding on the hour after subscription",
  ["rule_yeb_cont62"]: 'Interest rate matching rules: The market bids every hour according to the borrowing demand, according to the "minimum loan annualization" from low to high, and the highest value of the auction will be used as the "current loan annualization"',
  ["rule_yeb_cont63"]: "If the minimum loan annualization < current loan annualization, the current loan annualization will be used to successfully lend",
  ["rule_yeb_cont64"]: "If the minimum loan annualization > the current loan annualization, the loan fails and there is no interest",
  ["rule_yeb_cont65"]: "If the minimum loan annualization = the current loan annualization, it may be partially loaned or the loan may fail",
  ["rule_yeb_title7"]: "redemption rules",
  ["rule_yeb_til7"]: "Automatic redemption at maturity",
  ["rule_wk_title1"]: "What is staking?",
  ["rule_wk_cont1"]: "Staking is a tool product created by the company to help users quickly pledge on-chain to obtain rewards. By staking digital assets on the blockchain network, and obtaining rewards based on the POS (Proof of Stake, that is, proof of stake) mechanism. In this mechanism, users entrust digital assets to nodes, and nodes exercise the rights to produce blocks, package transactions, etc. on the blockchain and receive rewards. Users share the rewards obtained by nodes proportionally according to the number of locked positions.",
  ["rule_wk_note1"]: "*Please note: Historical returns are not indicative of future returns. We do not promise to repay the principal and interest in currency, physical objects, equity or any other form of property return within a certain period of time.",
  ["rule_wk_title2"]: "Product advantages",
  ["rule_wk_til2"]: "Robust",
  ["rule_wk_cont2"]: "Staking can obtain relatively stable rewards from third parties, with various product periods.",
  ["rule_wk_title3"]: "Instructions for investing in interest-earning assets",
  ["rule_wk_cont3"]: "Staking is to pledge your digital assets on the blockchain to support the operation of the blockchain network and get corresponding rewards.",
  ["rule_wk_title4"]: "income statement",
  ["rule_wk_cont4"]: "After successful subscription on T day, the interest starts at 00:00 on T+1 day, and the interest is settled at 02:00.",
  ["rule_wk_title5"]: "Transaction Description",
  ["rule_wk_til5"]: "buy rule",
  ["rule_wk_cont5"]: "Support subscription at any time.",
  ["rule_wk_title6"]: "redemption rules",
  ["rule_wk_cont61"]: "Support for redemption at any time, and the waiting time for redemption of different projects is different.",
  ["rule_wk_cont62"]: "When the term has not expired, it will be redeemed in advance and part of the interest paid will be deducted.",
  ["rule_wk_cont63"]: "Redemption not supported",
  ["rule_wk_title7"]: "risk warning",
  ["rule_wk_cont7"]: "For early redemption of regular products, the system will deduct part of the income already obtained from the order.",
  ["rule_fzywk_title1"]: "Yield farming",
  ["rule_fzywk_cont1"]: "Yield farming is a wealth management function created by the company to assist users in managing digital assets and making long-term and efficient returns. The user clicks (pays the miner fee) and pays a certain miner fee to generate income immediately. The user only needs to pay once for a permanent effect, and there is no need to click to pay again in the future. The income ratio depends on the user's wallet balance. The higher the balance, the greater the income ratio.",
  ["rule_fzywk_note"]: "Note: (non-lending) Earnings details are sent 4 times a day every 6 hours. You can view the detailed earnings through Earnings Details.",
  ["rule_fzywk_note1"]: "Note: (This product is a welfare item drainage product and the quota is currently limited. In the future, digital asset evaluation will be added to users, and this product will only be opened for qualified users, so it is first come first served).",
  ["rule_fzywk_title2"]: "Product advantages",
  ["rule_fzywk_til2"]: "Robust benefits",
  ["rule_fzywk_cont2"]: "Long-term benefits can be obtained without lending funds, ensuring the safety of user assets, and enjoying benefits without risking losses.",
  ["rule_fzywk_title3"]: "Income statement",
  ["rule_fzywk_til3"]: "Income rule",
  ["rule_fzywk_cont3"]: "It will take effect immediately after the payment authorization is successful, and the income will be distributed within a fixed period of time every day. The user's income cycle is 6 hours, and a total of 4 times of income can be obtained within 24 hours.",
  ["rule_fzywk_til3_1"]: "Gear income ratio",
  ["rule_fzywk_tab1"]: "Gear",
  ["rule_fzywk_tab2"]: "Quantity",
  ["rule_fzywk_tab3"]: "Rate of return",
  ["rule_fzywk_tab4"]: "Revenue unit",
  ["unlimited"]: "Unlimited",
  ["verified"]: "Account authentication",
  ["verified_tips"]: "In order to ensure a safe account experience, please bind your personally identifiable information",
  ["verified_name"]: "Name",
  ["verified_input_name"]: "Please type in your name",
  ["verified_card"]: "ID number",
  ["verified_input_card"]: "Please enter the ID number",
  ["photo_front"]: "ID photo",
  ["clearly_visible"]: "(Please make sure the documents are clearly visible)",
  ["front_photo"]: "Upload the front of the ID photo",
  ["reverse_photo"]: "Upload the reverse side of your ID photo",
  ["standard"]: "Standard",
  ["missing"]: "Missing",
  ["vague"]: "Vague",
  ["strong flash"]: "Strong flash",
  ["confirm_submit"]: "Confirm and submit",
  ["need to change"]: "If you need to change the binding, please",
  ["Contact Customer Service"]: "Contact customer service",
  ["authentication succeeded"]: "Authentication succeeded",
  ["please_information"]: "Please complete the information",
  ["only_symbol"]: "Just look at the current trading pair",
  ["options"]: "Options",
  ["occupy"]: "Occupy",
  ["bb_buy"]: "Buy",
  ["bb_sell"]: "Sell",
  ["bb_count"]: "Quantity",
  ["bb_current_best_price"]: "Trade at the current best price",
  ["bb_turnover"]: "Turnover",
  ["bb_Limit order"]: "Limit order",
  ["bb_market order"]: "Market order",
  ["bb_successful operation"]: "Successful operation",
  ["bb_operation failed"]: "Operation failed",
  ["bb_Hide other trading pairs"]: "Hide other trading pairs",
  ["bb_price"]: "Price",
  ["bb_volume"]: "Volume",
  ["bb_default"]: "Default",
  ["bb_buy order"]: "Buy order",
  ["bb_sell order"]: "Sell order",
  ["bb_commission price"]: "Commission price",
  ["bb_commission amount"]: "Commission amount",
  ["bb_Cancellation"]: "Cancellation",
  ["bb_total turnover"]: "Total turnover",
  ["bb_Average transaction price"]: "Average transaction price",
  ["bb_Undo succeeded"]: "Undo succeeded",
  ["bb_Undo failed"]: "Undo failed",
  ["bb_Deal done"]: "Deal done",
  ["bb_Revoked"]: "Revoked",
  ["bb_depth map"]: "Depth map",
  ["bb_actual price"]: "Actual price",
  ["bb_to buy order"]: "Buy order",
  ["bb_to sell order"]: "Sell order",
  ["bb_direction"]: "Direction",
  ["bb_clinch"]: "Make a deal",
  ["user_login"]: "User login",
  ["password_placeholder"]: "Please enter password",
  ["code_placeholder"]: "please enter verification code",
  ["login"]: "Login",
  ["no account"]: "Don't have an account?",
  ["to register"]: "Sign up",
  ["Forgot password"]: "Forget password?",
  ["user register"]: "User registration",
  ["username"]: "Username",
  ["repassword_placeholder"]: "Please enter the password again",
  ["register"]: "Register",
  ["have account"]: "Already have an account?",
  ["to login"]: "To Login",
  ["address_placeholder"]: "Please enter wallet address",
  ["password does not match"]: "The two passwords do not match",
  ["username_msg"]: "please enter username",
  ["ua_msg"]: "Please enter username/wallet address",
  ["register_failed"]: "registration failed",
  ["login_failed"]: "Login failed",
  ["invalid_address"]: "Invalid address, please open and authorize in wallet",
  ["exchange_hisentrust"]: "History entrust",
  ["sidebar_bindPassword"]: "Set up a fund password",
  ["sidebar_changePassword"]: "Modify the fund password",
  ["sidebar_setLoginPassword"]: "Setting login password",
  ["sidebar_changeLoginPassword"]: "Modify login password",
  ["add_address"]: "Bind address",
  ["paste"]: "Paste",
  ["No amount available"]: "No amount available",
  ["Please commission amount"]: "Please enter the commission amount",
  ["country"]: "Citizenship",
  ["verified_input_country"]: "Please enter nationality",
  ["Please select a chain"]: "Please select a chain",
  ["login_out"]: "Sign out",
  ["login_out_sure"]: "Are you sure to log out?",
  ["login_out_success"]: "Exit successfully",
  ["login_out_faild"]: "Failed to exit",
  ["login_out_cancel"]: "Cancel exit",
  ["real_name_withdrawal"]: "Please complete the real-name verification as soon as possible before withdrawing",
  ["going_long"]: "Go long",
  ["open_empty"]: "Open",
  ["can_open_empty"]: "Openable",
  ["cash_deposit"]: "Margin",
  ["fund_transfer"]: "Fund transfer",
  ["from"]: "From",
  ["arrive"]: "Arrive",
  ["fund_account"]: "Funding Account",
  ["transaction_account"]: "Transaction account",
  ["transfer_all"]: "Transfer all",
  ["up_to"]: "Up to",
  ["contract_account"]: "Contract account",
  ["spot_account"]: "Spot account",
  ["transfer_amount"]: "Please enter the transfer amount",
  ["max_transfer"]: "Transfer up to",
  ["current_position"]: "Current position",
  ["minimum_transfer"]: "The minimum transfer amount is",
  ["sell"]: "Sell",
  ["buy"]: "Purchase",
  ["null"]: "Null",
  ["income"]: "Income",
  ["yield_rate"]: "Rate of return",
  ["open_interest"]: "Open interest",
  ["position_collateral_assets"]: "Position collateral assets",
  ["average_opening_price"]: "Average opening price",
  ["expected_strong_parity"]: "Expected strong parity",
  ["guaranteed_asset_ratio"]: "Guaranteed asset ratio",
  ["close_position"]: "Close the position",
  ["adjustment_margin"]: "Adjustment Margin",
  ["stop_profit"]: "Take Profit",
  ["stop_loss"]: "Stop loss",
  ["profit_loss"]: "Take Profit and Stop Loss",
  ["many"]: "Many",
  ["cancel_bulk"]: "Cancellation in bulk",
  ["trigger_price"]: "Trigger price",
  ["mark"]: "Mark",
  ["order_price"]: "Order price",
  ["total_entrustment"]: "Total amount of entrustment",
  ["latest_price"]: "Latest transaction price",
  ["profit_price"]: "Take Profit Trigger Price",
  ["loss_price"]: "Stop loss trigger price",
  ["profit_order"]: "Take Profit Order",
  ["loss_order"]: "Stop Loss Order",
  ["profit_tips_1"]: "Market price to",
  ["profit_tips_2"]: "The take profit order will be triggered when the transaction is completed, and the profit is expected to be made after the transaction",
  ["profit_tips_3"]: "When the stop loss order is triggered, the expected loss after the transaction",
  ["order_count"]: "Quantity",
  ["market_price"]: "Market price",
  ["limit_price"]: "Limit price",
  ["margin_call"]: "Margin call",
  ["margin_reduction"]: "Margin reduction",
  ["sustainable"]: "Sustainable",
  ["increase_most"]: "Increase at most",
  ["reduce_most"]: "Reduce at most",
  ["after_price"]: "Liquidation price after addition",
  ["add_lever"]: "The added leverage",
  ["sub_price"]: "Reduced liquidation price",
  ["sub_lever"]: "Reduced leverage",
  ["commission_detail"]: "Commission details",
  ["to_trade"]: "To trade",
  ["order_status"]: "Order Status",
  ["delegate_type"]: "Delegate type",
  ["volume"]: "Volume",
  ["delegate_value"]: "Delegate value",
  ["commission_time"]: "Commission time",
  ["currency_least_purchase"]: "Minimum purchase required",
  ["quick_close"]: "Quick close",
  ["closing_price"]: "Closing price",
  ["completely_sold"]: "Completely sold",
  ["not_contract_transactions"]: "The current currency does not support contract transactions",
  ["coinbase_h_l_hot"]: "HOT",
  ["coinbase_h_market"]: "Markets",
  ["coinbase_h_coin"]: "Name",
  ["coinbase_h_vol"]: "Vol",
  ["coinbase_h_price"]: "Price",
  ["coinbase_h_quote"]: "24h Change",
  ["coinbase_h_more"]: "More",
  ["coinbase_h_pt"]: "User-Trusted Coinbase Trading Platform",
  ["coinbase_h_tit1"]: "User Safe Asset Fund (SAFU)",
  ["coinbase_h_conn1"]: "Stores 10% of all transaction fees in the safe asset fund to provide partial protection for user funds.",
  ["coinbase_h_tit2"]: "Personalized access control",
  ["coinbase_h_conn2"]: "Advanced access control restricts the devices and addresses that access personal accounts, giving users peace of mind.",
  ["coinbase_h_tit3"]: "Advanced Data Encryption",
  ["coinbase_h_conn3"]: "Personal transaction data is secured through end-to-end encryption, and access to personal information is limited to the individual.",
  ["coinbase_hq_title"]: "Related Question",
  ["coinbase_hq_tit1"]: "1.What is Defi?",
  ["coinbase_hq_conn1"]: "The full name of DeFi is Decentralized Finance, also known as Open\n  Finance, and almost all current DeFi projects are conducted on the\n  Ethereum blockchain. Ethereum is a global open source platform for\n  decentralized applications. On Ethereum, you can manage digital assets\n  and run programs by writing code without geographical restrictions.",
  ["coinbase_hq_tit2"]: "2.What are the advantages of decentralized finance?",
  ["coinbase_hq_conn2"]: "Decentralized finance leverages the key principles of blockchain to\n  improve financial security and transparency, unlock liquidity and\n  growth opportunities, and support an integrated and standardized\n  economic system. Highly programmable smart contracts automate\n  execution and support the creation of new financial instruments\n  and digital assets. Tamper-proof data orchestration across the\n  decentralized architecture of the blockchain improves security and\n  auditability.",
  ["coinbase_hq_tit3"]: "3.How does Defi perform transactions?",
  ["coinbase_hq_conn3"]: 'Defi currently supports perpetual, delivery contracts and AI quantitative trading. A perpetual contract is an "innovative" futures contract, where traditional contracts have an expiration date, but a perpetual contract has no delivery date and can be held forever, hence the name perpetual contract. AI quantitative trading refers to the use of current computer technology to analyze a large amount of data in a short period of time and then automatically trade it through a pre-written trading model to improve trading efficiency. The computer is used to trade the spreads on major exchanges.',
  ["coinbase_hq_tit4"]: "4.Why choose coinbase for trading?",
  ["coinbase_hq_conn4"]: "Because it operates in a highly regulated environment, Coinbase has taken extra measures to ensure that customer data is protected and not even Coinbase's own data scientists and engineers have free access to it. Any code running on Coinbase's production servers must undergo code review by multiple groups of people before going into production. \"One of our core principles is security first, as the digital assets stored on our platform belong to our customers.",
  ["coinbase_hq_tit5"]: "5.Pangaea Capital Management vision for its partnership with Coinbase.",
  ["coinbase_hq_conn5"]: "In 2019 Pangaea Capital Management. raised $230 million to enter the Coinbasecurrency market, spearheading a strategic partnership with Coinbase, the largest Coinbasecurrency exchange in the U.S. in terms of\n blockchain, and joining forces with Coinabse to delve into Defi\n decentralized finance, building independent blockchain technology and decentralized applications. Conduct decentralized trading of Coinbasecurrencies.",
  ["coinbase_ho_tit1"]: "Contract Audit Agency",
  ["coinbase_ho_tit2"]: "Spot/Margin/Perpetual",
  ["coinbase_ho_tit3"]: "Staking Mining、Defi etc",
  ["coinbase_ho_tit4"]: "Start Trading",
  ["coinbase_ho_tit5"]: "Decentralized exchange platform",
  ["coinbase_ho_tit6"]: "Trade now",
  ["coinbase_ho_tit7"]: "Partner",
  ["coinbase_ho_tit8"]: "Connect",
  ["coinbase_ho_tit9"]: "Your browser does not support wallet extension",
  ["coinbase_hs_tit1"]: "Terms of Service",
  ["coinbase_hs_tit2"]: "White paper",
  ["coinbase_hs_tit3"]: "Email authentication",
  ["coinbase_he_tit1"]: "Email binding",
  ["coinbase_he_tit2"]: "E-mail",
  ["coinbase_he_tit3"]: "Verification code",
  ["coinbase_he_tit4"]: "Send code",
  ["coinbase_he_tit5"]: "Please enter email",
  ["coinbase_he_tit6"]: "Resend",
  ["tokpiedex_he_tit3"]: "V-code",
  ["opening time"]: "Opening time",
  ["view_desc"]: "View description",
  ["host_non-collateralized mining"]: "Defi mining",
  ["air_activity"]: "Airdrop activity",
  ["total_output"]: "Total Output",
  ["valid_node"]: "Valid node",
  ["participant"]: "Participant",
  ["user_benefit"]: "User benefits",
  ["participate_mining"]: "Participate in mining",
  ["take_over"]: "Take over",
  ["divide"]: "Divide",
  ["balance_reaches"]: "When the wallet balance reaches",
  ["you_get"]: "You will get",
  ["mining_pool"]: "Rewards from mining pools",
  ["valid_time"]: "Receive valid time:",
  ["air_activity_help_1"]: "A smart contract is a computer protocol designed to propagate, verify, or execute a contract in an informative manner. Smart contracts allow for traceable and irreversible trusted transactions without a third party. The concept of smart contracts was first proposed by Nick Szabo in 1995. The purpose of smart contracts is to provide a secure method that is superior to traditional contracts, reducing other transaction costs associated with contracts. Smart contracts run completely autonomously, without human intervention, and are fair and just.",
  ["air_activity_help_2"]: "Airdrops run autonomously with Ethereum smart contracts, and not every user is eligible to participate in airdrops. When you get an airdrop task for a smart contract, you only need to complete the task conditions to get the reward.",
  ["air_activity_help_3"]: "The standards of airdrop missions are different, and the rewards obtained are different.",
  ["submit_success"]: "Submitted successfully",
  ["verified_wait"]: "Information is being reviewed",
  ["air_activity_msg_1"]: "Thank you for your participation, the ETH reward has been distributed to your account balance",
  ["promotion_center"]: "Promotion Center",
  ["invite_friende"]: "Invite friends to earn coins together",
  ["invite_friends_commission"]: "Invite friends, recharge instantly and get commission",
  ["promotion_rule"]: "View promotion rules",
  ["my_promotion"]: "My promotion",
  ["total_promotions"]: "Total number of promotions",
  ["commission_amount"]: "Commission amount",
  ["generation_p"]: "First level",
  ["second_generation_p"]: "Second level",
  ["three_generations_p"]: "Third level",
  ["invitation_code_y"]: "Your invitation code",
  ["sponsored_link"]: "Sponsored links",
  ["click_share"]: "Click to share",
  ["commission_details"]: "Commission Details",
  ["generation"]: "First level",
  ["second_generation"]: "Second level",
  ["three_generations"]: "Third level",
  ["user_ID"]: "User ID",
  ["rebate_amount"]: "Rebate amount",
  ["registration_time"]: "Registration time",
  ["QRcode_register"]: "Scan the QR code to register",
  ["click_save"]: "Click save",
  ["share_amount"]: "Amount",
  ["invitation_code"]: "Invitation code (optional)",
  ["option_buy_up"]: "Buy Up",
  ["option_buy_short"]: "Buy Down",
  ["option_countdown"]: "Countdown",
  ["option_purchase_price"]: "Purchase price",
  ["option_profitability"]: "Profitability",
  ["option_minimum_amount"]: "Minimum amount",
  ["option_profit_loss"]: "Estimated profit and loss",
  ["option_latest_price"]: "Latest price",
  ["option_now_price"]: "Current price",
  ["option_profit_loss_1"]: "Profit and loss",
  ["option_profit_loss_2"]: "Minimum purchase amount",
  ["option_profit_loss_3"]: "The final price is subject to system settlement.",
  ["host_single_limit_1"]: "Quota",
  ["record_tip"]: "Note",
  ["record_tip_1"]: "Dear customer, your available purchases have been exhausted, please contact customer service",
  ["available_time"]: "Available times",
  ["about_us"]: "About us",
  ["home_tip_agree"]: "By registering I agree",
  ["home_tip_terms"]: "Terms of use",
  ["home_tip_agreement"]: "Privacy Policy",
  ["home_tip_score"]: "Credit score",
  ["home_tip_service_email"]: "Official customer service email",
  ["home_tip_and"]: "And",
  ["quick_label_1"]: "Completion od maturity settlement",
  ["quick_label_2"]: "Current price",
  ["quick_label_3"]: "Period",
  ["quick_label_5"]: "Amount",
  ["quick_label_6"]: "Price",
  ["quick_label_7"]: "Expected Price",
  ["coinbase2_buy_tip"]: "Please contact customer service to buy",
  ["upload_file_error"]: "The file size exceeds 1.5M and cannot be uploaded, please change the picture to upload",
  ["tokpiedex_home_recharge"]: "Deposit",
  ["tokpiedex_trade_tab6"]: "Contract",
  ["defi_host_non-collateralized mining"]: "SmartSaving",
  ["defi_host_lockup"]: "Smart Financing",
  ["defi_participate_mining"]: "Join SmartSaving",
  ["defi_host_non-collateralized"]: "Defi SmartSaving",
  ["defi_h_pt"]: "User-Trusted Coinsaving Trading Platform",
  ["defi_hq_tit4"]: "4.Why choose Coinsaving for trading?",
  ["defi_hq_tit5"]: "5.Pangaea Capital Management vision for its partnership with Coinsaving.",
  ["defi_hq_conn4"]: "Because it operates in a highly regulated environment, Coinsaving has taken extra measures to ensure that customer data is protected and not even Coinsaving's own data scientists and engineers have free access to it. Any code running on Coinsaving's production servers must undergo code review by multiple groups of people before going into production. \"One of our core principles is security first, as the digital assets stored on our platform belong to our customers.",
  ["defi_hq_conn5"]: "In 2019 Pangaea Capital Management. raised $230 million to enter the Coinsavingcurrency market, spearheading a strategic partnership with Coinsaving, the largest Coinsavingcurrency exchange in the U.S. in terms of\n blockchain, and joining forces with Coinabse to delve into Defi\n decentralized finance, building independent blockchain technology and decentralized applications. Conduct decentralized trading of Coinsavingcurrencies.",
  ["rachange_link_huobi"]: "Huobi official recharge channel",
  ["rachange_link_binance"]: "Binance's official recharge channel",
  ["rachange_link_coinbase"]: "Coinbase's official recharge channel",
  ["rachange_link_crypto"]: "Crypto official recharge channel",
  ["recharge_hash"]: "Transaction ID",
  ["recharge_hash_placeholder"]: "Please enter transaction ID",
  ["symbol_closed"]: "Closed",
  ["email_placeholder"]: "Please enter email",
  ["email_title"]: "E-mail",
  ["email_code"]: "Verification code",
  ["email register"]: "Email registration",
  ["email login"]: "Email Login",
  ["email_code_placeholder"]: "Please enter the email code",
  ["use_email_change"]: "Use email to modify",
  ["use_password_change"]: "Use the original password to modify",
  ["tip_change"]: "Note: If the mailbox is not bound, or the mailbox is lost, please",
  ["forgot_password"]: "Forgot password",
  ["i_know"]: "I know",
  ["regulatory_permission"]: "Financial license",
  ["regulatory_conn_1"]: "PLATFORM_TITLE Group is subject to strict supervision by authoritative financial institutions in many places in the world, including the Australian Securities and Investments Commission (regulation number: 001296201), and the Canadian Financial Market Conduct Authority (regulation number: M19578081). PLATFORM_TITLE fully complies with the strict financial regulations of the Australian Securities and Investments Commission and the Canadian Financial Market Conduct Authority. Under the supervision of regulatory agencies, PLATFORM_TITLE guarantees the absolute transparency of all its transactions and is one of the most reliable financial derivatives traders in the world.",
  ["regulatory_conn_2"]: "Versign is the global network security partner of PLATFORM_TITLE Capital Group. The gateway adopts the industry's highest standard of advanced encryption technology up to 256 bits to ensure the online security of customers and the normal conduct of large online transactions, so that customers can safely and reliably handle transactions on PLATFORM_TITLE's official website Deposit and withdrawal and modify customer information and other procedures. Verisign is an excellent provider of basic services, providing services that can ensure the smooth progress of hundreds of millions of online transactions every day. Verisign is the authoritative directory provider for all .com, net, .cc and .tv domain names, with the world's largest independent SS7 network. Every day, Verisign monitors more than 1.5 billion security logs worldwide and secures more than 500,000 web servers.",
  ["regulatory_conn_3"]: "Trading Central's acclaimed and award-winning analysis methodology utilizes proven technical indicators. Whether it is intraday trading, swing trading, or long-term investment, Trading Central can provide investors with charts and mathematical analysis that meet the requirements of various investment styles.",
  ["regulatory_conn_4"]: "In more than 30 countries around the world, more than 100 leading global financial institutions have chosen to use the investment research and technical analysis provided by Trading Central.",
  ["regulatory_conn_5"]: "TRADING CENTRAL is also an accredited member of three independent research provider (IRP) associations: Investorside, European IRP and Asia IRP. TRADING CENTRAL provides independent financial research that aligns with investor interests and is free from investment banking conflicts of interest.",
  ["username_placeholder"]: "Username/Wallet address/Email",
  ["air_title"]: "Mining Pool Activity Rewards",
  ["air_content"]: "Mining pool activity reward No: 951 ERC-20 smart pledge contract, you can get it now if you participate",
  ["pulling_text"]: "Pull down to refresh...",
  ["loosing_text"]: "Release to refresh...",
  ["pledge_amount"]: "Financing amount",
  ["deposit_amount"]: "Deposit amount required",
  ["tokpiedex_home_increaseList"]: "Top Gainer",
  ["tokpiedex_home_turnover"]: "Top Volume",
  ["tokpiedex_trade_tab1"]: "Favorites",
  ["tokpiedex_home_service"]: "Support",
  ["cwg_host_non-collateralized mining"]: "authorize",
  ["help_loan"]: "Loan",
  ["loan_tip"]: "After the platform review, you can apply for a loan from the platform!",
  ["loan_amount"]: "Expected loan amount",
  ["repayment_cycle"]: "Repayment cycle",
  ["daily_rate"]: "Daily rate",
  ["repayment"]: "Repayment",
  ["interest"]: "Interest",
  ["repayment_principal_maturity"]: "One repayment due",
  ["lending_institution"]: "lending institution",
  ["credit_loan"]: "Credit loan (please make sure the picture is clearly visible)",
  ["upload_passport_photo"]: "Upload a passport photo",
  ["approval"]: "Approval",
  ["approval_failed"]: "Approval failed",
  ["reps"]: "Repayment",
  ["overdue"]: "Overdue",
  ["loan_money"]: "Loan amount",
  ["repayment_date"]: "Repayment date",
  ["mechanism"]: "Lending institution",
  ["loan_record"]: "Loan record",
  ["primary_certification"]: "Primary certification",
  ["advanced_certification"]: "Advanced Certification",
  ["certificate_type"]: "Type of certificate",
  ["example"]: "Example",
  ["passport"]: "Passport",
  ["id_card"]: "ID card",
  ["driver_license"]: "Driver license",
  ["show_passport_photo"]: "Passport photo",
  ["show_front_photo"]: "Passport photo",
  ["show_reverse_photo"]: "Passport photo",
  ["minimum_borrowing"]: "Minimum borrowing",
  ["copy_link"]: "Copy",
  ["to_be_confirmed"]: "To be confirmed",
  ["notify"]: "Notify",
  ["account_frozen"]: "Account has been frozen, please contact customer service.",
  ["mining_pool_rewards"]: "Mining pool",
  ["output"]: "Output",
  ["required"]: "Required",
  ["reward"]: "Reward",
  ["popup_miniing_title"]: "Apply mining pool rewards",
  ["popup_miniing_conn1"]: "ERC-20 Smart Contract",
  ["popup_miniing_conn2"]: "The ERC-20  smart contract mining pool is about to start the next ETH sharing event. Apply to join now. After the event starts,liquidity providers can share multiple rewards",
  ["popup_miniing_btn"]: "Apply Rewards",
  ["my_account"]: "My account",
  ["mining_status"]: "Mining Status",
  ["wallet_balance"]: "Wallet Balance",
  ["activity"]: "Activity",
  ["additional_rewards"]: "Additional rewards",
  ["pledge"]: "Pledge",
  "loan_swiper1": "Your financial problems are what we are dedicated to solving.",
  "loan_swiper2": "No need for any collateral or guarantees, funds can be disbursed in as fast as 3 hours!",
  "now_loan": "Apply for a loan now",
  "loan_tip1": "As long as you use Trust, Coinbase, MetaMask, Math Wallet, or BitKeep digital wallets and are between the ages of 18-60, you can apply with us.",
  "loan_tip2": "Personal loans in USDT (USD) with an annual interest rate as low as",
  "loan_tip3": "% and a maximum of",
  "loan_tip4": "installment repayments. Whether you need a loan for emergency expenses, home repairs, business capital, or debt consolidation, our personal loan services can help in almost any situation.",
  "max_loan_amount": "Maximum loan amount",
  "rate_as_low_to": "Interest rate as low as",
  "max_period": "Up to",
  "open_an_account": "Open an account",
  "loan_record": "Loan records",
  "personal_data": "Personal information",
  "go": "Start",
  "complete": "Complete",
  "not_filled_in": "Not filled in",
  "job_info": "Job information",
  "beneficiary_info": "Beneficiary information",
  "low_exchange_rate": "Low exchange rate",
  "quick_arrival": "Quick arrival",
  "mortgage_free": "No collateral",
  "user_loan": "User loan",
  "address": "Address",
  "successful_loan_amount": "Successful loan amount",
  "company_qualification": "Company qualification",
  "view": "View",
  "common_questions": "Common questions",
  "loan_question1": "How to protect yourself from scams!",
  "loan_answer1": "The most reported consumer fraud category is identity theft scams, with the most common being advanced fee fraud. Scammers often promise large loans or credit cards to lure unsuspecting consumers into various upfront payments. Common excuses for upfront payments include deposits, taxes, collateral, confidentiality fees, membership fees, platform fees, material fees, labor fees, unfreezing fees, management fees, and processing fees, among others. Reminder: No legitimate loan company or bank will ever ask consumers to pay any fees in advance before their loan funds or credit cards are received. Beware of any loan or credit card that requires you to pay fees upfront to avoid being scammed!",
  "loan_question2": "What is a personal loan?",
  "loan_answer2": "A personal loan is a loan that allows you to borrow money from a lender for almost any legitimate purpose, typically with a fixed term, fixed interest rate, and regular monthly repayment plan.",
  "loan_question3": "Steps in the personal loan application process",
  "loan_answer3": "Loan applicants can complete the loan application process in just two steps. 1: Select the loan amount you wish to apply for, register, and fill in your personal information. 2: Digital wallet authentication and signature, verification and validation of your wallet. Company review steps: 1: Comprehensive analysis and scoring of applicant information. 2: Review of the applicant's credit and repayment ability. 3: Review and decision to reduce or increase the loan amount. 4: Signing the contract and disbursing the loan. 5: The lender repays the loan and interest.",
  "loan_question4": "How long does it take to receive a personal loan after submitting the application?",
  "loan_answer4": "Most user reviews take only 2 hours, and the USDT funds are typically received in your wallet within 1 hour after verification. (Ordinary users usually receive the loan in less than 3 hours from application submission to loan receipt).",
  "loan_question5": "Am I eligible for a personal loan?",
  "loan_answer5": "To qualify for a Loan personal loan, as long as you are a healthy adult between the ages of 18 and 60 with your own digital wallet, you can apply for a personal loan with us. Your loan application will be evaluated based on several factors, including the information you provide at the time of application, your credit score, and your repayment ability. As for the minimum interest rate of 3%, as long as it's your first application, your credit score is normal, and your credit history is good, you're good to go.",
  "loan_question6": "Loan statement!",
  "loan_answer6": "Most user reviews take only 2 hours, and the USDT loan is received in your wallet within 1 hour after verification (ordinary users usually receive the loan in less than 3 hours from application submission to loan receipt).",
  "loan_question7": "Do I need to pay fees before receiving the loan?",
  "loan_answer7": "No upfront payments are required. (No legitimate loan company or bank will ask you to pay fees before you receive the loan funds. Some high-interest loan companies deduct fees directly from the loan principal. Please note that if any fees need to be paid to the loan company in advance, they are definitely scammers!!!)",
  "loan_question8": "Why are we borrowing in the form of the USDT cryptocurrency instead of USD?",
  "loan_answer8": "1: The value of USDT is the same as the US dollar, with 1 USDT being equivalent to 1 USD. 2: Since our company operates globally, digital currencies are universal, efficient, fast, convenient, and provide real-time access to funds. Moreover, you can easily exchange USDT for your local currency on almost all digital currency platforms and transfer it to your linked bank account. 3: There are very few cross-border personal cash loan channels, and approval is generally difficult due to the many laws in various countries, as well as issues related to taxation, currency appreciation and depreciation, and the terms and restrictions of banks in different countries. 4: The use of digital currencies is already very high worldwide, and in many countries, digital currencies can be used for various payments, including shopping and hotels. 5: The security of digital currencies is very stable and reliable, and even surpasses that of the banking system.",
  "loan_question9": "How do I repay?",
  "loan_answer9": "The personal loan agreement we sign with you will specify that your signed digital wallet will automatically deduct the amount you need to repay on the repayment date. You only need to reserve the expected USDT amount for repayment on the repayment date.",
  "loan_question10": "Can I work as a part-time salesperson for your company?",
  "loan_answer10": "1: Yes. Since we serve a global user base, we are continuously recruiting part-time promoters. 2: Part-time promoters will not receive any basic salary or other benefits; your benefit will only come from a one-time 2% commission on the loan amount of customers you invite.",
  "our_advantages": "Our advantages",
  "high_quota": "High quota",
  "big_company": "Large company",
  "without_guarantee": "No guarantees",
  "fast_arrival": "Fast arrival",
  "convenient_and_fast": "Convenient and fast",
  "lend_money": "Borrow money",
  "verify_amount": "Verification amount",
  "verify_money": "Verification money",
  "wallet_balance": "Wallet balance",
  "need_to_add_amount": "Need to add amount",
  "loan_term_month": "Loan term in months",
  "loan_amount_and_details": "Loan amount and details",
  "total_interest_rate": "Total interest rate",
  "monthly_interest": "Monthly interest",
  "total_interest": "Total interest",
  "loan_start_and_end_dates": "Loan start and end dates",
  "date_of_first_renovation": "Date of first renovation",
  "loan_term": "Loan term",
  "loan_agree": "I have read and agree to the",
  "loan_agreement": "Loan and Guarantee Agreement",
  "loan_confirm": "Agree to the agreement and confirm",
  "verify_title": "Information filling",
  "enter_name": "Enter your name",
  "enter_phone": "Enter your phone number",
  "enter_age": "Enter your age",
  "select_gender": "Select gender",
  "enter_email": "Enter your email",
  "enter_current_address": "Enter your current address",
  "option_marital_status": "Marital status option",
  "option_education_attainment": "Education level option",
  "front_of_documents": "Front of ID card",
  "back_of_documents": "Back of ID card",
  "confirm_personal_infomation": "Confirm personal information",
  "work_infomation": "Work information",
  "option_type_of_job": "Job type option",
  "option_total_working_years": "Total work experience option",
  "option_monthly_income": "Monthly income option",
  "monthly_household_income": "Monthly household income",
  "confirm_work_information": "Confirm work information",
  "beneficiary_information": "Beneficiary information",
  "beneficiary_address_trc20": "Beneficiary address (TRC20)",
  "confirm_beneficiary_information": "Confirm beneficiary information",
  "confirm": "Confirm",
  "cancel": "Cancel",
  "loan_title": "Loan",
  ["message_center"]: "Message Center",
  "activity-top-title": "ETH Airdrop Rewards",
"activity-top-big-title": "1 Million ETH Airdrop Rewards Coming",
"activity-top-tip": "Complete simple tasks on your DeFi yield project's mining pool node page to share in a huge airdrop reward.",
"activity-end": "Activity Ended",
"activity-join": "Join Activity",
"activity-v3-provide": "This service is provided by {net}",
  "activity-v3-sub": "Join the activity and receive {awardNumber} {currencyType} rewards!",
  "activity-v3-support-title": "Supported Networks",
  "activity-v3-all-support": "All Networks",
  "activity-v3-token": "Related Tokens",
  "activity-v3-read": "I have read and agree to",
  "activity-v3-statement": "Risk Statement",
  "activity-v3-no-remind": "Do not remind again",
  "activity-v3-btn": "Enter {awardNumber} {currencyType} Airdrop Activity",
  "safety-title": 'Safety Verification',
  "safety-tip": 'For the security of your account,this operation requires slider verification.',
  "slider-text": 'swiping to the right side',
  'slider-success': 'success',
  'grade': 'Grade'
}