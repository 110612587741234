import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import { Dialog } from 'vant';
import Cookies from 'js-cookie'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import("@/views/homeLayout"),
    redirect: '/host',
    children: [
      {
        path: '/host/:code?',
        component: () => import('@/views/home')
      },
      {
        path: '/loan',
        component: () => import('@/views/loan')
      },
      {
        path: '/user',
        component: () => import('@/views/wallet')
      }
    ]
  },
  {
    path: '/safety-verify',
    name: 'safetyVerify',
    meta: {
      auth: false
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/safety-verify')
  },
  {
    path: '/recharge',
    name: 'recharge',
    meta: {
      auth: true
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/recharge')
  },
  {
    path: '/recharge-record',
    name: 'rechargeRecord',
    meta: {
      auth: true
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/recharge-record')
  },
  {
    path: '/withdraw',
    name: 'withdraw',
    meta: {
      auth: true
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/withdraw')
  },
  {
    path: '/withdraw-detail',
    name: 'withdrawDetail',
    meta: {
      auth: true
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/withdraw-detail')
  },
  {
    path: '/withdraw-record',
    name: 'withdrawRecord',
    meta: {
      auth: true
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/withdraw-record')
  },
  {
    path: '/exchange',
    name: 'exchange',
    meta: {
      auth: true
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/exchange')
  },
  {
    path: '/message-center',
    name: 'messageCenter',
    meta: {
      auth: true
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/message-center')
  },
  {
    path: '/promotion-center',
    name: 'promotionCenter',
    meta: {
      auth: true
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/promotion-center')
  },
  {
    path: '/share',
    name: 'share',
    meta: {
      auth: true
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/share')
  },
  {
    path: '/set-pass',
    name: 'setPass',
    meta: {
      auth: true
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/set-pwd')
  },
  {
    path: '/set-login-pass',
    name: 'setLoginPass',
    meta: {
      auth: true
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/set-login-pwd')
  },
  {
    path: '/bind-email',
    name: 'bindEmail',
    meta: {
      auth: true
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/bind-email')
  },
  {
    path: '/terms-service',
    name: 'termsService',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/terms-service')
  },
  {
    path: '/promotion-rule',
    name: 'promotionRule',
    meta: {
      auth: true
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/promotion-rule')
  },
  {
    path: '/income-detail',
    name: 'incomeDetail',
    meta: {
      auth: true
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/income-detail')
  },
  {
    path: '/lend',
    name: 'lend',
    meta: {
      auth: true
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/lend')
  },
  {
    path: '/submit',
    name: 'submit',
    meta: {
      auth: true
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/verify-form')
  },
  {
    path: '/loan-record',
    name: 'loanRecord',
    meta: {
      auth: true
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/loan-record')
  },
  {
    path: '/loan-record-detail',
    name: 'loanRecordDetail',
    meta: {
      auth: true
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/loan-record-detail')
  },
  {
    path: '/agreement/:period?/:amount?',
    name: 'agreement',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/agreement')
  },
  {
    path: '/sign/:period?/:amount?',
    name: 'sign',
    meta: {
      auth: true
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/sign')
  },
  {
    path: '/download',
    name: 'download',
    meta: {
      auth: false
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/download')
  },
  {
    path: '/scan',
    name: 'scan',
    meta: {
      auth: false
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/scan')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  let token = localStorage.getItem(process.env.VUE_APP_TOKEN_KEY)
  NProgress.start()
  let verify = Cookies.get('verify')
  if (!verify && process.env.VUE_APP_VERIFY == 'need') {
    if(to.path == '/safety-verify') {
      next()
    }else{
      next('/safety-verify')
    }
  } else {
    if (!token && to.meta.auth) {
      next('/host')
    }else{
      if(to.path == '/safety-verify') {
      next('/host')
      }else{
        next()
      }
    }
   
  //   if (!token && to.meta.auth) {
  //     next('/host')
  //   } else {
  //     next()
  //   }
  }
})

router.afterEach(() => {
  NProgress.done()
})


export default router
