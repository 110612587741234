export default {
  ["k_time"]: "zaman",
  ["k_open"]: "açık",
  ["k_hight"]: "yüksek",
  ["k_low"]: "Düşük",
  ["k_close"]: "almak",
  ["k_quote_amount"]: "Miktarı değiştir",
  ["k_quote_change"]: "Teklif değişikliği",
  ["k_volume"]: "devir",
  ["exchange_1m"]: "1 puan",
  ["exchange_5m"]: "5 puan",
  ["exchange_15m"]: "15 puan",
  ["exchange_30m"]: "30 puan",
  ["exchange_60m"]: "60 puan",
  ["exchange_more"]: "Daha",
  ["exchange_4h"]: "4 saat",
  ["exchange_day"]: "gün",
  ["exchange_month"]: "ay",
  ["exchange_week"]: "hafta",
  ["exchange_year"]: "Yıl",
  ["tabbar.home"]: "ön Sayfa",
  ["tabbar.trade"]: "alıntılar",
  ["tabbar.hosting"]: "finansal Yönetim",
  ["tabbar.exchange"]: "ticaret",
  ["tabbar.account"]: "varlıklar",
  ["common.confirm"]: "onaylamak",
  ["index.number"]: "denge",
  ["index.Total revenue"]: "Toplam gelir",
  ["index.Trading"]: "AI akıllı ticarete başlayın",
  ["index.intelligentive trading"]: "AI akıllı kantitatif ticarete şimdi başlayın",
  ["host.detail"]: "detaylar",
  ["host.todayProfit"]: "Bugünün Kazançları",
  ["host.totalProfit"]: "Tahmini gelir",
  ["host.Order quantity"]: "sipariş miktarı",
  ["host.Strategic"]: "Stratejik Etkinlik Ürünleri",
  ["host.escrow"]: "Emanet tutarı",
  ["host.max"]: "maksimum",
  ["host.determine"]: "Elbette",
  ["host.balance"]: "Kalan bakiye",
  ["host.Deposit"]: "para yatırmak",
  ["host.quota"]: "kota",
  ["host.years"]: "Toplam Yıllık Oran",
  ["host.expect"]: "beklenen getiri",
  ["host.profit"]: "Yu'e Bao",
  ["host.lockup"]: "Rehin madenciliği",
  ["host.liquidated"]: "Erken itfa tasfiye edilmiş zararlar",
  ["host.redeem"]: "kefaret",
  ["host.isitredeemed"]: "Erken itfa için bir ceza var mı, itfa mı?",
  ["host.consignmentOrder"]: "görevlendirilen sipariş",
  ["host.purchase"]: "satın almak",
  ["exchange.bond"]: "Mevcut marj",
  ["exchange.optionNo"]: "Sorun",
  ["exchange.endTime"]: "bu süre biter",
  ["exchange.Forecast"]: "tahmin döngüsü",
  ["enter the amount"]: "Lütfen tutarı girin",
  ["Insufficient wallet balance"]: "Yetersiz cüzdan bakiyesi, satın alınamıyor",
  ["minimum purchase amount is"]: "Minimum satın alma tutarı",
  ["Purchase successful"]: "başarılı satın alma",
  ["exchange.orderConifrm"]: "Sipariş onayı",
  ["exchange.balance"]: "Kalan bakiye",
  ["exchange.custormAmount"]: "özel miktar",
  ["exchange.input"]: "girin lütfen",
  ["exchange.confirm"]: "Elbette",
  ["exchange.minute"]: "nokta",
  ["exchange.curentrust"]: "mevcut komisyon",
  ["exchange.hisentrust"]: "tarihi komisyon",
  ["exchange.noneEntrust"]: "emanet yok",
  ["exchange.wait"]: "açılması bekleniyor",
  ["exchange.opened"]: "açıldı",
  ["exchange.rescinded"]: "iptal edildi",
  ["exchange.type"]: "tip",
  ["exchange.finashTime"]: "kapanış saati",
  ["exchange.openingQuantity"]: "Açılış Miktarı",
  ["exchange.awards"]: "ödül sayısı",
  ["exchange.charge"]: "açılış ücreti",
  ["exchange.openPrice"]: "açılış fiyatı",
  ["exchange.close"]: "Kapanış fiyatı",
  ["exchange.prediction"]: "Konum yönü",
  ["exchange.pump"]: "su pompalamak",
  ["hello"]: "Merhaba",
  ["welcome"]: "Hoşgeldiniz",
  ["change success"]: "Başarıyla değiştirildi",
  ["set success"]: "başarıyla ayarlandı",
  ["confirm"]: "Elbette",
  ["confirm1"]: "Elbette",
  ["confirm2"]: "Elbette",
  ["cancel"]: "İptal etmek",
  ["copySuccess"]: "başarıyla kopyala",
  ["please enter"]: "girin lütfen",
  ["no data"]: "Veri yok",
  ["all"]: "Tümü",
  ["processing"]: "İşleme",
  ["credited"]: "Vardı",
  ["fail"]: "hata",
  ["timeout failed"]: "zaman aşımı başarısız oldu",
  ["amount"]: "Tutar",
  ["state"]: "durum",
  ["time"]: "zaman",
  ["pwd_password"]: "şifre",
  ["pwd_Enter password"]: "lütfen şifre giriniz",
  ["pwd_old"]: "eski Şifre",
  ["pwd_new"]: "Yeni Şifre",
  ["pwd_new again"]: "Şifreyi Onayla",
  ["pwd_Enter old password"]: "Lütfen eski şifreyi girin",
  ["pwd_Enter new password"]: "Lütfen yeni bir şifre girin",
  ["pwd_Enter new password again"]: "Lütfen yeni şifreyi tekrar girin",
  ["pwd_set"]: "şifreyi belirle",
  ["pwd_not empty"]: "şifre boş olamaz",
  ["pwd_length"]: "Şifre uzunluğu 6 karakterden az olamaz",
  ["pwd_old not empty"]: "Eski şifre boş olamaz",
  ["pwd_new not empty"]: "Yeni şifre boş olamaz",
  ["pwd_inconsistent twice"]: "İki şifre eşleşmiyor",
  ["home_msg"]: "Teknoloji, onu geliştirenlerin ortak ruhudur.",
  ["home_recharge"]: "hızlı para yatırma",
  ["home_exchange"]: "opsiyon ticareti",
  ["home_mining"]: "Rehin madenciliği",
  ["home_service"]: "çevrimiçi servis",
  ["home_increaseList"]: "Kazanç listesi",
  ["home_turnover"]: "devir",
  ["home_currencyName"]: "isim",
  ["home_newPrice"]: "Son fiyat",
  ["home_vol24"]: "24 saatlik işlem hacmi",
  ["home_upDown"]: "Teklif değişikliği",
  ["home_warrant"]: "ana para birimi",
  ["trade_tab1"]: "isteğe bağlı",
  ["trade_tab2"]: "teslimat sözleşmesi",
  ["trade_tab3"]: "Döviz ticareti",
  ["trade_tab4"]: "Sözleşme işlemi",
  ["trade_tab5"]: "U standart sözleşme",
  ["trade_tab6"]: "ikinci sözleşme",
  ["trade.empty"]: "Veri yok",
  ["edit_choice"]: "Editörün Seçimi",
  ["add_optional"]: "favorilere ekle",
  ["save"]: "kaydetmek",
  ["select_all"]: "hepsini seç",
  ["delete"]: "silmek",
  ["please_choose"]: "lütfen seç",
  ["sidebar_share"]: "Ben paylaşmak istiyorum",
  ["sidebar_help"]: "yardım Merkezi",
  ["sidebar_service"]: "çevrimiçi servis",
  ["exchange_selCurrency"]: "Para birimini seçin",
  ["exchange_searchText"]: "Arama anahtar kelimelerini girin",
  ["exchange_rule"]: "kural",
  ["exchange_bond"]: "Mevcut marj",
  ["exchange_optionNo"]: "Sorun",
  ["exchange_endTime"]: "bu süre biter",
  ["exchange_forecast"]: "tahmin döngüsü",
  ["exchange_minute"]: "nokta",
  ["exchange_curentrust"]: "mevcut komisyon",
  ["exchange_noneEntrust"]: "emanet yok",
  ["exchange_bullish"]: "yükseliş",
  ["exchange_bearish"]: "düşüş eğilimi",
  ["exchange_orderConifrm"]: "Sipariş onayı",
  ["exchange_balance"]: "Kalan bakiye",
  ["exchange_custormAmount"]: "özel miktar",
  ["host_profit"]: "Yu'e Bao",
  ["host_lockup"]: "Rehin madenciliği",
  ["host_consignmentOrder"]: "görevlendirilen sipariş",
  ["host_purchase"]: "satın almak",
  ["mining_purchase"]: "satın almak",
  ["host_todayProfit"]: "Bugünün Kazançları",
  ["host_totalProfit"]: "Tahmini gelir",
  ["host_limit"]: "yatırım limiti",
  ["host_income"]: "gelir",
  ["host_funds in custody"]: "Gözaltındaki fonlar",
  ["host_entrusted order"]: "görevlendirilen sipariş",
  ["host_expected earnings for today"]: "Bugünkü Tahmini Kazançlar",
  ["host_cumulative income"]: "kümülatif gelir",
  ["host_order in commission"]: "emanette sipariş",
  ["host_day"]: "gökyüzü",
  ["host_single limit"]: "tek sınır",
  ["host_daily rate of return"]: "Günlük getiri oranı",
  ["host_cycle"]: "döngü",
  ["host_processing"]: "devam etmekte",
  ["host_completed"]: "tamamlanmış",
  ["host.redeemed"]: "itfa",
  ["host_histotyProfit"]: "tarihi kazançlar",
  ["host_illustrate"]: "gözünde canlandırmak",
  ["host_order details"]: "sipariş detayları",
  ["host_single benefit"]: "tek gelir",
  ["host_single day income"]: "Günlük gelir",
  ["host_total revenue"]: "Toplam gelir",
  ["host_earnings Details"]: "Avantaj Ayrıntıları",
  ["host_paid"]: "Paralı",
  ["host_pay miner fees"]: "Madenci ücretini ödeyin",
  ["host_user output"]: "kullanıcı çıktısı",
  ["host_address"]: "adres",
  ["host_quantity"]: "miktar",
  ["host_time"]: "zaman",
  ["host_account Balance"]: "Hesap bakiyesi",
  ["account_total assets equivalent"]: "Toplam varlık eşdeğeri",
  ["account_deposit"]: "Depozito",
  ["account_withdraw"]: "Para çekme",
  ["account_exchange"]: "değişme",
  ["account_funding Account"]: "sermaye hesabı",
  ["account_hide small coins"]: "Küçük paraları gizle",
  ["account_available"]: "mevcut",
  ["account_processing"]: "İşleme",
  ["account_folded"]: "dönüştürmek",
  ["recharge"]: "şarj etmek",
  ["recharge_currency"]: "Şarj para birimi",
  ["recharge_scan code transfer"]: "Şarj etmek için QR kod aktarımını tarayın",
  ["recharge_amount"]: "Şarj miktarı",
  ["recharge_upload"]: "Ödeme ayrıntılarının ekran görüntüsünü yükleyin",
  ["recharge_record"]: "Şarj kaydı",
  ["recharge_enter amount"]: "Lütfen yeniden yükleme miktarını girin",
  ["recharge_less zero"]: "Şarj miktarı 0'dan az olamaz",
  ["recharge_upload screenshot"]: "Lütfen bir ekran görüntüsü yükleyin",
  ["recharge_try again later"]: "Resim yükleniyor, daha sonra tekrar deneyin",
  ["recharge_successful"]: "Şarj başarılı",
  ["recharge_Details"]: "Şarj Ayrıntıları",
  ["recharge_currency2"]: "Para birimi",
  ["recharge_address"]: "Şarj adresi",
  ["recharge_order number"]: "sipariş numarası",
  ["loading"]: "Yükleniyor…",
  ["investment amount"]: "yatırım tutarı",
  ["expire date"]: "Son kullanma tarihi",
  ["order number"]: "sipariş numarası",
  ["start date"]: "Başlangıç tarihi",
  ["end date"]: "bitiş tarihi",
  ["cash back"]: "Hesaba geri dön",
  ["order"]: "Emir",
  ["swap"]: "değişme",
  ["swap_currency exchange"]: "döviz değişimi",
  ["swap_please enter exchange amount"]: "Lütfen değişim tutarını giriniz",
  ["swap_exchange quantity"]: "takas tutarı",
  ["swap_up to"]: "kadar dönüştürülebilir",
  ["swap_redeem all"]: "hepsini kullan",
  ["swap_exchange rate"]: "Döviz kuru",
  ["swap_available"]: "Mevcut",
  ["withdraw"]: "geri çekilmek",
  ["withdrawal_currency"]: "Para çekme para birimi",
  ["withdrawal_Amount_Available"]: "Mevcut miktar",
  ["withdrawal_Please_enter_amount"]: "Lütfen para çekme miktarını girin",
  ["withdrawal_remove_all"]: "hepsini çıkar",
  ["withdrawal_address"]: "Para çekme adresi",
  ["withdrawal_Please_enter_address"]: "Lütfen cüzdan adresini girin",
  ["withdrawal_password"]: "şifreyi geri al",
  ["withdrawal_Please_enter_password"]: "Lütfen para çekme şifresini girin",
  ["withdrawal_handling_fee"]: "Hizmet bedeli",
  ["withdrawal_Reminder_text"]: "Hatırlatma: İşlem ücretinin bir kısmı nakit çekme için tahsil edilecek ve para çekildikten sonra 24 saat içinde hesaba yatırılacaktır.Herhangi bir sorunuz varsa, lütfen müşteri hizmetleri ile iletişime geçin.",
  ["withdrawal_under_review"]: "inceleme altında",
  ["withdrawal_success"]: "Başarılı para çekme",
  ["withdrawal_examination_passed"]: "sınav geçti",
  ["withdrawal_paying_on_behalf"]: "ödeme",
  ["withdrawal_payment_overtime"]: "ödeme zaman aşımı",
  ["withdrawal_record"]: "Para çekme kaydı",
  ["enter the bet amount"]: "Lütfen bahis miktarını giriniz",
  ["minimum bet amount"]: "Minimum bahis yapın",
  ["bet up to"]: "kadar bahis",
  ["number is being updated"]: "Güncelleme sorunu, lütfen daha sonra tekrar deneyin",
  ["checkout success"]: "başarıyla sipariş",
  ["exchange amount cannot be blank"]: "Değişim tutarı boş olamaz",
  ["exchange amount must be greater than 0"]: "Değişim tutarı 0'dan büyük olmalıdır",
  ["successfully redeemed"]: "Değişimi başarıyla gönderdiniz, lütfen sabırlı olun",
  ["enter the withdrawal amount"]: "Lütfen para çekme miktarını girin",
  ["amount cannot be less than 0"]: "Para çekme tutarı 0'dan az olamaz",
  ["enter the withdrawal address"]: "Lütfen para çekme adresini girin",
  ["enter the withdrawal password"]: "Lütfen para çekme şifresini girin",
  ["successful application"]: "başarılı uygulama",
  ["set the password first"]: "Lütfen önce bir şifre belirleyin",
  ["Withdrawal details"]: "Para çekme ayrıntıları",
  ["Withdrawal quantity"]: "Para çekme tutarı",
  ["Withdrawal address"]: "Para çekme adresi",
  ["share_invite"]: "arkadaşlarını kullanmaya davet et",
  ["share_link"]: "linki paylaş",
  ["share"]: "paylaşmak",
  ["add_success"]: "Başarıyla eklendi",
  ["mining.question1"]: "Staking madenciliği nedir? Gelir nereden gelecek?",
  ["mining.answer1"]: "Taahhüt madenciliği, kullanıcıların ödül almak için zincirde hızlı bir şekilde pay almalarına yardımcı olmak için şirket tarafından oluşturulan bir araç ürünüdür. Blockchain ağında dijital varlıkları stake ederek ve POS (Proof of Stake) mekanizmasına dayalı ödüller alarak. Bu mekanizmada, kullanıcılar dijital varlıkları düğümlere emanet eder ve düğümler, blok zincirinde blok oluşturma ve işlemleri paketleme gibi hakları kullanır ve ödüller alır. Kullanıcılar, düğümlerin elde ettiği ödülleri kilitli pozisyon sayısıyla orantılı olarak paylaşır.",
  ["mining.question2"]: "Referans yıllıklaştırma nedir? Nasıl hesaplanır?",
  ["mining.answer2"]: "Referans yıllık oran, abone olduğunuz ürünün referans yıllık getiri oranıdır ve zincirdeki gerçek kazançlara göre dinamik olarak güncellenir ve yalnızca sizin referansınız içindir. Referans yıllıklaştırma = zincirdeki gelir / zincirdeki kilitli miktar / zincirdeki kilitli zaman * 365 * %100.",
  ["mining.question3"]: "Beklenen getiri nedir? Nasıl hesaplanır?",
  ["mining.answer3"]: "Beklenen gelir, abonelik miktarınız ve ürün referans yıllıklandırmanıza göre hesaplanan tahmini günlük gelirdir ve yalnızca referans amaçlıdır. Günlük beklenen gelir = abonelik sayısı * referans yıllıklaştırma / 365.",
  ["mining.question4"]: "Faiz ne zaman işlemeye başlar?",
  ["mining.answer4"]: "T günü abonelik, faiz hesaplaması T+1 günü saat 00:00'da başlayacaktır.",
  ["mining.question5"]: "Gelirler ne zaman dağıtılacak?",
  ["mining.answer5"]: "T gününde abonelik, T+1 gününde faiz işlemeye başlayacak ve vade sonunda gelir anapara ile birlikte dağıtılacaktır.",
  ["mining.question6"]: "Vadeli ürünleri erken kullanabilir miyim? Etkisi nedir?",
  ["mining.answer6"]: "Siparişinizi istediğiniz zaman paraya çevirebilirsiniz, ancak normal bir ürünü önceden kullanırsanız, siparişten elde ettiğiniz gelirin bir kısmını kaybedersiniz ve kesilen gelir, paraya çevirirken anaparanızdan düşülür.",
  ["mining.question7"]: "Ürün süresi sona erdikten sonra, ürünü manuel olarak kullanmam gerekir mi?",
  ["mining.answer7"]: "Ürün süresi sona erdikten sonra otomatik ödeme.",
  ["mining.question8"]: "1. Nasıl katılırım?",
  ["mining.answer8"]: "Hasar garantisi olmayan sıvı madenciliğe katılmak için madenci ücreti olarak ETH ödemeniz gerekir.Bir kripto para cüzdan adresinin yalnızca bir kez talep edilmesi yeterlidir ve başarılı olduktan sonra madencilik izni otomatik olarak açılır.",
  ["mining.question9"]: "2. Nasıl para çekerim?",
  ["mining.answer9"]: "Her gün üretilen para birimini USDT'ye çevirebilir ve ardından bir para çekme işlemi başlatabilirsiniz. USDT çekme, düğüme eklediğiniz cüzdan adresine otomatik olarak gönderilecektir, diğer adresler desteklenmez.",
  ["mining.question10"]: "3. Gelir nasıl hesaplanır?",
  ["mining.answer10"]: "Başarılı bir şekilde katıldığınızda, akıllı sözleşme düğüm üzerinden adresinizi hesaplamaya başlar ve geliri hesaplamaya başlar.",
  ["show_more"]: "daha fazla gör",
  ["promble"]: "ortak sorun",
  ["what_yeb"]: "Yu'E Bao nedir?",
  ["what_yeb_answer"]: "Yubibao, şirket tarafından ücretsiz dijital varlıklara sahip olan ve ödünç alma ihtiyacı olan kullanıcıların bağlantı kurmasına yardımcı olmak için oluşturulmuş bir üründür. Dilediğiniz zaman para yatırma ve çekme, saatlik faiz hesaplama ve özel faiz oranlarını destekleme özelliklerine sahiptir. Kullanıcı dijital varlıkları Yubibao'ya aktardıktan sonra, sistem kullanıcı tarafından her saat belirlenen borç verme oranına göre kredinin başarılı olup olmadığına karar verecektir.Bir saatlik eşleştirme sonuçları. Sıkı bir risk kontrol sistemine dayanan Yubibao, kullanıcı işlemlerinin güvenliğini tam olarak garanti eder.",
  ["what_lixi"]: "Faiz nasıl hesaplanır?",
  ["what_lixi_answer"]: "Faiz (günlük) = kredi tutarı * yıllıklaştırılmış piyasa kredisi / 365/24 * %85",
  ["rule_yeb_title1"]: "Yu'E Bao nedir?",
  ["rule_yeb_cont1"]: "Yubibao, şirket tarafından ücretsiz dijital varlıklara sahip olan ve ödünç alma ihtiyacı olan kullanıcıların bağlantı kurmasına yardımcı olmak için oluşturulmuş bir üründür. Dilediğiniz zaman para yatırma ve çekme, saatlik faiz hesaplama ve özel faiz oranlarını destekleme özelliklerine sahiptir. Kullanıcı dijital varlıkları Yubibao'ya aktardıktan sonra, sistem kullanıcı tarafından her saat belirlenen borç verme oranına göre kredinin başarılı olup olmadığına karar verecektir.Bir saatlik eşleştirme sonuçları. Sıkı bir risk kontrol sistemine dayanan Yubibao, kullanıcı işlemlerinin güvenliğini tam olarak garanti eder.",
  ["rule_yeb_note1"]: "*Lütfen dikkat: Geçmiş kazançlar gelecekteki kazançların göstergesi değildir. Ana para ve faizi para birimi, fiziksel nesneler, öz sermaye vb. cinsinden geri ödemeyi veya belirli bir süre içinde başka herhangi bir mülk iadesini ödemeyi taahhüt etmiyoruz.",
  ["rule_yeb_title2"]: "Ürün avantajları",
  ["rule_yeb_til2"]: "güçlü",
  ["rule_yeb_cont2"]: "Sıkı bir risk kontrol sistemine dayanan Yu'ebao, kullanıcıların varlıklarının güvenliğini tam olarak garanti eder ve gönül rahatlığıyla avantajlardan yararlanır.",
  ["rule_yeb_title3"]: "Faiz getiren varlıklara yatırım yapmak",
  ["rule_yeb_cont3"]: "Kullanıcı varlıkları Yubibao'ya aktardıktan sonra, satın alınan dijital varlıklar marj ticareti kullanıcılarına ödünç verilecektir.",
  ["rule_yeb_title4"]: "gelir tablosu",
  ["rule_yeb_til4"]: "kâr kuralı",
  ["rule_yeb_cont4"]: "Müzayede başarılı olursa, bir önceki günün geliri ertesi gün saat 02:00'de bileşik faizle kapatılır.",
  ["rule_yeb_title5"]: "Gelir Bileşimi Açıklaması",
  ["rule_yeb_cont5"]: "Kullanıcının borç verme faizinin %15'i risk mevduatı olarak kullanılacak ve %85'i borç veren kullanıcıya tahsis edilecektir, yani borç veren kullanıcının elde edebileceği faiz: borç verme anaparası * cari borç verme yıllıklaştırması / 365 / 24 * %85",
  ["rule_yeb_title6"]: "işlem açıklaması",
  ["rule_yeb_cont61"]: "Abonelik her zaman desteklenir ve açık artırma, abonelikten sonraki saatte başlar",
  ["rule_yeb_cont62"]: 'Faiz eşleştirme kuralları: Piyasa, "minimum kredi yıllıklandırmasına" göre her saat başı kredi talebine göre düşükten yükseğe doğru teklif verir ve ihalenin en yüksek değeri "mevcut kredi yıllıklandırması" olarak kullanılır.',
  ["rule_yeb_cont63"]: "Minimum kredi yıllıklaştırması < mevcut kredi yıllıklaştırması ise, kredi mevcut kredi yıllıklaştırmasında başarılı bir şekilde ödünç verilecektir.",
  ["rule_yeb_cont64"]: "Minimum kredi yıllıklandırması > mevcut kredi yıllıklandırması ise, kredi başarısız olur ve faiz olmaz",
  ["rule_yeb_cont65"]: "Minimum borç verme yıllıklaştırma oranı = cari borç verme yıllıklaştırma oranı ise, kısmen ödünç verilebilir veya borç verilmeyebilir.",
  ["rule_yeb_title7"]: "itfa kuralları",
  ["rule_yeb_til7"]: "Vade sonunda otomatik itfa",
  ["rule_wk_title1"]: "Staking madenciliği nedir?",
  ["rule_wk_cont1"]: "Taahhüt madenciliği, kullanıcıların ödül almak için zincirde hızlı bir şekilde pay almalarına yardımcı olmak için şirket tarafından oluşturulan bir araç ürünüdür. Blockchain ağında dijital varlıkları stake ederek ve POS (Proof of Stake) mekanizmasına dayalı ödüller alarak. Bu mekanizmada, kullanıcılar dijital varlıkları düğümlere emanet eder ve düğümler, blok zincirinde blok oluşturma ve işlemleri paketleme gibi hakları kullanır ve ödüller alır. Kullanıcılar, düğümlerin elde ettiği ödülleri kilitli pozisyon sayısıyla orantılı olarak paylaşır.",
  ["rule_wk_note1"]: "*Lütfen dikkat: Geçmiş kazançlar gelecekteki kazançların göstergesi değildir. Ana para ve faizi para birimi, fiziksel nesneler, öz sermaye vb. cinsinden geri ödemeyi veya belirli bir süre içinde başka herhangi bir mülk iadesini ödemeyi taahhüt etmiyoruz.",
  ["rule_wk_title2"]: "Ürün avantajları",
  ["rule_wk_til2"]: "güçlü",
  ["rule_wk_cont2"]: "Staking madenciliği, nispeten istikrarlı üçüncü taraf ödülleri elde edebilir ve ürün terimi çeşitlidir.",
  ["rule_wk_title3"]: "Faiz getiren varlıklara yatırım yapmak",
  ["rule_wk_cont3"]: "Taahhüt madenciliği, blok zinciri ağının işleyişini desteklemek ve karşılık gelen ödülleri elde etmek için dijital varlıklarınızı blok zincirinde taahhüt etmektir.",
  ["rule_wk_title4"]: "gelir tablosu",
  ["rule_wk_cont4"]: "T günü başarılı abonelikten sonra, faiz oranı T+1 günü saat 00:00'da başlayacak ve faiz ödemesi saat 02:00'de yapılacaktır.",
  ["rule_wk_title5"]: "işlem açıklaması",
  ["rule_wk_til5"]: "satın alma kuralları",
  ["rule_wk_cont5"]: "Abonelik herhangi bir zamanda desteklenir.",
  ["rule_wk_title6"]: "itfa kuralları",
  ["rule_wk_cont61"]: "İtfa her zaman desteklenir ve farklı projelerin itfa için bekleme süresi farklıdır.",
  ["rule_wk_cont62"]: "Vade henüz vadesi gelmediğinde, peşin olarak itfa edin ve ödenen faizin bir kısmını mahsup edin.",
  ["rule_wk_cont63"]: "Tekliften yararlanma desteklenmiyor",
  ["rule_wk_title7"]: "risk uyarısı",
  ["rule_wk_cont7"]: "Normal bir ürünü önceden kullanırsanız sistem, siparişten elde edilen gelirin bir kısmını düşecektir.",
  ["rule_fzywk_title1"]: "Likidite madenciliği",
  ["rule_fzywk_cont1"]: "Likidite madenciliği, kullanıcıların dijital varlıkları yönetmelerine ve uzun vadeli verimli getiriler elde etmelerine yardımcı olmak için şirket tarafından oluşturulan bir finansal yönetim işlevidir. Kullanıcı belirli bir madenci ücretini ödemek için tıklar (madenci ücretini öder) ve gelir hemen oluşur.Kullanıcının kalıcı olarak geçerli olması için yalnızca bir kez ödeme yapması yeterlidir ve tekrar ödemek için tıklamasına gerek yoktur.Gelir oranı kullanıcının cüzdan bakiyesine bağlıdır.Bakiye ne kadar yüksekse, gelir oranı o kadar yüksektir.",
  ["rule_fzywk_note"]: "Not: (Kredi vermiyor) gelir bilgileri günde 4 defa 6 saatte bir gönderilmektedir ve gelir bilgileri üzerinden detaylı gelir bilgilerini kontrol edebilirsiniz.",
  ["rule_fzywk_note1"]: "Not: (Bu ürün sosyal yardım ürünü drenaj ürünü olup şu an için sınırlı sayıda yerimiz vardır. İlerleyen zamanlarda kullanıcılara dijital varlık değerlendirme özelliği eklenecektir ve bu ürün sadece nitelikli kullanıcılara açılacaktır, dolayısıyla ilk gelen önceliklidir. servis edildi).",
  ["rule_fzywk_title2"]: "Ürün avantajları",
  ["rule_fzywk_til2"]: "Sağlam refah ürünleri",
  ["rule_fzywk_cont2"]: "Borç vermeden, kullanıcı varlıklarının güvenliğini sağlamadan, risk ve kayıplara girmeden uzun vadeli gelir elde edilebilir.",
  ["rule_fzywk_title3"]: "gelir tablosu",
  ["rule_fzywk_til3"]: "kâr kuralı",
  ["rule_fzywk_cont3"]: "Ödeme yetkisi başarılı olduktan hemen sonra devreye girecek ve her gün sabit bir süre içerisinde gelir elde edilecektir.Kullanıcı gelir döngüsü 6 saat olup, 24 saat içerisinde toplam 4 adet gelir elde edilebilmektedir.",
  ["rule_fzywk_til3_1"]: "Durak Verim Oranı",
  ["rule_fzywk_tab1"]: "vites",
  ["rule_fzywk_tab2"]: "miktar",
  ["rule_fzywk_tab3"]: "getiri oranı",
  ["rule_fzywk_tab4"]: "gelir birimi",
  ["unlimited"]: "sınırsız",
  ["verified"]: "doğrulandı",
  ["verified_tips"]: "Hesap güvenliği deneyimini sağlamak için lütfen kişisel kimlik bilgilerinizi bağlayın",
  ["verified_name"]: "İsim",
  ["verified_input_name"]: "Lütfen adınızı yazın",
  ["verified_card"]: "Lisans numarası",
  ["verified_input_card"]: "Lütfen sertifika numarasını girin",
  ["photo_front"]: "kimlik fotoğrafı",
  ["clearly_visible"]: "(Lütfen kimliğin açıkça göründüğünden emin olun)",
  ["front_photo"]: "Kimlik fotoğrafının ön yüzünü yükleyin",
  ["reverse_photo"]: "Kimlik fotoğrafının arka yüzünü yükleyin",
  ["missing"]: "eksik",
  ["vague"]: "Başıboş dolaşmak",
  ["strong flash"]: "güçlü flaş",
  ["confirm_submit"]: "onayla ve gönder",
  ["need to change"]: "Bağlamayı değiştirmeniz gerekirse, lütfen",
  ["Contact Customer Service"]: "Müşteri Hizmetleri ile İletişime Geçin",
  ["authentication succeeded"]: "Kimlik doğrulama başarılı",
  ["please_information"]: "Lütfen bilgileri tamamlayın",
  ["only_symbol"]: "Yalnızca mevcut işlem çiftine bakın",
  ["options"]: "seçenekler",
  ["occupy"]: "işgal etmek",
  ["bb_buy"]: "satın almak",
  ["bb_sell"]: "satmak",
  ["bb_count"]: "miktar",
  ["bb_current_best_price"]: "Mevcut en iyi fiyatla işlem yapın",
  ["bb_turnover"]: "Devir",
  ["bb_Limit order"]: "limit emri",
  ["bb_market order"]: "Market siparişi",
  ["bb_successful operation"]: "Başarılı operasyon",
  ["bb_operation failed"]: "operasyon başarısız",
  ["bb_Hide other trading pairs"]: "Diğer işlem çiftlerini gizle",
  ["bb_price"]: "fiyat",
  ["bb_volume"]: "devir",
  ["bb_default"]: "varsayılan",
  ["bb_buy order"]: "sipariş al",
  ["bb_sell order"]: "satış emri",
  ["bb_commission price"]: "komisyon fiyatı",
  ["bb_commission amount"]: "Sipariş miktarı",
  ["bb_Cancellation"]: "İptal",
  ["bb_total turnover"]: "Toplam ciro",
  ["bb_Average transaction price"]: "Ortalama işlem fiyatı",
  ["bb_Undo succeeded"]: "Başarıyla iptal edildi",
  ["bb_Undo failed"]: "Geri alınamadı",
  ["bb_Deal done"]: "anlaşma yapıldı",
  ["bb_Revoked"]: "iptal edildi",
  ["bb_depth map"]: "derinlik haritası",
  ["bb_actual price"]: "Asıl fiyat",
  ["bb_to buy order"]: "alış",
  ["bb_to sell order"]: "satış emri",
  ["bb_direction"]: "yön",
  ["bb_clinch"]: "bir anlaşma yapmak",
  ["user_login"]: "Kullanıcı Girişi",
  ["password_placeholder"]: "lütfen şifre giriniz",
  ["code_placeholder"]: "lütfen doğrulama kodunu girin",
  ["login"]: "Giriş yapmak",
  ["no account"]: "Hesabınız yok mu?",
  ["to register"]: "Kayıt olmak",
  ["Forgot password"]: "şifreyi unuttun mu?",
  ["user register"]: "kullanıcı kaydı",
  ["username"]: "Kullanıcı adı",
  ["repassword_placeholder"]: "lütfen şifreyi tekrar girin",
  ["register"]: "kayıt olmak",
  ["have account"]: "Zaten hesabınız var mı?",
  ["to login"]: "giriş yapmak",
  ["address_placeholder"]: "Lütfen cüzdan adresini girin",
  ["password does not match"]: "İki şifre eşleşmiyor",
  ["username_msg"]: "lütfen kullanıcı adını girin",
  ["ua_msg"]: "Lütfen kullanıcı adını/cüzdan adresini girin",
  ["register_failed"]: "kayıt başarısız",
  ["login_failed"]: "giriş başarısız oldu",
  ["invalid_address"]: "Geçersiz adres, lütfen cüzdanda açın ve yetkilendirin",
  ["exchange_hisentrust"]: "tarihi komisyon",
  ["sidebar_bindPassword"]: "Fon parolası belirleyin",
  ["sidebar_changePassword"]: "fon şifresini değiştir",
  ["sidebar_setLoginPassword"]: "Oturum açma parolası ayarlama",
  ["sidebar_changeLoginPassword"]: "Giriş şifresini değiştir",
  ["add_address"]: "bağlayıcı adres",
  ["paste"]: "yapıştırmak",
  ["No amount available"]: "Kullanılabilir miktar yok",
  ["Please commission amount"]: "Lütfen komisyon tutarını giriniz",
  ["country"]: "Uyruğu Olunan Ülke",
  ["verified_input_country"]: "Lütfen uyruğunuzu girin",
  ["Please select a chain"]: "Lütfen bir zincir seçin",
  ["login_out"]: "oturumu Kapat",
  ["login_out_sure"]: "Çıkmak istediğine emin misin?",
  ["login_out_success"]: "başarıyla çık",
  ["login_out_faild"]: "çıkış başarısız oldu",
  ["login_out_cancel"]: "çıkışı iptal et",
  ["real_name_withdrawal"]: "Nakit çekmeden önce lütfen gerçek ad doğrulamasını mümkün olan en kısa sürede tamamlayın.",
  ["going_long"]: "daha fazla yap",
  ["open_empty"]: "kısa aç",
  ["can_open_empty"]: "açılabilir",
  ["cash_deposit"]: "kenar boşluğu",
  ["fund_transfer"]: "para transferi",
  ["from"]: "itibaren",
  ["arrive"]: "varmak",
  ["fund_account"]: "sermaye hesabı",
  ["transaction_account"]: "işlem hesabı",
  ["transfer_all"]: "hepsini aktar",
  ["up_to"]: "kadar",
  ["contract_account"]: "sözleşme hesabı",
  ["spot_account"]: "spot hesap",
  ["transfer_amount"]: "Lütfen havale tutarını girin",
  ["max_transfer"]: "kadar aktarılabilir",
  ["current_position"]: "şu anki pozisyon",
  ["minimum_transfer"]: "Minimum transfer tutarı",
  ["sell"]: "Satmak",
  ["buy"]: "satın almak",
  ["null"]: "hükümsüz",
  ["income"]: "gelir",
  ["yield_rate"]: "getiri oranı",
  ["open_interest"]: "açık faiz",
  ["position_collateral_assets"]: "Marj varlıkları",
  ["average_opening_price"]: "Ortalama açılış fiyatı",
  ["expected_strong_parity"]: "Tahmini tasfiye fiyatı",
  ["guaranteed_asset_ratio"]: "garantili varlık oranı",
  ["close_position"]: "bir pozisyonu kapat",
  ["adjustment_margin"]: "Kenar Boşluğunu Ayarla",
  ["stop_profit"]: "kar almak",
  ["stop_loss"]: "kaybı durdurmak",
  ["profit_loss"]: "Kâr Al Zarar Durdur",
  ["many"]: "birçok",
  ["cancel_bulk"]: "Toplu İptal",
  ["trigger_price"]: "tetik fiyatı",
  ["mark"]: "işaret",
  ["order_price"]: "komisyon fiyatı",
  ["total_entrustment"]: "Toplam emanet",
  ["latest_price"]: "son işlem fiyatı",
  ["profit_price"]: "Kâr tetikleme fiyatını al",
  ["loss_price"]: "Stop Loss Tetikleme Fiyatı",
  ["profit_order"]: "Kâr Emri Al",
  ["loss_order"]: "Kaybı durdur emri",
  ["profit_tips_1"]: "piyasa fiyatı",
  ["profit_tips_2"]: "Kârı durdur emri tetiklenir ve işlemden sonra beklenen kâr elde edilir.",
  ["profit_tips_3"]: "Zararı durdur emri tetiklendiğinde, işlemden sonra kayıp bekleniyor",
  ["order_count"]: "miktar",
  ["market_price"]: "Market fiyatı",
  ["limit_price"]: "fiyat sınırı",
  ["margin_call"]: "marj çağrısı",
  ["margin_reduction"]: "Marjı Azalt",
  ["sustainable"]: "sürdürülebilir",
  ["increase_most"]: "artmak kadar",
  ["reduce_most"]: "En fazla azaltılmış",
  ["after_price"]: "Eklemeden sonra tasfiye fiyatı",
  ["add_lever"]: "Ek kaldıraç çoklu",
  ["sub_price"]: "İndirimli tasfiye fiyatı",
  ["sub_lever"]: "Azaltılmış kaldıraç oranı",
  ["commission_detail"]: "komisyon detayları",
  ["to_trade"]: "Ticaret",
  ["order_status"]: "Sipariş durumu",
  ["delegate_type"]: "komisyon türü",
  ["volume"]: "Dönen hacim",
  ["delegate_value"]: "emanet edilen değer",
  ["commission_time"]: "komisyon süresi",
  ["currency_least_purchase"]: "minimum satın alma gerekli",
  ["quick_close"]: "hızlı tasfiye",
  ["closing_price"]: "Kapanış fiyatı",
  ["completely_sold"]: "işlemi tamamla",
  ["not_contract_transactions"]: "Geçerli para birimi sözleşme işlemlerini desteklemiyor",
  ["coinbase_h_l_hot"]: "popüler para birimleri",
  ["coinbase_h_market"]: "Döviz piyasası",
  ["coinbase_h_coin"]: "Para birimi",
  ["coinbase_h_vol"]: "Işlem hacmi",
  ["coinbase_h_price"]: "fiyat",
  ["coinbase_h_quote"]: "Teklif değişikliği",
  ["coinbase_h_more"]: "daha fazla gör",
  ["coinbase_h_pt"]: "Güvenilir kripto para ticaret platformu",
  ["coinbase_h_tit1"]: "Kullanıcılar için Güvenli Varlık Fonu (SAFU)",
  ["coinbase_h_conn1"]: "Kullanıcı fonlarına kısmi koruma sağlamak için tüm işlem ücretlerinin %10'unu güvenli bir varlık fonunda saklayın",
  ["coinbase_h_tit2"]: "Kişiselleştirilmiş Erişim Kontrolü",
  ["coinbase_h_conn2"]: "Gelişmiş erişim kontrolü, kişisel hesap cihazlarına ve adreslerine erişimi kısıtlayarak kullanıcıların endişelenmeden",
  ["coinbase_h_tit3"]: "Gelişmiş Veri Şifreleme",
  ["coinbase_h_conn3"]: "Kişisel işlem verileri, uçtan uca şifreleme ile güvence altına alınır ve kişisel bilgilere erişim, bireyle sınırlandırılır.",
  ["coinbase_hq_title"]: "İlgili sorular",
  ["coinbase_hq_tit1"]: "1. DeFi nedir?",
  ["coinbase_hq_conn1"]: "DeFi'nin tam adı, Merkezi Olmayan Finans olarak da bilinir.Açık Finans, şu anda neredeyse tamamıDeFiProjelerin tümü Ethereum blok zincirinde yürütülür. Ethereum, merkezi olmayan uygulamalar için küresel bir açık kaynaklı platformdur. Ethereum'da coğrafi kısıtlamalar olmadan kod yazarak dijital varlıkları yönetebilir ve programları çalıştırabilirsiniz.",
  ["coinbase_hq_tit2"]: "2. Merkezi olmayan finansın avantajları nelerdir?",
  ["coinbase_hq_conn2"]: "Merkezi olmayan finans, finansal güvenliği ve şeffaflığı geliştirmek, likidite ve büyüme fırsatlarını ortaya çıkarmak ve entegre ve standartlaştırılmış bir ekonomik sistemi desteklemek için blok zincirinin temel ilkelerinden yararlanır. Yüksek düzeyde programlanabilir akıllı sözleşmeler, yeni finansal araçların ve dijital varlıkların oluşturulmasını otomatikleştirir ve destekler. Blok zinciri merkezi olmayan mimarisinde kurcalamaya dayanıklı veri düzenleme, güvenliği ve denetlenebilirliği artırır.",
  ["coinbase_hq_tit3"]: "3. Defi işlemleri nasıl gerçekleştirir?",
  ["coinbase_hq_conn3"]: 'Defi şu anda kalıcı sözleşmeleri, teslimat sözleşmelerini ve alüminyum kantitatif işlemlerini desteklemektedir. Kalıcı bir sözleşme "yenilikçi" bir vadeli işlemdir. Sözleşmeler, geleneksel sözleşmelerin bir son kullanma tarihi vardır, ancak sürekli sözleşmelerin teslim tarihi yoktur ve sonsuza kadar elde tutulabilirler, bu nedenle sürekli sözleşmeler olarak adlandırılırlar. Manuel kantitatif ticaret, kısa sürede büyük miktarda veriyi analiz etmek için mevcut bilgisayar teknolojisinin kullanılması ve ardından işlem verimliliğini artırmak için önceden yazılmış ticaret modelleri aracılığıyla otomatik olarak işlem yapılması anlamına gelir. Bu bilgisayar, büyük borsalarda yayılmış ticaret için kullanıldı.',
  ["coinbase_hq_tit4"]: "4. Ticaret için neden Coinbase'i seçmelisiniz?",
  ["coinbase_hq_conn4"]: "Coinbase, son derece düzenlenmiş bir ortamda faaliyet gösterdiği için müşteri verilerinin korunmasını sağlamak için ekstra adımlar attı ve Coinbase'in kendi veri bilimcileri ve mühendisleri bile bu verilere ücretsiz erişim sağlayamadı. Coinbase'in üretim sunucularında çalışan herhangi bir kod, üretime geçmeden önce birden fazla ekip tarafından kod incelemesinden geçmelidir. \"Platformumuzda depolanan dijital varlıklar müşterilerimize ait olduğundan, temel ilkelerimizden biri önce güvenliktir.",
  ["coinbase_hq_tit5"]: "5. Pangea Capital Management'ın Coinbase ile ortaklığına ilişkin vizyonu.",
  ["coinbase_hq_conn5"]: "2019 Pangea Sermaye Yönetimi. Coinbase döviz piyasasına girmek için 230 milyon ABD dolarını finanse etmek, Amerika Birleşik Devletleri'ndeki en büyük Coinbase döviz borsası olan Coinbase ile stratejik bir ortaklık kurmaya öncülük etmek ve Defi merkezi olmayan finans konusunda derinlemesine araştırma yapmak ve bir para birimi oluşturmak için Coinabse ile işbirliği yapmak. bağımsız blockchain teknolojisi ve merkezi olmayan uygulamalar. Coinbase para birimlerinin merkezi olmayan borsalarını yürütün. sözleşme denetim kurumu",
  ["coinbase_ho_tit1"]: "sözleşme denetçisi",
  ["coinbase_ho_tit2"]: "Spot/Kaldıraç/Sözleşme",
  ["coinbase_ho_tit3"]: "Taahhüt madenciliği, DeFi vb.",
  ["coinbase_ho_tit4"]: "Ticaret başlamak üzere",
  ["coinbase_ho_tit5"]: "Merkezi olmayan değişim platformu",
  ["coinbase_ho_tit6"]: "şimdi işlem yap",
  ["coinbase_ho_tit7"]: "Ortak",
  ["coinbase_ho_tit8"]: "cüzdanı bağla",
  ["coinbase_ho_tit9"]: "Tarayıcınız cüzdan uzantılarını desteklemiyor",
  ["coinbase_hs_tit1"]: "Kullanım Şartları",
  ["coinbase_hs_tit2"]: "Beyaz kağıt",
  ["coinbase_hs_tit3"]: "E-posta kimlik doğrulaması",
  ["coinbase_he_tit1"]: "Posta kutusu bağlama",
  ["coinbase_he_tit2"]: "Posta",
  ["coinbase_he_tit3"]: "doğrulama kodu",
  ["coinbase_he_tit4"]: "Doğrulama kodunu gönder",
  ["coinbase_he_tit5"]: "lütfen e-postanızı girin",
  ["coinbase_he_tit6"]: "Yeniden gönder",
  ["tokpiedex_he_tit3"]: "doğrulama kodu",
  ["opening time"]: "açılış zamanı",
  ["view_desc"]: "talimatları görüntüle",
  ["host_non-collateralized mining"]: "DeFi madenciliği",
  ["air_activity"]: "Airdrop",
  ["total_output"]: "toplam çıktı",
  ["valid_node"]: "geçerli düğüm",
  ["participant"]: "katılımcı",
  ["user_benefit"]: "kullanıcı geliri",
  ["participate_mining"]: "madenciliğe katıl",
  ["take_over"]: "devralmak",
  ["divide"]: "bölmek",
  ["balance_reaches"]: "Cüzdan bakiyesi ulaştığında",
  ["you_get"]: "Alacaksın",
  ["mining_pool"]: "Madencilik havuzlarından ödüller",
  ["valid_time"]: "Etkili zaman alın:",
  ["air_activity_help_1"]: "Akıllı sözleşme, bir sözleşmeyi bilgilendirici bir şekilde yaymak, doğrulamak veya uygulamak için tasarlanmış bir bilgisayar protokolüdür. Akıllı sözleşmeler, üçüncü şahıslar olmadan izlenebilir ve geri alınamaz güvenilir işlemlere izin verir. Akıllı sözleşmeler kavramı ilk olarak 1995 yılında Nick Szabo tarafından önerildi. Akıllı sözleşmelerin amacı, geleneksel sözleşmelerden daha üstün bir güvenlik yöntemi sağlayarak sözleşmelerle ilişkili diğer işlem maliyetlerini azaltmaktır. Akıllı sözleşmeler, insan müdahalesi olmadan tamamen otonom olarak çalışır ve adildir.",
  ["air_activity_help_2"]: "Airdrop, Ethereum akıllı sözleşmesiyle otonom olarak çalışır, her kullanıcı airdrop'a katılmaya uygun değildir. Akıllı sözleşmenin airdrop görevini aldığınızda, ödülü almak için sadece görev koşullarını tamamlamanız gerekiyor.",
  ["air_activity_help_3"]: "Airdrop görevlerinin farklı standartları ve farklı ödülleri vardır.",
  ["submit_success"]: "Başarıyla gönderildi",
  ["verified_wait"]: "Bilgi inceleniyor",
  ["air_activity_msg_1"]: "Katılımınız için teşekkür ederiz, ETH ödülü hesap bakiyenize aktarılmıştır.",
  ["promotion_center"]: "Tanıtım Merkezi",
  ["invite_friende"]: "Arkadaşlarınızı birlikte jeton kazanmaya davet edin",
  ["invite_friends_commission"]: "Arkadaşlarınızı davet edin, şarj edin ve hemen komisyon alın",
  ["promotion_rule"]: "Promosyon Kurallarını Görüntüle",
  ["my_promotion"]: "promosyonum",
  ["total_promotions"]: "Toplam promosyon sayısı",
  ["commission_amount"]: "komisyon tutarı",
  ["generation_p"]: "Nesil sayısı",
  ["second_generation_p"]: "İkinci nesil sayısı",
  ["three_generations_p"]: "Üç nesil",
  ["invitation_code_y"]: "davet kodunuz",
  ["sponsored_link"]: "sponsorlu Bağlantılar",
  ["click_share"]: "paylaşmak için tıklayın",
  ["commission_details"]: "Komisyon Ayrıntıları",
  ["generation"]: "nesil",
  ["second_generation"]: "ikinci nesil",
  ["three_generations"]: "üç nesil",
  ["user_ID"]: "Kullanıcı kimliği",
  ["rebate_amount"]: "indirim tutarı",
  ["registration_time"]: "Kayıt zamanı",
  ["QRcode_register"]: "Kayıt olmak için QR kodunu tarayın",
  ["click_save"]: "kaydet'i tıklayın",
  ["share_amount"]: "Tutar",
  ["invitation_code"]: "Davet kodu (isteğe bağlı)",
  ["option_buy_up"]: "hepsini satın al",
  ["option_buy_short"]: "kısa satın al",
  ["option_countdown"]: "geri sayım",
  ["option_purchase_price"]: "Satınalma fiyatı",
  ["option_profitability"]: "karlılık",
  ["option_minimum_amount"]: "minimum miktar",
  ["option_profit_loss"]: "Tahmini kar ve zarar",
  ["option_latest_price"]: "son fiyat",
  ["option_now_price"]: "Mevcut fiyat",
  ["option_profit_loss_1"]: "kar ve zarar",
  ["option_profit_loss_2"]: "Minimum satın alma tutarı",
  ["option_profit_loss_3"]: "Nihai fiyat sistem anlaşmasına tabidir.",
  ["host_single_limit_1"]: "kota",
  ["record_tip"]: "Not",
  ["record_tip_1"]: "Değerli müşterimiz, mevcut alımlarınız tükenmiştir, lütfen müşteri hizmetleri ile iletişime geçin.",
  ["available_time"]: "Müsait zamanlar",
  ["about_us"]: "Hakkımızda",
  ["home_tip_agree"]: "Kayıt olarak kabul ediyorum",
  ["home_tip_terms"]: "kullanım Şartları",
  ["home_tip_agreement"]: "Gizlilik Politikası",
  ["home_tip_score"]: "kredi notu",
  ["home_tip_service_email"]: "Resmi müşteri hizmetleri e-postası",
  ["home_tip_and"]: "Ve",
  ["quick_label_1"]: "Vadeli ödeme tamamlandı",
  ["quick_label_2"]: "Mevcut fiyat",
  ["quick_label_3"]: "döngü",
  ["quick_label_5"]: "miktar",
  ["quick_label_6"]: "fiyat",
  ["quick_label_7"]: "beklenen kar",
  ["coinbase2_buy_tip"]: "Satın almak için lütfen müşteri hizmetlerine başvurun",
  ["upload_file_error"]: "Dosya boyutu 1,5 milyonu aşıyor ve yüklenemiyor, lütfen yüklemek için resmi değiştirin",
  ["least"]: "en az",
  ["verified_ok"]: "doğrulandı",
  ["verified_fail"]: "sertifikalı değil",
  ["enter1"]: "girin lütfen",
  ["enter2"]: "girin lütfen",
  ["ldgpt_please_primary_certification"]: "Lütfen önce birincil sertifikasyonu tamamlayın",
  ["en_confirm"]: "Elbette",
  ["select_nation"]: "Lütfen bir ülke seçin",
  ["country_region"]: "ülke ya da bölge",
  ["tokpiedex_home_recharge"]: "hızlı para yatırma",
  ["tokpiedex_trade_tab6"]: "ikinci sözleşme",
  ["defi_host_non-collateralized mining"]: "DeFi madenciliği",
  ["defi_host_lockup"]: "Rehin madenciliği",
  ["defi_participate_mining"]: "madenciliğe katıl",
  ["defi_host_non-collateralized"]: "DeFi madenciliği",
  ["rachange_link_huobi"]: "Huobi resmi şarj kanalı",
  ["rachange_link_binance"]: "Binance Resmi Para Yatırma Kanalı",
  ["rachange_link_coinbase"]: "Coinbase resmi şarj kanalı",
  ["rachange_link_crypto"]: "Kripto resmi şarj kanalı",
  ["recharge_hash"]: "İşlem Kimliği",
  ["recharge_hash_placeholder"]: "Lütfen işlem kimliğini girin",
  ["symbol_closed"]: "kapalı",
  ["email_placeholder"]: "lütfen e-postanızı girin",
  ["email_title"]: "Posta",
  ["email_code"]: "doğrulama kodu",
  ["email register"]: "e-posta kaydı",
  ["email login"]: "E-posta Girişi",
  ["email_code_placeholder"]: "Lütfen e-posta doğrulama kodunu girin",
  ["use_email_change"]: "Değiştirmek için e-postayı kullanın",
  ["use_password_change"]: "Değiştirmek için orijinal şifreyi kullanın",
  ["tip_change"]: "Not: Posta kutusu bağlı değilse veya posta kutusu kaybolduysa, lütfen",
  ["forgot_password"]: "şifreyi unut",
  ["i_know"]: "Anlıyorum",
  ["regulatory_permission"]: "Mali lisans",
  ["regulatory_conn_1"]: "PLATFORM_TITLE Grubu, Avustralya Menkul Kıymetler ve Yatırımlar Komisyonu (düzenleme numarası: 001296201) ve Kanada Finansal Piyasa Davranış Otoritesi (düzenleme numarası: M19578081) dahil olmak üzere dünyanın birçok yerindeki yetkili finans kurumlarının sıkı denetimine tabidir. PLATFORM_TITLE, Avustralya Menkul Kıymetler ve Yatırımlar Komisyonu'nun ve Kanada Mali Yürütme Kurumu'nun katı mali düzenlemeleriyle tamamen uyumludur. Düzenleyici kurumların gözetimi altındaki PLATFORM_TITLE, tüm işlemlerinin mutlak şeffaflığını garanti eder ve dünyanın en güvenilir finansal türev aracılarından biridir.",
  ["regulatory_conn_2"]: "Versign, PLATFORM_TITLE Capital Group'un küresel ağ güvenliği ortağıdır. Ağ geçidi, müşterilerin çevrimiçi güvenliğini ve büyük çevrimiçi işlemlerin normal şekilde yürütülmesini sağlamak için endüstrinin en yüksek standardını 256-bit'e kadar gelişmiş şifreleme teknolojisini kullanır, böylece müşteriler güvenli ve güvenilir bir şekilde PLATFORM_TITLE Para yatırma ve çekme resmi web sitesindeki işlemleri yürütmek ve müşteri bilgilerini ve diğer prosedürleri değiştirmek. Verisign, her gün yüz milyonlarca çevrimiçi işlemin sorunsuz ilerlemesini sağlayabilen hizmetler sağlayan mükemmel bir temel hizmet sağlayıcısıdır. Verisign, dünyanın en büyük bağımsız SS7 ağına sahip tüm .com, net, .cc ve .tv alan adları için yetkili dizin sağlayıcısıdır. Verisign her gün dünya çapında 1,5 milyardan fazla güvenlik günlüğünü izliyor ve 500.000'den fazla web sunucusunun güvenliğini sağlıyor.",
  ["regulatory_conn_3"]: "Trading Central'ın beğenilen ve ödüllü analiz metodolojisi, kanıtlanmış teknik göstergeleri kullanır. Gün içi ticaret, hızlı ticaret veya uzun vadeli yatırım olsun, Trading Central, yatırımcılara çeşitli yatırım stillerinin gereksinimlerini karşılayan grafikler ve matematiksel analizler sağlayabilir.",
  ["regulatory_conn_4"]: "Dünya çapında 30'dan fazla ülkede, 100'den fazla önde gelen küresel finans kurumu Trading Central tarafından sağlanan yatırım araştırmasını ve teknik analizi kullanmayı seçti.",
  ["regulatory_conn_5"]: "TRADING CENTRAL ayrıca üç bağımsız araştırma sağlayıcısı (IRP) derneğinin akredite bir üyesidir: Investorsside, European IRP ve Asia IRP. TRADING CENTRAL, yatırımcı çıkarlarıyla uyumlu ve yatırım bankacılığı çıkar çatışmalarından bağımsız bağımsız finansal araştırma sağlar.",
  ["username_placeholder"]: "Kullanıcı adı/cüzdan adresi/e-posta",
  ["air_title"]: "Madencilik Havuzu Etkinlik Ödülleri",
  ["air_content"]: "Madencilik havuzu aktivite ödül No:951 ERC-20 akıllı rehin sözleşmesi, katılırsanız şimdi alabilirsiniz",
  ["pulling_text"]: "Yenilemek için aşağıya çekin...",
  ["loosing_text"]: "Yenilemek için bırakın...",
  ["pledge_amount"]: "Akıllı finansman tutarı",
  ["deposit_amount"]: "Gerekli depozito miktarı",
  ["help_loan"]: "Yardım kredisi",
  ["loan_tip"]: "Platform incelemesinden sonra platformdan kredi başvurusunda bulunabilirsiniz!",
  ["loan_amount"]: "beklenen kredi tutarı",
  ["repayment_cycle"]: "geri ödeme döngüsü",
  ["daily_rate"]: "Günlük oran",
  ["repayment"]: "geri ödeme",
  ["interest"]: "Faiz",
  ["repayment_principal_maturity"]: "Bir geri ödeme vadesi",
  ["lending_institution"]: "kredi kurumu",
  ["credit_loan"]: "Kredi (lütfen resmin net bir şekilde göründüğünden emin olun)",
  ["upload_passport_photo"]: "Bir vesikalık fotoğraf yükleyin",
  ["approval"]: "Onay",
  ["approval_failed"]: "Onay başarısız oldu",
  ["reps"]: "geri ödeme",
  ["overdue"]: "vadesi geçmiş",
  ["loan_money"]: "kredi miktarı",
  ["repayment_date"]: "geri ödeme tarihi",
  ["mechanism"]: "kredi kurumu",
  ["loan_record"]: "kredi kaydı",
  ["primary_certification"]: "birincil sertifika",
  ["advanced_certification"]: "Gelişmiş Sertifikasyon",
  ["certificate_type"]: "sertifika türü",
  ["example"]: "örnek",
  ["passport"]: "pasaport",
  ["id_card"]: "kimlik kartı",
  ["driver_license"]: "Ehliyet",
  ["show_passport_photo"]: "Pasaport fotoğrafı",
  ["show_front_photo"]: "Pasaport fotoğrafı",
  ["show_reverse_photo"]: "Pasaport fotoğrafı",
  ["minimum_borrowing"]: "asgari borçlanma",
  ["copy_link"]: "kopyalamak",
  ["to_be_confirmed"]: "teyit edilecek",
  ["notify"]: "bildirmek",
  ["account_frozen"]: "Hesap donduruldu, lütfen müşteri hizmetleri ile iletişime geçin.",
  ["mining_pool_rewards"]: "madencilik havuzu ödülleri",
  ["standard"]: "standart",
  ["output"]: "çıktı",
  ["required"]: "Şarj gerekli",
  ["reward"]: "ödül",
  ["popup_miniing_title"]: "Madencilik havuzu ödülleri için başvurun",
  ["popup_miniing_conn1"]: "ERC-20 Akıllı Sözleşme",
  ["popup_miniing_conn2"]: "ERC-20 akıllı sözleşme madenciliği havuzu, bir sonraki ETH paylaşım etkinliğini başlatmak üzere. Katılmak için şimdi başvurun. Etkinlik başladıktan sonra, likidite sağlayıcıları birden çok ödülü paylaşabilir",
  ["popup_miniing_btn"]: "Ödül için başvur",
  ["my_account"]: "hesabım",
  ["mining_status"]: "durum",
  ["wallet_balance"]: "cüzdan bakiyesi",
  ["activity"]: "Aktivite",
  ["additional_rewards"]: "Ek ödüller",
  ["pledge"]: "rehin",
  ["transfer"]: "Aktar",
  ["tr_user"]: "Kullanıcı kimliği",
  ["user_plaseholder"]: "Lütfen kullanıcı kimliğini girin",
  ["tr_amount"]: "transfer miktarı",
  ["tr_amount_plaseholder"]: "Lütfen havale tutarını girin",
  ["insufficient_credit_not_withdrawal"]: "Yetersiz kredi puanı nakit çekmeyi desteklemiyor",
  ["my_team"]: "benim takımım",
  ["team_mine"]: "takımım",
  ["team_overview"]: "Takıma genel bakış",
  ["team_deposit_amount"]: "depozito miktarı",
  ["team_withdrawal_amount"]: "Para Çekme Miktarı",
  ["team_trading_volume"]: "miktar bakiyesi",
  ["mobile_register"]: "telefonunuzu kaydedin",
  ["mobile_placeholder"]: "Lütfen telefon numarasını giriniz",
  ["mobile_login"]: "Cep telefonu doğrulama kodu girişi",
  ["register_success"]: "başarılı Kayıt",
  ["please_invitation_code"]: "Lütfen davet kodunu girin",
  ["vip0_limit"]: "Ekip tarafından inşa edilen toplam depo sayısı",
  ["vip1_limit1"]: "Ekip üyeleri arasında toplam",
  ["vip1_limit2"]: "insanlar ulaşır",
  ["vip0_privilege"]: "Topluluk temettüleri",
  ["vip1_privilege"]: "Topluluk geliri için ek temettüler",
  ["vip5_privilege"]: "küresel temettü",
  ["invite_member"]: "şimdi davet et",
  ["my_vip"]: "VIP'im",
  ["select_bank"]: "banka kartı seç",
  ["add_bank"]: "banka kartı ekle",
  ["bind_bank"]: "banka kartı bağla",
  ["bind_bank_tip"]: "Lütfen banka kartınızı bağlayın, ayardan sonra banka kartı değiştirilemez.",
  ["bankName"]: "Banka",
  ["bankNumber"]: "Banka kartı numarası",
  ["branchBankName"]: "şube adı",
  ["scl_enter"]: "isteğe bağlı",
  ["bankAddress"]: "Kart banka adresi veya numarası",
  ["bankCode"]: "Banka Uluslararası Kodu",
  ["userAddress"]: "Ev Adresi",
  ["bankItem"]: "Banka kartı listesi",
  ["withdraw_amount"]: "Para Çekme Miktarı",
  ["default_payment"]: "varsayılan ödeme",
  ["please_enter_bankNumber"]: "Lütfen banka kartı numarasını giriniz",
  ["please_enter_bankName"]: "Lütfen bankanızı girin",
  ["bank_usdt_tip"]: "Banka kartından nakit çekme sadece USDT ile sınırlıdır!",
  ["fund_password"]: "fon şifresi",
  ["other_bank"]: "Diğer banka kartları",
  ["card_limit"]: "Zaten bir banka kartı eklediniz",
  ["not-selected-bank"]: "Banka kartı seçilmedi",
  ["advanced_realName"]: "Gelişmiş gerçek ad kimlik doğrulaması",
  ["primary_realName"]: "Birincil gerçek ad kimlik doğrulaması",
  ["please_primary_certification"]: "Lütfen önce birincil sertifikasyonu tamamlayın",
  ["go_now"]: "git şimdi",
  ["please_bind_bank card"]: "Nakit para çekme işlemleri için lütfen resepsiyon görevlisine başvurun",
  ["confirm_recharge"]: "Şarjı onayla",
  ["bank"]: "Banka kartı para çekme",
  ["payee_name"]: "Alıcı adı",
  ["bank_name"]: "banka adı",
  ["bank_account"]: "Banka hesabı",
  ["branch_name"]: "şube adı",
  ["branch_number"]: "Şube numarası",
  ["please_seven_account"]: "Lütfen 7 haneli bir hesap girin",
  ["please_branch_names"]: "Lütfen bir şube adı girin",
  ["please_branch_numbers"]: "Lütfen şube numarasını giriniz",
  ["download"]: "indirmek",
  ["contact_information"]: "İletişim bilgileri",
  ["etoro_bank_usdt_tip"]: "Banka kartından nakit çekme sadece VND ile sınırlıdır!",
  ["exchange_rate"]: "Döviz kuru",
  ["kind_tips"]: "Tür ipuçları:",
  ["tips_text"]: "Nakit çekme işlemi için işlem ücretinin bir kısmı tahsil edilecek ve nakit çekildikten sonra 24 saat içinde hesaba ulaşılacaktır.Herhangi bir sorunuz varsa lütfen müşteri hizmetleri ile iletişime geçin.",
  ["customer_test1"]: "Lütfen telegramda ASUS resmi müşteri hizmetlerini arayın ve telgrafta kullanıcı adını kontrol edin.Diğerleri sahte ve kalitesizdir, aldanmaya dikkat edin.",
  ["customer_test2"]: "ASUS Teknoloji Müşteri Hizmetleri13:00-24:00(Lütfen aktarımı sesli olarak onaylayın)",
  ["customer_test3"]: "Paket ağ danışmanlığı-müşteri servisi(11.00-24.00)",
  ["customer_test4"]: "AsusPlatform yapımıözel yapılmış",
  ["customer_test5"]: "Platformu açmanız gerekiyorsa, lütfen aşağıdaki promosyonel müşteri hizmetleriyle iletişime geçin.",
  ["customer_test6"]: "ASUS Teknoloji Bakım Uzmanı-fazla13:00-24:00(Para transferleri için lütfen sesli onay veriniz)",
  ["customer_test7"]: "Hayranım",
  ["upgrade"]: "Platform yükseltmesi",
  ["select_cycle"]: "döngü seç",
  ["buying_volume"]: "satın alma hacmi",
  ["Going right now"]: "şimdi gidiyor",
  ["For cash withdrawal"]: "Nakit çekmek için lütfen resepsiyonist ile iletişime geçiniz.",
  ["winnings"]: "kazançlar",
  ["withdrawing"]: "geri çekilme",
  ["view_contract"]: "sözleşmeyi görüntüle",
  ["Collection account type"]: "Tahsilat hesabı türü",
  ["identification number"]: "kimlik Numarası",
  ["Collection account"]: "Tahsilat hesabı",
  ["VIP_member"]: "VIP Üye",
  ["Genesis_Member"]: "Genesis Üyesi",
  ["Creation_employees"]: "Yaratılış çalışanları",
  ["confirm_withdraw"]: "Bekreft uttak",
  ["bank_service"]: "Banka kartı yüklemesi, lütfen müşteri hizmetleri ile iletişime geçin",
  ["platform_earn"]: "plattform",
  ["Waiting"]: "Bekle",
  ["wallet"]: "lommebok",
  ["monthly income"]: "Aylık gelir miktarı",
  ["add monthly income"]: "Lütfen aylık gelir ekleyin",
  ["wealthBank"]: "banka kartı",
  ["contact the receptionist"]: "Kart numarası için lütfen resepsiyon görevlisine başvurun",
  ["tokpiedex_home_increaseList"]: "Kazanç listesi",
  ["tokpiedex_home_turnover"]: "devir",
  ["tokpiedex_trade_tab1"]: "isteğe bağlı",
  ["tokpiedex_home_service"]: "çevrimiçi servis",
  ["cwg_host_non-collateralized mining"]: "yetkili",
  ["coin58_trade_tab6"]: "ikinci sözleşme",
  ["star_hq_tit1"]: "1. Web3.0 nedir?",
  ["star_hq_conn1"]: "Web3.0, World Wide Web'in gelişimi hakkında bir kavramdır ve esas olarak blockchain, kripto para birimi ve homojen olmayan belirteçlere dayalı ademi merkeziyetçilikle ilgilidir. Blockchain ile ilgili web3 konsepti, Ethereum'un kurucu ortağı Gavin Wood tarafından 2014 yılında önerilmiş ve 2021 yılında kripto para meraklılarının, büyük teknoloji şirketlerinin ve risk sermayesi şirketlerinin ilgisini çekmiştir.",
  ["star_hq_tit3"]: "3. Web3.0 işlemleri nasıl gerçekleştirir?",
  ["star_hq_conn3"]: 'Web3.0 şu anda kalıcı sözleşmeleri, teslimat sözleşmelerini ve alüminyum kantitatif işlemlerini desteklemektedir. Kalıcı bir sözleşme "yenilikçi" bir vadeli işlemdir. Sözleşmeler, geleneksel sözleşmelerin bir son kullanma tarihi vardır, ancak sürekli sözleşmelerin teslim tarihi yoktur ve sonsuza kadar elde tutulabilirler, bu nedenle sürekli sözleşmeler olarak adlandırılırlar. Manuel kantitatif ticaret, kısa sürede büyük miktarda veriyi analiz etmek için mevcut bilgisayar teknolojisinin kullanılması ve ardından işlem verimliliğini artırmak için önceden yazılmış ticaret modelleri aracılığıyla otomatik olarak işlem yapılması anlamına gelir. Bu bilgisayar, büyük borsalarda yayılmış ticaret için kullanıldı.',
  ["star_hq_tit4"]: "4. Ticaret için neden Trust'ı seçmelisiniz?",
  ["star_hq_conn4"]: "Son derece düzenlenmiş bir ortamda çalıştığı için Trust, müşteri verilerinin korunmasını sağlamak için ekstra adımlar atıyor ve Trust'ın kendi veri bilimcileri ve mühendisleri bile bunlara ücretsiz erişemiyor. Trust'ın üretim sunucularında çalışan herhangi bir kod, üretime geçmeden önce birden çok grup tarafından kod incelemelerinden geçmelidir. \"Platformumuzda depolanan dijital varlıklar müşterilerimize ait olduğundan, temel ilkelerimizden biri önce güvenliktir.",
  ["star_hq_tit5"]: "5. Güven nasıl çalışır?",
  ["star_hq_conn5"]: "Trust, kullanıcıların tek bir uygulama içinde birden çok zincire erişmesine izin veren çok belirteçli bir mobil cüzdandır. Tarayıcı tabanlı cüzdanların aksine, her blok zincirine uygulama indirildikten sonra erişilebilir. Blockchain ayrıntılarını eklemeden merkezi olmayan uygulamalarla (DApp'ler) işlem yapmaya veya etkileşimde bulunmaya başlayın.",
  ["star_mining.question1"]: "Niceliksel rehin nedir? Gelir nereden gelecek?",
  ["star_mining.answer1"]: "Niceliksel taahhüt, kullanıcıların ödül almak için zincirde hızlı bir şekilde taahhütte bulunmasına yardımcı olmak için şirket tarafından oluşturulan bir araç ürünüdür. Blockchain ağında dijital varlıkları stake ederek ve POS (Proof of Stake) mekanizmasına dayalı ödüller alarak. Bu mekanizmada, kullanıcılar dijital varlıkları düğümlere emanet eder ve düğümler, blok zincirinde blok oluşturma ve işlemleri paketleme gibi hakları kullanır ve ödüller alır. Kullanıcılar, düğümlerin elde ettiği ödülleri kilitli pozisyon sayısıyla orantılı olarak paylaşır.",
  ["coin58_quick_deposit"]: "hızlı para yatırma",
  ["coin58_quick_withdraw"]: "Hızlı para çekme",
  ["coin58_swap"]: "değişme",
  ["coin58_promotion_center"]: "Tanıtım Merkezi",
  ["coin58_defi_mining"]: "DIFI madenciliği",
  ["coin58_pledge_mining"]: "Rehin madenciliği",
  ["coin58_second_contract"]: "ikinci sözleşme",
  ["coin58_currency_trading"]: "Döviz ticareti",
  ["mxecoin_home_recharge"]: "hızlı para yatırma",
  ["mxecoin_home_mining"]: "Rehin madenciliği",
  ["star_amount_host_lockup"]: "web3.0 kantitatif madencilik",
  ["mxecoin_trade_tab6"]: "ikinci sözleşme",
  ["star_defi_host_lockup"]: "web3.0 kantitatif rehin madenciliği",
  ["deficst_h_pt"]: "Güvenilir kripto para ticaret platformu",
  ["deficst_hq_tit4"]: "4. Ticaret için neden Coinsaving'i seçmelisiniz?",
  ["deficst_hq_tit5"]: "5. Pangea Capital Management'ın Coinsaving ile işbirliği vizyonu.",
  ["deficst_hq_conn4"]: "Coinsaving, son derece düzenlenmiş bir ortamda faaliyet gösterdiği için müşteri verilerinin korunmasını sağlamak için ek adımlar attı ve Coinsaving'in kendi veri bilimcileri ve mühendisleri bile bunlara ücretsiz erişemiyor. Coinsaving'in üretim sunucularında çalışan herhangi bir kodun, üretime geçmeden önce birden çok ekip tarafından kod incelemesinden geçmesi gerekir. \"Platformumuzda depolanan dijital varlıklar müşterilerimize ait olduğundan, temel ilkelerimizden biri önce güvenliktir.",
  ["deficst_hq_conn5"]: "2019 Pangea Sermaye Yönetimi. Coinsaving döviz piyasasına girmek için 230 milyon ABD dolarını finanse etmek, Amerika Birleşik Devletleri'ndeki en büyük Coinsaving döviz borsası olan Coinsaving ile stratejik bir ortaklık kurmada liderlik etmek.Blockchain açısından ve Coinabse ile işbirliği yapmak, merkezi olmayan Defi hakkında derinlemesine araştırma bağımsız blockchain teknolojisi ve merkezi olmayan uygulamaları finanse etmek ve oluşturmak. Coinsaving para birimlerinin merkezi olmayan değişimlerini gerçekleştirin. sözleşme denetim kurumu",
  ["idcm_h_pt"]: "Güvenilir kripto para ticaret platformu",
  ["idcm_hq_tit4"]: "4. Ticaret için neden Crypto'yu seçmelisiniz?",
  ["idcm_hq_tit5"]: "5. Pangea Capital Management'ın Crypto ile ortaklığına ilişkin vizyonu.",
  ["idcm_hq_conn4"]: "Kripto, son derece düzenlenmiş bir ortamda faaliyet gösterdiği için müşteri verilerinin korunmasını ve hatta Crypto'nun kendi veri bilimcileri ve mühendisleri tarafından ücretsiz olarak erişilebilir olmamasını sağlamak için ekstra adımlar atıyor. Crypto'nun üretim sunucularında çalışan herhangi bir kodun, üretime geçmeden önce birden çok ekip tarafından kod incelemesinden geçmesi gerekir. \"Platformumuzda depolanan dijital varlıklar müşterilerimize ait olduğundan, temel ilkelerimizden biri önce güvenliktir.",
  ["idcm_hq_conn5"]: "2019 Pangea Sermaye Yönetimi. Kripto para birimi piyasasına girmek için 230 milyon ABD dolarını finanse etmek, Amerika Birleşik Devletleri'ndeki en büyük Kripto para birimi borsası olan Crypto ile stratejik bir ortaklık kurmaya öncülük etmek Blockchain açısından ve Coinabse ile işbirliği yapmak, Defi merkezi olmayan derinlemesine araştırma bağımsız blockchain teknolojisi ve merkezi olmayan uygulamaları finanse etmek ve oluşturmak. Kripto para birimlerinin merkezi olmayan borsalarını yürütün. sözleşme denetim kurumu",
  ["idcm_trade_tab6"]: "teslimat sözleşmesi",
  ["idcm_trade_tab3"]: "sürekli sözleşmeler",
  ["idcm_withdrawal_Reminder_text"]: "Hatırlatma: Para çekme, işlem ücretinin bir kısmını alacaktır. Para çekme işleminden sonra, hesap 48 saat içinde size ulaşacaktır. Herhangi bir sorunuz varsa, lütfen müşteri hizmetleri ile iletişime geçin.",
  ["elephant_marka1"]: "Sadeliği, zarafeti ve zamansız tarzıyla CHANEL, dünyanın en iyi modasıyla eş anlamlı hale geldi. 20. yüzyılın başlarında Coco Chanel tarafından kurulan marka, lüks hazır giyim ürünleri, klasik çift C logosu ve rakipsiz aksesuarlarıyla tanınıyor. CHANEL, kadınların özgüven ve bağımsızlık ruhunu taşıyan, sadece moda değil, aynı zamanda bir yaşam biçimidir.",
  ["elephant_marka2"]: "Bir Fransız markası olan Celine, sade modernizmi ve üst düzey deri ürünleri ile dikkatleri üzerine çekmiştir. Marka, kalite ve klasikleri vurgulayan, moda endüstrisine zarif ve pratik stiller getiren, daha az olan tasarım konseptine bağlı kalıyor. Denge peşinde koşan ve moda ile pratikliği yakından birleştiren Celine, onu modern şehirlilerin tercihi haline getiriyor.",
  ["elephant_marka3"]: "Bir Fransız markası olan CHLOE, rahat, özgür ve romantik tarzıyla ünlüdür. Marka, moda dünyasında feminenliği, ilhamı ve stili vurgulayan feminen bir güçlenme havası yarattı. CHLOE'nin tasarımı, incelik ve gündelikliği birleştiren, özgür bir yaşam peşinde koşan kadınların tercihi haline gelen sanatsal atmosferle doludur.",
  ["elephant_marka4"]: "Bir Amerikan markası olan COACH, kaliteli deri ürünleri ve klasik tasarımlarıyla popülerdir. 1941 yılında kurulan COACH, her zaman önce işçilik ve kalite kavramına bağlı kalmıştır. Marka sadece lüks ürünler sunmakla kalmıyor, aynı zamanda işlevsellik ve pratikliğe daha fazla önem vererek konfor ve modanın füzyonunu modern şehirlilere taşıyor.",
  ["current_order"]: "şuan ki sipariş",
  ["Historical_order"]: "Tarihsel düzen",
  ["world_charities"]: "Dünyadaki hayır kurumlarına kısmi koruma sağlamak için şirketin günlük gelirinin %10'unu hayır kurumlarında saklayın",
  ["trading_platform"]: "Güvenilir küresel marka ticaret platformu",
  "loan_swiper1": "Mali sorunlarınızı çözmeye adamışız",
  "loan_swiper2": "Hiçbir teminat ve garantiye ihtiyaç duymadan en fazla 3 saat içinde kredi alın!",
  "now_loan": "Şimdi Kredi Al",
  "loan_tip1": "Trust veya Coinbase veya MetaMask veya Math Cüzdan veya BitKeep dijital cüzdanını kullanmanız ve 18-60 yaş arasında olmanız durumunda başvuruda bulunabilirsiniz.",
  "loan_tip2": "USDT (Dolar) kişisel kredisi, yıllık faiz oranı sadece",
  "loan_tip3": "%, en fazla",
  "loan_tip4": "taksit ödeme olanağı sunar. Acil harcamaları, ev bakımını, iş sermayesini veya diğer borçları ödemek için krediye ihtiyacınız olsun, kişisel kredi hizmetimiz neredeyse her durumda size yardımcı olabilir.",
  "max_loan_amount": "Maksimum Kredi Miktarı",
  "rate_as_low_to": "Düşük faiz oranı",
  "max_period": "En fazla taksit sayısı",
  "open_an_account": "Hesap Aç",
  "loan_record": "Kredi Kaydı",
  "personal_data": "Kişisel Bilgiler",
  "go": "Başla",
  "complete": "Tamamla",
  "not_filled_in": "Doldurulmadı",
  "job_info": "İş Bilgileri",
  "beneficiary_info": "Faydalanıcı Bilgileri",
  "low_exchange_rate": "Düşük Döviz Kuru",
  "quick_arrival": "Hızlı Varış",
  "mortgage_free": "Teminatsız",
  "user_loan": "Kullanıcı Kredisi",
  "address": "Adres",
  "successful_loan_amount": "Başarılı Kredi Miktarı",
  "company_qualification": "Şirket Niteliği",
  "view": "Görüntüle",
  "common_questions": "Sıkça Sorulan Sorular",
  "loan_question1": "Kendinizi dolandırıcılardan nasıl korursunuz!",
  "loan_answer1": "En fazla tüketici dolandırıcılığı kategorisine 'Kimlik Hırsızlığı Dolandırıcılığı' denir ve en yaygın olanı 'Ön Ödeme Dolandırıcılığı'dır. Dolandırıcılar genellikle çeşitli ön ödemeleri yapmaya ikna etmek için büyük kredi veya kredi kartları vaat ederler. Genellikle kullanılan bahaneler arasında depozito, vergi, teminat, gizlilik ücreti, üyelik ücreti, platform ücreti, malzeme ücreti, işçilik ücreti, çözünme ücreti, yönetim ücreti, işlem ücreti vb. bulunur. Unutmayın: Kredi veya kredi kartınız hesabınıza yatmadan önce hiçbir resmi kredi şirketi veya banka sizden herhangi bir ücret ödemenizi istemez. Uyarı: Kredi veya kredi kartı başvurusundan önce herhangi bir ücret ödemenizi isteyen teklifleri reddedin, böylece dolandırılmazsınız!",
  "loan_question2": "Kişisel kredi nedir?",
  "loan_answer2": "Kişisel kredi, genellikle sabit bir vade süresi, sabit bir faiz oranı ve aylık düzenli ödeme planı olan, hemen hemen herhangi bir yasal amaç için kredi sağlayan bir tür krediyi ifade eder.",
  "loan_question3": "Kişisel kredi başvuru süreci adımları",
  "loan_answer3": "Kredi başvuru sahibi sadece iki adımda kredi başvuru sürecini tamamlayabilir. 1: Başvurmak istediğiniz kredi miktarını seçin, kaydolun ve başvuranın kişisel bilgilerini doldurun. 2: Dijital cüzdan kimlik doğrulama imzasıyla, kredinizle bağlantılandırın. Şirketin inceme adımları: 1: Başvuran bilgilerini kapsamlı bir şekilde analiz eder ve değerlendirir. 2: Başvuranın kredi geçmişi ve geri ödeme yeteneğini gözden geçirir. 3: Kredi miktarını azaltma veya artırma konusunda bir karar alır. 4: Sözleşme imzalar ve krediyi verir. 5: Kredi ve faiz ödeme.",
  "loan_question4": "Başvuru gönderdikten sonra kişisel krediyi ne kadar sürede alabilirim?",
  "loan_answer4": "Çoğu kullanıcının incelemesi sadece 2 saat sürer ve kimliğinizi doğruladığınız dijital cüzdan, inceleme onaylandıktan sonraki 1 saat içinde USDT alır (genellikle başvurudan krediyi alana kadar 3 saatten az sürer).",
  "loan_question5": "Kişisel kredi için uygun muyum?",
  "loan_answer5": "Loan kişisel kredisi için uygunluk kazanmak için 18-60 yaş arasında sağlıklı bir yetişkin olmanız ve kendi dijital cüzdanınızı kullanmanız yeterlidir. Kredi başvurunuz, verdiğiniz bilgilere, kredi geçmişinize ve geri ödeme yeteneğinize göre değerlendirilir. En düşük %3 faiz oranı, ilk kez başvuruyorsanız, kredi geçmişiniz iyi durumdaysa ve kredi geçmişiniz iyiyse neredeyse kesinlikle uygun olacaktır.",
  "loan_question6": "Kredi Beyanı!",
  "loan_answer6": "Çoğu kullanıcının incelemesi sadece 2 saat sürer ve kimliğinizi doğruladığınız dijital cüzdan, inceleme onaylandıktan sonraki 1 saat içinde USDT krediyi alır (genellikle başvurudan krediyi alana kadar 3 saatten az sürer).",
  "loan_question7": "Kredi almadan önce herhangi bir ücret ödemeniz gerekiyor mu?",
  "loan_answer7": "Ön ödeme yapmanıza gerek yoktur. (Herhangi bir resmi kredi şirketi veya banka, kredi fonunuzu almadan önce herhangi bir ücret ödemenizi istemez. Bazı yüksek faizli küçük şirketler, ilgili ücretleri kredinin başlamasından önce doğrudan kredi başkalanından keser. Unutmayın, herhangi bir ücretin önceden kredi şirketine ödenmesi gerekiyorsa, bu kesinlikle dolandırıcıdır!)",
  "loan_question8": "Neden USDT (Dijital Para) ile kredi alıyoruz, neden dolar değil?",
  "loan_answer8": "1: USDT'nin değeri dolarla aynıdır, 1 USDT'nin değeri 1 dolarla eşdeğerdir. 2: İşimiz küresel bir odaklı olduğu için dijital para evrensel, verimli, hızlı ve pratik bir ödeme yöntemidir ve neredeyse tüm dijital para platformlarında USDT'yi yerel para biriminize dönüştürebilir ve bağlı olduğunuz banka hesabına aktarabilirsiniz. 3: Uluslararası kişisel nakit kredi kanalları genellikle sınırlıdır ve başvurular genellikle onaylanmaz, birçok ülkenin yasaları dahil olmak üzere çok sayıda yasa gereklilikleri ve vergi, para birimi değer artışı ve düşüşü, uluslararası bankaların kısıtlamaları içerir. 4: Dijital para dünya genelinde yaygın olarak kullanılmaktadır ve birçok ülkede alışveriş merkezleri, oteller dahil olmak üzere birçok alanda doğrudan dijital para kullanabilirsiniz. 5: Dijital paranın güvenliği artık oldukça istikrarlı ve güvenilirdir ve hatta banka sistemini aşmıştır.",
  "loan_question9": "Nasıl geri ödeyeceğim?",
  "loan_answer9": "İşte size sunduğumuz kişisel kredi sözleşmesi, kimliğinizi doğruladığınız dijital cüzdanınızın ödeme tarihinde ödemeniz gereken tutarı otomatik olarak tahsil edeceğini belirler, bu nedenle sadece tahmin ettiğiniz USDT miktarını saklamanız yeterlidir. Ödeme tarihinde ödeyin.",
  "loan_question10": "Şirketinizde satış temsilcisi olarak çalışabilir miyim?",
  "loan_answer10": "1: Evet. Küresel kullanıcılara yönelik olduğumuz için sürekli olarak yarı zamanlı satış temsilcileri işe almaktayız. 2: Yarı zamanlı satış temsilcileri herhangi bir taban maaşı veya diğer faydaları olmayacak, tek faydanız davet ettiğiniz müşterilerin kredi tutarından gelen tek seferlik %2 komisyondur.",
  "our_advantages": "Avantajlarımız",
  "high_quota": "Yüksek Kotalar",
  "big_company": "Büyük Şirket",
  "without_guarantee": "Teminatsız",
  "fast_arrival": "Hızlı Varış",
  "convenient_and_fast": "Kolay ve Hızlı",
  "lend_money": "Para Ver",
  "verify_amount": "Doğrulama Miktarı",
  "verify_money": "Doğrulama Parası",
  "wallet_balance": "Cüzdan Bakiyesi",
  "need_to_add_amount": "Eklemek İçin Miktar",
  "loan_term_month": "Kredi Vadesi (Ay)",
  "loan_amount_and_details": "Kredi Miktarı ve Detayları",
  "total_interest_rate": "Toplam Faiz Oranı",
  "monthly_interest": "Aylık Faiz",
  "total_interest": "Toplam Faiz",
  "loan_start_and_end_dates": "Kredi Başlangıç ve Bitiş Tarihleri",
  "date_of_first_renovation": "İlk Onarım Tarihi",
  "loan_term": "Kredi Süresi",
  "loan_agree": "Okudum ve Kabul Ediyorum",
  "loan_agreement": "Kredi ve Teminat Anlaşması",
  "loan_confirm": "Anlaşmayı Kabul Edip Onayla",
  "verify_title": "Bilgi Doldurma",
  "enter_name": "Adınızı Girin",
  "enter_phone": "Telefon Numaranızı Girin",
  "enter_age": "Yaşınızı Girin",
  "select_gender": "Cinsiyet Seçin",
  "enter_email": "E-posta Adresinizi Girin",
  "enter_current_address": "Mevcut Adresinizi Girin",
  "option_marital_status": "Medeni Durum Seçeneği",
  "option_education_attainment": "Eğitim Derecesi Seçeneği",
  "front_of_documents": "Kimlik Kartının Ön Yüzü",
  "back_of_documents": "Kimlik Kartının Arka Yüzü",
  "confirm_personal_infomation": "Kişisel Bilgileri Onayla",
  "work_infomation": "İş Bilgileri",
  "option_type_of_job": "İş Türü Seçeneği",
  "option_total_working_years": "Toplam Çalışma Yılı Seçeneği",
  "option_monthly_income": "Aylık Gelir Seçeneği",
  "monthly_household_income": "Aylık Aile Geliri",
  "confirm_work_information": "İş Bilgilerini Onayla",
  "beneficiary_information": "Yararlanıcı Bilgileri",
  "beneficiary_address_trc20": "Yararlanıcı Adresi (TRC20)",
  "confirm_beneficiary_information": "Yararlanıcı Bilgilerini Onayla",
  "confirm": "Onayla",
  "cancel": "İptal",
  "loan_title": "borç",
  "message_center": "mesaj merkezi",
  "activity-top-title": "ETH Airdrop Ödülleri",
"activity-top-big-title": "1 Milyon ETH Airdrop Ödülü Geliyor",
"activity-top-tip": "DeFi getiri projesinin madencilik havuzu düğüm sayfasında basit görevleri tamamlayarak dev bir hava damlası ödülü paylaşın.",
"activity-end": "Etkinlik Sonlandı",
"activity-join": "Etkinliğe Katıl",
"activity-v3-provide": "Bu hizmet {net} tarafından sağlanmaktadır",
  "activity-v3-sub": "Etkinliğe katılın ve {awardNumber} {currencyType} ödülleri kazanın!",
  "activity-v3-support-title": "Desteklenen Ağlar",
  "activity-v3-all-support": "Tüm Ağlar",
  "activity-v3-token": "İlgili Tokenler",
  "activity-v3-read": "Okudum ve kabul ediyorum",
  "activity-v3-statement": "Risk Beyanı",
  "activity-v3-no-remind": "Tekrar hatırlatma",
  "activity-v3-btn": "{awardNumber} {currencyType} Airdrop Etkinliğine Katıl",
  "safety-title": "Güvenlik Doğrulaması",
  "safety-tip": "Hesabınızın güvenliği için, bu işlem kaydırıcı doğrulama gerektirir.",
  "slider-text": "sağa kaydırma",
  "slider-success": "başarılı",
  'grade': 'Seviye'
}