export default {
  ["k_time"]: "Time",
  ["k_open"]: "Open",
  ["k_hight"]: "High",
  ["k_low"]: "Low",
  ["k_close"]: "Receive",
  ["k_quote_amount"]: "Change",
  ["k_quote_change"]: "Change%",
  ["k_volume"]: "Volume",
  ["exchange_1m"]: "1 ponto",
  ["exchange_5m"]: "5 pontos",
  ["exchange_15m"]: "15 pontos",
  ["exchange_30m"]: "30 pontos",
  ["exchange_60m"]: "60 pontos",
  ["exchange_more"]: "Mais",
  ["exchange_4h"]: "4 horas",
  ["exchange_day"]: "dia",
  ["exchange_month"]: "lua",
  ["exchange_week"]: "semana",
  ["exchange_year"]: "ano",
  ["tabbar.home"]: "primeira página",
  ["tabbar.trade"]: "Citações",
  ["tabbar.hosting"]: "gestão financeira",
  ["tabbar.exchange"]: "troca",
  ["tabbar.account"]: "ativos",
  ["common.confirm"]: "confirme",
  ["index.number"]: "Saldo",
  ["index.Total revenue"]: "Rendimento total",
  ["index.Trading"]: "Iniciar o Expert Advisor de IA",
  ["index.intelligentive trading"]: "Comece a negociação quantitativa inteligente agora",
  ["host.detail"]: "Detalhes",
  ["host.todayProfit"]: "Ganhos hoje",
  ["host.totalProfit"]: "Ganhos esperados",
  ["host.Order quantity"]: "quantidade de pedido",
  ["host.Strategic"]: "Produtos de Atividades Estratégicas",
  ["host.escrow"]: "Valor do depósito",
  ["host.max"]: "máximo",
  ["host.determine"]: "Claro",
  ["host.balance"]: "Saldo disponível",
  ["host.Deposit"]: "fundos de depósito",
  ["host.quota"]: "limite",
  ["host.years"]: "taxa anualizada total",
  ["host.expect"]: "lucro esperado",
  ["host.profit"]: "Yu'e Bao",
  ["host.lockup"]: "Mineração de estacas",
  ["host.liquidated"]: "Pena de resgate antecipado",
  ["host.redeem"]: "redenção",
  ["host.isitredeemed"]: "Existe uma penalidade para resgate antecipado, é resgate?",
  ["host.consignmentOrder"]: "ordem confiada",
  ["host.purchase"]: "comprar",
  ["exchange.bond"]: "margem livre",
  ["exchange.optionNo"]: "Questão",
  ["exchange.endTime"]: "Encerramento desta questão",
  ["exchange.Forecast"]: "período de previsão",
  ["enter the amount"]: "Por favor, insira o valor",
  ["Insufficient wallet balance"]: "Saldo de carteira insuficiente para comprar",
  ["minimum purchase amount is"]: "O valor mínimo de compra é",
  ["Purchase successful"]: "compra bem sucedida",
  ["exchange.orderConifrm"]: "Confirmação do pedido",
  ["exchange.balance"]: "Saldo disponível",
  ["exchange.custormAmount"]: "valor personalizado",
  ["exchange.input"]: "Por favor, insira",
  ["exchange.confirm"]: "Claro",
  ["exchange.minute"]: "Minuto",
  ["exchange.curentrust"]: "mandato atual",
  ["exchange.hisentrust"]: "comissão histórica",
  ["exchange.noneEntrust"]: "Ainda sem mandato",
  ["exchange.wait"]: "aguarde a abertura",
  ["exchange.opened"]: "abrir",
  ["exchange.rescinded"]: "Revogado",
  ["exchange.type"]: "modelo",
  ["exchange.finashTime"]: "horário de encerramento",
  ["exchange.openingQuantity"]: "Número de posições abertas",
  ["exchange.awards"]: "Número de prêmios",
  ["exchange.charge"]: "Taxa de abertura de cargo",
  ["exchange.openPrice"]: "preço de abertura",
  ["exchange.close"]: "Preço final",
  ["exchange.prediction"]: "Orientação da posição",
  ["exchange.pump"]: "bombeamento",
  ["hello"]: "Olá",
  ["welcome"]: "Bem-vindo ao",
  ["change success"]: "Modificado com sucesso",
  ["set success"]: "definido com sucesso",
  ["confirm"]: "Claro",
  ["confirm1"]: "Claro",
  ["confirm2"]: "Claro",
  ["cancel"]: "Cancelar",
  ["copySuccess"]: "Copiar com sucesso",
  ["please enter"]: "Por favor, insira",
  ["no data"]: "Sem dados",
  ["all"]: "tudo",
  ["processing"]: "Em processamento",
  ["credited"]: "creditado",
  ["fail"]: "falhou",
  ["timeout failed"]: "tempo limite falhou",
  ["amount"]: "quantia",
  ["state"]: "Estado",
  ["time"]: "Tempo",
  ["pwd_password"]: "senha",
  ["pwd_Enter password"]: "Por favor, digite a senha",
  ["pwd_old"]: "Senha Antiga",
  ["pwd_new"]: "Nova Senha",
  ["pwd_new again"]: "Confirme a Senha",
  ["pwd_Enter old password"]: "Por favor, digite a senha antiga",
  ["pwd_Enter new password"]: "Por favor, digite uma nova senha",
  ["pwd_Enter new password again"]: "Por favor, digite a nova senha novamente",
  ["pwd_set"]: "configurar senha",
  ["pwd_not empty"]: "senha não pode ficar em branco",
  ["pwd_length"]: "O comprimento da senha não pode ser inferior a 6 caracteres",
  ["pwd_old not empty"]: "A senha antiga não pode ficar vazia",
  ["pwd_new not empty"]: "A nova senha não pode ficar vazia",
  ["pwd_inconsistent twice"]: "As duas senhas não combinam",
  ["home_msg"]: "A tecnologia é a alma comum de quem a desenvolve",
  ["home_recharge"]: "depósito rápido",
  ["home_exchange"]: "Negociação de opções",
  ["home_mining"]: "Mineração de estacas",
  ["home_service"]: "serviço on-line",
  ["home_increaseList"]: "Lista de ganhadores",
  ["home_turnover"]: "volume",
  ["home_currencyName"]: "nome",
  ["home_newPrice"]: "Último preço",
  ["home_vol24"]: "Volume de negociação de 24 horas",
  ["home_upDown"]: "Alteração de cotação",
  ["home_warrant"]: "moeda corrente",
  ["trade_tab1"]: "opcional",
  ["trade_tab2"]: "Coin-M Futures",
  ["trade_tab3"]: "Exchange",
  ["trade_tab4"]: "transação de contrato",
  ["trade_tab5"]: "USDⓈ-M",
  ["trade_tab6"]: "segundo contrato",
  ["trade.empty"]: "Sem dados",
  ["edit_choice"]: "Escolha dos editores",
  ["add_optional"]: "Adicionar ao opcional",
  ["save"]: "Salvar",
  ["select_all"]: "selecionar tudo",
  ["delete"]: "excluir",
  ["please_choose"]: "por favor escolha",
  ["sidebar_share"]: "eu quero compartilhar",
  ["sidebar_help"]: "Centro de ajuda",
  ["sidebar_service"]: "serviço on-line",
  ["exchange_selCurrency"]: "Escolha a moeda",
  ["exchange_searchText"]: "Por favor, digite a palavra-chave de pesquisa",
  ["exchange_rule"]: "regra",
  ["exchange_bond"]: "margem livre",
  ["exchange_optionNo"]: "Questão",
  ["exchange_endTime"]: "Encerramento desta questão",
  ["exchange_forecast"]: "período de previsão",
  ["exchange_minute"]: "Minuto",
  ["exchange_curentrust"]: "mandato atual",
  ["exchange_noneEntrust"]: "Ainda sem mandato",
  ["exchange_bullish"]: "otimista",
  ["exchange_bearish"]: "grosseiro",
  ["exchange_orderConifrm"]: "Confirmação do pedido",
  ["exchange_balance"]: "Saldo disponível",
  ["exchange_custormAmount"]: "valor personalizado",
  ["host_profit"]: "Yu'e Bao",
  ["host_lockup"]: "Mineração de estacas",
  ["host_consignmentOrder"]: "ordem confiada",
  ["host_purchase"]: "comprar",
  ["mining_purchase"]: "comprar",
  ["host_todayProfit"]: "Ganhos hoje",
  ["host_totalProfit"]: "Ganhos esperados",
  ["host_limit"]: "limite de investimento",
  ["host_income"]: "renda",
  ["host_funds in custody"]: "fundos em custódia",
  ["host_entrusted order"]: "ordem confiada",
  ["host_expected earnings for today"]: "Lucro esperado para hoje",
  ["host_cumulative income"]: "renda acumulada",
  ["host_order in commission"]: "Pedidos que chegam na comissão",
  ["host_day"]: "céu",
  ["host_single limit"]: "limite único",
  ["host_daily rate of return"]: "taxa diária de retorno",
  ["host_cycle"]: "ciclo",
  ["host_processing"]: "em processamento",
  ["host_completed"]: "concluído",
  ["host.redeemed"]: "resgatado",
  ["host_histotyProfit"]: "ganhos históricos",
  ["host_illustrate"]: "ilustrar",
  ["host_order details"]: "detalhes do pedido",
  ["host_single benefit"]: "benefício único",
  ["host_single day income"]: "renda de um dia",
  ["host_total revenue"]: "Rendimento total",
  ["host_earnings Details"]: "Detalhes de ganhos",
  ["host_paid"]: "Pago",
  ["host_pay miner fees"]: "Pagar taxas de minerador",
  ["host_user output"]: "saída do usuário",
  ["host_address"]: "Morada",
  ["host_quantity"]: "quantidade",
  ["host_time"]: "Tempo",
  ["host_account Balance"]: "Saldo da conta",
  ["account_total assets equivalent"]: "Total de ativos equivalente",
  ["account_deposit"]: "depósito",
  ["account_withdraw"]: "Retirar",
  ["account_exchange"]: "intercâmbio",
  ["account_funding Account"]: "Conta de financiamento",
  ["account_hide small coins"]: "Esconder moedas pequenas",
  ["account_available"]: "acessível",
  ["account_processing"]: "Em processamento",
  ["account_folded"]: "guardada",
  ["recharge"]: "recarrega",
  ["recharge_currency"]: "moeda de recarga",
  ["recharge_scan code transfer"]: "Transferência de código de digitalização e recarga",
  ["recharge_amount"]: "Valor da recarga",
  ["recharge_upload"]: "Carregar captura de tela dos detalhes de pagamento",
  ["recharge_record"]: "Registro de recarga",
  ["recharge_enter amount"]: "Insira o valor da recarga",
  ["recharge_less zero"]: "O valor da recarga não pode ser inferior a 0",
  ["recharge_upload screenshot"]: "Faça o upload da captura de tela",
  ["recharge_try again later"]: "Upload de imagem, tente novamente mais tarde",
  ["recharge_successful"]: "recarga bem sucedida",
  ["recharge_Details"]: "Detalhes da recarga",
  ["recharge_currency2"]: "moeda",
  ["recharge_address"]: "endereço de recarga",
  ["recharge_order number"]: "número do pedido",
  ["loading"]: "Carregando…",
  ["investment amount"]: "valor do investimento",
  ["expire date"]: "Data de validade",
  ["order number"]: "número do pedido",
  ["start date"]: "data de início",
  ["end date"]: "data final",
  ["cash back"]: "Dinheiro de volta",
  ["order"]: "Ordem",
  ["swap"]: "intercâmbio",
  ["swap_currency exchange"]: "Câmbio monetário",
  ["swap_please enter exchange amount"]: "Por favor, insira o valor da troca",
  ["swap_exchange quantity"]: "Quantidade de troca",
  ["swap_up to"]: "até",
  ["swap_redeem all"]: "Resgatar tudo",
  ["swap_exchange rate"]: "taxa de câmbio",
  ["swap_available"]: "Disponível",
  ["withdraw"]: "retirar o",
  ["withdrawal_currency"]: "Moeda de saque",
  ["withdrawal_Amount_Available"]: "Quantia disponível",
  ["withdrawal_Please_enter_amount"]: "Por favor, insira o valor da retirada",
  ["withdrawal_remove_all"]: "deletar tudo",
  ["withdrawal_address"]: "Endereço de retirada",
  ["withdrawal_Please_enter_address"]: "Insira o endereço da carteira",
  ["withdrawal_password"]: "Senha de saque",
  ["withdrawal_Please_enter_password"]: "Por favor, digite a senha de retirada",
  ["withdrawal_handling_fee"]: "taxa de manuseio",
  ["withdrawal_Reminder_text"]: "Lembrete: Parte da taxa de manuseio será cobrada pela retirada e será creditada na conta em tempo real após a retirada. Em caso de dúvidas, entre em contato com o atendimento ao cliente.",
  ["withdrawal_under_review"]: "sob revisão",
  ["withdrawal_success"]: "Retirada com sucesso",
  ["withdrawal_examination_passed"]: "exame aprovado",
  ["withdrawal_paying_on_behalf"]: "Pagando em nome",
  ["withdrawal_payment_overtime"]: "Pagamento de horas extras",
  ["withdrawal_record"]: "Registro de saques",
  ["enter the bet amount"]: "Por favor, insira o valor da aposta",
  ["minimum bet amount"]: "O valor da aposta mínima é",
  ["bet up to"]: "O valor máximo da aposta é",
  ["number is being updated"]: "Problema de atualização, tente novamente mais tarde",
  ["checkout success"]: "pedido com sucesso",
  ["exchange amount cannot be blank"]: "O valor da troca não pode ficar vazio",
  ["exchange amount must be greater than 0"]: "O valor da troca deve ser maior que 0",
  ["successfully redeemed"]: "Você enviou a troca com sucesso. Por favor, aguarde pacientemente",
  ["enter the withdrawal amount"]: "Por favor, insira o valor da retirada",
  ["amount cannot be less than 0"]: "O valor da retirada não pode ser inferior a 0",
  ["enter the withdrawal address"]: "Por favor, insira o endereço de retirada",
  ["enter the withdrawal password"]: "Por favor, digite a senha de retirada",
  ["successful application"]: "Aplicação bem-sucedida",
  ["set the password first"]: "Por favor, defina uma senha primeiro",
  ["Withdrawal details"]: "Detalhes da retirada",
  ["Withdrawal quantity"]: "Quantidade retirada",
  ["Withdrawal address"]: "Endereço de retirada",
  ["share_invite"]: "Convide amigos para usar",
  ["share_link"]: "compartilhar link",
  ["share"]: "compartilhar",
  ["add_success"]: "Adicionado com sucesso",
  ["mining.question1"]: "que é mineração de staking? De onde vem a renda?",
  ["mining.answer1"]: "A mineração de staking é um produto de ferramenta criado pela empresa para ajudar os usuários a se comprometerem rapidamente na cadeia para obter recompensas. Ao apostar ativos digitais na rede blockchain e obter recompensas com base no mecanismo POS (Proof of Stake, ou seja, prova de participação). Nesse mecanismo, os usuários confiam ativos digitais aos nós e os nós exercem os direitos de produzir blocos, transações de pacotes etc. no blockchain e receber recompensas. Os usuários compartilham as recompensas obtidas pelos nós proporcionalmente de acordo com o número de posições bloqueadas.",
  ["mining.question2"]: "O que é anualização de referência? Como calcular?",
  ["mining.answer2"]: "A taxa anualizada de referência é a taxa de retorno anualizada de referência do produto que você assinou, que será atualizada dinamicamente de acordo com os ganhos reais na rede, apenas para sua referência. Anualização de referência = receita na cadeia/quantidade de bloqueio na cadeia/tempo de bloqueio na cadeia*365*100%.",
  ["mining.question3"]: "Qual é o retorno esperado? Como calcular?",
  ["mining.answer3"]: "A receita esperada é a receita diária esperada calculada com base na quantidade de sua assinatura e na referência do produto anualizada e serve apenas para sua referência. Retorno esperado diário = número de assinaturas * referência anualizada / 365.",
  ["mining.question4"]: "Quando os juros começam a acumular?",
  ["mining.answer4"]: "Para subscrição no dia T, os juros serão calculados a partir das 00:00 do dia T+1.",
  ["mining.question5"]: "Quando os benefícios serão distribuídos?",
  ["mining.answer5"]: "Assinatura no dia T, os juros começarão a ser gerados no dia D+1, e a renda será distribuída junto com o principal após o vencimento.",
  ["mining.question6"]: "Posso resgatar produtos a prazo antecipadamente? Qual é o impacto?",
  ["mining.answer6"]: "Você pode resgatar seu pedido a qualquer momento, mas se resgatar o produto a prazo antecipadamente, perderá parte da receita já obtida com o pedido, e a receita emitida será deduzida do seu principal no momento do resgate.",
  ["mining.question7"]: "Preciso resgatar manualmente após a expiração do prazo do produto?",
  ["mining.answer7"]: "Resgate automático após a expiração do prazo do produto.",
  ["mining.question8"]: "1. Como faço para participar?",
  ["mining.answer8"]: "Para participar da mineração de liquidez não destrutiva e não segura, você precisa pagar uma taxa de minerador ETH. Um endereço de carteira ETH só precisa ser reivindicado uma vez, e a permissão de mineração será aberta automaticamente após o sucesso.",
  ["mining.question9"]: "2. Como faço para sacar dinheiro?",
  ["mining.answer9"]: "Você pode converter suas moedas geradas diariamente em USDT e, em seguida, iniciar uma retirada. As retiradas de USDT serão enviadas automaticamente para o endereço da carteira que você adicionou ao nó, outros endereços não são suportados.",
  ["mining.question10"]: "3. Como calcular a renda?",
  ["mining.answer10"]: "Quando você entra com sucesso, o contrato inteligente começa a calcular seu endereço por meio do nó e começa a calcular a receita.",
  ["show_more"]: "ver mais",
  ["promble"]: "problema comum",
  ["what_yeb"]: "O que é Yu'e Bao?",
  ["what_yeb_answer"]: "Yubibao é um produto criado pela empresa para ajudar usuários que possuem ativos digitais ociosos e usuários que precisam pedir dinheiro emprestado. Possui as características de depósito e saque instantâneos, cálculo de juros por hora e suporte para personalização de taxas de juros. Depois que o usuário transfere os ativos digitais para Yubibao, o sistema determinará se o empréstimo é bem-sucedido a cada hora de acordo com a taxa de empréstimo definida pelo usuário. Após o empréstimo ser bem-sucedido, os juros da hora podem ser obtidos, caso contrário, os juros não pode ser obtido, e você precisa esperar pela próxima vez. Uma hora de resultados correspondentes. Contando com um rigoroso sistema de controle de risco, a Yubibao garante totalmente a segurança das transações dos usuários.",
  ["what_lixi"]: "Como são calculados os juros?",
  ["what_lixi_answer"]: "Juros (por dia) = valor do empréstimo * anualização do empréstimo de mercado / 365/24 * 85%",
  ["rule_yeb_title1"]: "O que é Yu'e Bao?",
  ["rule_yeb_cont1"]: "Yubibao é um produto criado pela empresa para ajudar usuários que possuem ativos digitais ociosos e usuários que precisam pedir dinheiro emprestado. Possui as características de depósito e saque instantâneos, cálculo de juros por hora e suporte para personalização de taxas de juros. Depois que o usuário transfere os ativos digitais para Yubibao, o sistema determinará se o empréstimo é bem-sucedido a cada hora de acordo com a taxa de empréstimo definida pelo usuário. Após o empréstimo ser bem-sucedido, os juros da hora podem ser obtidos, caso contrário, os juros não pode ser obtido, e você precisa esperar pela próxima vez. Uma hora de resultados correspondentes. Contando com um rigoroso sistema de controle de risco, a Yubibao garante totalmente a segurança das transações dos usuários.",
  ["rule_yeb_note1"]: "*Observação: os retornos históricos não são indicativos de retornos futuros. Não prometemos reembolsar o principal e os juros em moeda, objetos físicos, patrimônio ou qualquer outra forma de retorno de propriedade dentro de um determinado período de tempo.",
  ["rule_yeb_title2"]: "Vantagens do produto",
  ["rule_yeb_til2"]: "Robusto",
  ["rule_yeb_cont2"]: "Contando com um rigoroso sistema de controle de risco, a Yu'ebao garante totalmente a segurança dos ativos dos usuários e permite que eles aproveitem os lucros com tranquilidade.",
  ["rule_yeb_title3"]: "Instruções para investir em ativos que rendem juros",
  ["rule_yeb_cont3"]: "Depois que os usuários transferirem seus ativos para a Yubibao, os ativos digitais adquiridos serão emprestados aos usuários de negociação alavancada.",
  ["rule_yeb_title4"]: "declaração de renda",
  ["rule_yeb_til4"]: "regra de renda",
  ["rule_yeb_cont4"]: "Se o leilão for bem sucedido, a renda do dia anterior será composta e liquidada às 02:00 do dia seguinte.",
  ["rule_yeb_title5"]: "Descrição da composição da renda",
  ["rule_yeb_cont5"]: "15% dos juros do empréstimo do usuário serão utilizados como depósito de risco, e 85% serão destinados ao usuário do empréstimo, ou seja, os juros que o usuário do empréstimo poderá obter é: Principal do empréstimo * Empréstimo anualizado atual / 365 / 24 * 85%",
  ["rule_yeb_title6"]: "Descrição da transação",
  ["rule_yeb_cont61"]: "Suporte a assinatura a qualquer momento, comece a licitar na hora após a assinatura",
  ["rule_yeb_cont62"]: 'Regras de casamento de taxas de juros: O mercado licita a cada hora de acordo com a demanda de empréstimo, de acordo com a "anualização mínima do empréstimo" de baixo para alto, e o maior valor do leilão será usado como a "anualização do empréstimo atual"',
  ["rule_yeb_cont63"]: "Se a anualização mínima do empréstimo < a anualização do empréstimo atual, a anualização do empréstimo atual será usada para emprestar com sucesso",
  ["rule_yeb_cont64"]: "Se a anualização mínima do empréstimo > a anualização do empréstimo atual, o empréstimo falha e não há juros",
  ["rule_yeb_cont65"]: "Se a anualização mínima do empréstimo = a anualização do empréstimo atual, pode ser parcialmente emprestado ou o empréstimo pode falhar",
  ["rule_yeb_title7"]: "regras de resgate",
  ["rule_yeb_til7"]: "Resgate automático no vencimento",
  ["rule_wk_title1"]: "O que é mineração de staking?",
  ["rule_wk_cont1"]: "A mineração de staking é um produto de ferramenta criado pela empresa para ajudar os usuários a se comprometerem rapidamente na cadeia para obter recompensas. Ao apostar ativos digitais na rede blockchain e obter recompensas com base no mecanismo POS (Proof of Stake, ou seja, prova de participação). Nesse mecanismo, os usuários confiam ativos digitais aos nós e os nós exercem os direitos de produzir blocos, transações de pacotes etc. no blockchain e receber recompensas. Os usuários compartilham as recompensas obtidas pelos nós proporcionalmente de acordo com o número de posições bloqueadas.",
  ["rule_wk_note1"]: "*Observação: os retornos históricos não são indicativos de retornos futuros. Não prometemos reembolsar o principal e os juros em moeda, objetos físicos, patrimônio ou qualquer outra forma de retorno de propriedade dentro de um determinado período de tempo.",
  ["rule_wk_title2"]: "Vantagens do produto",
  ["rule_wk_til2"]: "Robusto",
  ["rule_wk_cont2"]: "A mineração de staking pode obter recompensas relativamente estáveis de terceiros, com vários períodos de produto.",
  ["rule_wk_title3"]: "Instruções para investir em ativos que rendem juros",
  ["rule_wk_cont3"]: "A mineração de staking é comprometer seus ativos digitais no blockchain para apoiar a operação da rede blockchain e obter recompensas correspondentes.",
  ["rule_wk_title4"]: "declaração de renda",
  ["rule_wk_cont4"]: "Após a subscrição bem sucedida no dia T, os juros começam às 00:00 do dia T+1 e os juros são liquidados às 02:00.",
  ["rule_wk_title5"]: "Descrição da transação",
  ["rule_wk_til5"]: "comprar regra",
  ["rule_wk_cont5"]: "Assinatura de suporte a qualquer momento.",
  ["rule_wk_title6"]: "regras de resgate",
  ["rule_wk_cont61"]: "Suporte para resgate a qualquer momento, e o tempo de espera para resgate de diferentes projetos é diferente.",
  ["rule_wk_cont62"]: "Quando o prazo não tiver expirado, ele será resgatado antecipadamente e parte dos juros pagos serão deduzidos.",
  ["rule_wk_cont63"]: "Resgate não suportado",
  ["rule_wk_title7"]: "aviso de risco",
  ["rule_wk_cont7"]: "Para resgate antecipado de produtos regulares, o sistema descontará parte da receita já obtida com o pedido.",
  ["rule_fzywk_title1"]: "Mineração de liquidez",
  ["rule_fzywk_cont1"]: "A mineração de liquidez é uma função de gerenciamento de patrimônio criada pela empresa para ajudar os usuários a gerenciar ativos digitais e obter retornos eficientes e de longo prazo. O usuário clica (paga a taxa do minerador) e paga uma determinada taxa do minerador para gerar renda imediatamente. O usuário só precisa pagar uma vez para um efeito permanente, e não há necessidade de clicar para pagar novamente no futuro. A relação de renda depende no saldo da carteira do usuário. Quanto maior o saldo, maior o índice de renda.",
  ["rule_fzywk_note"]: "Observação: (sem empréstimos) Os detalhes dos ganhos são enviados 4 vezes por dia a cada 6 horas. Você pode ver os ganhos detalhados em Detalhes dos ganhos.",
  ["rule_fzywk_note1"]: "Nota: (Este produto é um produto de drenagem de bem-estar com cota limitada no momento. No futuro, a avaliação de ativos digitais será adicionada aos usuários, e este produto será aberto apenas para usuários qualificados, portanto, é por ordem de chegada).",
  ["rule_fzywk_title2"]: "Vantagens do produto",
  ["rule_fzywk_til2"]: "Benefícios robustos",
  ["rule_fzywk_cont2"]: "Benefícios de longo prazo podem ser obtidos sem empréstimos de fundos, garantindo a segurança dos ativos dos usuários e usufruindo dos benefícios sem risco de perdas.",
  ["rule_fzywk_title3"]: "declaração de renda",
  ["rule_fzywk_til3"]: "regra de renda",
  ["rule_fzywk_cont3"]: "Entrará em vigor imediatamente após a autorização de pagamento ser bem sucedida, e a renda será distribuída dentro de um período de tempo fixo todos os dias. O ciclo de renda do usuário é de 6 horas, e um total de 4 vezes de renda pode ser obtido em 24 horas.",
  ["rule_fzywk_til3_1"]: "Relação de rendimento de engrenagem",
  ["rule_fzywk_tab1"]: "engrenagem",
  ["rule_fzywk_tab2"]: "quantidade",
  ["rule_fzywk_tab3"]: "taxa de retorno",
  ["rule_fzywk_tab4"]: "unidade de receita",
  ["unlimited"]: "ilimitado",
  ["verified"]: "Verificado",
  ["verified_tips"]: "Para garantir uma experiência de conta segura, vincule suas informações de identificação pessoal",
  ["verified_name"]: "Nome",
  ["verified_input_name"]: "Por favor, digite seu nome",
  ["verified_card"]: "número de licença",
  ["verified_input_card"]: "Por favor, insira o número de identificação",
  ["photo_front"]: "foto de identificação",
  ["clearly_visible"]: "(Por favor, certifique-se de que os documentos estão claramente visíveis)",
  ["front_photo"]: "Carregue a frente da foto de identificação",
  ["reverse_photo"]: "Carregue o verso da sua foto de identificação",
  ["standard"]: "padrão",
  ["missing"]: "ausência de",
  ["vague"]: "Vago",
  ["strong flash"]: "flash forte",
  ["confirm_submit"]: "Confirme e envie",
  ["need to change"]: "Se você precisar alterar a ligação, por favor",
  ["Contact Customer Service"]: "Entre em contato com o Atendimento ao Cliente",
  ["authentication succeeded"]: "Autenticação bem-sucedida",
  ["please_information"]: "Por favor complete as informações",
  ["only_symbol"]: "Basta olhar para o par de negociação atual",
  ["options"]: "opções",
  ["occupy"]: "Ocupar",
  ["bb_buy"]: "Comprar",
  ["bb_sell"]: "vender",
  ["bb_count"]: "quantidade",
  ["bb_current_best_price"]: "Negocie ao melhor preço atual",
  ["bb_turnover"]: "Volume de negócios",
  ["bb_Limit order"]: "Ordem de limite",
  ["bb_market order"]: "ordem de mercado",
  ["bb_successful operation"]: "Operação bem-sucedida",
  ["bb_operation failed"]: "Operação falhou",
  ["bb_Hide other trading pairs"]: "Ocultar outros pares de negociação",
  ["bb_price"]: "preço",
  ["bb_volume"]: "volume",
  ["bb_default"]: "predefinição",
  ["bb_buy order"]: "ordem de compra",
  ["bb_sell order"]: "ordem de venda",
  ["bb_commission price"]: "preço da comissão",
  ["bb_commission amount"]: "valor da comissão",
  ["bb_Cancellation"]: "Cancelamento",
  ["bb_total turnover"]: "faturamento total",
  ["bb_Average transaction price"]: "Preço médio da transação",
  ["bb_Undo succeeded"]: "Desfazer bem-sucedido",
  ["bb_Undo failed"]: "Falha ao desfazer",
  ["bb_Deal done"]: "Acordo feito",
  ["bb_Revoked"]: "Revogado",
  ["bb_depth map"]: "mapa de profundidade",
  ["bb_actual price"]: "preço real",
  ["bb_to buy order"]: "ordem de compra",
  ["bb_to sell order"]: "ordem de venda",
  ["bb_direction"]: "direção",
  ["bb_clinch"]: "fazer um acordo",
  ["user_login"]: "Login de usuário",
  ["password_placeholder"]: "Por favor, digite a senha",
  ["code_placeholder"]: "por favor insira o código de verificação",
  ["login"]: "Conecte-se",
  ["no account"]: "Não tem uma conta?",
  ["to register"]: "registrar",
  ["Forgot password"]: "Esqueceu a senha?",
  ["user register"]: "Registro do usuário",
  ["username"]: "nome de usuário",
  ["repassword_placeholder"]: "Por favor, digite a senha novamente",
  ["register"]: "registro",
  ["have account"]: "já tem uma conta?",
  ["to login"]: "ir para entrar",
  ["address_placeholder"]: "Insira o endereço da carteira",
  ["password does not match"]: "As duas senhas não combinam",
  ["username_msg"]: "por favor, digite o nome de usuário",
  ["ua_msg"]: "Insira o nome de usuário/endereço da carteira",
  ["register_failed"]: "registração falhou",
  ["login_failed"]: "Falha na autenticação",
  ["invalid_address"]: "Endereço inválido, abra e autorize na carteira",
  ["exchange_hisentrust"]: "comissão histórica",
  ["sidebar_bindPassword"]: "Configurar uma senha de fundo",
  ["sidebar_changePassword"]: "Modificar a senha do fundo",
  ["sidebar_setLoginPassword"]: "Configurando a senha de login",
  ["sidebar_changeLoginPassword"]: "Modificar senha de acesso",
  ["add_address"]: "endereço de ligação",
  ["paste"]: "colar",
  ["No amount available"]: "Nenhum valor disponível",
  ["Please commission amount"]: "Insira o valor da comissão",
  ["country"]: "País de Cidadania",
  ["verified_input_country"]: "Por favor, insira a nacionalidade",
  ["Please select a chain"]: "Selecione uma cadeia",
  ["login_out"]: "sair",
  ["login_out_sure"]: "Tem certeza de sair?",
  ["login_out_success"]: "sair com sucesso",
  ["login_out_faild"]: "Falha ao sair",
  ["login_out_cancel"]: "cancelar saída",
  ["real_name_withdrawal"]: "Por favor, complete a verificação de nome real o mais rápido possível antes de retirar",
  ["going_long"]: "vá longe",
  ["open_empty"]: "abrir",
  ["can_open_empty"]: "que pode ser aberto",
  ["cash_deposit"]: "Margem",
  ["fund_transfer"]: "Transferência de fundos",
  ["from"]: "a partir de",
  ["arrive"]: "chegar",
  ["fund_account"]: "Conta de financiamento",
  ["transaction_account"]: "conta de transação",
  ["transfer_all"]: "transferir tudo",
  ["up_to"]: "até",
  ["contract_account"]: "conta de contrato",
  ["spot_account"]: "conta spot",
  ["transfer_amount"]: "Por favor, insira o valor da transferência",
  ["max_transfer"]: "Transferir até",
  ["current_position"]: "posição atual",
  ["minimum_transfer"]: "O valor mínimo de transferência é",
  ["sell"]: "Vender",
  ["buy"]: "comprar",
  ["null"]: "nulo",
  ["income"]: "renda",
  ["yield_rate"]: "taxa de retorno",
  ["open_interest"]: "interesse aberto",
  ["position_collateral_assets"]: "Posicionar ativos de garantia",
  ["average_opening_price"]: "Preço médio de abertura",
  ["expected_strong_parity"]: "Paridade forte esperada",
  ["guaranteed_asset_ratio"]: "Índice de ativos garantidos",
  ["close_position"]: "fechar a posição",
  ["adjustment_margin"]: "Margem de Ajuste",
  ["stop_profit"]: "Obter lucros",
  ["stop_loss"]: "parar a perda de",
  ["profit_loss"]: "Take Profit e Stop Loss",
  ["many"]: "muitos",
  ["cancel_bulk"]: "Cancelamento em massa",
  ["trigger_price"]: "preço de gatilho",
  ["mark"]: "marca",
  ["order_price"]: "Preço do pedido",
  ["total_entrustment"]: "Valor total da atribuição",
  ["latest_price"]: "Último preço de transação",
  ["profit_price"]: "Preço de acionamento do lucro",
  ["loss_price"]: "Preço de gatilho de stop loss",
  ["profit_order"]: "Ordem de lucro",
  ["loss_order"]: "Ordem de Stop Loss",
  ["profit_tips_1"]: "preço de mercado para",
  ["profit_tips_2"]: "A ordem de lucro será acionada quando a transação for concluída, e espera-se que o lucro seja obtido após a transação",
  ["profit_tips_3"]: "Quando a ordem de stop loss é acionada, a perda esperada após a transação",
  ["order_count"]: "quantidade",
  ["market_price"]: "preço de mercado",
  ["limit_price"]: "preço limite",
  ["margin_call"]: "chamada de margem",
  ["margin_reduction"]: "Redução de margem",
  ["sustainable"]: "sustentável",
  ["increase_most"]: "aumentar no máximo",
  ["reduce_most"]: "reduzir no máximo",
  ["after_price"]: "Preço de liquidação após adição",
  ["add_lever"]: "A alavancagem adicional",
  ["sub_price"]: "Preço de liquidação reduzido",
  ["sub_lever"]: "Alavancagem reduzida",
  ["commission_detail"]: "Detalhes da comissão",
  ["to_trade"]: "negociar",
  ["order_status"]: "Status do pedido",
  ["delegate_type"]: "Tipo de delegado",
  ["volume"]: "volume",
  ["delegate_value"]: "Valor do delegado",
  ["commission_time"]: "Tempo de comissão",
  ["currency_least_purchase"]: "Compra mínima necessária",
  ["quick_close"]: "Fechamento rápido",
  ["closing_price"]: "Preço final",
  ["completely_sold"]: "Completamente vendido",
  ["not_contract_transactions"]: "A moeda atual não suporta transações de contrato",
  ["coinbase_h_l_hot"]: "Moedas populares",
  ["coinbase_h_market"]: "Cotações de moedas",
  ["coinbase_h_coin"]: "moeda",
  ["coinbase_h_vol"]: "Volume de negócios",
  ["coinbase_h_price"]: "preço",
  ["coinbase_h_quote"]: "Alteração de cotação",
  ["coinbase_h_more"]: "ver mais",
  ["coinbase_h_pt"]: "Plataforma de negociação de criptomoedas confiável para o usuário",
  ["coinbase_h_tit1"]: "Fundo de ativos de segurança do usuário (SAFU)",
  ["coinbase_h_conn1"]: "Armazene 10% de todas as taxas de transação no fundo de ativos seguros para fornecer proteção parcial aos fundos do usuário",
  ["coinbase_h_tit2"]: "Controle de acesso personalizado",
  ["coinbase_h_conn2"]: "O controle de acesso avançado restringe o acesso a dispositivos e endereços de contas pessoais, proporcionando tranquilidade aos usuários",
  ["coinbase_h_tit3"]: "Criptografia de dados avançada",
  ["coinbase_h_conn3"]: "Os dados de transações pessoais são protegidos por meio de criptografia de ponta a ponta e somente a pessoa pode acessar as informações pessoais",
  ["coinbase_hq_title"]: "pergunta relacionada",
  ["coinbase_hq_tit1"]: "1. O que é Defi?",
  ["coinbase_hq_conn1"]: "O nome completo do DeFi é Finanças Descentralizadas, também conhecido comoFinanças Abertas, quase tudoDeFiOs projetos são todos realizados na blockchain Ethereum. Ethereum é uma plataforma global de código aberto para aplicativos descentralizados. No Ethereum, você pode escrever código para gerenciar ativos digitais e executar programas sem restrições geográficas.",
  ["coinbase_hq_tit2"]: "2. Quais são as vantagens do financiamento descentralizado?",
  ["coinbase_hq_conn2"]: "As finanças descentralizadas aproveitam os princípios-chave do blockchain para melhorar a segurança e a transparência financeiras, desbloquear oportunidades de liquidez e crescimento e apoiar um sistema econômico integrado e padronizado. Contratos inteligentes altamente programáveis automatizam e suportam a criação de novos instrumentos financeiros e ativos digitais. A orquestração de dados à prova de adulteração na arquitetura descentralizada de blockchain melhora a segurança e a auditabilidade.",
  ["coinbase_hq_tit3"]: "3. Como o Defi executa as transações?",
  ["coinbase_hq_conn3"]: 'Atualmente, a Defi oferece suporte a contratos perpétuos, contratos de entrega e negociação quantitativa de alumínio. Os contratos perpétuos são um tipo de futuros "inovadores". Um contrato, um contrato tradicional tem uma data de vencimento, mas um contrato perpétuo não tem data de entrega e pode ser mantido para sempre, por isso é chamado de contrato perpétuo. A negociação quantitativa manual refere-se ao uso da tecnologia de computador existente para analisar uma grande quantidade de dados em um curto período de tempo e, em seguida, negociar automaticamente por meio de um modelo de negociação pré-escrito para melhorar a eficiência da negociação. Este computador é usado para negociar spreads nas principais bolsas.',
  ["coinbase_hq_tit4"]: "4. Por que escolher a coinbase para negociação?",
  ["coinbase_hq_conn4"]: 'Operando em um ambiente altamente regulamentado, a Coinbase toma medidas extras para garantir que os dados do cliente sejam protegidos, e nem mesmo os cientistas e engenheiros de dados da Coinbase têm acesso gratuito a esses dados. Qualquer código executado nos servidores de produção da Coinbase deve passar por revisão de código por vários grupos antes de entrar em produção. "Um dos nossos princípios fundamentais é a segurança em primeiro lugar, pois os ativos digitais armazenados em nossa plataforma pertencem aos nossos clientes.',
  ["coinbase_hq_tit5"]: "5. A visão da Pangea Capital Management para sua parceria com a Coinbase.",
  ["coinbase_hq_conn5"]: "2019 Pan Continental Capital Management. Financiando US$ 230 milhões para entrar no mercado de moedas Coinbase, assumindo a liderança no estabelecimento de uma parceria estratégica com a Coinbase, a maior casa de câmbio Coinbase nos Estados Unidos, em termos de blockchain, e cooperando com a Coinabse para realizar pesquisas aprofundadas sobre finanças descentralizadas Defi e construir uma tecnologia blockchain independente e aplicativos descentralizados. Realize trocas descentralizadas de moedas base de moedas. agência de auditoria de contrato",
  ["coinbase_ho_tit1"]: "auditor de contrato",
  ["coinbase_ho_tit2"]: "Spot/Alavancagem/Contrato",
  ["coinbase_ho_tit3"]: "Mineração de staking, DeFi, etc.",
  ["coinbase_ho_tit4"]: "prestes a começar a negociar",
  ["coinbase_ho_tit5"]: "Plataforma de câmbio descentralizada",
  ["coinbase_ho_tit6"]: "troque agora",
  ["coinbase_ho_tit7"]: "Parceiro",
  ["coinbase_ho_tit8"]: "conectar carteira",
  ["coinbase_ho_tit9"]: "Seu navegador não suporta a extensão de carteira",
  ["coinbase_hs_tit1"]: "Termos de serviço",
  ["coinbase_hs_tit2"]: "papel branco",
  ["coinbase_hs_tit3"]: "Autenticação do email",
  ["coinbase_he_tit1"]: "Vinculação de e-mail",
  ["coinbase_he_tit2"]: "Correspondência",
  ["coinbase_he_tit3"]: "Código de verificação",
  ["coinbase_he_tit4"]: "Envie o código de verificação",
  ["coinbase_he_tit5"]: "por favor insira seu e-mail",
  ["coinbase_he_tit6"]: "Reenviar",
  ["tokpiedex_he_tit3"]: "Código de verificação",
  ["opening time"]: "Tempo de abertura",
  ["view_desc"]: "Ver descrição",
  ["host_non-collateralized mining"]: "Mineração desativada",
  ["air_activity"]: "Atividade de lançamento aéreo",
  ["total_output"]: "produção total",
  ["valid_node"]: "nó válido",
  ["participant"]: "participante",
  ["user_benefit"]: "Benefícios do usuário",
  ["participate_mining"]: "Participe da mineração",
  ["take_over"]: "assumir",
  ["divide"]: "dividir",
  ["balance_reaches"]: "Quando o saldo da carteira atingir",
  ["you_get"]: "você vai ter",
  ["mining_pool"]: "Recompensas de pools de mineração",
  ["valid_time"]: "Receba hora válida:",
  ["air_activity_help_1"]: "Um contrato inteligente é um protocolo de computador projetado para propagar, verificar ou executar um contrato de maneira informativa. Os contratos inteligentes permitem transações confiáveis rastreáveis e irreversíveis sem terceiros. O conceito de contratos inteligentes foi proposto pela primeira vez por Nick Szabo em 1995. O objetivo dos contratos inteligentes é fornecer um método seguro que seja superior aos contratos tradicionais, reduzindo outros custos de transação associados aos contratos. Os contratos inteligentes são executados de forma totalmente autônoma, sem intervenção humana, e são justos.",
  ["air_activity_help_2"]: "Airdrops são executados de forma autônoma com contratos inteligentes Ethereum, e nem todo usuário é elegível para participar de airdrops. Quando você recebe uma tarefa de lançamento aéreo para um contrato inteligente, só precisa concluir as condições da tarefa para obter a recompensa.",
  ["air_activity_help_3"]: "Os padrões das missões de lançamento aéreo são diferentes e as recompensas obtidas são diferentes.",
  ["submit_success"]: "submetido com sucesso",
  ["verified_wait"]: "As informações estão sendo revisadas",
  ["air_activity_msg_1"]: "Obrigado por sua participação, a recompensa ETH foi distribuída para o saldo da sua conta",
  ["promotion_center"]: "Centro de promoção",
  ["invite_friende"]: "Convide amigos para ganhar moedas juntos",
  ["invite_friends_commission"]: "Convide amigos, recarregue instantaneamente e ganhe comissão",
  ["promotion_rule"]: "Ver regras da promoção",
  ["my_promotion"]: "minha promoção",
  ["total_promotions"]: "Número total de promoções",
  ["commission_amount"]: "Valor da comissão",
  ["generation_p"]: "geração",
  ["second_generation_p"]: "Número de segunda geração",
  ["three_generations_p"]: "Três gerações",
  ["invitation_code_y"]: "seu código de convite",
  ["sponsored_link"]: "Links patrocinados",
  ["click_share"]: "Clique para compartilhar",
  ["commission_details"]: "Detalhes da comissão",
  ["generation"]: "geração",
  ["second_generation"]: "segunda geração",
  ["three_generations"]: "três gerações",
  ["user_ID"]: "ID do usuário",
  ["rebate_amount"]: "Valor do desconto",
  ["registration_time"]: "tempo de registro",
  ["QRcode_register"]: "Digitalize o código QR para se registrar",
  ["click_save"]: "clique em salvar",
  ["share_amount"]: "quantia",
  ["invitation_code"]: "Código de convite (opcional)",
  ["option_buy_up"]: "comprar",
  ["option_buy_short"]: "comprar curto",
  ["option_countdown"]: "contagem regressiva",
  ["option_purchase_price"]: "preço de compra",
  ["option_profitability"]: "Lucratividade",
  ["option_minimum_amount"]: "quantidade mínima",
  ["option_profit_loss"]: "Lucro e perda estimados",
  ["option_latest_price"]: "último preço",
  ["option_now_price"]: "preço atual",
  ["option_profit_loss_1"]: "lucros e perdas",
  ["option_profit_loss_2"]: "Valor mínimo de compra",
  ["option_profit_loss_3"]: "O preço final está sujeito à liquidação do sistema.",
  ["host_single_limit_1"]: "contingente",
  ["record_tip"]: "Observação",
  ["record_tip_1"]: "Prezado cliente, suas compras disponíveis se esgotaram, entre em contato com o atendimento ao cliente",
  ["available_time"]: "Horários disponíveis",
  ["about_us"]: "sobre nós",
  ["home_tip_agree"]: "Ao me cadastrar eu concordo",
  ["home_tip_terms"]: "Termos de uso",
  ["home_tip_agreement"]: "Política de Privacidade",
  ["home_tip_score"]: "pontuação de crédito",
  ["home_tip_service_email"]: "E-mail oficial de atendimento ao cliente",
  ["home_tip_and"]: "e",
  ["quick_label_1"]: "Liquidação devida concluída",
  ["quick_label_2"]: "preço atual",
  ["quick_label_3"]: "ciclo",
  ["quick_label_5"]: "quantidade",
  ["quick_label_6"]: "preço",
  ["quick_label_7"]: "lucro esperado",
  ["coinbase2_buy_tip"]: "Entre em contato com o serviço ao cliente para comprar",
  ["upload_file_error"]: "O tamanho do arquivo excede 1,5M e não pode ser carregado, altere a imagem para fazer o upload",
  ["tokpiedex_home_recharge"]: "depósito rápido",
  ["tokpiedex_trade_tab6"]: "segundo contrato",
  ["defi_host_non-collateralized mining"]: "Mineração desativada",
  ["defi_host_lockup"]: "Mineração de estacas",
  ["defi_participate_mining"]: "Participe da mineração",
  ["defi_host_non-collateralized"]: "Mineração desativada",
  ["defi_h_pt"]: "Plataforma de negociação de criptomoedas confiável para o usuário",
  ["defi_hq_tit4"]: "4. Por que escolher a Coinsaving para negociação?",
  ["defi_hq_tit5"]: "5. A visão da Pangea Capital Management para sua parceria com a Coinsaving.",
  ["defi_hq_conn4"]: 'Operando em um ambiente altamente regulamentado, a Coinsaving toma medidas extras para garantir que os dados do cliente sejam protegidos, e nem mesmo os cientistas e engenheiros de dados da Coinsaving têm acesso gratuito a esses dados. Qualquer código executado nos servidores de produção da Coinsaving deve passar por revisão de código por vários grupos antes de entrar em produção. "Um dos nossos princípios fundamentais é a segurança em primeiro lugar, pois os ativos digitais armazenados em nossa plataforma pertencem aos nossos clientes.',
  ["defi_hq_conn5"]: "2019 Pan Continental Capital Management. Financiando US$ 230 milhões para entrar no mercado de moedas Coinsaving, assumindo a liderança no estabelecimento de uma parceria estratégica com a Coinsaving, a maior casa de câmbio Coinsaving nos Estados Unidos, em termos de blockchain, e cooperando com a Coinabse para realizar pesquisas aprofundadas sobre finanças descentralizadas Defi e construir uma tecnologia blockchain independente e aplicativos descentralizados. Realize trocas descentralizadas de moedas base de moedas. agência de auditoria de contrato",
  ["rachange_link_huobi"]: "Canal de recarga oficial Huobi",
  ["rachange_link_binance"]: "Canal oficial de depósito da Binance",
  ["rachange_link_coinbase"]: "Canal de recarga oficial da Coinbase",
  ["rachange_link_crypto"]: "Canal oficial de recarga criptográfica",
  ["recharge_hash"]: "ID da transação",
  ["recharge_hash_placeholder"]: "Insira o ID da transação",
  ["symbol_closed"]: "fechado",
  ["email_placeholder"]: "por favor insira seu e-mail",
  ["email_title"]: "Correspondência",
  ["email_code"]: "Código de verificação",
  ["email register"]: "Registro de email",
  ["email login"]: "Login de e-mail",
  ["email_code_placeholder"]: "Por favor, insira o código de verificação de e-mail",
  ["use_email_change"]: "Use o e-mail para modificar",
  ["use_password_change"]: "Use a senha original para modificar",
  ["tip_change"]: "Nota: Se a caixa de correio não estiver vinculada ou se a caixa de correio for perdida, por favor",
  ["forgot_password"]: "Esqueceu a senha",
  ["i_know"]: "Eu vejo",
  ["regulatory_permission"]: "Licença financeira",
  ["regulatory_conn_1"]: "O PLATFORM_TITLE Group está sujeito à supervisão estrita de instituições financeiras autorizadas em muitos lugares do mundo, incluindo a Australian Securities and Investments Commission (número do regulamento: 001296201) e a Canadian Financial Market Conduct Authority (número do regulamento: M19578081). A PLATFORM_TITLE cumpre integralmente os rígidos regulamentos financeiros da Australian Securities and Investments Commission e da Canadian Financial Market Conduct Authority. Sob a supervisão de agências reguladoras, a PLATFORM_TITLE garante a transparência absoluta de todas as suas transações e é uma das mais confiáveis negociadoras de derivativos financeiros do mundo.",
  ["regulatory_conn_2"]: "Versign é o parceiro global de segurança de rede do PLATFORM_TITLE Capital Group. O gateway adota o mais alto padrão da indústria de tecnologia de criptografia avançada de até 256 bits para garantir a segurança online dos clientes e a condução normal de grandes transações online, para que os clientes possam lidar com transações no site oficial da PLATFORM_TITLE Depósito e retirada e modificar as informações do cliente e outros procedimentos. A Verisign é uma excelente provedora de serviços básicos, fornecendo serviços que podem garantir o bom andamento de centenas de milhões de transações online todos os dias. A Verisign é a provedora de diretório oficial para todos os nomes de domínio .com, net, .cc e .tv, com a maior rede SS7 independente do mundo. Todos os dias, a Verisign monitora mais de 1,5 bilhão de logs de segurança em todo o mundo e protege mais de 500.000 servidores da web.",
  ["regulatory_conn_3"]: "A aclamada e premiada metodologia de análise da Trading Central utiliza indicadores técnicos comprovados. Quer se trate de negociação intradiária, negociação de swing ou investimento de longo prazo, a Trading Central pode fornecer aos investidores gráficos e análises matemáticas que atendem aos requisitos de vários estilos de investimento.",
  ["regulatory_conn_4"]: "Em mais de 30 países ao redor do mundo, mais de 100 instituições financeiras globais líderes optaram por usar a pesquisa de investimento e a análise técnica fornecida pela Trading Central.",
  ["regulatory_conn_5"]: "A TRADING CENTRAL também é membro credenciado de três associações independentes de provedores de pesquisa (IRP): Investorside, European IRP e Asia IRP. A TRADING CENTRAL fornece pesquisa financeira independente que se alinha com os interesses dos investidores e é livre de conflitos de interesse bancários de investimento.",
  ["username_placeholder"]: "Nome de usuário/endereço da carteira/e-mail",
  ["air_title"]: "Recompensas de atividade do pool de mineração",
  ["air_content"]: "Recompensa da atividade do pool de mineração Não: 951 ERC-20 contrato de promessa inteligente, você pode obtê-lo agora se participar",
  ["pulling_text"]: "Puxe para baixo para atualizar...",
  ["loosing_text"]: "Solte para atualizar...",
  ["pledge_amount"]: "Valor do financiamento inteligente",
  ["deposit_amount"]: "Valor do depósito necessário",
  ["tokpiedex_home_increaseList"]: "Lista de ganhadores",
  ["tokpiedex_home_turnover"]: "volume",
  ["tokpiedex_trade_tab1"]: "opcional",
  ["tokpiedex_home_service"]: "serviço on-line",
  ["cwg_host_non-collateralized mining"]: "Mineração desativada",
  ["help_loan"]: "Empréstimo de ajuda",
  ["loan_tip"]: "Após a análise da plataforma, você pode solicitar um empréstimo na plataforma!",
  ["loan_amount"]: "valor esperado do empréstimo",
  ["repayment_cycle"]: "ciclo de reembolso",
  ["daily_rate"]: "Diária",
  ["repayment"]: "Reembolso",
  ["interest"]: "Interesse",
  ["repayment_principal_maturity"]: "Um reembolso devido",
  ["lending_institution"]: "instituição de empréstimo",
  ["credit_loan"]: "Empréstimo de crédito (certifique-se de que a imagem esteja claramente visível)",
  ["upload_passport_photo"]: "Carregue uma foto de passaporte",
  ["approval"]: "Aprovação",
  ["approval_failed"]: "Falha na aprovação",
  ["reps"]: "reembolso",
  ["overdue"]: "atrasado",
  ["loan_money"]: "montante do empréstimo",
  ["repayment_date"]: "data de reembolso",
  ["mechanism"]: "instituição de empréstimo",
  ["loan_record"]: "registro de empréstimo",
  ["primary_certification"]: "certificação primária",
  ["advanced_certification"]: "Certificação Avançada",
  ["certificate_type"]: "tipo de certificado",
  ["example"]: "exemplo",
  ["passport"]: "Passaporte",
  ["id_card"]: "carteira de identidade",
  ["driver_license"]: "carteira de motorista",
  ["show_passport_photo"]: "Foto do passaporte",
  ["show_front_photo"]: "Foto do passaporte",
  ["show_reverse_photo"]: "Foto do passaporte",
  ["minimum_borrowing"]: "empréstimo mínimo",
  ["copy_link"]: "cópia de",
  ["to_be_confirmed"]: "a ser confirmado",
  ["notify"]: "notificar",
  ["account_frozen"]: "A conta foi congelada, entre em contato com o atendimento ao cliente.",
  ["mining_pool_rewards"]: "recompensas do pool de mineração",
  ["output"]: "saída",
  ["required"]: "Recarga necessária",
  ["reward"]: "prêmio",
  ["popup_miniing_title"]: "Inscreva-se para recompensas do pool de mineração",
  ["popup_miniing_conn1"]: "Contrato Inteligente ERC-20",
  ["popup_miniing_conn2"]: "O pool de mineração de contrato inteligente ERC-20 está prestes a iniciar o próximo evento de compartilhamento de ETH. Inscreva-se agora para participar. Após o início do evento, os provedores de liquidez podem compartilhar várias recompensas",
  ["popup_miniing_btn"]: "Candidate-se a um prêmio",
  ["my_account"]: "minha conta",
  ["mining_status"]: "estado",
  ["wallet_balance"]: "saldo da carteira",
  ["activity"]: "Atividade",
  ["additional_rewards"]: "Recompensas adicionais",
  ["pledge"]: "juramento",
  "loan_swiper1": "Os seus problemas financeiros são o que estamos comprometidos em resolver.",
  "loan_swiper2": "Sem necessidade de qualquer garantia, empréstimo em até 3 horas!",
  "now_loan": "Empréstimo Agora",
  "loan_tip1": "Basta usar a carteira digital Trust, Coinbase, MetaMask, Math Wallet ou BitKeep, e ter entre 18 e 60 anos, para nos solicitar um empréstimo.",
  "loan_tip2": "Empréstimo pessoal em USDT (dólar) com taxa de juro anual a partir de apenas",
  "loan_tip3": "%, com a possibilidade de pagamento em até",
  "loan_tip4": "parcelas. Quer você precise de um empréstimo para despesas inesperadas, reparos em casa, capital de negócios ou quitação de outras dívidas, nosso serviço de empréstimo pessoal pode ajudar em quase todas as situações.",
  "max_loan_amount": "Valor Máximo do Empréstimo",
  "rate_as_low_to": "Taxa de Juros tão Baixa Quanto",
  "max_period": "Até",
  "open_an_account": "Abrir uma Conta",
  "loan_record": "Histórico de Empréstimos",
  "personal_data": "Dados Pessoais",
  "go": "Iniciar",
  "complete": "Concluir",
  "not_filled_in": "Não Preenchido",
  "job_info": "Informações de Emprego",
  "beneficiary_info": "Informações do Beneficiário",
  "low_exchange_rate": "Taxa de Câmbio Baixa",
  "quick_arrival": "Chegada Rápida",
  "mortgage_free": "Sem Hipoteca",
  "user_loan": "Empréstimo do Usuário",
  "address": "Endereço",
  "successful_loan_amount": "Valor do Empréstimo Bem-sucedido",
  "company_qualification": "Qualificação da Empresa",
  "view": "Ver",
  "common_questions": "Perguntas Frequentes",
  "loan_question1": "Como me proteger contra fraudes!",
  "loan_answer1": "O tipo de fraude mais relatado é a fraude de roubo de identidade, com a fraude de pagamento antecipado sendo a mais comum. Os fraudadores geralmente prometem empréstimos ou cartões de crédito de alto valor para atrair consumidores desavisados para várias taxas adiantadas. Algumas das desculpas comuns para as taxas antecipadas incluem: depósito, impostos, garantia, taxa de confidencialidade, taxa de associação, taxa de plataforma, taxa de material, taxa de mão de obra, taxa de descongelamento, taxa de gerenciamento, taxa de processo, entre outras. Lembre-se: nenhuma instituição financeira legítima pedirá que você pague taxas antecipadas antes de receber os fundos do seu empréstimo ou cartão de crédito. Recuse qualquer pedido de pagamento antecipado para evitar ser enganado!",
  "loan_question2": "O que é um empréstimo pessoal?",
  "loan_answer2": "Um empréstimo pessoal permite que você peça dinheiro emprestado de um credor para quase qualquer finalidade legal, geralmente com um prazo fixo, taxa de juros fixa e um plano de pagamento mensal.",
  "loan_question3": "Passos do Processo de Solicitação de Empréstimo Pessoal",
  "loan_answer3": "O requerente do empréstimo pode concluir o processo de solicitação em apenas dois passos. 1: Escolha o valor do empréstimo que deseja solicitar, registre-se e preencha as informações pessoais. 2: Autenticação da carteira digital, verificação de assinatura e validação da carteira vinculada ao empréstimo. Etapas de aprovação da empresa: 1: Análise abrangente e pontuação das informações do requerente. 2: Avaliação do crédito e da capacidade de pagamento do requerente. 3: Decisão de aprovar ou modificar o valor do empréstimo. 4: Assinatura do contrato e liberação do empréstimo. 5: Pagamento do valor do empréstimo e juros pelo mutuário.",
  "loan_question4": "Quanto tempo leva para receber um empréstimo pessoal após o registro?",
  "loan_answer4": "A maioria dos pedidos é analisada em apenas 2 horas, e a carteira digital assinada recebe os USDT dentro de 1 hora após a aprovação (normalmente, os usuários recebem o empréstimo em menos de 3 horas após o envio da solicitação).",
  "loan_question5": "Tenho direito a um empréstimo pessoal?",
  "loan_answer5": "Para se qualificar para um empréstimo pessoal da Loan, você só precisa ter entre 18 e 60 anos de idade, ser um adulto saudável e possuir uma carteira digital. Sua solicitação de empréstimo será avaliada com base em vários fatores, incluindo as informações fornecidas no momento da solicitação, sua pontuação de crédito e sua capacidade de pagamento. No que diz respeito à taxa mínima de 3%, se você estiver solicitando pela primeira vez, tiver uma pontuação de crédito decente e um histórico de crédito sólido, suas chances de aprovação são boas.",
  "loan_question6": "Declaração de Empréstimo!",
  "loan_answer6": "A maioria dos pedidos é analisada em apenas 2 horas, e a carteira digital assinada recebe os USDT em até 1 hora após a aprovação (normalmente, os usuários recebem o empréstimo em menos de 3 horas após a solicitação).",
  "loan_question7": "Preciso pagar alguma taxa antes de receber o empréstimo?",
  "loan_answer7": "Nenhuma taxa antecipada é necessária. (Nenhuma instituição de empréstimo legítima ou banco exigirá que você pague taxas antes de receber os fundos do empréstimo. Algumas pequenas empresas de empréstimo de alto risco podem deduzir taxas do principal do empréstimo. Lembre-se de que se você for solicitado a pagar qualquer taxa antecipadamente a uma empresa de empréstimo, provavelmente é uma fraude!)",
  "loan_question8": "Por que estamos emprestando em USDT em vez de dólares?",
  "loan_answer8": "1: O valor do USDT é igual ao do dólar, com 1 USDT valendo 1 dólar. 2: Devido à natureza global de nossos negócios, criptomoedas são universais, eficientes, rápidas e convenientes, com transferências em tempo real. Além disso, na maioria das plataformas de criptomoedas, você pode facilmente converter o USDT para a moeda de seu país e transferi-lo para sua conta bancária vinculada. 3: As vias internacionais de empréstimo pessoal são normalmente difíceis de aprovar devido a várias leis, impostos, flutuações cambiais e restrições bancárias em diferentes países. 4: A adoção de criptomoedas em todo o mundo é alta, com muitos países aceitando pagamentos em criptomoedas em várias áreas, incluindo lojas e hotéis. 5: A segurança das criptomoedas é amplamente confiável, superando até mesmo os sistemas bancários convencionais.",
  "loan_question9": "Como faço para pagar?",
  "loan_answer9": "Nosso contrato de empréstimo pessoal estipula que o valor devido será automaticamente deduzido de sua carteira digital assinada na data de vencimento. Tudo o que você precisa fazer é garantir que o valor do USDT necessário para o pagamento esteja disponível em sua carteira digital na data de vencimento.",
  "loan_question10": "Posso trabalhar como vendedor em tempo parcial para sua empresa?",
  "loan_answer10": "1: Sim, devido à nossa base de usuários global, estamos constantemente recrutando promotores em tempo parcial. 2: Os promotores em tempo parcial não recebem salário base ou outros benefícios; seu único benefício é uma comissão única de 2% sobre o valor do empréstimo de clientes que você indicar.",
  "our_advantages": "Nossas Vantagens",
  "high_quota": "Alta Cota",
  "big_company": "Empresa de Grande Porte",
  "without_guarantee": "Sem Necessidade de Garantia",
  "fast_arrival": "Chegada Rápida",
  "convenient_and_fast": "Conveniente e Rápido",
  "lend_money": "Emprestar Dinheiro",
  "verify_amount": "Verificar Montante",
  "verify_money": "Montante Verificado",
  "wallet_balance": "Saldo da Carteira",
  "need_to_add_amount": "Necessidade de Adicionar Montante",
  "loan_term_month": "Prazo do Empréstimo (em meses)",
  "loan_amount_and_details": "Detalhes do Valor do Empréstimo",
  "total_interest_rate": "Taxa de Juros Total",
  "monthly_interest": "Juros Mensais",
  "total_interest": "Juros Totais",
  "loan_start_and_end_dates": "Datas de Início e Término do Empréstimo",
  "date_of_first_renovation": "Data da Primeira Renovação",
  "loan_term": "Prazo do Empréstimo",
  "loan_agree": "Li e Concordo com o",
  "loan_agreement": "Contrato de Empréstimo e Garantia",
  "loan_confirm": "Concordar com o Contrato e Confirmar",
  "verify_title": "Preenchimento de Informações",
  "enter_name": "Insira seu Nome",
  "enter_phone": "Insira seu Número de Telefone",
  "enter_age": "Insira sua Idade",
  "select_gender": "Selecione o Gênero",
  "enter_email": "Insira seu E-mail",
  "enter_current_address": "Insira seu Endereço Atual",
  "option_marital_status": "Estado Civil Opcional",
  "option_education_attainment": "Grau de Educação Opcional",
  "front_of_documents": "Frente do Documento de Identidade",
  "back_of_documents": "Verso do Documento de Identidade",
  "confirm_personal_infomation": "Confirmar Informações Pessoais",
  "work_infomation": "Informações de Trabalho",
  "option_type_of_job": "Tipo de Emprego Opcional",
  "option_total_working_years": "Total de Anos de Experiência Opcional",
  "option_monthly_income": "Renda Mensal Opcional",
  "monthly_household_income": "Renda Mensal do Agregado Familiar",
  "confirm_work_information": "Confirmar Informações de Trabalho",
  "beneficiary_information": "Informações do Beneficiário",
  "beneficiary_address_trc20": "Endereço do Beneficiário (TRC20)",
  "confirm_beneficiary_information": "Confirmar Informações do Beneficiário",
  "confirm": "Confirmar",
  "cancel": "Cancelar",
  "loan_title": "empréstimo",
  ["message_center"]: "centro de mensagens",
  "activity-top-title": "Recompensas de Airdrop ETH",
"activity-top-big-title": "1 Milhão de Recompensas de Airdrop ETH Chegando",
"activity-top-tip": "Complete tarefas simples na página do nó da piscina de mineração do seu projeto de rendimento DeFi para compartilhar uma enorme recompensa de airdrop.",
"activity-end": "Atividade Encerrada",
"activity-join": "Participar da Atividade",
"activity-v3-provide": "Este serviço é fornecido por {net}",
  "activity-v3-sub": "Participe da atividade e receba recompensas de {awardNumber} {currencyType}!",
  "activity-v3-support-title": "Redes Suportadas",
  "activity-v3-all-support": "Todas as Redes",
  "activity-v3-token": "Tokens Relacionados",
  "activity-v3-read": "Eu li e concordo",
  "activity-v3-statement": "Declaração de Risco",
  "activity-v3-no-remind": "Não lembrar novamente",
  "activity-v3-btn": "Participar do Airdrop de {awardNumber} {currencyType}",
  "safety-title": "Verificação de Segurança",
  "safety-tip": "Para a segurança da sua conta, esta operação requer verificação deslizante.",
  "slider-text": "deslize para a direita",
  "slider-success": "sucesso",
  'grade': 'Nível'
}