export default {
  ["k_time"]: "Time",
  ["k_open"]: "Open",
  ["k_hight"]: "High",
  ["k_low"]: "Low",
  ["k_close"]: "Receive",
  ["k_quote_amount"]: "Change",
  ["k_quote_change"]: "Change%",
  ["k_volume"]: "Volume",
  ["exchange_1m"]: "1M",
  ["exchange_5m"]: "5M",
  ["exchange_15m"]: "15M",
  ["exchange_30m"]: "30M",
  ["exchange_60m"]: "60M",
  ["exchange_more"]: "もっと",
  ["exchange_4h"]: "4時間",
  ["exchange_day"]: "日",
  ["exchange_month"]: "月",
  ["exchange_week"]: "週",
  ["exchange_year"]: "年",
  ["tabbar.home"]: "トップページ",
  ["tabbar.trade"]: "相場",
  ["tabbar.hosting"]: "財務管理",
  ["tabbar.exchange"]: "取引",
  ["tabbar.account"]: "資産",
  ["common.confirm"]: "確認",
  ["index.number"]: "残高",
  ["index.Total revenue"]: "総収入",
  ["index.Trading"]: "AI 人工知能 を起動",
  ["index.intelligentive trading"]: "AI 人工知能による生産性の向上を今すぐ始めましょう",
  ["host.detail"]: "詳細",
  ["host.todayProfit"]: "今日の収益",
  ["host.totalProfit"]: "期待収益",
  ["host.Order quantity"]: "注文数量",
  ["host.Strategic"]: "戦略的に活動的な商品",
  ["host.escrow"]: "エスクロー金額",
  ["host.max"]: "最大",
  ["host.determine"]: "確定",
  ["host.balance"]: "利用可能な残高",
  ["host.Deposit"]: "預金資金",
  ["host.quota"]: "限度額",
  ["host.years"]: "年率",
  ["host.expect"]: "期待収益",
  ["host.profit"]: "ユエ バオ",
  ["host.lockup"]: "ステーキングマイニング",
  ["host.liquidated"]: "事前にペナルティを償還払い",
  ["host.redeem"]: "償還払い",
  ["host.isitredeemed"]: "ペナルティ償還払い、償還払いですか？",
  ["host.consignmentOrder"]: "委託注文",
  ["host.purchase"]: "購入する",
  ["exchange.bond"]: "利用可能な保証金",
  ["exchange.optionNo"]: "番号",
  ["exchange.endTime"]: "今期の締め切り",
  ["exchange.Forecast"]: "予測期間",
  ["enter the amount"]: "金額を入力してください",
  ["Insufficient wallet balance"]: "ウォレットの残高が不足しているため購入できません",
  ["minimum purchase amount is"]: "最低購入金額は",
  ["Purchase successful"]: "購入完了",
  ["exchange.orderConifrm"]: "注文確認",
  ["exchange.balance"]: "利用可能な残高",
  ["exchange.custormAmount"]: "購入数量",
  ["exchange.confirm"]: "確定",
  ["exchange.minute"]: "分",
  ["exchange.curentrust"]: "現在の委託",
  ["exchange.hisentrust"]: "委託履歴",
  ["exchange.noneEntrust"]: "現時点では委託なし",
  ["exchange.wait"]: "取引開始待ち",
  ["exchange.opened"]: "取引開始した",
  ["exchange.rescinded"]: "取引開始を取り消された",
  ["exchange.type"]: "取引時間",
  ["exchange.finashTime"]: "終了時間",
  ["exchange.openingQuantity"]: "金額",
  ["exchange.awards"]: "収益",
  ["exchange.charge"]: "ポジション開設手数料",
  ["exchange.openPrice"]: "始値",
  ["exchange.close"]: "終値",
  ["exchange.prediction"]: "持ち手方向",
  ["exchange.pump"]: "ポンピング",
  ["hello"]: "こんにちは",
  ["welcome"]: "へようこそ",
  ["change success"]: "正常に変更されました",
  ["set success"]: "正常に設定されました",
  ["confirm"]: "確定",
  ["confirm1"]: "入金",
  ["confirm2"]: "出金",
  ["cancel"]: "キャンセル",
  ["copySuccess"]: "正常にコピーされました",
  ["no data"]: "データなし",
  ["all"]: "全て",
  ["processing"]: "処理",
  ["credited"]: "クレジットされた",
  ["fail"]: "失敗",
  ["timeout failed"]: "タイムアウト失敗",
  ["amount"]: "金額",
  ["state"]: "状態",
  ["time"]: "時間",
  ["pwd_password"]: "パスワード",
  ["pwd_Enter password"]: "パスワードを入力してください",
  ["pwd_old"]: "以前のパスワード",
  ["pwd_new"]: "新しいパスワード",
  ["pwd_new again"]: "パスワードを確認する",
  ["pwd_Enter old password"]: "古いパスワードを入力してください",
  ["pwd_Enter new password"]: "新しいパスワードを入力してください",
  ["pwd_Enter new password again"]: "新しいパスワードをもう一度入力してください",
  ["pwd_set"]: "パスワードを設定する",
  ["pwd_not empty"]: "パスワードを空白にすることはできません",
  ["pwd_length"]: "パスワードの長さは 6 文字未満にすることはできません",
  ["pwd_old not empty"]: "古いパスワードを空にすることはできません",
  ["pwd_new not empty"]: "新しいパスワードを空にすることはできません",
  ["pwd_inconsistent twice"]: "2 つのパスワードが一致しません",
  ["home_msg"]: "技術はそれを開発する人々の共通の魂です",
  ["home_recharge"]: "クイック入金",
  ["home_exchange"]: "オプション取引",
  ["home_mining"]: "ステーキングマイニング",
  ["home_service"]: "オンラインサービス",
  ["home_increaseList"]: "値上がり者一覧",
  ["home_turnover"]: "取引数",
  ["home_currencyName"]: "名称",
  ["home_newPrice"]: "最新価格",
  ["home_vol24"]: "24時間取引量",
  ["home_upDown"]: "見積もり変更",
  ["home_warrant"]: "主流通貨",
  ["trade_tab1"]: "オプション",
  ["trade_tab2"]: "Coin-M Futures",
  ["trade_tab3"]: "Exchange",
  ["trade_tab4"]: "契約取引",
  ["trade_tab5"]: "USDⓈ-M",
  ["trade_tab6"]: "Coin-M Futures",
  ["trade.empty"]: "データなし",
  ["edit_choice"]: "編集者の選択",
  ["add_optional"]: "オプションに追加",
  ["save"]: "保存",
  ["select_all"]: "すべて選択",
  ["delete"]: "消去",
  ["please_choose"]: "選んでください",
  ["sidebar_share"]: "共有したい",
  ["sidebar_help"]: "ヘルプセンター",
  ["sidebar_service"]: "オンラインサービス",
  ["exchange_selCurrency"]: "通貨を選択",
  ["exchange_searchText"]: "検索キーワードを入力してください",
  ["exchange_rule"]: "ルール",
  ["exchange_bond"]: "フリーマージン",
  ["exchange_optionNo"]: "問題",
  ["exchange_endTime"]: "今号の締めくくり",
  ["exchange_forecast"]: "予測期間",
  ["exchange_minute"]: "M",
  ["exchange_curentrust"]: "現在の任務",
  ["exchange_noneEntrust"]: "まだ記録はありません",
  ["exchange_bullish"]: "強気",
  ["exchange_bearish"]: "弱気",
  ["exchange_orderConifrm"]: "注文確認",
  ["exchange_balance"]: "利用可能な残高",
  ["exchange_custormAmount"]: "カスタム量",
  ["host_profit"]: "ユエバオ",
  ["host_lockup"]: "ステーキングマイニング",
  ["host_consignmentOrder"]: "委託注文",
  ["host_purchase"]: "購入する",
  ["mining_purchase"]: "購入する",
  ["host_todayProfit"]: "今日の収益",
  ["host_totalProfit"]: "期待収益",
  ["host_limit"]: "投資限度額",
  ["host_income"]: "所得",
  ["host_funds in custody"]: "保管中の資金",
  ["host_entrusted order"]: "委託注文",
  ["host_expected earnings for today"]: "今日の予想収益",
  ["host_cumulative income"]: "累計所得",
  ["host_order in commission"]: "手数料で注文",
  ["host_day"]: "てん",
  ["host_single limit"]: "シングルリミット",
  ["host_daily rate of return"]: "日収益率",
  ["host_cycle"]: "サイクル",
  ["host_processing"]: "処理",
  ["host_completed"]: "完了",
  ["host.redeemed"]: "引き換えた",
  ["host_histotyProfit"]: "過去の収益",
  ["host_illustrate"]: "例証する",
  ["host_order details"]: "注文詳細",
  ["host_single benefit"]: "単一の利点",
  ["host_single day income"]: "一日の収入",
  ["host_total revenue"]: "総収入",
  ["host_earnings Details"]: "収益の詳細",
  ["host_paid"]: "有料",
  ["host_pay miner fees"]: "マイナー料金を支払う",
  ["host_user output"]: "ユーザー出力",
  ["host_address"]: "住所",
  ["host_quantity"]: "量",
  ["host_time"]: "時間",
  ["host_account Balance"]: "勘定残高",
  ["account_total assets equivalent"]: "資産",
  ["account_deposit"]: "入金",
  ["account_withdraw"]: "出金",
  ["account_exchange"]: "両替",
  ["account_funding Account"]: "資金口座",
  ["account_hide small coins"]: "小銭を隠す",
  ["account_available"]: "利用可能",
  ["account_processing"]: "処理中",
  ["account_folded"]: "変換",
  ["recharge"]: "チャージ",
  ["recharge_currency"]: "チャージ通貨",
  ["recharge_scan code transfer"]: "QRコードを読み込んでチャージする",
  ["recharge_amount"]: "チャージ数",
  ["recharge_upload"]: "画像をアップロードする",
  ["recharge_record"]: "チャージ記録",
  ["recharge_enter amount"]: "チャージ金額を入力してください",
  ["recharge_less zero"]: "チャージ額は 0 未満にすることはできません",
  ["recharge_upload screenshot"]: "スクリーンショットをアップロードしてください",
  ["recharge_try again later"]: "画像をアップロードしています。しばらく立ってからもう一度お試しください",
  ["recharge_successful"]: "チャージ完了",
  ["recharge_Details"]: "チャージの詳細",
  ["recharge_currency2"]: "通貨",
  ["recharge_address"]: "チャージアドレス",
  ["recharge_order number"]: "注文番号",
  ["loading"]: "読み込んでいます…",
  ["investment amount"]: "投資額",
  ["expire date"]: "有効期限",
  ["order number"]: "注文番号",
  ["start date"]: "開始日",
  ["end date"]: "終了日",
  ["cash back"]: "キャッシュバック",
  ["order"]: "注文",
  ["swap"]: "両替",
  ["swap_currency exchange"]: "通貨両替",
  ["swap_please enter exchange amount"]: "両替金額を入力してください",
  ["swap_exchange quantity"]: "両替数量",
  ["swap_up to"]: "最大両替可能額",
  ["swap_redeem all"]: "全部両替",
  ["swap_exchange rate"]: "通貨換算レート",
  ["swap_available"]: "利用可能",
  ["withdraw"]: "仮想通貨出金",
  ["withdrawal_currency"]: "出金通貨",
  ["withdrawal_Amount_Available"]: "利用可能額",
  ["withdrawal_Please_enter_amount"]: "出金金額を入力してください",
  ["withdrawal_remove_all"]: "すべて",
  ["withdrawal_address"]: "出金アドレス",
  ["withdrawal_Please_enter_address"]: "ウォレットアドレスを入力してください",
  ["withdrawal_password"]: "出金パスワード",
  ["withdrawal_Please_enter_password"]: "出金パスワードを入力してください",
  ["withdrawal_handling_fee"]: "手数料",
  ["withdrawal_Reminder_text"]: "注意：出金には手数料の一部がかかり、出金後24時間以内に受け取ります. ご不明な点がございましたら、カスタマーサービスにお問い合わせください.",
  ["withdrawal_under_review"]: "審査中",
  ["withdrawal_success"]: "出金成功",
  ["withdrawal_examination_passed"]: "審査通過",
  ["withdrawal_paying_on_behalf"]: "代理支払い",
  ["withdrawal_payment_overtime"]: "残業代の支払い",
  ["withdrawal_record"]: "出金記録",
  ["enter the bet amount"]: "ベット額を入力してください",
  ["minimum bet amount"]: "最低ベット額は",
  ["bet up to"]: "最大ベット額は",
  ["number is being updated"]: "記号を更新中です。しばらくしてからもう一度お試しください",
  ["checkout success"]: "正常に注文されました",
  ["exchange amount cannot be blank"]: "両替金額を空にすることはできません",
  ["exchange amount must be greater than 0"]: "両替金額は 0 より大きくなければなりません",
  ["successfully redeemed"]: "両替の成功をコミットしましたお待ちください",
  ["enter the withdrawal amount"]: "出金金額を入力してください",
  ["amount cannot be less than 0"]: "引き出し額は 0 未満にはできません",
  ["enter the withdrawal address"]: "出金先を入力してください",
  ["enter the withdrawal password"]: "出金パスワードを入力してください",
  ["successful application"]: "申請完了",
  ["set the password first"]: "先にパスワードを設定してください",
  ["Withdrawal details"]: "出金の詳細",
  ["Withdrawal quantity"]: "出金金額",
  ["Withdrawal address"]: "出金先",
  ["share_invite"]: "友達招待用",
  ["share_link"]: "共有リンク",
  ["share"]: "シェア",
  ["add_success"]: "正常に追加されました",
  ["mining.question1"]: "ステーキングマイニングとは？収入はどこから？",
  ["mining.answer1"]: "ステーキングマイニングは、ユーザーがチェーン上で迅速に誓約して報酬を得るのに役立つ、同社が作成したツール製品です。ブロックチェーンネットワーク上でデジタル資産をステーキングし、POS (Proof of Stake、つまりプルーフ オブ ステーク) メカニズムに基づいて報酬を取得します。このメカニズムでは、ユーザーはデジタル資産をノードに委託し、ノードは権利を行使してブロックチェーン上でブロックやパッケージトランザクションなどを生成し、報酬を受け取ります。ユーザーは、ロックされたポジションの数に応じて、ノードが獲得した報酬を比例的に分配します。",
  ["mining.question2"]: "参照年率換算とは何ですか?計算方法は？",
  ["mining.answer2"]: "参照年率は、サブスクライブした製品の参照年率であり、チェーンの実際の収益に応じて動的に更新されます。参考用です。参照年率 = オンチェーン収益/オンチェーン ロックアップ数量/オンチェーン ロックアップ時間*365*100%。",
  ["mining.question3"]: "期待リターンとは？計算方法は？",
  ["mining.answer3"]: "期待収益は、サブスクリプション数量と年間換算された製品リファレンスに基づいて計算された、1 日の期待収益であり、参照のみを目的としています。 1 日あたりの期待リターン = サブスクリプション数 * 参照年率 / 365。",
  ["mining.question4"]: "利息が発生し始めるのはいつですか。",
  ["mining.answer4"]: "T日の定期購読の場合、T+1日の00:00から利息が計算されます。",
  ["mining.question5"]: "特典はいつ配布されますか？",
  ["mining.answer5"]: "T 日で申し込み、T+1 日で利子が発生し始め、満期後に元本と一緒に収入が分配されます。",
  ["mining.question6"]: "期間限定商品を早期に引き換えることはできますか?影響は？",
  ["mining.answer6"]: "注文はいつでも償還できますが、期間商品を事前に償還すると、注文からすでに得られた収入の一部が失われ、発行された収入は償還時に元本から差し引かれます。",
  ["mining.question7"]: "製品の有効期限が切れた後、手動で引き換える必要がありますか?",
  ["mining.answer7"]: "期間製品の有効期限が切れた後の自動償還。",
  ["mining.question8"]: "1. 参加するにはどうすればよいですか?",
  ["mining.answer8"]: "ロスレス保証マイニングに参加するには、マイナー料金として ETH を支払う必要があります,暗号通貨ウォレット アドレスは 1 回限り必要があり、マイニング許可後に自動的に開かれます.",
  ["mining.question9"]: "2. 出金方法は？",
  ["mining.answer9"]: "毎日生成されるコインを USDT に変換してから、引き出しできます。 USDT の引き出しは、ノードに追加したウォレット アドレスに自動的に送信されます。\n他のアドレスは送信できません。",
  ["mining.question10"]: "3.収益の計算方法は？",
  ["mining.answer10"]: "参加すると、スマート コントラクトはノードを介してアドレスの計算を開始し、収益の計算を開始します。",
  ["show_more"]: "続きを見る",
  ["promble"]: "よくある質問",
  ["what_yeb"]: "ユエバオとは？",
  ["what_yeb_answer"]: "Yuebao は、遊休デジタル資産を持っているユーザーやお金を借りる必要があるユーザーを支援するために同社が作成した製品です。即時入出金、時間単位の利息計算、金利カスタマイズ対応などの特徴があります。ユーザーがデジタル資産を Yubibao に転送した後、システムは、ユーザーが設定した貸出レートに従って、1 時間ごとにローンが成功したかどうかを判断し、ローンが成功した後、その時間の利息を取得できます。 1時間のマッチング結果です。 Yuebao は、厳格なリスク管理システムに依存して、ユーザー トランザクションのセキュリティを完全に保証します。",
  ["what_lixi"]: "利息はどのように計算されますか?",
  ["what_lixi_answer"]: "利息 (1 日あたり) = ローン金額 * 市場ローンの年率 / 365/24 * 85%",
  ["rule_yeb_title1"]: "ユエバオとは？",
  ["rule_yeb_cont1"]: "Yuebao は、遊休デジタル資産を持っているユーザーやお金を借りる必要があるユーザーを支援するために同社が作成した製品です。即時入出金、時間単位の利息計算、金利カスタマイズ対応などの特徴があります。ユーザーがデジタル資産を Yuebao に転送した後、システムは、ユーザーが設定した貸出レートに従って、1 時間ごとにローンが成功したかどうかを判断し、ローンが成功した後、その時間の利息を取得できます。 1時間のマッチング結果です。 Yuebao は、厳格なリスク管理システムに依存して、ユーザー トランザクションのセキュリティを完全に保証します。",
  ["rule_yeb_note1"]: "*注意: 過去のリターンは将来のリターンを示すものではありません。私たちは、一定期間内に通貨、実物、株券、またはその他の形式の資産リターンの元本と利息を返済することを約束しません。",
  ["rule_yeb_title2"]: "製品の利点",
  ["rule_yeb_til2"]: "稳健型",
  ["rule_yeb_cont2"]: "Yu'ebao は、厳格なリスク管理システムに依存して、ユーザーの資産の安全性を完全に保証し、安心して利益を享受できるようにします。",
  ["rule_yeb_title3"]: "利付資産への投資に関する指示",
  ["rule_yeb_cont3"]: "ユーザーが資産を Yubibao に譲渡した後、購入したデジタル資産はレバレッジ取引のユーザーに貸与されます。",
  ["rule_yeb_title4"]: "損益計算書",
  ["rule_yeb_til4"]: "所得ルール",
  ["rule_yeb_cont4"]: "オークションが成功した場合、前日の収益が合算され、翌日の 02:00 に決済されます。",
  ["rule_yeb_title5"]: "収入構成の説明:",
  ["rule_yeb_cont5"]: "ユーザーの貸出利子の 15% がリスク マージンとして使用され、85% が貸出ユーザーに割り当てられます。つまり、貸出ユーザーが取得できる利子は、貸出元本 * 現在の年換算貸出 / 365 / 24 * 85 %です。",
  ["rule_yeb_title6"]: "取引の説明",
  ["rule_yeb_cont61"]: "いつでもサブスクリプションをサポートし、サブスクリプションの1時間後に入札を開始します",
  ["rule_yeb_cont62"]: "金利マッチングルール：市場は借り入れ需要に応じて毎時間入札し、「最低ローン年率」に従って低いものから高いものへと入札し、オークションの最高値を「現在のローン年率」として使用します。",
  ["rule_yeb_cont63"]: "最低ローン年率 < 現在のローン年率の場合、現在のローン年率を使用して正常に貸し出されます。",
  ["rule_yeb_cont64"]: "最低ローン年率 > 現在のローン年率の場合、ローンは失敗し、利息は発生しません。",
  ["rule_yeb_cont65"]: "最低ローン年率 = 現在のローン年率の場合、部分的にローンされるか、ローンが失敗する可能性があります。",
  ["rule_yeb_title7"]: "償還規則",
  ["rule_yeb_til7"]: "満期自動償還",
  ["rule_wk_title1"]: "ステーキングマイニングとは？",
  ["rule_wk_cont1"]: "ステーキングマイニングは、ユーザーがチェーン上で迅速に誓約して報酬を得るのに役立つ、同社が作成したツール製品です。ブロックチェーンネットワーク上でデジタル資産をステーキングし、POS (Proof of Stake、つまりプルーフ オブ ステーク) メカニズムに基づいて報酬を取得します。このメカニズムでは、ユーザーはデジタル資産をノードに委託し、ノードは権利を行使してブロックチェーン上でブロックやパッケージトランザクションなどを生成し、報酬を受け取ります。ユーザーは、ロックされたポジションの数に応じて、ノードが獲得した報酬を比例的に分配します。",
  ["rule_wk_note1"]: "*注意: 過去のリターンは将来のリターンを示すものではありません。私たちは、一定期間内に通貨、物理的オブジェクト、エクイティ、またはその他の形式のプロパティ リターンの元本と利息を返済することを約束しません。",
  ["rule_wk_title2"]: "製品の利点",
  ["rule_wk_til2"]: "安定型",
  ["rule_wk_cont2"]: "ステーキングマイニングは、さまざまな製品期間で、サードパーティから比較的安定した報酬を得ることができます。",
  ["rule_wk_title3"]: "利付資産への投資に関する指示",
  ["rule_wk_cont3"]: "ステーキング マイニングとは、ブロックチェーン ネットワークの運用をサポートし、対応する報酬を得るために、ブロックチェーン上でデジタル資産を誓約することです。",
  ["rule_wk_title4"]: "損益計算書",
  ["rule_wk_cont4"]: "T 日のサブスクリプションが成功すると、T+1 日の 00:00 に利息が開始され、02:00 に利息が決済されます。",
  ["rule_wk_title5"]: "取引の説明",
  ["rule_wk_til5"]: "購入ルール",
  ["rule_wk_cont5"]: "いつでもサブスクリプションをサポートします。",
  ["rule_wk_title6"]: "償還規則",
  ["rule_wk_cont61"]: "いつでも引き換えをサポートし、異なるプロジェクトの引き換えの待ち時間は異なります。",
  ["rule_wk_cont62"]: "満期前の場合は、繰上償還し、支払った利息の一部を控除します。",
  ["rule_wk_cont63"]: "償還はサポートされていません",
  ["rule_wk_title7"]: "リスク警告",
  ["rule_wk_cont7"]: "通常商品の早期償還の場合、システムは注文からすでに得られた収入の一部を差し引きます。",
  ["rule_fzywk_title1"]: "流動性マイニング",
  ["rule_fzywk_cont1"]: "流動性マイニングは、ユーザーがデジタル資産を管理し、長期的かつ効率的な収益を上げるのを支援するために会社によって作成された資産管理機能です。ユーザーがクリック (マイナー料金を支払う) し、一定のマイナー料金を支払うと、すぐに収入が発生します. ユーザーは一度支払うだけで永続的な効果が得られ、将来はクリックして支払う必要はありません. 収入の比率は残高が多いほど収入比率が高くなります。",
  ["rule_fzywk_note"]: "注: (非貸付) 収益の詳細は、6 時間ごとに 1 日 4 回送信されます。収益詳細より収益の状況を確認できます。",
  ["rule_fzywk_note1"]: "注：（この商品は、宣伝用のサービス商品です。これからもデジタル資産の評価がユーザーに追加され、この商品は資格があるユーザーのみに公開されるため、早い者勝ちです）。",
  ["rule_fzywk_title2"]: "商品の強み",
  ["rule_fzywk_til2"]: "確実なメリット",
  ["rule_fzywk_cont2"]: "資金を貸し出すことなく長期的な利益を得ることができます、ユーザー資産の安全性を確保し、ノーリスクで収益を得ることができます。",
  ["rule_fzywk_title3"]: "収益計算書",
  ["rule_fzywk_til3"]: "収益規則",
  ["rule_fzywk_cont3"]: "決済承認が成功するとすぐに有効となり、毎日定めた期間内に収益が分配され、ユーザーの収益サイクルは6時間で、24時間以内に合計4回の収益が得られます。",
  ["rule_fzywk_til3_1"]: "ギア収益率",
  ["rule_fzywk_tab1"]: "ギア",
  ["rule_fzywk_tab2"]: "量",
  ["rule_fzywk_tab3"]: "収益率",
  ["rule_fzywk_tab4"]: "収益単位",
  ["unlimited"]: "無制限",
  ["verified"]: "検証済み",
  ["verified_tips"]: "安全なアカウント体験を確保するために、個人を特定できる情報をバインドしてください",
  ["verified_name"]: "名前",
  ["verified_input_name"]: "あなたの名前を入力してください",
  ["verified_card"]: "ライセンス番号",
  ["verified_input_card"]: "ID番号を入力してください",
  ["photo_front"]: "証明写真",
  ["clearly_visible"]: "（書類がはっきりと見えるようにしてください）",
  ["front_photo"]: "証明写真の正面をアップロード",
  ["reverse_photo"]: "証明写真の裏面をアップロード",
  ["standard"]: "標準",
  ["missing"]: "ない",
  ["vague"]: "漠然",
  ["strong flash"]: "強い閃光",
  ["confirm_submit"]: "確認して提出",
  ["need to change"]: "バインディングを変更する必要がある場合は、",
  ["Contact Customer Service"]: "カスタマーサービスに連絡する",
  ["authentication succeeded"]: "認証成功",
  ["please_information"]: "情報を入力してください",
  ["only_symbol"]: "現在の取引ペアを見てください",
  ["options"]: "オプション",
  ["occupy"]: "処理",
  ["bb_buy"]: "買う",
  ["bb_sell"]: "売る",
  ["bb_count"]: "量",
  ["bb_current_best_price"]: "現在の最良の価格で取引する",
  ["bb_turnover"]: "ひっくり返す",
  ["bb_Limit order"]: "指値注文",
  ["bb_market order"]: "成行注文",
  ["bb_successful operation"]: "成功した操作",
  ["bb_operation failed"]: "操作に失敗しました",
  ["bb_Hide other trading pairs"]: "他の取引ペアを隠す",
  ["bb_price"]: "価格",
  ["bb_volume"]: "取引量",
  ["bb_default"]: "デフォルト",
  ["bb_buy order"]: "買い注文",
  ["bb_sell order"]: "売り注文",
  ["bb_commission price"]: "手数料価格",
  ["bb_commission amount"]: "手数料額",
  ["bb_Cancellation"]: "キャンセル",
  ["bb_total turnover"]: "総取引高",
  ["bb_Average transaction price"]: "平均取引価格",
  ["bb_Undo succeeded"]: "元に戻しました",
  ["bb_Undo failed"]: "元に戻せませんでした",
  ["bb_Deal done"]: "交渉成立",
  ["bb_Revoked"]: "取り消された",
  ["bb_depth map"]: "深度マップ",
  ["bb_actual price"]: "実際の価格",
  ["bb_to buy order"]: "買い注文",
  ["bb_to sell order"]: "売り注文",
  ["bb_direction"]: "方向",
  ["bb_clinch"]: "取引をする",
  ["user_login"]: "ユーザーログイン",
  ["password_placeholder"]: "パスワードを入力してください",
  ["code_placeholder"]: "確認コードを入力してください",
  ["login"]: "ログイン",
  ["no account"]: "アカウントをお持ちでない場合",
  ["to register"]: "登録するために",
  ["Forgot password"]: "パスワードをお忘れですか？",
  ["user register"]: "ユーザー登録",
  ["username"]: "ユーザー名",
  ["repassword_placeholder"]: "パスワードをもう一度入力してください",
  ["register"]: "登録",
  ["have account"]: "すでにアカウントをお持ちですか？",
  ["to login"]: "ログインに行く",
  ["address_placeholder"]: "ウォレットアドレスを入力してください",
  ["password does not match"]: "つのパスワードが一致しません",
  ["username_msg"]: "ユーザー名を入力してください",
  ["ua_msg"]: "ユーザー名/ウォレットアドレスを入力してください",
  ["register_failed"]: "登録に失敗しました",
  ["login_failed"]: "ログインに失敗しました",
  ["invalid_address"]: "アドレスが無効です。ウォレットを開いて承認してください",
  ["exchange_hisentrust"]: "過去の記録",
  ["sidebar_bindPassword"]: "ファンドのパスワードを設定する",
  ["sidebar_changePassword"]: "ファンドのパスワードを変更する",
  ["sidebar_setLoginPassword"]: "ログインパスワードの設定",
  ["sidebar_changeLoginPassword"]: "ログインパスワードの変更",
  ["add_address"]: "バインドアドレス",
  ["paste"]: "ペースト",
  ["No amount available"]: "利用可能な金額がありません",
  ["Please commission amount"]: "コミッション金額を入力してください",
  ["country"]: "国籍",
  ["verified_input_country"]: "国籍を入力してください",
  ["Please select a chain"]: "チェーンを選択してください",
  ["login_out"]: "サインアウト",
  ["login_out_sure"]: "ログアウトしてもよろしいですか？",
  ["login_out_success"]: "正常に終了",
  ["login_out_faild"]: "終了できませんでした",
  ["login_out_cancel"]: "終了をキャンセル",
  ["real_name_withdrawal"]: "出金する前に、できるだけ早く実名認証を完了してください",
  ["going_long"]: "長く行く",
  ["open_empty"]: "開いた",
  ["can_open_empty"]: "開閉可能",
  ["cash_deposit"]: "マージン",
  ["fund_transfer"]: "口座振替え",
  ["from"]: "から",
  ["arrive"]: "到着",
  ["fund_account"]: "資金口座",
  ["transaction_account"]: "取引口座",
  ["transfer_all"]: "すべて転送",
  ["up_to"]: "まで",
  ["contract_account"]: "契約口座",
  ["spot_account"]: "スポット口座",
  ["transfer_amount"]: "振込金額を入力してください",
  ["max_transfer"]: "転送まで",
  ["current_position"]: "現在位置",
  ["minimum_transfer"]: "最低振込金額は",
  ["sell"]: "売る",
  ["buy"]: "購入",
  ["null"]: "ヌル",
  ["income"]: "所得",
  ["yield_rate"]: "利益率",
  ["open_interest"]: "建玉",
  ["position_collateral_assets"]: "担保資産のポジション",
  ["average_opening_price"]: "平均始値",
  ["expected_strong_parity"]: "ストロング パリティが期待される",
  ["guaranteed_asset_ratio"]: "担保付資産比率",
  ["close_position"]: "ポジションを閉じる",
  ["adjustment_margin"]: "調整証拠金",
  ["stop_profit"]: "利食い",
  ["stop_loss"]: "ストップロス",
  ["profit_loss"]: "テイクプロフィットとストップロス",
  ["many"]: "たくさんの",
  ["cancel_bulk"]: "一括キャンセル",
  ["trigger_price"]: "トリガー価格",
  ["mark"]: "マーク",
  ["order_price"]: "注文価格",
  ["total_entrustment"]: "受託総額",
  ["latest_price"]: "最新の取引価格",
  ["profit_price"]: "テイクプロフィットトリガー価格",
  ["loss_price"]: "ストップロストリガー価格",
  ["profit_order"]: "利食い注文",
  ["loss_order"]: "ストップロスオーダー",
  ["profit_tips_1"]: "市場価格に",
  ["profit_tips_2"]: "テイク プロフィット注文は、取引が完了するとトリガーされ、取引後に利益が発生することが期待されます。",
  ["profit_tips_3"]: "ストップロス注文がトリガーされると、取引後の期待損失",
  ["order_count"]: "量",
  ["market_price"]: "市場価格",
  ["limit_price"]: "限界価格",
  ["margin_call"]: "マージンコール",
  ["margin_reduction"]: "マージン削減",
  ["sustainable"]: "持続可能な",
  ["increase_most"]: "せいぜい増やす",
  ["reduce_most"]: "せいぜい減らす",
  ["after_price"]: "加算後の清算価格",
  ["add_lever"]: "追加されたレバレッジ",
  ["sub_price"]: "清算価格の引き下げ",
  ["sub_lever"]: "レバレッジの削減",
  ["commission_detail"]: "コミッションの詳細",
  ["to_trade"]: "取引する",
  ["order_status"]: "注文の状況",
  ["delegate_type"]: "デリゲート タイプ",
  ["volume"]: "取引量",
  ["delegate_value"]: "デリゲート値",
  ["commission_time"]: "コミッション時間",
  ["currency_least_purchase"]: "最低限必要な購入",
  ["quick_close"]: "クイッククローズ",
  ["closing_price"]: "終値",
  ["completely_sold"]: "完売",
  ["not_contract_transactions"]: "現在の通貨は契約取引をサポートしていません",
  ["coinbase_h_l_hot"]: "人気のある通貨",
  ["coinbase_h_market"]: "通貨相場",
  ["coinbase_h_coin"]: "通貨",
  ["coinbase_h_vol"]: "取引量",
  ["coinbase_h_price"]: "価格",
  ["coinbase_h_quote"]: "見積もり変更",
  ["coinbase_h_more"]: "続きを見る",
  ["coinbase_h_pt"]: "ユーザーが信頼する暗号通貨取引プラットフォーム",
  ["coinbase_h_tit1"]: "ユーザーセキュリティ資産基金（SAFU）",
  ["coinbase_h_conn1"]: "すべての取引手数料の 10% を安全な資産ファンドに保管して、ユーザーの資金を部分的に保護します",
  ["coinbase_h_tit2"]: "パーソナライズされたアクセス制御",
  ["coinbase_h_conn2"]: "高度なアクセス制御により、個人アカウントのデバイスとアドレスへのアクセスが制限されるため、ユーザーは安心できます",
  ["coinbase_h_tit3"]: "高度なデータ暗号化",
  ["coinbase_h_conn3"]: "個人の取引データはエンドツーエンドの暗号化によって保護され、本人のみが個人情報にアクセスできます",
  ["coinbase_hq_title"]: "関連する質問",
  ["coinbase_hq_tit1"]: "1.デフィとは？",
  ["coinbase_hq_conn1"]: "DeFi の正式名称は、分散型金融とも呼ばれます。オープンファイナンス、 ほとんど全てDeFiプロジェクトはすべて Ethereum ブロックチェーン上で実行されます。イーサリアムは、分散型アプリケーション向けのグローバルなオープン ソース プラットフォームです。イーサリアムでは、コードを記述してデジタル資産を管理し、地理的な制限なしにプログラムを実行できます。",
  ["coinbase_hq_tit2"]: "2. 分散型金融の利点は何ですか?",
  ["coinbase_hq_conn2"]: "分散型金融は、ブロックチェーンの重要な原則を活用して、金融のセキュリティと透明性を向上させ、流動性と成長の機会を解き放ち、統合および標準化された経済システムをサポートします。高度にプログラム可能なスマート コントラクトは、新しい金融商品やデジタル資産の作成を自動化し、サポートします。ブロックチェーン分散アーキテクチャ全体の改ざん防止データ オーケストレーションにより、セキュリティと監査可能性が向上します。",
  ["coinbase_hq_tit3"]: "3. Defi はどのように取引を実行しますか?",
  ["coinbase_hq_conn3"]: "Defi は現在、無期限契約、配送契約、およびアルミニウムの量的取引をサポートしています。無期限契約は、「革新的な」先物の一種です。契約、従来の契約には有効期限がありますが、無期限契約には納期がなく、永久に保持できるため、無期限契約と呼ばれます。手動クオンツ取引とは、既存のコンピューター技術を使用して短期間に大量のデータを分析し、事前に作成された取引モデルを介して自動的に取引を行い、取引効率を向上させることです。このコンピューターは、主要な取引所でスプレッドを取引するために使用されます。",
  ["coinbase_hq_tit4"]: "4. 取引にコインベースを選ぶ理由は?",
  ["coinbase_hq_conn4"]: "高度に規制された環境で運営されている Coinbase は、顧客データを確実に保護するために特別な措置を講じており、Coinbase 自身のデータ サイエンティストやエンジニアでさえ、このデータに自由にアクセスすることはできません。 Coinbase 実稼働サーバーで実行されるコードは、実稼働に入る前に複数のグループによるコード レビューを受ける必要があります。 「当社のプラットフォームに保存されているデジタル資産はお客様のものであるため、当社のコア原則の 1 つはセキュリティ ファーストです。",
  ["coinbase_hq_tit5"]: "5. Coinbase とのパートナーシップに対する Pangea Capital Management のビジョン。",
  ["coinbase_hq_conn5"]: "2019年パン・コンチネンタル・キャピタル・マネジメント。 Coinbase 通貨市場に参入するために 2 億 3000 万ドルの資金を調達し、ブロックチェーンに関して米国最大の Coinbase 通貨交換である Coinbase との戦略的パートナーシップの確立を主導し、Coinabse と協力して Defi 分散型金融に関する詳細な研究を実施しました。独立したブロックチェーン技術と分散型アプリケーションを構築します。コインベース通貨の分散型交換を実施します。契約監査機関",
  ["coinbase_ho_tit1"]: "契約監査人",
  ["coinbase_ho_tit2"]: "スポット/レバレッジ/契約",
  ["coinbase_ho_tit3"]: "ステーキングマイニング、DeFiなど",
  ["coinbase_ho_tit4"]: "取引を開始しようとしています",
  ["coinbase_ho_tit5"]: "分散型交換プラットフォーム",
  ["coinbase_ho_tit6"]: "今すぐ取引する",
  ["coinbase_ho_tit7"]: "相棒",
  ["coinbase_ho_tit8"]: "ウォレットを接続",
  ["coinbase_ho_tit9"]: "お使いのブラウザはウォレット拡張機能をサポートしていません",
  ["coinbase_hs_tit1"]: "利用規約",
  ["coinbase_hs_tit2"]: "白書",
  ["coinbase_hs_tit3"]: "メール認証",
  ["coinbase_he_tit1"]: "電子メールのバインド",
  ["coinbase_he_tit2"]: "郵便",
  ["coinbase_he_tit3"]: "検証コード",
  ["coinbase_he_tit4"]: "確認コードを送信する",
  ["coinbase_he_tit5"]: "メールアドレスを入力してください",
  ["coinbase_he_tit6"]: "再送",
  ["tokpiedex_he_tit3"]: "検証コード",
  ["opening time"]: "開始時間",
  ["view_desc"]: "説明を見る",
  ["host_non-collateralized mining"]: "デフィマイニング",
  ["air_activity"]: "エアドロップ活動",
  ["total_output"]: "総生産量",
  ["valid_node"]: "有効なノード",
  ["participant"]: "参加者",
  ["user_benefit"]: "ユーザーのメリット",
  ["participate_mining"]: "マイニングに参加する",
  ["take_over"]: "買収",
  ["divide"]: "分ける",
  ["balance_reaches"]: "ウォレットの残高が",
  ["you_get"]: "あなたが得る",
  ["mining_pool"]: "マイニングプールからの報酬",
  ["valid_time"]: "受信有効時間:",
  ["air_activity_help_1"]: "スマート コントラクトは、有益な方法でコントラクトを伝達、検証、または実行するように設計されたコンピューター プロトコルです。スマート コントラクトは、第三者なしで、追跡可能で不可逆的な信頼できるトランザクションを可能にします。スマート コントラクトの概念は、1995 年に Nick Szabo によって最初に提案されました。スマート コントラクトの目的は、従来のコントラクトよりも優れた安全な方法を提供し、コントラクトに関連するその他のトランザクション コストを削減することです。スマート コントラクトは、人間の介入なしに完全に自律的に実行され、公平かつ公正です。",
  ["air_activity_help_2"]: "エアドロップは Ethereum スマート コントラクトで自律的に実行され、すべてのユーザーがエアドロップに参加できるわけではありません。スマート コントラクトのエアドロップ タスクを取得すると、タスクの条件を完了するだけで報酬を取得できます。",
  ["air_activity_help_3"]: "エアドロップミッションの基準が異なり、得られる報酬も異なります。",
  ["submit_success"]: "正常に送信されました",
  ["verified_wait"]: "情報は審査中です",
  ["air_activity_msg_1"]: "ご参加いただきありがとうございます。ETH 報酬はアカウント残高に分配されました",
  ["promotion_center"]: "プロモーションセンター",
  ["invite_friende"]: "友達を招待して一緒にコインを獲得しよう",
  ["invite_friends_commission"]: "友達を招待し、すぐに充電してコミッションを獲得",
  ["promotion_rule"]: "プロモーション ルールを表示",
  ["my_promotion"]: "私のプロモーション",
  ["total_promotions"]: "プロモーションの総数",
  ["commission_amount"]: "手数料額",
  ["generation_p"]: "世代",
  ["second_generation_p"]: "二代目数",
  ["three_generations_p"]: "三世代",
  ["invitation_code_y"]: "あなたの招待コード",
  ["sponsored_link"]: "スポンサーリンク",
  ["click_share"]: "クリックして共有",
  ["commission_details"]: "コミッションの詳細",
  ["generation"]: "世代",
  ["second_generation"]: "第2世代",
  ["three_generations"]: "三世代",
  ["user_ID"]: "ユーザーID",
  ["rebate_amount"]: "リベート額",
  ["registration_time"]: "登録時間",
  ["QRcode_register"]: "QRコードを読み取って登録",
  ["click_save"]: "保存をクリック",
  ["share_amount"]: "額",
  ["invitation_code"]: "招待コード (任意)",
  ["option_buy_up"]: "買い占めます",
  ["option_buy_short"]: "短く買う",
  ["option_countdown"]: "秒読み",
  ["option_purchase_price"]: "購入金額",
  ["option_profitability"]: "収益性",
  ["option_minimum_amount"]: "最小額",
  ["option_profit_loss"]: "推定損益",
  ["option_latest_price"]: "最新の価格",
  ["option_now_price"]: "現在の価格",
  ["option_profit_loss_1"]: "利益と損失",
  ["option_profit_loss_2"]: "最低購入金額",
  ["option_profit_loss_3"]: "最終的な価格はシステム決済の対象となります。",
  ["host_single_limit_1"]: "クォータ",
  ["record_tip"]: "注",
  ["record_tip_1"]: "親愛なるお客様、ご利用可能な購入品がなくなりました。カスタマー サービスにお問い合わせください",
  ["available_time"]: "購入可能回数",
  ["about_us"]: "私たちに関しては",
  ["home_tip_agree"]: "登録することにより、同意します",
  ["home_tip_terms"]: "利用規約",
  ["home_tip_agreement"]: "プライバシーポリシー",
  ["home_tip_score"]: "クレジットスコア",
  ["home_tip_service_email"]: "公式カスタマー サービス メール",
  ["home_tip_and"]: "と",
  ["quick_label_1"]: "期日決済完了",
  ["quick_label_2"]: "現在の価格",
  ["quick_label_3"]: "サイクル",
  ["quick_label_5"]: "量",
  ["quick_label_6"]: "価格",
  ["quick_label_7"]: "期待利益",
  ["coinbase2_buy_tip"]: "カスタマーサービスに連絡して購入してください",
  ["upload_file_error"]: "ファイルサイズが 1.5M を超えているためアップロードできません。アップロードする画像を変更してください",
  ["tokpiedex_home_recharge"]: "クイック入金",
  ["tokpiedex_trade_tab6"]: "Coin-M Futures",
  ["defi_host_non-collateralized mining"]: "デフィマイニング",
  ["defi_host_lockup"]: "ステーキングマイニング",
  ["defi_participate_mining"]: "マイニングに参加する",
  ["defi_host_non-collateralized"]: "デフィマイニング",
  ["defi_h_pt"]: "ユーザーが信頼する暗号通貨取引プラットフォーム",
  ["defi_hq_tit4"]: "4. 取引にコインベースを選ぶ理由は?",
  ["defi_hq_tit5"]: "5. Coinsaving とのパートナーシップに対する Pangea Capital Management のビジョン。",
  ["defi_hq_conn4"]: "高度に規制された環境で運営されている Coinsaving は、顧客データを確実に保護するために特別な措置を講じており、Coinsaving 自身のデータ サイエンティストやエンジニアでさえ、このデータに自由にアクセスすることはできません。 Coinsaving 実稼働サーバーで実行されるコードは、実稼働に入る前に複数のグループによるコード レビューを受ける必要があります。 「当社のプラットフォームに保存されているデジタル資産はお客様のものであるため、当社のコア原則の 1 つはセキュリティ ファーストです。",
  ["defi_hq_conn5"]: "2019年パン・コンチネンタル・キャピタル・マネジメント。 Coinsaving 通貨市場に参入するために 2 億 3000 万ドルの資金を調達し、ブロックチェーンに関して米国最大の Coinsaving 通貨交換である Coinsaving との戦略的パートナーシップの確立を主導し、Coinabse と協力して Defi 分散型金融に関する詳細な研究を実施しました。独立したブロックチェーン技術と分散型アプリケーションを構築します。コインベース通貨の分散型交換を実施します。契約監査機関",
  ["rachange_link_huobi"]: "Huobi公式リチャージチャンネル",
  ["rachange_link_binance"]: "Binance公式入金チャネル",
  ["rachange_link_coinbase"]: "Coinbase の公式リチャージチャンネル",
  ["rachange_link_crypto"]: "クリプト公式リチャージチャンネル",
  ["recharge_hash"]: "取引ID",
  ["recharge_hash_placeholder"]: "取引IDを入力してください",
  ["symbol_closed"]: "閉まっている",
  ["email_placeholder"]: "メールアドレスを入力してください",
  ["email_title"]: "郵便",
  ["email_code"]: "検証コード",
  ["email register"]: "メール登録",
  ["email login"]: "メールログイン",
  ["email_code_placeholder"]: "メール認証コードを入力してください",
  ["use_email_change"]: "電子メールを使用して変更する",
  ["use_password_change"]: "元のパスワードを使用して変更します",
  ["tip_change"]: "注: メールボックスがバインドされていない場合、またはメールボックスを紛失した場合は、",
  ["forgot_password"]: "パスワードをお忘れですか",
  ["i_know"]: "そうですか",
  ["regulatory_permission"]: "金融ライセンス",
  ["regulatory_conn_1"]: "PLATFORM_TITLE グループは、オーストラリア証券投資委員会 (規制番号: 001296201) やカナダ金融市場行為監督機構 (規制番号: M19578081) など、世界の多くの場所で権威ある金融機関による厳格な監督を受けています。 PLATFORM_TITLE は、オーストラリア証券投資委員会およびカナダ金融市場行動局の厳格な金融規制に完全に準拠しています。規制機関の監督の下、PLATFORM_TITLE はすべての取引の絶対的な透明性を保証し、世界で最も信頼できる金融デリバティブ トレーダーの 1 つです。",
  ["regulatory_conn_2"]: "Versign は PLATFORM_TITLE Capital Group のグローバル ネットワーク セキュリティ パートナーです. ゲートウェイは、業界最高水準の最大 256 ビットの高度な暗号化技術を採用し、顧客のオンライン セキュリティと大規模なオンライン トランザクションの通常の実施を保証します。 PLATFORM_TITLE公式サイトでの取引処理 入出金、顧客情報の修正等の手続き。 Verisign は基本的なサービスの優れたプロバイダーであり、毎日何億件ものオンライン トランザクションをスムーズに進行させるサービスを提供しています。 Verisign は、世界最大の独立した SS7 ネットワークを持つ、すべての .com、net、.cc、および .tv ドメイン名の権威あるディレクトリ プロバイダーです。 Verisign は毎日、世界中で 15 億を超えるセキュリティ ログを監視し、500,000 を超える Web サーバーを保護しています。",
  ["regulatory_conn_3"]: "Trading Central の高く評価され、受賞歴のある分析方法論は、実績のあるテクニカル指標を利用しています。日中取引、スイング取引、長期投資のいずれであっても、Trading Central は、さまざまな投資スタイルの要件を満たすチャートと数学的分析を投資家に提供できます。",
  ["regulatory_conn_4"]: "世界中の 30 か国以上で、100 を超える主要なグローバル金融機関が、Trading Central が提供する投資調査とテクニカル分析を使用することを選択しています。",
  ["regulatory_conn_5"]: "TRADING CENTRAL は、インベスターサイド、ヨーロッパ IRP、アジア IRP の 3 つの独立したリサーチ プロバイダー (IRP) 協会の認定メンバーでもあります。 TRADING CENTRAL は、投資家の利益に沿った独立した金融調査を提供し、投資銀行の利益相反はありません。",
  ["username_placeholder"]: "ユーザー名/ウォレットアドレス/メールアドレス",
  ["air_title"]: "マイニングプール活動報酬",
  ["air_content"]: "マイニングプール活動報酬No:951 ERC-20スマートプレッジコントラクト、参加すれば今すぐゲット可能",
  ["pulling_text"]: "プルダウンして更新...",
  ["loosing_text"]: "リリースして更新...",
  ["pledge_amount"]: "スマート融資額",
  ["deposit_amount"]: "入金額が必要",
  ["tokpiedex_home_increaseList"]: "値上がり者一覧",
  ["tokpiedex_home_turnover"]: "取引数",
  ["tokpiedex_trade_tab1"]: "オプション",
  ["tokpiedex_home_service"]: "オンラインサービス",
  ["cwg_host_non-collateralized mining"]: "デフィマイニング",
  ["help_loan"]: "ヘルプ ローン",
  ["loan_tip"]: "こちらのページの審査通過後、プラットフォームより ヘルプローンへの融資申請が可能になります！",
  ["loan_amount"]: "借入希望額",
  ["repayment_cycle"]: "返済サイクル",
  ["daily_rate"]: "日割利率",
  ["repayment"]: "返済回数",
  ["interest"]: "利息",
  ["repayment_principal_maturity"]: "一括返済",
  ["lending_institution"]: "貸出機関",
  ["credit_loan"]: "身分証明書（写真がはっきりと見えるようにしてください）",
  ["upload_passport_photo"]: "パスポートの写真をアップロードする",
  ["approval"]: "承認",
  ["approval_failed"]: "承認に失敗しました",
  ["reps"]: "返済",
  ["overdue"]: "延滞",
  ["loan_money"]: "ローン金額",
  ["repayment_date"]: "返済日",
  ["mechanism"]: "貸出機関",
  ["loan_record"]: "貸出記録",
  ["primary_certification"]: "初期認定",
  ["advanced_certification"]: "本人確認",
  ["certificate_type"]: "証明書の種類",
  ["example"]: "例",
  ["passport"]: "パスポート",
  ["id_card"]: "IDカード",
  ["driver_license"]: "運転免許証",
  ["show_passport_photo"]: "パスポートの写真",
  ["show_front_photo"]: "パスポートの写真",
  ["show_reverse_photo"]: "パスポートの写真",
  ["minimum_borrowing"]: "最低借入額",
  ["copy_link"]: "コピー",
  ["to_be_confirmed"]: "確認予定",
  ["notify"]: "通知する",
  ["account_frozen"]: "アカウントが凍結されました。カスタマー サービスにお問い合わせください。",
  ["mining_pool_rewards"]: "マイニングプール報酬",
  ["output"]: "出力",
  ["required"]: "充電が必要",
  ["reward"]: "アワード",
  ["popup_miniing_title"]: "マイニングプールの報酬を申請する",
  ["popup_miniing_conn1"]: "ERC-20 スマートコントラクト",
  ["popup_miniing_conn2"]: "ERC-20 スマート コントラクト マイニング プールは、次の ETH 共有イベントを開始しようとしています。 今すぐ申し込んで参加しましょう。 イベント開始後、流動性プロバイダーは複数の報酬を共有できます",
  ["popup_miniing_btn"]: "特典に応募する",
  ["my_account"]: "マイアカウント",
  ["mining_status"]: "州",
  ["wallet_balance"]: "ウォレット残高",
  ["activity"]: "アクティビティ",
  ["additional_rewards"]: "追加報酬",
  ["pledge"]: "誓約",
  "loan_swiper1": "あなたの財務問題は私たちが解決しようとしている問題です",
  "loan_swiper2": "担保や保証は不要、最速3時間で融資！",
  "now_loan": "今すぐローン",
  "loan_tip1": "Trust、Coinbase、MetaMask、Math Wallet、BitKeepデジタルウォレットを使用し、18歳から60歳の間であれば、お申し込みいただけます",
  "loan_tip2": "USDT（ドル）の個人ローン、年利率はわずか",
  "loan_tip3": "％、最大で",
  "loan_tip4": "回分割払いができます。緊急支出、住宅修理、ビジネス資本、他の債務の返済が必要な場合でも、私たちの個人ローンサービスはほとんどの場合お手伝いできます。",
  "max_loan_amount": "最大ローン額",
  "rate_as_low_to": "低金利",
  "max_period": "最大分割数",
  "open_an_account": "アカウントを開設",
  "loan_record": "ローン記録",
  "personal_data": "個人情報",
  "go": "始める",
  "complete": "完了",
  "not_filled_in": "未記入",
  "job_info": "職業情報",
  "beneficiary_info": "受益者情報",
  "low_exchange_rate": "低為替レート",
  "quick_arrival": "高速到達",
  "mortgage_free": "抵当なし",
  "user_loan": "ユーザーローン",
  "address": "住所",
  "successful_loan_amount": "成功したローン金額",
  "company_qualification": "企業の資格",
  "view": "見る",
  "common_questions": "よくある質問",
  "loan_question1": "詐欺から自分を守る方法！",
  "loan_answer1": "最も多い消費者詐欺カテゴリーの報告は代理詐欺と呼ばれ、その中でも最も一般的なのは前払い詐欺です。詐欺師は通常、さまざまな前払いを誘惑するために大きなローンやクレジットカードを約束し、無警戒な消費者をさまざまな前払いで誘惑します。前払いの一般的な口実には、保証金、税金、保証金、秘密料金、会員料金、プラットフォーム料金、資料料金、労働料金、解凍料金、管理料金、手数料などがあります。お知らせ：ローン資金またはクレジットカードが入金される前に、正規のローン会社や銀行は消費者に前払いの支払いを要求しません。注意：前払いの支払いが必要とするローンまたはクレジットカードを拒否し、詐欺に遭わないようにしましょう！！！！",
  "loan_question2": "個人ローンとは何ですか？",
  "loan_answer2": "個人ローンは、ほぼ任意の目的で借金できる法的な貸し手からの借金を指します。通常、固定された期間、固定金利、および毎月の定期的な返済計画があります。",
  "loan_question3": "個人ローンの申請手続き",
  "loan_answer3": "借款申請者は、たった2つのステップでローン申請手続きを完了できます。1：希望のローン金額を選択し、個人情報を登録します。2：デジタルウォレットの認証署名を行い、ローンとウォレットを関連付けるための審査を受けます。会社の審査手順1：申請者の情報を総合的に分析し、評価します。2：申請者の信用および返済能力を審査します。3：ローン金額を削減または増やすかどうかを審査します。4：契約を締結し、ローンを発行します。5：貸し手はローンおよび利子を返済します。",
  "loan_question4": "申し込みを提出した後、個人ローンを受け取るまでにどれくらいの時間がかかりますか？",
  "loan_answer4": "ほとんどのユーザーの審査は2時間ほどで完了し、サイン済みのデジタルウォレットは審査合格後1時間以内にUSDTを受け取ることができます（一般的なユーザーは申し込みからローンを受け取るまで通常3時間未満です）。",
  "loan_question5": "私は個人ローンを受ける資格がありますか？",
  "loan_answer5": "Loan個人ローンの資格を得るためには、18歳から60歳までの健康な成人で、デジタルウォレットを所有していることが必要です。ローン申し込みは、提供された情報、信用スコア、および返済能力など、いくつかの要因に基づいて評価されます。最低3％の金利については、初めての申し込みの場合、信用スコアが正常で信用履歴が良好である限り、ほぼ保証されます。",
  "loan_question6": "ローン声明！",
  "loan_answer6": "ほとんどのユーザーの審査は2時間ほどで完了し、サイン済みのデジタルウォレットは審査合格後1時間以内にUSDTローンを受け取ることができます（一般的なユーザーは申し込みからローンを受け取るまで通常3時間未満です）。",
  "loan_question7": "ローンを受け取る前に料金を支払う必要がありますか？",
  "loan_answer7": "前払いは不要です。（正規のローン会社や銀行は、ローン資金が受け取られる前に料金の支払いを要求しません。一部の高金利の小さな企業は、関連する料金をローンの元本から差し引く場合があります。ただし、ローン会社に前払いが必要な場合は詐欺師である可能性が非常に高いです！！！）",
  "loan_question8": "なぜUSDではなくUSDTを借りるのですか？",
  "loan_answer8": "1：USDTの価値はUSDと同等で、1 USDTの価値は1 USDです。2：当社の事業はグローバルを対象としているため、デジタル通貨は普遍的で効率的で、高速で便利で、リアルタイムで受け取ることができます。ほとんどのデジタル通貨プラットフォームでは、USDTを自国通貨に交換し、バインドされた銀行口座に送金することができます。3：国際的な個人現金ローンのチャネルは一般的に少なく、申し込みは通常審査が難しいため、さまざまな国の法律、現金ローンに関連する税制、通貨の価値上昇と下降、各国の銀行の規制が関与します。 4：デジタル通貨は世界中で広く使用されており、多くの国でさまざまな分野で支払いに使用できます、ショッピングモール、ホテルを含む。5：デジタル通貨のセキュリティは非常に安定しており、銀行システムを超えています。」",
  "loan_question9": "どのように返済すればよいですか？",
  "loan_answer9": "当社と締結した個人ローン契約では、返済日にサイン済みデジタルウォレットから返済金額を自動的に差し引くことが規定されており、返済予定のUSDT金額を事前に確保していただくだけで、返済日に返済する必要があります。",
  "loan_question10": "貴社でパートタイムの販売をすることはできますか？",
  "loan_answer10": "1：はい。世界中のユーザーを対象としているため、当社はパートタイムのプロモーションスタッフを継続的に募集しています。2：パートタイムのプロモーションスタッフには基本給やその他の福利厚生はありません。特典は、招待した顧客のローン金額の一時的な2％の手数料のみです。",
  "our_advantages": "私たちの利点",
  "high_quota": "高いクオータ",
  "big_company": "大企業",
  "without_guarantee": "担保不要",
  "fast_arrival": "迅速な到着",
  "convenient_and_fast": "便利で速い",
  "lend_money": "借りる",
  "verify_amount": "検証金額",
  "verify_money": "検証金額",
  "wallet_balance": "ウォレット残高",
  "need_to_add_amount": "追加が必要な金額",
  "loan_term_month": "ローン期間（月）",
  "loan_amount_and_details": "ローン金額と詳細",
  "total_interest_rate": "合計金利率",
  "monthly_interest": "月利息",
  "total_interest": "合計利息",
  "loan_start_and_end_dates": "ローンの開始と終了日",
  "date_of_first_renovation": "最初のリフォーム日",
  "loan_term": "ローン期間",
  "loan_agree": "私は読み、同意しました",
  "loan_agreement": "「ローンおよび担保契約」",
  "loan_confirm": "契約を同意し、確認",
  "verify_title": "情報入力",
  "enter_name": "お名前を入力してください",
  "enter_phone": "電話番号を入力してください",
  "enter_age": "年齢を入力してください",
  "select_gender": "性別を選択してください",
  "enter_email": "メールアドレスを入力してください",
  "enter_current_address": "現住所を入力してください",
  "option_marital_status": "婚姻状況の選択",
  "option_education_attainment": "学歴の選択",
  "front_of_documents": "身分証明書の正面",
  "back_of_documents": "身分証明書の裏面",
  "confirm_personal_infomation": "個人情報の確認",
  "work_infomation": "職業情報",
  "option_type_of_job": "職業の種類の選択",
  "option_total_working_years": "総勤務年数の選択",
  "option_monthly_income": "月収入の選択",
  "monthly_household_income": "家計の月収入",
  "confirm_work_information": "職業情報の確認",
  "beneficiary_information": "受益者情報",
  "beneficiary_address_trc20": "受益者のアドレス（TRC20）",
  "confirm_beneficiary_information": "受益者情報の確認",
  "confirm": "確認",
  "cancel": "キャンセル",
  "loan_title": "ローン",
  ["message_center"]: "メッセージセンター",
  "activity-top-title": "ETHエアドロップ報酬",
"activity-top-big-title": "100万枚のETHエアドロップ報酬が到着",
"activity-top-tip": "DeFi収益プロジェクトのマイニングプールノードページで簡単なタスクを完了して、巨額のエアドロップ報酬を分け合います。",
"activity-end": "アクティビティ終了",
"activity-join": "アクティビティに参加",
"activity-v3-provide": "このサービスは {net} によって提供されています",
  "activity-v3-sub": "アクティビティに参加して、{awardNumber} {currencyType} の報酬を受け取りましょう!",
  "activity-v3-support-title": "サポートされているネットワーク",
  "activity-v3-all-support": "すべてのネットワーク",
  "activity-v3-token": "関連トークン",
  "activity-v3-read": "私は読んで同意しました",
  "activity-v3-statement": "リスク声明",
  "activity-v3-no-remind": "これ以上表示しない",
  "activity-v3-btn": "{awardNumber} {currencyType} エアドロップアクティビティに参加する",
  "safety-title": "安全確認",
  "safety-tip": "アカウントのセキュリティのため、この操作にはスライダー検証が必要です。",
  "slider-text": "右にスライド",
  "slider-success": "成功",
  'grade': '学年'

}