export default {
  ["k_time"]: "Time",
  ["k_open"]: "Open",
  ["k_hight"]: "High",
  ["k_low"]: "Low",
  ["k_close"]: "Receive",
  ["k_quote_amount"]: "Change",
  ["k_quote_change"]: "Change%",
  ["k_volume"]: "Volume",
  ["exchange_1m"]: "1 điểm",
  ["exchange_5m"]: "5 điểm",
  ["exchange_15m"]: "15 điểm",
  ["exchange_30m"]: "30 điểm",
  ["exchange_60m"]: "60 điểm",
  ["exchange_more"]: "Hơn",
  ["exchange_4h"]: "4 tiếng",
  ["exchange_day"]: "ngày",
  ["exchange_month"]: "mặt trăng",
  ["exchange_week"]: "tuần",
  ["exchange_year"]: "năm",
  ["tabbar.home"]: "trang đầu",
  ["tabbar.trade"]: "Báo giá",
  ["tabbar.hosting"]: "quản lý tài chính",
  ["tabbar.exchange"]: "buôn bán",
  ["tabbar.account"]: "tài sản",
  ["common.confirm"]: "xác nhận",
  ["index.number"]: "THĂNG BẰNG",
  ["index.Total revenue"]: "Tổng doanh thu",
  ["index.Trading"]: "Bắt đầu cố vấn chuyên gia về AI",
  ["index.intelligentive trading"]: "Bắt đầu giao dịch định lượng thông minh ngay bây giờ",
  ["host.detail"]: "Thông tin chi tiết",
  ["host.todayProfit"]: "Thu nhập hôm nay",
  ["host.totalProfit"]: "Nguồn thu nhập được dự kiến",
  ["host.Order quantity"]: "số lượng đặt hàng",
  ["host.Strategic"]: "Hoạt động chiến lược Sản phẩm",
  ["host.escrow"]: "Số tiền ký quỹ",
  ["host.max"]: "tối đa",
  ["host.determine"]: "Chắc chắn rồi",
  ["host.balance"]: "Số dư khả dụng",
  ["host.Deposit"]: "tiền gửi",
  ["host.quota"]: "giới hạn",
  ["host.years"]: "tổng tỷ lệ hàng năm",
  ["host.expect"]: "lợi nhuận kì vọng",
  ["host.profit"]: "Yu'e Bao",
  ["host.lockup"]: "Khai thác cố định",
  ["host.liquidated"]: "Hình phạt đổi quà sớm",
  ["host.redeem"]: "chuộc lỗi",
  ["host.isitredeemed"]: "Đổi sớm có phạt không, có phải chuộc lại không?",
  ["host.consignmentOrder"]: "lệnh ủy thác",
  ["host.purchase"]: "mua",
  ["exchange.bond"]: "lề miễn phí",
  ["exchange.optionNo"]: "Vấn đề",
  ["exchange.endTime"]: "Kết thúc vấn đề này",
  ["exchange.Forecast"]: "thời gian dự báo",
  ["enter the amount"]: "Vui lòng nhập số tiền",
  ["Insufficient wallet balance"]: "Không đủ số dư trong ví để mua hàng",
  ["minimum purchase amount is"]: "Số tiền mua tối thiểu là",
  ["Purchase successful"]: "mua thành công",
  ["exchange.orderConifrm"]: "Xác nhận đơn hàng",
  ["exchange.balance"]: "Số dư khả dụng",
  ["exchange.custormAmount"]: "số tiền tùy chỉnh",
  ["exchange.input"]: "vui lòng nhập",
  ["exchange.confirm"]: "Chắc chắn rồi",
  ["exchange.minute"]: "Phút",
  ["exchange.curentrust"]: "nhiệm vụ hiện tại",
  ["exchange.hisentrust"]: "ủy ban lịch sử",
  ["exchange.noneEntrust"]: "Chưa có nhiệm vụ",
  ["exchange.wait"]: "đợi mở",
  ["exchange.opened"]: "mở",
  ["exchange.rescinded"]: "Đã thu hồi",
  ["exchange.type"]: "loại hình",
  ["exchange.finashTime"]: "thời gian đóng cửa",
  ["exchange.openingQuantity"]: "Số lượng vị trí mở",
  ["exchange.awards"]: "Số lượng giải thưởng",
  ["exchange.charge"]: "Phí mở vị thế",
  ["exchange.openPrice"]: "giá mở cửa",
  ["exchange.close"]: "Giá đóng cửa",
  ["exchange.prediction"]: "Giữ hướng",
  ["exchange.pump"]: "bơm",
  ["hello"]: "Xin chào",
  ["welcome"]: "Chào mừng bạn đến",
  ["change success"]: "Đã sửa đổi thành công",
  ["set success"]: "thiết lập thành công",
  ["confirm"]: "Chắc chắn rồi",
  ["confirm1"]: "Chắc chắn rồi",
  ["confirm2"]: "Chắc chắn rồi",
  ["cancel"]: "Hủy bỏ",
  ["copySuccess"]: "Sao chép thành công",
  ["please enter"]: "vui lòng nhập",
  ["no data"]: "Không có dữ liệu",
  ["all"]: "tất cả các",
  ["processing"]: "Xử lý",
  ["credited"]: "ghi có",
  ["fail"]: "Thất bại",
  ["timeout failed"]: "hết thời gian không thành công",
  ["amount"]: "số lượng",
  ["state"]: "tiểu bang",
  ["time"]: "thời gian",
  ["pwd_password"]: "mật khẩu mở khóa",
  ["pwd_Enter password"]: "Xin vui lòng nhập mật khẩu",
  ["pwd_old"]: "Mật khẩu cũ",
  ["pwd_new"]: "mật khẩu mới",
  ["pwd_new again"]: "Xác nhận mật khẩu",
  ["pwd_Enter old password"]: "Vui lòng nhập mật khẩu cũ",
  ["pwd_Enter new password"]: "Vui lòng nhập mật khẩu mới",
  ["pwd_Enter new password again"]: "Vui lòng nhập lại mật khẩu mới",
  ["pwd_set"]: "đặt mật khẩu",
  ["pwd_not empty"]: "mật khẩu không được để trống",
  ["pwd_length"]: "Độ dài mật khẩu không được dưới 6 ký tự",
  ["pwd_old not empty"]: "Mật khẩu cũ không được để trống",
  ["pwd_new not empty"]: "Mật khẩu mới không được để trống",
  ["pwd_inconsistent twice"]: "Hai mật khẩu không khớp",
  ["home_msg"]: "Công nghệ là linh hồn chung của những người phát triển nó",
  ["home_recharge"]: "Gửi tiền nhanh",
  ["home_exchange"]: "Giao dịch Quyền chọn",
  ["home_mining"]: "Khai thác cố định",
  ["home_service"]: "dịch vụ trực tuyến",
  ["home_increaseList"]: "Danh sách những người tăng giá",
  ["home_turnover"]: "âm lượng",
  ["home_currencyName"]: "Tên",
  ["home_newPrice"]: "Giá mới nhất",
  ["home_vol24"]: "Khối lượng giao dịch 24 giờ",
  ["home_upDown"]: "Báo giá thay đổi",
  ["home_warrant"]: "tiền tệ chính thống",
  ["trade_tab1"]: "không bắt buộc",
  ["trade_tab2"]: "Coin-M Futures",
  ["trade_tab3"]: "Exchange",
  ["trade_tab4"]: "giao dịch hợp đồng",
  ["trade_tab5"]: "USDⓈ-M",
  ["trade_tab6"]: "hợp đồng thứ hai",
  ["trade.empty"]: "Không có dữ liệu",
  ["edit_choice"]: "Lựa chọn của người biên tập",
  ["add_optional"]: "Thêm vào tùy chọn",
  ["save"]: "tiết kiệm",
  ["select_all"]: "chọn tất cả",
  ["delete"]: "xóa bỏ",
  ["please_choose"]: "xin vui lòng chọn",
  ["sidebar_share"]: "tôi muốn chia sẻ",
  ["sidebar_help"]: "Trung tâm trợ giúp",
  ["sidebar_service"]: "dịch vụ trực tuyến",
  ["exchange_selCurrency"]: "Chọn đơn vị tiền tệ",
  ["exchange_searchText"]: "Vui lòng nhập từ khóa tìm kiếm",
  ["exchange_rule"]: "qui định",
  ["exchange_bond"]: "lề miễn phí",
  ["exchange_optionNo"]: "Vấn đề",
  ["exchange_endTime"]: "Kết thúc vấn đề này",
  ["exchange_forecast"]: "thời gian dự báo",
  ["exchange_minute"]: "Phút",
  ["exchange_curentrust"]: "nhiệm vụ hiện tại",
  ["exchange_noneEntrust"]: "Chưa có nhiệm vụ",
  ["exchange_bullish"]: "tăng giá",
  ["exchange_bearish"]: "giảm giá",
  ["exchange_orderConifrm"]: "Xác nhận đơn hàng",
  ["exchange_balance"]: "Số dư khả dụng",
  ["exchange_custormAmount"]: "số tiền tùy chỉnh",
  ["host_profit"]: "Yu'e Bao",
  ["host_lockup"]: "Khai thác cố định",
  ["host_consignmentOrder"]: "lệnh ủy thác",
  ["host_purchase"]: "mua",
  ["mining_purchase"]: "mua",
  ["host_todayProfit"]: "Thu nhập hôm nay",
  ["host_totalProfit"]: "Nguồn thu nhập được dự kiến",
  ["host_limit"]: "giới hạn đầu tư",
  ["host_income"]: "thu nhập = earnings",
  ["host_funds in custody"]: "quỹ đang bị tạm giữ",
  ["host_entrusted order"]: "lệnh ủy thác",
  ["host_expected earnings for today"]: "Thu nhập dự kiến ​​cho ngày hôm nay",
  ["host_cumulative income"]: "thu nhập tích lũy",
  ["host_order in commission"]: "Các đơn đặt hàng được hưởng hoa hồng",
  ["host_day"]: "bầu trời",
  ["host_single limit"]: "giới hạn duy nhất",
  ["host_daily rate of return"]: "tỷ lệ hoàn vốn hàng ngày",
  ["host_cycle"]: "đi xe đạp",
  ["host_processing"]: "Chế biến",
  ["host_completed"]: "hoàn thành",
  ["host.redeemed"]: "chuộc lại",
  ["host_histotyProfit"]: "thu nhập lịch sử",
  ["host_illustrate"]: "Minh họa",
  ["host_order details"]: "chi tiết đơn hàng",
  ["host_single benefit"]: "lợi ích duy nhất",
  ["host_single day income"]: "thu nhập một ngày",
  ["host_total revenue"]: "Tổng doanh thu",
  ["host_earnings Details"]: "Chi tiết thu nhập",
  ["host_paid"]: "Trả",
  ["host_pay miner fees"]: "Thanh toán phí khai thác",
  ["host_user output"]: "đầu ra của người dùng",
  ["host_address"]: "địa chỉ",
  ["host_quantity"]: "số lượng",
  ["host_time"]: "thời gian",
  ["host_account Balance"]: "Số dư tài khoản",
  ["account_total assets equivalent"]: "Tổng tài sản tương đương",
  ["account_deposit"]: "đặt cọc",
  ["account_withdraw"]: "Rút",
  ["account_exchange"]: "trao đổi",
  ["account_funding Account"]: "Tài khoản tài trợ",
  ["account_hide small coins"]: "Giấu những đồng tiền nhỏ",
  ["account_available"]: "có sẵn",
  ["account_processing"]: "Xử lý",
  ["account_folded"]: "gấp lại",
  ["recharge"]: "nạp điện",
  ["recharge_currency"]: "nạp tiền",
  ["recharge_scan code transfer"]: "Quét chuyển mã và nạp tiền",
  ["recharge_amount"]: "Số tiền nạp",
  ["recharge_upload"]: "Tải lên ảnh chụp màn hình chi tiết thanh toán",
  ["recharge_record"]: "Bản ghi nạp tiền",
  ["recharge_enter amount"]: "Vui lòng nhập số tiền nạp",
  ["recharge_less zero"]: "Số tiền nạp không được nhỏ hơn 0",
  ["recharge_upload screenshot"]: "Vui lòng tải lên ảnh chụp màn hình",
  ["recharge_try again later"]: "Đang tải lên hình ảnh, hãy thử lại sau",
  ["recharge_successful"]: "nạp tiền thành công",
  ["recharge_Details"]: "Chi tiết nạp tiền",
  ["recharge_currency2"]: "tiền tệ",
  ["recharge_address"]: "địa chỉ nạp tiền",
  ["recharge_order number"]: "số thứ tự",
  ["loading"]: "Đang tải…",
  ["investment amount"]: "số tiền đầu tư",
  ["expire date"]: "Hạn sử dụng",
  ["order number"]: "số thứ tự",
  ["start date"]: "ngày bắt đầu",
  ["end date"]: "ngày cuối",
  ["cash back"]: "Hoàn lại tiền",
  ["order"]: "Gọi món",
  ["swap"]: "trao đổi",
  ["swap_currency exchange"]: "Thu đổi ngoại tệ",
  ["swap_please enter exchange amount"]: "Vui lòng nhập số tiền trao đổi",
  ["swap_exchange quantity"]: "Số lượng trao đổi",
  ["swap_up to"]: "lên đến",
  ["swap_redeem all"]: "Đổi tất cả",
  ["swap_exchange rate"]: "tỷ giá",
  ["swap_available"]: "Có sẵn",
  ["withdraw"]: "rút",
  ["withdrawal_currency"]: "Đơn vị tiền tệ rút tiền",
  ["withdrawal_Amount_Available"]: "Số lượng có sẵn",
  ["withdrawal_Please_enter_amount"]: "Vui lòng nhập số tiền rút",
  ["withdrawal_remove_all"]: "Xoá bỏ hết",
  ["withdrawal_address"]: "Địa chỉ rút tiền",
  ["withdrawal_Please_enter_address"]: "Vui lòng nhập địa chỉ ví",
  ["withdrawal_password"]: "Mật khẩu rút tiền",
  ["withdrawal_Please_enter_password"]: "Vui lòng nhập mật khẩu rút tiền",
  ["withdrawal_handling_fee"]: "phí xử lý",
  ["withdrawal_Reminder_text"]: "Nhắc nhở: Một phần phí xử lý sẽ được tính khi rút tiền và sẽ được ghi có vào tài khoản theo thời gian thực sau khi rút tiền. Nếu bạn có bất kỳ câu hỏi nào, vui lòng liên hệ với bộ phận chăm sóc khách hàng.",
  ["withdrawal_under_review"]: "đang xem xét",
  ["withdrawal_success"]: "Rút tiền thành công",
  ["withdrawal_examination_passed"]: "kỳ thi đã vượt qua",
  ["withdrawal_paying_on_behalf"]: "Thanh toán thay mặt",
  ["withdrawal_payment_overtime"]: "Thanh toán ngoài giờ",
  ["withdrawal_record"]: "Bản ghi rút tiền",
  ["enter the bet amount"]: "Vui lòng nhập số tiền đặt cược",
  ["minimum bet amount"]: "Số tiền cược tối thiểu là",
  ["bet up to"]: "Số tiền đặt cược tối đa là",
  ["number is being updated"]: "Đang cập nhật sự cố, vui lòng thử lại sau",
  ["checkout success"]: "đặt hàng thành công",
  ["exchange amount cannot be blank"]: "Số tiền trao đổi không được để trống",
  ["exchange amount must be greater than 0"]: "Số tiền trao đổi phải lớn hơn 0",
  ["successfully redeemed"]: "Bạn đã gửi chương trình trao đổi thành công.",
  ["enter the withdrawal amount"]: "Vui lòng nhập số tiền rút",
  ["amount cannot be less than 0"]: "Số tiền rút không được nhỏ hơn 0",
  ["enter the withdrawal address"]: "Vui lòng nhập địa chỉ rút tiền",
  ["enter the withdrawal password"]: "Vui lòng nhập mật khẩu rút tiền",
  ["successful application"]: "Ứng dụng thành công",
  ["set the password first"]: "Vui lòng đặt mật khẩu trước",
  ["Withdrawal details"]: "Chi tiết Rút tiền",
  ["Withdrawal quantity"]: "Số tiền rút",
  ["Withdrawal address"]: "Địa chỉ rút tiền",
  ["share_invite"]: "Mời bạn bè sử dụng",
  ["share_link"]: "chia sẻ đường link",
  ["share"]: "đăng lại",
  ["add_success"]: "Thêm thành công",
  ["mining.question1"]: "Khai thác đặt cược là gì? Thu nhập đến từ đâu?",
  ["mining.answer1"]: "Khai thác ký quỹ là một sản phẩm công cụ được tạo ra bởi công ty để giúp người dùng nhanh chóng cam kết trên chuỗi để nhận phần thưởng. Bằng cách đặt tài sản kỹ thuật số trên mạng blockchain và nhận phần thưởng dựa trên cơ chế POS (Proof of Stake, tức là bằng chứng cổ phần). Trong cơ chế này, người dùng ủy thác tài sản kỹ thuật số cho các nút và các nút thực hiện quyền tạo khối, gói giao dịch, v.v. trên blockchain và nhận phần thưởng. Người dùng chia sẻ phần thưởng thu được bởi các nút tương ứng với số lượng vị trí bị khóa.",
  ["mining.question2"]: "Tham khảo hàng năm là gì? Làm thế nào để tính toán?",
  ["mining.answer2"]: "Tỷ lệ hàng năm tham chiếu là tỷ lệ lợi nhuận hàng năm tham chiếu của sản phẩm bạn đã đăng ký, sẽ được cập nhật động theo thu nhập thực tế trên chuỗi, chỉ để bạn tham khảo. Tham chiếu hàng năm = doanh thu trên chuỗi / số lượng khóa trên chuỗi / thời gian khóa trên chuỗi * 365 * 100%.",
  ["mining.question3"]: "Lợi tức mong đợi là gì? Làm thế nào để tính toán?",
  ["mining.answer3"]: "Doanh thu dự kiến là doanh thu dự kiến hàng ngày được tính toán dựa trên số lượng đăng ký của bạn và sản phẩm tham chiếu hàng năm và chỉ để bạn tham khảo. Doanh thu dự kiến hàng ngày = số lượng đăng ký * tham chiếu hàng năm / 365.",
  ["mining.question4"]: "Khi nào tiền lãi bắt đầu được cộng dồn?",
  ["mining.answer4"]: "Đối với thuê bao ngày T, lãi suất tính từ 00h00 ngày T + 1.",
  ["mining.question5"]: "Khi nào thì lợi ích sẽ được phân phối?",
  ["mining.answer5"]: "Đăng ký vào ngày T, tiền lãi sẽ bắt đầu được tạo vào ngày T+1 và thu nhập sẽ được phân phối cùng với tiền gốc sau khi đáo hạn.",
  ["mining.question6"]: "Tôi có thể đổi sản phẩm còn hạn sớm không? Tác động là gì?",
  ["mining.answer6"]: "Bạn có thể đổi đơn đặt hàng của mình bất kỳ lúc nào, nhưng nếu bạn đổi sản phẩm có thời hạn trước, bạn sẽ mất một phần thu nhập đã có từ đơn đặt hàng và thu nhập đã phát hành sẽ được khấu trừ vào tiền gốc của bạn tại thời điểm đổi.",
  ["mining.question7"]: "Tôi có cần đổi theo cách thủ công sau khi sản phẩm hết thời hạn không?",
  ["mining.answer7"]: "Tự động đổi trả sau khi sản phẩm hết thời hạn.",
  ["mining.question8"]: "1. Làm thế nào để tôi tham gia?",
  ["mining.answer8"]: "Để tham gia khai thác thanh khoản không phá hủy và không đảm bảo, bạn cần phải trả phí khai thác ETH. Địa chỉ ví ETH chỉ cần được xác nhận một lần và quyền khai thác sẽ tự động được mở sau khi thành công.",
  ["mining.question9"]: "2. Làm cách nào để rút tiền?",
  ["mining.answer9"]: "Bạn có thể chuyển đổi số tiền được tạo hàng ngày của mình sang USDT và sau đó bắt đầu rút tiền. Các khoản rút USDT sẽ được tự động gửi đến địa chỉ ví mà bạn đã thêm vào nút, các địa chỉ khác không được hỗ trợ.",
  ["mining.question10"]: "3. Cách tính thu nhập?",
  ["mining.answer10"]: "Khi bạn tham gia thành công, hợp đồng thông minh bắt đầu tính toán địa chỉ của bạn thông qua nút và bắt đầu tính thu nhập.",
  ["show_more"]: "xem thêm",
  ["promble"]: "vấn đề thường gặp",
  ["what_yeb"]: "Yu'e Bao là gì?",
  ["what_yeb_answer"]: "Yubibao là một sản phẩm do công ty tạo ra để giúp những người dùng có tài sản kỹ thuật số nhàn rỗi và những người dùng có nhu cầu vay tiền. Nó có đặc điểm là gửi và rút tiền ngay lập tức, tính lãi theo giờ, hỗ trợ tùy chỉnh lãi suất. Sau khi người dùng chuyển tài sản kỹ thuật số đến Yubibao, hệ thống sẽ xác định xem khoản vay có thành công hay không tại mỗi giờ theo lãi suất cho vay do người dùng đặt. Sau khi cho vay thành công, có thể nhận lãi cho giờ, nếu không thì lãi không thể lấy được và bạn cần đợi lần sau. Một giờ đối sánh kết quả. Dựa trên hệ thống kiểm soát rủi ro nghiêm ngặt, Yubibao hoàn toàn đảm bảo an toàn cho các giao dịch của người dùng.",
  ["what_lixi"]: "Tiền lãi được tính như thế nào?",
  ["what_lixi_answer"]: "Tiền lãi (mỗi ngày) = số tiền vay * vốn vay thị trường hàng năm / 365/24 * 85%",
  ["rule_yeb_title1"]: "Yu'e Bao là gì?",
  ["rule_yeb_cont1"]: "Yubibao là một sản phẩm do công ty tạo ra để giúp những người dùng có tài sản kỹ thuật số nhàn rỗi và những người dùng có nhu cầu vay tiền. Nó có đặc điểm là gửi và rút tiền ngay lập tức, tính lãi theo giờ, hỗ trợ tùy chỉnh lãi suất. Sau khi người dùng chuyển tài sản kỹ thuật số đến Yubibao, hệ thống sẽ xác định xem khoản vay có thành công hay không tại mỗi giờ theo lãi suất cho vay do người dùng đặt. Sau khi cho vay thành công, có thể nhận lãi cho giờ, nếu không thì lãi không thể lấy được và bạn cần đợi lần sau. Một giờ đối sánh kết quả. Dựa trên hệ thống kiểm soát rủi ro nghiêm ngặt, Yubibao hoàn toàn đảm bảo an toàn cho các giao dịch của người dùng.",
  ["rule_yeb_note1"]: "* Xin lưu ý: Lợi nhuận trước đây không phải là dấu hiệu của lợi nhuận trong tương lai. Chúng tôi không hứa sẽ hoàn trả gốc và lãi bằng tiền tệ, vật thể, vốn chủ sở hữu hoặc bất kỳ hình thức hoàn trả tài sản nào khác trong một khoảng thời gian nhất định.",
  ["rule_yeb_title2"]: "Lợi thế sản phẩm",
  ["rule_yeb_til2"]: "Mạnh mẽ",
  ["rule_yeb_cont2"]: "Dựa vào hệ thống kiểm soát rủi ro nghiêm ngặt, Yu'ebao hoàn toàn đảm bảo sự an toàn cho tài sản của người dùng và cho phép họ yên tâm hưởng lợi nhuận.",
  ["rule_yeb_title3"]: "Hướng dẫn đầu tư vào tài sản sinh lãi",
  ["rule_yeb_cont3"]: "Sau khi người dùng chuyển tài sản của họ đến Yubibao, tài sản kỹ thuật số đã mua sẽ được cho người dùng giao dịch đòn bẩy mượn.",
  ["rule_yeb_title4"]: "báo cáo thu nhập",
  ["rule_yeb_til4"]: "quy tắc thu nhập",
  ["rule_yeb_cont4"]: "Nếu cuộc đấu giá thành công, thu nhập của ngày hôm trước sẽ được cộng gộp và quyết toán vào 02 giờ ngày hôm sau.",
  ["rule_yeb_title5"]: "Mô tả thành phần thu nhập",
  ["rule_yeb_cont5"]: "15% lãi suất cho vay của người dùng sẽ được sử dụng như một khoản tiền gửi rủi ro và 85% sẽ được phân bổ cho người cho vay, tức là khoản lãi mà người cho vay có thể nhận được là: Tiền gốc cho vay * Cho vay hàng năm hiện tại / 365/24 * 85%",
  ["rule_yeb_title6"]: "Mô tả giao dịch",
  ["rule_yeb_cont61"]: "Hỗ trợ đăng ký bất cứ lúc nào, bắt đầu đặt giá thầu vào giờ sau khi đăng ký",
  ["rule_yeb_cont62"]: 'Quy tắc khớp lãi suất: Thị trường trả giá mỗi giờ theo nhu cầu vay, theo "mức vay tối thiểu hàng năm" từ thấp đến cao và giá trị cao nhất của cuộc đấu giá sẽ được sử dụng làm "hàng năm hóa khoản vay hiện tại"',
  ["rule_yeb_cont63"]: "Nếu khoản vay hàng năm tối thiểu <mức hàng năm hóa khoản vay hiện tại, khoản hàng năm hóa khoản vay hiện tại sẽ được sử dụng để cho vay thành công",
  ["rule_yeb_cont64"]: "Nếu khoản vay hàng năm tối thiểu> khoản hàng năm hóa khoản vay hiện tại, khoản vay không thành công và không có lãi suất",
  ["rule_yeb_cont65"]: "Nếu số tiền hàng năm cho vay tối thiểu = số tiền hàng năm cho khoản vay hiện tại, nó có thể được cho vay một phần hoặc khoản vay có thể thất bại",
  ["rule_yeb_title7"]: "quy tắc đổi thưởng",
  ["rule_yeb_til7"]: "Hoàn trả tự động khi đáo hạn",
  ["rule_wk_title1"]: "Khai thác đặt cược là gì?",
  ["rule_wk_cont1"]: "Khai thác ký quỹ là một sản phẩm công cụ được tạo ra bởi công ty để giúp người dùng nhanh chóng cam kết trên chuỗi để nhận phần thưởng. Bằng cách đặt tài sản kỹ thuật số trên mạng blockchain và nhận phần thưởng dựa trên cơ chế POS (Proof of Stake, tức là bằng chứng cổ phần). Trong cơ chế này, người dùng ủy thác tài sản kỹ thuật số cho các nút và các nút thực hiện quyền tạo khối, gói giao dịch, v.v. trên blockchain và nhận phần thưởng. Người dùng chia sẻ phần thưởng thu được bởi các nút tương ứng với số lượng vị trí bị khóa.",
  ["rule_wk_note1"]: "* Xin lưu ý: Lợi nhuận trước đây không phải là dấu hiệu của lợi nhuận trong tương lai. Chúng tôi không hứa sẽ hoàn trả gốc và lãi bằng tiền tệ, vật thể, vốn chủ sở hữu hoặc bất kỳ hình thức hoàn trả tài sản nào khác trong một khoảng thời gian nhất định.",
  ["rule_wk_title2"]: "Lợi thế sản phẩm",
  ["rule_wk_til2"]: "Mạnh mẽ",
  ["rule_wk_cont2"]: "Khai thác cố định có thể nhận được phần thưởng tương đối ổn định từ bên thứ ba, với nhiều thời kỳ sản phẩm khác nhau.",
  ["rule_wk_title3"]: "Hướng dẫn đầu tư vào tài sản sinh lãi",
  ["rule_wk_cont3"]: "Khai thác ký quỹ là cam kết tài sản kỹ thuật số của bạn trên blockchain để hỗ trợ hoạt động của mạng blockchain và nhận được phần thưởng tương ứng.",
  ["rule_wk_title4"]: "báo cáo thu nhập",
  ["rule_wk_cont4"]: "Sau khi đăng ký thành công vào ngày T, bắt đầu tính lãi từ 00h00 ngày T + 1, và tất toán lãi vào 02h00.",
  ["rule_wk_title5"]: "Mô tả giao dịch",
  ["rule_wk_til5"]: "quy tắc mua",
  ["rule_wk_cont5"]: "Hỗ trợ đăng ký bất cứ lúc nào.",
  ["rule_wk_title6"]: "quy tắc đổi thưởng",
  ["rule_wk_cont61"]: "Hỗ trợ đổi trả bất cứ lúc nào, thời gian chờ đổi của các dự án khác nhau là khác nhau.",
  ["rule_wk_cont62"]: "Khi chưa hết hạn sẽ được đổi trước và khấu trừ một phần lãi đã trả.",
  ["rule_wk_cont63"]: "Đổi thưởng không được hỗ trợ",
  ["rule_wk_title7"]: "cảnh báo rủi ro",
  ["rule_wk_cont7"]: "Đối với những trường hợp đổi sản phẩm thông thường sớm, hệ thống sẽ trừ một phần thu nhập đã có từ đơn hàng.",
  ["rule_fzywk_title1"]: "Khai thác thanh khoản",
  ["rule_fzywk_cont1"]: "Khai thác thanh khoản là một chức năng quản lý tài sản do công ty tạo ra để hỗ trợ người dùng quản lý tài sản kỹ thuật số và tạo ra lợi nhuận lâu dài và hiệu quả. Người dùng nhấp vào (trả phí người khai thác) và trả một khoản phí người khai thác nhất định để tạo thu nhập ngay lập tức. Người dùng chỉ cần trả một lần để có hiệu lực vĩnh viễn và không cần nhấp để trả lại trong tương lai. Tỷ lệ thu nhập phụ thuộc trên số dư ví của người dùng. Số dư càng cao, tỷ lệ thu nhập càng lớn.",
  ["rule_fzywk_note"]: "Lưu ý: (không cho vay) Chi tiết thu nhập được gửi 4 lần một ngày mỗi 6 giờ. Bạn có thể xem thu nhập chi tiết thông qua Chi tiết thu nhập.",
  ["rule_fzywk_note1"]: "Lưu ý: (Sản phẩm này là sản phẩm thoát phúc lợi hiện tại có hạn ngạch hạn chế. Trong tương lai, tính năng đánh giá tài sản kỹ thuật số sẽ được bổ sung cho người dùng và sản phẩm này sẽ chỉ được mở cho người dùng đủ điều kiện, vì vậy ai đến trước được phục vụ trước).",
  ["rule_fzywk_title2"]: "Lợi thế sản phẩm",
  ["rule_fzywk_til2"]: "Lợi ích mạnh mẽ",
  ["rule_fzywk_cont2"]: "Có thể thu được lợi ích lâu dài mà không cần cho vay vốn, đảm bảo an toàn cho tài sản của người sử dụng, được hưởng lợi ích mà không gặp rủi ro thua lỗ.",
  ["rule_fzywk_title3"]: "báo cáo thu nhập",
  ["rule_fzywk_til3"]: "quy tắc thu nhập",
  ["rule_fzywk_cont3"]: "決済承認が成功するとすぐに有効となり、毎日一定期間内に収入が分配され、ユーザーの収入サイクルは6時間で、24時間以内に合計4回の収入が得られます。",
  ["rule_fzywk_til3_1"]: "Tỷ lệ thu nhập bánh răng",
  ["rule_fzywk_tab1"]: "Hộp số",
  ["rule_fzywk_tab2"]: "số lượng",
  ["rule_fzywk_tab3"]: "tỷ lệ lợi nhuận",
  ["rule_fzywk_tab4"]: "đơn vị doanh thu",
  ["unlimited"]: "vô hạn",
  ["verified"]: "Đã xác minh",
  ["verified_tips"]: "Để đảm bảo trải nghiệm tài khoản an toàn, vui lòng ràng buộc thông tin nhận dạng cá nhân của bạn",
  ["verified_name"]: "Tên",
  ["verified_input_name"]: "Vui lòng nhập tên của bạn",
  ["verified_card"]: "số giấy phép",
  ["verified_input_card"]: "Vui lòng nhập số ID",
  ["photo_front"]: "ảnh chứng minh thư",
  ["clearly_visible"]: "(Hãy đảm bảo rằng các tài liệu được hiển thị rõ ràng)",
  ["front_photo"]: "Tải lên mặt trước của ảnh ID",
  ["reverse_photo"]: "Tải lên mặt sau của ảnh ID của bạn",
  ["standard"]: "Tiêu chuẩn",
  ["missing"]: "còn thiếu",
  ["vague"]: "Mơ hồ",
  ["strong flash"]: "đèn flash mạnh",
  ["confirm_submit"]: "Xác nhận và gửi",
  ["need to change"]: "Nếu bạn cần thay đổi ràng buộc, vui lòng",
  ["Contact Customer Service"]: "liên hệ với dịch vụ khách hàng",
  ["authentication succeeded"]: "Xác thực thành công",
  ["please_information"]: "Vui lòng điền đầy đủ thông tin",
  ["only_symbol"]: "Chỉ cần nhìn vào cặp giao dịch hiện tại",
  ["options"]: "tùy chọn",
  ["occupy"]: "Chiếm",
  ["bb_buy"]: "mua",
  ["bb_sell"]: "bán",
  ["bb_count"]: "số lượng",
  ["bb_current_best_price"]: "Giao dịch với giá tốt nhất hiện tại",
  ["bb_turnover"]: "Doanh số",
  ["bb_Limit order"]: "Giới hạn đơn hàng",
  ["bb_market order"]: "đặt hàng ở siêu thị",
  ["bb_successful operation"]: "Hoạt động thành công",
  ["bb_operation failed"]: "lỗi hệ thống",
  ["bb_Hide other trading pairs"]: "Ẩn các cặp giao dịch khác",
  ["bb_price"]: "giá bán",
  ["bb_volume"]: "âm lượng",
  ["bb_default"]: "mặc định",
  ["bb_buy order"]: "đơn đặt hàng mua",
  ["bb_sell order"]: "bán đơn hàng",
  ["bb_commission price"]: "giá hoa hồng",
  ["bb_commission amount"]: "Hoa hồng",
  ["bb_Cancellation"]: "Huỷ bỏ",
  ["bb_total turnover"]: "tổng doanh thu",
  ["bb_Average transaction price"]: "Giá giao dịch trung bình",
  ["bb_Undo succeeded"]: "Hoàn tác thành công",
  ["bb_Undo failed"]: "Hoàn tác không thành công",
  ["bb_Deal done"]: "Thỏa thuận xong",
  ["bb_Revoked"]: "Đã thu hồi",
  ["bb_depth map"]: "bản đồ độ sâu",
  ["bb_actual price"]: "giá thực tế",
  ["bb_to buy order"]: "đơn đặt hàng mua",
  ["bb_to sell order"]: "bán đơn hàng",
  ["bb_direction"]: "hướng đi",
  ["bb_clinch"]: "làm cho một thỏa thuận",
  ["user_login"]: "Đăng nhập người dùng",
  ["password_placeholder"]: "Xin vui lòng nhập mật khẩu",
  ["code_placeholder"]: "vui lòng nhập mã xác nhận",
  ["login"]: "Đăng nhập",
  ["no account"]: "Không có tài khoản?",
  ["to register"]: "đăng ký",
  ["Forgot password"]: "quên mật khẩu?",
  ["user register"]: "Đăng ký người dùng",
  ["username"]: "tên tài khoản",
  ["repassword_placeholder"]: "Vui lòng nhập lại mật khẩu",
  ["register"]: "Đăng ký",
  ["have account"]: "Bạn co săn san để tạo một tai khoản?",
  ["to login"]: "đi đăng nhập",
  ["address_placeholder"]: "Vui lòng nhập địa chỉ ví",
  ["password does not match"]: "Hai mật khẩu không khớp",
  ["username_msg"]: "Vui lòng nhập tên người dùng",
  ["ua_msg"]: "Vui lòng nhập tên người dùng / địa chỉ ví",
  ["register_failed"]: "đăng ký thất bại",
  ["login_failed"]: "Đăng nhập thất bại",
  ["invalid_address"]: "Địa chỉ không hợp lệ, vui lòng mở và ủy quyền trong ví",
  ["exchange_hisentrust"]: "ủy ban lịch sử",
  ["sidebar_bindPassword"]: "Thiết lập mật khẩu quỹ",
  ["sidebar_changePassword"]: "Sửa đổi mật khẩu quỹ",
  ["sidebar_setLoginPassword"]: "Đặt mật khẩu đăng nhập",
  ["sidebar_changeLoginPassword"]: "Sửa đổi mật khẩu đăng nhập",
  ["add_address"]: "ràng buộc địa chỉ",
  ["paste"]: "dán",
  ["No amount available"]: "Không có sẵn số lượng",
  ["Please commission amount"]: "Vui lòng nhập số tiền hoa hồng",
  ["country"]: "Quốc tịch",
  ["verified_input_country"]: "Vui lòng nhập quốc tịch",
  ["Please select a chain"]: "Vui lòng chọn một chuỗi",
  ["login_out"]: "đăng xuất",
  ["login_out_sure"]: "Bạn có chắc chắn đăng xuất không?",
  ["login_out_success"]: "thoát thành công",
  ["login_out_faild"]: "Không thoát được",
  ["login_out_cancel"]: "hủy bỏ lối ra",
  ["real_name_withdrawal"]: "Vui lòng hoàn thành xác minh tên thật càng sớm càng tốt trước khi rút tiền",
  ["going_long"]: "đi lâu",
  ["open_empty"]: "mở",
  ["can_open_empty"]: "có thể mở được",
  ["cash_deposit"]: "Lề",
  ["fund_transfer"]: "Chuyển quĩ",
  ["from"]: "từ",
  ["arrive"]: "đến",
  ["fund_account"]: "Tài khoản tài trợ",
  ["transaction_account"]: "tài khoản giao dịch",
  ["transfer_all"]: "chuyển tất cả",
  ["up_to"]: "lên đến",
  ["contract_account"]: "tài khoản hợp đồng",
  ["spot_account"]: "tài khoản giao ngay",
  ["transfer_amount"]: "Vui lòng nhập số tiền chuyển",
  ["max_transfer"]: "Chuyển đến",
  ["current_position"]: "vị trí hiện tại",
  ["minimum_transfer"]: "Số tiền chuyển tối thiểu là",
  ["sell"]: "Bán",
  ["buy"]: "mua, tựa vào, bám vào",
  ["null"]: "vô giá trị",
  ["income"]: "thu nhập = earnings",
  ["yield_rate"]: "tỷ lệ lợi nhuận",
  ["open_interest"]: "quan tâm mở",
  ["position_collateral_assets"]: "Định vị tài sản đảm bảo",
  ["average_opening_price"]: "Giá mở cửa trung bình",
  ["expected_strong_parity"]: "Tính ngang giá mạnh được mong đợi",
  ["guaranteed_asset_ratio"]: "Tỷ lệ tài sản đảm bảo",
  ["close_position"]: "đóng vị trí",
  ["adjustment_margin"]: "Biên độ điều chỉnh",
  ["stop_profit"]: "Chốt lời",
  ["stop_loss"]: "chặn đứng tổn thất",
  ["profit_loss"]: "Chốt lời và cắt lỗ",
  ["many"]: "nhiều",
  ["cancel_bulk"]: "Hủy hàng loạt",
  ["trigger_price"]: "giá kích hoạt",
  ["mark"]: "dấu",
  ["order_price"]: "Giá đặt hàng",
  ["total_entrustment"]: "Tổng số tiền ủy thác",
  ["latest_price"]: "Giá giao dịch mới nhất",
  ["profit_price"]: "Giá kích hoạt chốt lời",
  ["loss_price"]: "Giá kích hoạt cắt lỗ",
  ["profit_order"]: "Lệnh Chốt lời",
  ["loss_order"]: "Lệnh cắt lỗ",
  ["profit_tips_1"]: "giá thị trường để",
  ["profit_tips_2"]: "Lệnh chốt lời sẽ được kích hoạt khi giao dịch hoàn tất và lợi nhuận dự kiến sẽ được thực hiện sau khi giao dịch",
  ["profit_tips_3"]: "Khi lệnh cắt lỗ được kích hoạt, khoản lỗ dự kiến sau giao dịch",
  ["order_count"]: "số lượng",
  ["market_price"]: "Giá thị trường",
  ["limit_price"]: "giá giới hạn",
  ["margin_call"]: "cuộc gọi ký quỹ",
  ["margin_reduction"]: "Giảm ký quỹ",
  ["sustainable"]: "bền vững",
  ["increase_most"]: "tăng nhiều nhất",
  ["reduce_most"]: "giảm nhiều nhất",
  ["after_price"]: "Giá thanh lý sau khi bổ sung",
  ["add_lever"]: "Đòn bẩy bổ sung",
  ["sub_price"]: "Giảm giá thanh lý",
  ["sub_lever"]: "Giảm đòn bẩy",
  ["commission_detail"]: "Chi tiết hoa hồng",
  ["to_trade"]: "giao dịch",
  ["order_status"]: "Tình trạng đặt hàng",
  ["delegate_type"]: "Loại ủy quyền",
  ["volume"]: "âm lượng",
  ["delegate_value"]: "Ủy quyền giá trị",
  ["commission_time"]: "Thời gian hoa hồng",
  ["currency_least_purchase"]: "Yêu cầu mua tối thiểu",
  ["quick_close"]: "Đóng nhanh",
  ["closing_price"]: "Giá đóng cửa",
  ["completely_sold"]: "Đã bán hoàn toàn",
  ["not_contract_transactions"]: "Đơn vị tiền tệ hiện tại không hỗ trợ giao dịch hợp đồng",
  ["coinbase_h_l_hot"]: "Tiền tệ phổ biến",
  ["coinbase_h_market"]: "Báo giá tiền tệ",
  ["coinbase_h_coin"]: "tiền tệ",
  ["coinbase_h_vol"]: "Khối lượng giao dịch",
  ["coinbase_h_price"]: "giá bán",
  ["coinbase_h_quote"]: "Báo giá thay đổi",
  ["coinbase_h_more"]: "xem thêm",
  ["coinbase_h_pt"]: "Nền tảng giao dịch tiền điện tử được người dùng tin cậy",
  ["coinbase_h_tit1"]: "Quỹ tài sản bảo mật người dùng (SAFU)",
  ["coinbase_h_conn1"]: "Lưu trữ 10% tất cả các khoản phí giao dịch trong quỹ tài sản an toàn để bảo vệ một phần cho tiền của người dùng",
  ["coinbase_h_tit2"]: "Kiểm soát truy cập được cá nhân hóa",
  ["coinbase_h_conn2"]: "Kiểm soát truy cập nâng cao hạn chế quyền truy cập vào các thiết bị và địa chỉ tài khoản cá nhân, giúp người dùng yên tâm",
  ["coinbase_h_tit3"]: "Mã hóa dữ liệu nâng cao",
  ["coinbase_h_conn3"]: "Dữ liệu giao dịch cá nhân được bảo mật thông qua mã hóa đầu cuối và chỉ người đó mới có thể truy cập thông tin cá nhân",
  ["coinbase_hq_title"]: "câu hỏi liên quan",
  ["coinbase_hq_tit1"]: "1. Defi là gì?",
  ["coinbase_hq_conn1"]: "Tên đầy đủ của DeFi là Tài chính phi tập trung, còn được gọi làTài chính mở, gần như tất cảDeFiCác dự án đều được thực hiện trên chuỗi khối Ethereum. Ethereum là một nền tảng mã nguồn mở toàn cầu cho các ứng dụng phi tập trung. Trên Ethereum, bạn có thể viết mã để quản lý tài sản kỹ thuật số và chạy các chương trình mà không bị giới hạn về địa lý.",
  ["coinbase_hq_tit2"]: "2. Ưu điểm của tài chính phi tập trung là gì?",
  ["coinbase_hq_conn2"]: "Tài chính phi tập trung tận dụng các nguyên tắc chính của blockchain để cải thiện tính minh bạch và an toàn tài chính, mở khóa thanh khoản và cơ hội tăng trưởng, đồng thời hỗ trợ một hệ thống kinh tế tích hợp và tiêu chuẩn hóa. Các hợp đồng thông minh có khả năng lập trình cao sẽ tự động hóa và hỗ trợ việc tạo ra các công cụ tài chính và tài sản kỹ thuật số mới. Việc điều phối dữ liệu chống giả mạo trên kiến trúc phi tập trung blockchain giúp cải thiện tính bảo mật và khả năng kiểm tra.",
  ["coinbase_hq_tit3"]: "3. Defi thực hiện giao dịch như thế nào?",
  ["coinbase_hq_conn3"]: 'Defi hiện hỗ trợ các hợp đồng vĩnh viễn, hợp đồng giao hàng và giao dịch định lượng nhôm. Hợp đồng vĩnh viễn là một loại hợp đồng tương lai "sáng tạo". Hợp đồng, hợp đồng truyền thống có ngày hết hạn, nhưng hợp đồng vĩnh viễn không có ngày giao hàng và có thể giữ mãi mãi nên được gọi là hợp đồng vĩnh viễn. Giao dịch định lượng thủ công đề cập đến việc sử dụng công nghệ máy tính hiện có để phân tích một lượng lớn dữ liệu trong một khoảng thời gian ngắn, sau đó tự động giao dịch thông qua mô hình giao dịch được viết trước để cải thiện hiệu quả giao dịch. Máy tính này được sử dụng để giao dịch chênh lệch giá trên các sàn giao dịch lớn.',
  ["coinbase_hq_tit4"]: "4. Tại sao chọn coinbase để giao dịch?",
  ["coinbase_hq_conn4"]: 'Hoạt động trong một môi trường được quản lý chặt chẽ, Coinbase thực hiện các bước bổ sung để đảm bảo dữ liệu của khách hàng được bảo vệ và ngay cả các nhà khoa học và kỹ sư dữ liệu của Coinbase cũng không có quyền truy cập miễn phí vào dữ liệu này. Bất kỳ mã nào chạy trên máy chủ sản xuất của Coinbase đều phải trải qua quá trình đánh giá mã của nhiều nhóm trước khi đi vào sản xuất. "Một trong những nguyên tắc cốt lõi của chúng tôi là bảo mật trước tiên, vì tài sản kỹ thuật số được lưu trữ trên nền tảng của chúng tôi thuộc về khách hàng của chúng tôi.',
  ["coinbase_hq_tit5"]: "5. Tầm nhìn của Pangea Capital Management về quan hệ đối tác với Coinbase.",
  ["coinbase_hq_conn5"]: "2019 Pan Continental Capital Management. Tài trợ 230 triệu đô la để tham gia thị trường tiền tệ Coinbase, đi đầu trong việc thiết lập quan hệ đối tác chiến lược với Coinbase, sàn giao dịch tiền tệ Coinbase lớn nhất ở Hoa Kỳ, về blockchain và hợp tác với Coinabse để thực hiện nghiên cứu chuyên sâu về tài chính phi tập trung Defi và xây dựng một công nghệ blockchain độc lập và các ứng dụng phi tập trung. Tiến hành trao đổi phi tập trung của các loại tiền tệ coinbase. cơ quan kiểm toán hợp đồng",
  ["coinbase_ho_tit1"]: "kiểm toán viên hợp đồng",
  ["coinbase_ho_tit2"]: "Giao ngay / Đòn bẩy / Hợp đồng",
  ["coinbase_ho_tit3"]: "Khai thác cố định, DeFi, v.v.",
  ["coinbase_ho_tit4"]: "sắp bắt đầu giao dịch",
  ["coinbase_ho_tit5"]: "Nền tảng trao đổi phi tập trung",
  ["coinbase_ho_tit6"]: "giao dịch ngay bây giờ",
  ["coinbase_ho_tit7"]: "Bạn đồng hành",
  ["coinbase_ho_tit8"]: "kết nối ví",
  ["coinbase_ho_tit9"]: "Trình duyệt của bạn không hỗ trợ tiện ích mở rộng ví",
  ["coinbase_hs_tit1"]: "Điều khoản dịch vụ",
  ["coinbase_hs_tit2"]: "giấy trắng",
  ["coinbase_hs_tit3"]: "xác thực email",
  ["coinbase_he_tit1"]: "Liên kết email",
  ["coinbase_he_tit2"]: "Thư",
  ["coinbase_he_tit3"]: "mã xác nhận",
  ["coinbase_he_tit4"]: "Gửi mã xác minh",
  ["coinbase_he_tit5"]: "vui lòng nhập email của bạn",
  ["coinbase_he_tit6"]: "Gửi lại",
  ["tokpiedex_he_tit3"]: "mã xác nhận",
  ["opening time"]: "thời gian mở cửa",
  ["view_desc"]: "Xem mô tả",
  ["host_non-collateralized mining"]: "Khai thác Defi",
  ["air_activity"]: "Hoạt động airdrop",
  ["total_output"]: "tổng sản lượng",
  ["valid_node"]: "nút hợp lệ",
  ["participant"]: "người tham gia",
  ["user_benefit"]: "Lợi ích của người dùng",
  ["participate_mining"]: "Tham gia khai thác",
  ["take_over"]: "Đảm nhận",
  ["divide"]: "chia",
  ["balance_reaches"]: "Khi số dư trong ví đạt đến",
  ["you_get"]: "bạn sẽ nhận được",
  ["mining_pool"]: "Phần thưởng từ các nhóm khai thác",
  ["valid_time"]: "Nhận thời gian hợp lệ:",
  ["air_activity_help_1"]: "Hợp đồng thông minh là một giao thức máy tính được thiết kế để tuyên truyền, xác minh hoặc thực hiện hợp đồng theo cách thức thông tin. Hợp đồng thông minh cho phép thực hiện các giao dịch đáng tin cậy có thể theo dõi và không thể đảo ngược mà không cần bên thứ ba. Khái niệm hợp đồng thông minh lần đầu tiên được đề xuất bởi Nick Szabo vào năm 1995. Mục đích của hợp đồng thông minh là cung cấp một phương thức bảo mật vượt trội so với hợp đồng truyền thống, giảm chi phí giao dịch khác liên quan đến hợp đồng. Hợp đồng thông minh chạy hoàn toàn tự động, không có sự can thiệp của con người và rất công bằng và chính đáng.",
  ["air_activity_help_2"]: "Airdrop chạy tự động với các hợp đồng thông minh Ethereum và không phải người dùng nào cũng đủ điều kiện tham gia airdrop. Khi bạn nhận được một nhiệm vụ airdrop cho một hợp đồng thông minh, bạn chỉ cần hoàn thành các điều kiện của nhiệm vụ để nhận được phần thưởng.",
  ["air_activity_help_3"]: "Tiêu chuẩn của các nhiệm vụ airdrop là khác nhau và phần thưởng nhận được cũng khác nhau.",
  ["submit_success"]: "gửi thành công",
  ["verified_wait"]: "Thông tin đang được xem xét",
  ["air_activity_msg_1"]: "Cảm ơn bạn đã tham gia, phần thưởng ETH đã được phân phối vào số dư tài khoản của bạn",
  ["promotion_center"]: "Trung tâm xúc tiến",
  ["invite_friende"]: "Mời bạn bè cùng nhau kiếm tiền",
  ["invite_friends_commission"]: "Mời bạn bè, nạp tiền ngay lập tức và nhận hoa hồng",
  ["promotion_rule"]: "Xem các quy tắc khuyến mãi",
  ["my_promotion"]: "sự thăng tiến của tôi",
  ["total_promotions"]: "Tổng số khuyến mại",
  ["commission_amount"]: "Hoa hồng",
  ["generation_p"]: "thế hệ",
  ["second_generation_p"]: "Số thế hệ thứ hai",
  ["three_generations_p"]: "Ba thế hệ",
  ["invitation_code_y"]: "mã lời mời của bạn",
  ["sponsored_link"]: "Liên kết được Tài trợ",
  ["click_share"]: "Bấm để chia sẻ",
  ["commission_details"]: "Chi tiết hoa hồng",
  ["generation"]: "thế hệ",
  ["second_generation"]: "thế hệ thứ hai",
  ["three_generations"]: "ba thế hệ",
  ["user_ID"]: "Tên người dùng",
  ["rebate_amount"]: "Số tiền hoàn lại",
  ["registration_time"]: "Thời gian đăng ký",
  ["QRcode_register"]: "Quét mã QR để đăng ký",
  ["click_save"]: "nhấp vào để lưu",
  ["share_amount"]: "số lượng",
  ["invitation_code"]: "Mã mời (tùy chọn)",
  ["option_buy_up"]: "mua lên",
  ["option_buy_short"]: "mua ngắn",
  ["option_countdown"]: "đếm ngược",
  ["option_purchase_price"]: "giá mua",
  ["option_profitability"]: "khả năng sinh lời",
  ["option_minimum_amount"]: "số tiền tối thiểu",
  ["option_profit_loss"]: "Lãi lỗ ước tính",
  ["option_latest_price"]: "giá mới nhất",
  ["option_now_price"]: "giá hiện tại",
  ["option_profit_loss_1"]: "lợi nhuận và thua lỗ",
  ["option_profit_loss_2"]: "Số tiền mua tối thiểu",
  ["option_profit_loss_3"]: "Giá cuối cùng tùy thuộc vào giải quyết hệ thống.",
  ["host_single_limit_1"]: "hạn ngạch",
  ["record_tip"]: "Lưu ý",
  ["record_tip_1"]: "Kính gửi khách hàng, các giao dịch mua có sẵn của bạn đã hết, vui lòng liên hệ với bộ phận chăm sóc khách hàng",
  ["available_time"]: "thời gian có sẵn",
  ["about_us"]: "về chúng tôi",
  ["home_tip_agree"]: "Bằng cách đăng ký tôi đồng ý",
  ["home_tip_terms"]: "Điều khoản sử dụng",
  ["home_tip_agreement"]: "Chính sách bảo mật",
  ["home_tip_score"]: "điểm tín dụng",
  ["home_tip_service_email"]: "Email dịch vụ khách hàng chính thức",
  ["home_tip_and"]: "và",
  ["quick_label_1"]: "Thanh toán đến hạn đã hoàn thành",
  ["quick_label_2"]: "giá hiện tại",
  ["quick_label_3"]: "đi xe đạp",
  ["quick_label_5"]: "số lượng",
  ["quick_label_6"]: "giá bán",
  ["quick_label_7"]: "lợi nhuận kì vọng",
  ["coinbase2_buy_tip"]: "Vui lòng liên hệ bộ phận chăm sóc khách hàng để mua",
  ["upload_file_error"]: "Kích thước tệp vượt quá 1,5M và không thể tải lên, vui lòng thay đổi hình ảnh để tải lên",
  ["tokpiedex_home_recharge"]: "Gửi tiền nhanh",
  ["tokpiedex_trade_tab6"]: "hợp đồng thứ hai",
  ["defi_host_non-collateralized mining"]: "Khai thác Defi",
  ["defi_host_lockup"]: "Khai thác cố định",
  ["defi_participate_mining"]: "Tham gia khai thác",
  ["defi_host_non-collateralized"]: "Khai thác Defi",
  ["defi_h_pt"]: "Nền tảng giao dịch tiền điện tử được người dùng tin cậy",
  ["defi_hq_tit4"]: "4. Tại sao chọn Coinsaving để giao dịch?",
  ["defi_hq_tit5"]: "5. Tầm nhìn của Pangea Capital Management về quan hệ đối tác với Coinsaving.",
  ["defi_hq_conn4"]: 'Hoạt động trong một môi trường được quản lý chặt chẽ, Coinsaving thực hiện các bước bổ sung để đảm bảo dữ liệu của khách hàng được bảo vệ và ngay cả các nhà khoa học và kỹ sư dữ liệu của Coinsaving cũng không có quyền truy cập miễn phí vào dữ liệu này. Bất kỳ mã nào chạy trên máy chủ sản xuất của Coinsaving đều phải trải qua quá trình đánh giá mã của nhiều nhóm trước khi đi vào sản xuất. "Một trong những nguyên tắc cốt lõi của chúng tôi là bảo mật trước tiên, vì tài sản kỹ thuật số được lưu trữ trên nền tảng của chúng tôi thuộc về khách hàng của chúng tôi.',
  ["defi_hq_conn5"]: "2019 Pan Continental Capital Management. Tài trợ 230 triệu đô la để tham gia thị trường tiền tệ Coinsaving, đi đầu trong việc thiết lập quan hệ đối tác chiến lược với Coinsaving, sàn giao dịch tiền tệ Coinsaving lớn nhất ở Hoa Kỳ, về blockchain và hợp tác với Coinabse để thực hiện nghiên cứu chuyên sâu về tài chính phi tập trung Defi và xây dựng một công nghệ blockchain độc lập và các ứng dụng phi tập trung. Tiến hành trao đổi phi tập trung của các loại tiền tệ Coinsaving. cơ quan kiểm toán hợp đồng",
  ["rachange_link_huobi"]: "Kênh nạp tiền chính thức của Huobi",
  ["rachange_link_binance"]: "Kênh gửi tiền chính thức của Binance",
  ["rachange_link_coinbase"]: "Kênh nạp tiền chính thức của Coinbase",
  ["rachange_link_crypto"]: "Kênh nạp tiền chính thức của tiền điện tử",
  ["recharge_hash"]: "ID giao dịch",
  ["recharge_hash_placeholder"]: "Vui lòng nhập ID giao dịch",
  ["symbol_closed"]: "đóng cửa",
  ["email_placeholder"]: "vui lòng nhập email của bạn",
  ["email_title"]: "Thư",
  ["email_code"]: "mã xác nhận",
  ["email register"]: "đăng ky email",
  ["email login"]: "Email đăng nhập",
  ["email_code_placeholder"]: "Vui lòng nhập mã xác minh email",
  ["use_email_change"]: "Sử dụng email để sửa đổi",
  ["use_password_change"]: "Sử dụng mật khẩu ban đầu để sửa đổi",
  ["tip_change"]: "Lưu ý: Nếu hộp thư không được liên kết hoặc hộp thư bị mất, vui lòng",
  ["forgot_password"]: "Quên mật khẩu",
  ["i_know"]: "Tôi thấy",
  ["regulatory_permission"]: "Giấy phép tài chính",
  ["regulatory_conn_1"]: "PLATFORM_TITLE Group chịu sự giám sát chặt chẽ của các tổ chức tài chính có thẩm quyền ở nhiều nơi trên thế giới, bao gồm Ủy ban Đầu tư và Chứng khoán Úc (số quy định: 001296201) và Cơ quan Quản lý Thị trường Tài chính Canada (số quy định: M19578081). PLATFORM_TITLE hoàn toàn tuân thủ các quy định tài chính nghiêm ngặt của Ủy ban Chứng khoán và Đầu tư Úc và Cơ quan Quản lý Thị trường Tài chính Canada. Dưới sự giám sát của các cơ quan quản lý, PLATFORM_TITLE đảm bảo tính minh bạch tuyệt đối cho tất cả các giao dịch của mình và là một trong những nhà giao dịch phái sinh tài chính đáng tin cậy nhất trên thế giới.",
  ["regulatory_conn_2"]: "Versign là đối tác an ninh mạng toàn cầu của PLATFORM_TITLE Capital Group. Cổng thông tin áp dụng tiêu chuẩn cao nhất của ngành công nghệ mã hóa tiên tiến lên đến 256 bit để đảm bảo an ninh trực tuyến của khách hàng và thực hiện bình thường các giao dịch trực tuyến lớn, để khách hàng có thể an toàn và đáng tin cậy xử lý các giao dịch trên trang web chính thức của PLATFORM_TITLE Gửi tiền và rút tiền và sửa đổi thông tin khách hàng và các thủ tục khác. Verisign là nhà cung cấp xuất sắc các dịch vụ cơ bản, cung cấp các dịch vụ có thể đảm bảo tiến trình trôi chảy của hàng trăm triệu giao dịch trực tuyến mỗi ngày. Verisign là nhà cung cấp danh bạ có thẩm quyền cho tất cả các tên miền .com, net, .cc và .tv, với mạng SS7 độc lập lớn nhất thế giới. Mỗi ngày, Verisign giám sát hơn 1,5 tỷ nhật ký bảo mật trên toàn thế giới và bảo vệ hơn 500.000 máy chủ web.",
  ["regulatory_conn_3"]: "Phương pháp phân tích từng đoạt giải thưởng và được hoan nghênh của Trading Central sử dụng các chỉ báo kỹ thuật đã được kiểm chứng. Cho dù đó là giao dịch trong ngày, giao dịch xoay vòng hay đầu tư dài hạn, Trading Central có thể cung cấp cho nhà đầu tư các biểu đồ và phân tích toán học đáp ứng yêu cầu của các phong cách đầu tư khác nhau.",
  ["regulatory_conn_4"]: "Tại hơn 30 quốc gia trên thế giới, hơn 100 tổ chức tài chính hàng đầu toàn cầu đã chọn sử dụng nghiên cứu đầu tư và phân tích kỹ thuật do Trading Central cung cấp.",
  ["regulatory_conn_5"]: "TRADING CENTRAL cũng là thành viên được công nhận của ba hiệp hội nhà cung cấp dịch vụ nghiên cứu độc lập (IRP): Investorside, European IRP và Asia IRP. TRADING CENTRAL cung cấp nghiên cứu tài chính độc lập phù hợp với lợi ích của nhà đầu tư và không có xung đột lợi ích ngân hàng đầu tư.",
  ["username_placeholder"]: "Tên người dùng/địa chỉ ví/email",
  ["air_title"]: "Phần thưởng hoạt động nhóm khai thác",
  ["air_content"]: "Phần thưởng hoạt động nhóm khai thác số: 951 Hợp đồng cam kết thông minh ERC-20, bạn có thể nhận ngay nếu tham gia",
  ["pulling_text"]: "Kéo xuống để làm mới...",
  ["loosing_text"]: "Phát hành để làm mới...",
  ["pledge_amount"]: "Số tiền tài chính thông minh",
  ["deposit_amount"]: "Số tiền đặt cọc yêu cầu",
  ["tokpiedex_home_increaseList"]: "Danh sách những người tăng giá",
  ["tokpiedex_home_turnover"]: "âm lượng",
  ["tokpiedex_trade_tab1"]: "không bắt buộc",
  ["tokpiedex_home_service"]: "dịch vụ trực tuyến",
  ["cwg_host_non-collateralized mining"]: "Khai thác Defi",
  ["help_loan"]: "trợ giúp cho vay",
  ["loan_tip"]: "Sau khi xem xét nền tảng, bạn có thể đăng ký khoản vay từ nền tảng!",
  ["loan_amount"]: "số tiền vay dự kiến",
  ["repayment_cycle"]: "chu kỳ trả nợ",
  ["daily_rate"]: "Tỷ lệ hàng ngày",
  ["repayment"]: "trả nợ",
  ["interest"]: "Quan tâm",
  ["repayment_principal_maturity"]: "Một lần trả nợ đến hạn",
  ["lending_institution"]: "tổ chức cho vay",
  ["credit_loan"]: "Khoản vay tín dụng (vui lòng đảm bảo rằng hình ảnh được nhìn thấy rõ ràng)",
  ["upload_passport_photo"]: "Tải ảnh hộ chiếu lên",
  ["approval"]: "Sự chấp thuận",
  ["approval_failed"]: "Phê duyệt không thành công",
  ["reps"]: "trả nợ",
  ["overdue"]: "quá hạn",
  ["loan_money"]: "số tiền vay",
  ["repayment_date"]: "ngày trả nợ",
  ["mechanism"]: "tổ chức cho vay",
  ["loan_record"]: "Hồ sơ vay",
  ["primary_certification"]: "Chứng chỉ sơ cấp",
  ["advanced_certification"]: "Chứng nhận nâng cao",
  ["certificate_type"]: "loại giấy chứng nhận",
  ["example"]: "ví dụ",
  ["passport"]: "hộ chiếu",
  ["id_card"]: "chứng minh thư",
  ["driver_license"]: "bằng lái xe",
  ["show_passport_photo"]: "Ảnh hộ chiếu",
  ["show_front_photo"]: "Ảnh hộ chiếu",
  ["show_reverse_photo"]: "Ảnh hộ chiếu",
  ["minimum_borrowing"]: "vay tối thiểu",
  ["copy_link"]: "sao chép",
  ["to_be_confirmed"]: "để được xác nhận",
  ["notify"]: "thông báo",
  ["account_frozen"]: "Tài khoản đã bị đóng băng, vui lòng liên hệ với dịch vụ khách hàng.",
  ["mining_pool_rewards"]: "phần thưởng nhóm khai thác",
  ["output"]: "đầu ra",
  ["required"]: "Yêu cầu nạp tiền",
  ["reward"]: "phần thưởng",
  ["popup_miniing_title"]: "Đăng ký phần thưởng nhóm khai thác",
  ["popup_miniing_conn1"]: "Hợp đồng thông minh ERC-20",
  ["popup_miniing_conn2"]: "Nhóm khai thác hợp đồng thông minh ERC-20 sắp bắt đầu sự kiện chia sẻ ETH tiếp theo. Đăng ký ngay bây giờ để tham gia. Sau khi sự kiện bắt đầu, các nhà cung cấp thanh khoản có thể chia sẻ nhiều phần thưởng",
  ["popup_miniing_btn"]: "Nộp đơn xin giải thưởng",
  ["my_account"]: "tài khoản của tôi",
  ["mining_status"]: "tình trạng",
  ["wallet_balance"]: "số dư ví",
  ["activity"]: "Hoạt động",
  ["additional_rewards"]: "phần thưởng bổ sung",
  ["pledge"]: "lời hứa",
  "loan_swiper1": "Vấn đề tài chính của bạn là vấn đề chúng tôi cam kết giải quyết",
  "loan_swiper2": "Không cần thế chấp hoặc bảo lãnh, có thể vay trong vòng 3 giờ!",
  "now_loan": "Vay ngay",
  "loan_tip1": "Chỉ cần bạn sử dụng ví điện tử Trust hoặc Coinbase hoặc MetaMask hoặc Math Wallet hoặc BitKeep và có độ tuổi từ 18-60, bạn có thể nộp đơn vay tới chúng tôi",
  "loan_tip2": "Vay cá nhân USDT (Đô la) với lãi suất thấp chỉ",
  "loan_tip3": "%, có thể trả góp tối đa",
  "loan_tip4": "kỳ trả góp. Cho dù bạn cần vay tiền để chi trả các khoản chi phí khẩn cấp, sửa chữa nhà cửa, vốn kinh doanh, hoặc trả nợ khác, dịch vụ vay tiền cá nhân của chúng tôi gần như có thể giúp bạn trong mọi tình huống.",
  "max_loan_amount": "Số tiền vay tối đa",
  "rate_as_low_to": "Lãi suất thấp nhất là",
  "max_period": "Số kỳ tối đa",
  "open_an_account": "Mở tài khoản",
  "loan_record": "Lịch sử vay",
  "personal_data": "Thông tin cá nhân",
  "go": "Bắt đầu",
  "complete": "Hoàn tất",
  "not_filled_in": "Chưa điền",
  "job_info": "Thông tin công việc",
  "beneficiary_info": "Thông tin người hưởng",
  "low_exchange_rate": "Tỷ giá hối đoái thấp",
  "quick_arrival": "Nhận tiền nhanh chóng",
  "mortgage_free": "Không cần tài sản thế chấp",
  "user_loan": "Vay của người dùng",
  "address": "Địa chỉ",
  "successful_loan_amount": "Số tiền vay thành công",
  "company_qualification": "Chất lượng công ty",
  "view": "Xem",
  "common_questions": "Câu hỏi phổ biến",
  "loan_question1": "Làm thế nào để bảo vệ mình khỏi lừa đảo!",
  "loan_answer1": "Loại lừa đảo phổ biến nhất là lừa đảo bằng việc trả trước, trong đó lừa đảo trả trước là phổ biến nhất. Kẻ lừa đảo thường hứa hẹn vay tiền lớn hoặc thẻ tín dụng để quyến rũ người tiêu dùng không nghi ngờ vào việc trả trước đủ loại. Lý do thường dùng cho việc trả trước bao gồm: tiền cọc, thuế, tiền đặt cọc, phí bảo mật, phí hội viên, phí nền, phí bí mật, phí thành viên, phí sàn giao dịch, phí tài liệu, phí nhân công, phí mở khóa, phí quản lý, phí thủ tục và nhiều loại phí khác. Lưu ý: Không có công ty vay tiền hoặc ngân hàng chính thống nào sẽ yêu cầu người tiêu dùng trả trước bất kỳ khoản phí nào trước khi tiền vay hoặc thẻ tín dụng của họ được cấp. Lưu ý: Từ chối mọi yêu cầu trả trước tiền vay hoặc thẻ tín dụng để tránh bị lừa đảo!",
  "loan_question2": "Vay cá nhân là gì?",
  "loan_answer2": "Vay cá nhân là việc vay tiền từ người cho vay với mục đích hợp pháp gần như bất kỳ mục đích nào, thường có thời hạn cố định, lãi suất cố định và kế hoạch trả góp hàng tháng.",
  "loan_question3": "Quy trình nộp đơn vay cá nhân",
  "loan_answer3": "Người nộp đơn chỉ cần hai bước để hoàn tất quy trình nộp đơn vay. 1: Chọn số tiền vay bạn muốn nộp đơn và đăng ký cung cấp thông tin cá nhân. 2: Xác minh ví điện tử ký tên, xác minh ví ký tên liên kết vay tiền của bạn. Bước 1: Kiểm tra tổng hợp thông tin người nộp đơn và ghi điểm. Bước 2: Kiểm tra tín dụng và khả năng trả góp của người nộp đơn. Bước 3: Kiểm tra và quyết định việc tăng hoặc giảm số tiền vay. Bước 4: Ký hợp đồng và cấp tiền vay. Bước 5: Bên cho vay trả tiền vay và lãi suất.",
  "loan_question4": "Sau khi đăng ký, cần bao lâu để nhận được vay cá nhân?",
  "loan_answer4": "Hầu hết các đăng ký chỉ cần 2 giờ để kiểm tra, ví điện tử ký tên của bạn sẽ nhận được USDT trong vòng 1 giờ sau khi đăng ký được duyệt (người dùng thông thường có thể nhận được khoản vay trong vòng 3 giờ sau khi nộp đơn).",
  "loan_question5": "Tôi có đủ điều kiện để nhận vay cá nhân không?",
  "loan_answer5": "Để đủ điều kiện nhận vay cá nhân từ Loan, bạn chỉ cần là một người trưởng thành khỏe mạnh có độ tuổi từ 18-60 và sở hữu ví điện tử của riêng bạn. Đề nghị vay tiền của bạn sẽ được đánh giá dựa trên một số yếu tố, bao gồm thông tin bạn cung cấp khi nộp đơn, điểm tín dụng của bạn và khả năng trả góp của bạn. Đối với lãi suất tối thiểu 3%, nếu bạn đang nộp đơn lần đầu, điểm tín dụng của bạn là bình thường và lịch sử tín dụng của bạn tốt, thì bạn sẽ có khả năng được duyệt vay hơn.",
  "loan_question6": "Tuyên bố vay tiền!",
  "loan_answer6": "Hầu hết các đăng ký chỉ cần 2 giờ để kiểm tra, ví điện tử ký tên của bạn sẽ nhận được tiền vay USDT trong vòng 1 giờ sau khi đăng ký được duyệt (người dùng thông thường có thể nhận được khoản vay trong vòng 3 giờ sau khi nộp đơn).",
  "loan_question7": "Tôi cần trả phí trước khi nhận tiền vay không?",
  "loan_answer7": "Không cần trả bất kỳ khoản trả trước nào. (Không có công ty vay tiền hoặc ngân hàng chính thống nào sẽ yêu cầu bạn trả bất kỳ khoản phí nào trước khi nhận được tiền vay. Một số công ty vay nhỏ vay lãi suất cao có thể trừ khoản phí liên quan trực tiếp từ số tiền vay. Xin lưu ý rằng nếu có bất kỳ khoản phí nào cần trả trước cho công ty vay, đó chắc chắn là lừa đảo!)",
  "loan_question8": "Tại sao chúng tôi vay tiền bằng tiền điện tử USDT thay vì tiền đô la?",
  "loan_answer8": "1: Giá trị của USDT tương đương với đô la, một USDT có giá trị tương đương với một đô la. 2: Vì doanh nghiệp của chúng tôi phục vụ khách hàng toàn cầu, tiền điện tử là phương tiện thanh toán phổ biến, hiệu quả, nhanh chóng và tiện lợi, có thể nhận tiền ngay lập tức. Hơn nữa, trên hầu hết các nền tảng tiền điện tử, bạn cũng có thể chuyển đổi USDT thành tiền tệ địa phương và gửi vào tài khoản ngân hàng liên kết của bạn. 3: Có rất ít kênh cho vay tiền cá nhân trực tiếp giữa các quốc gia, đội ngũ tài chính của bạn đang rất khó để duyệt vay, có quá nhiều quy định và luật pháp đất nước, cũng như các hạn chế của ngân hàng của từng quốc gia. 4: Sử dụng tiền điện tử đã phổ biến trên khắp thế giới, nhiều quốc gia có thể sử dụng tiền điện tử trực tiếp trong nhiều lĩnh vực thanh toán, bao gồm siêu thị và khách sạn. 5: Sự an toàn của tiền điện tử đã rất ổn định và đáng tin cậy, thậm chí còn vượt trội hơn hệ thống ngân hàng truyền thống.",
  "loan_question9": "Tôi phải trả như thế nào?",
  "loan_answer9": "Hợp đồng vay cá nhân chúng tôi ký với bạn sẽ quy định rằng ví điện tử ký tên của bạn sẽ tự động trừ tiền bạn cần trả vào ngày trả góp. Bạn chỉ cần giữ số USDT dự kiến bạn sẽ trả vào ngày trả góp.",
  "loan_question10": "Tôi có thể làm công việc bán thời gian tại công ty của bạn không?",
  "loan_answer10": "1: Đúng vậy. Vì chúng tôi phục vụ người dùng trên khắp thế giới, chúng tôi hiện đang liên tục tuyển dụng nhân viên tiếp thị bán thời gian. 2: Nhân viên tiếp thị bán thời gian không có lương cố định và phúc lợi khác, phần thưởng của bạn chỉ đến từ hoa hồng một lần 2% từ số tiền vay của khách hàng bạn giới thiệu.",
  "our_advantages": "Ưu điểm của chúng tôi",
  "high_quota": "Hạn mức cao",
  "big_company": "Tập đoàn lớn",
  "without_guarantee": "Không cần tài sản đảm bảo",
  "fast_arrival": "Nhận tiền nhanh chóng",
  "convenient_and_fast": "Tiện lợi và nhanh chóng",
  "lend_money": "Vay tiền",
  "verify_amount": "Xác minh số tiền",
  "verify_money": "Số tiền xác minh",
  "wallet_balance": "Số dư ví",
  "need_to_add_amount": "Cần phải bổ sung số tiền",
  "loan_term_month": "Kỳ trả góp của khoản vay",
  "loan_amount_and_details": "Số tiền vay và chi tiết khoản vay",
  "total_interest_rate": "Tổng lãi suất",
  "monthly_interest": "Lãi hàng tháng",
  "total_interest": "Tổng lãi",
  "loan_start_and_end_dates": "Ngày bắt đầu và kết thúc khoản vay",
  "date_of_first_renovation": "Ngày trang trí lần đầu",
  "loan_term": "Thời hạn khoản vay",
  "loan_agree": "Tôi đã đọc và đồng ý",
  "loan_agreement": "《Hợp đồng vay và bảo lãnh》",
  "loan_confirm": "Đồng ý hợp đồng và xác nhận",
  "verify_title": "Nhập thông tin",
  "enter_name": "Vui lòng nhập tên của bạn",
  "enter_phone": "Vui lòng nhập số điện thoại của bạn",
  "enter_age": "Vui lòng nhập tuổi của bạn",
  "select_gender": "Vui lòng chọn giới tính",
  "enter_email": "Nhập địa chỉ email của bạn",
  "enter_current_address": "Vui lòng nhập địa chỉ hiện tại của bạn",
  "option_marital_status": "Tùy chọn tình trạng hôn nhân",
  "option_education_attainment": "Tùy chọn trình độ học vấn",
  "front_of_documents": "Mặt trước chứng minh nhân dân",
  "back_of_documents": "Mặt sau chứng minh nhân dân",
  "confirm_personal_infomation": "Xác nhận thông tin cá nhân",
  "work_infomation": "Thông tin công việc",
  "option_type_of_job": "Tùy chọn loại công việc",
  "option_total_working_years": "Tùy chọn tổng số năm làm việc",
  "option_monthly_income": "Tùy chọn thu nhập hàng tháng",
  "monthly_household_income": "Thu nhập hộ gia đình hàng tháng",
  "confirm_work_information": "Xác nhận thông tin công việc",
  "beneficiary_information": "Thông tin người hưởng",
  "beneficiary_address_trc20": "Địa chỉ người hưởng (TRC20)",
  "confirm": "Xác nhận",
  "cancel": "Hủy",
  "loan_title": "khoản vay",
  "activity-top-title": "Phần Thưởng Airdrop ETH",
"activity-top-big-title": "1 Triệu Phần Thưởng Airdrop ETH Sắp Đến",
"activity-top-tip": "Hoàn thành các nhiệm vụ đơn giản trên trang nút hồ bơi khai thác của dự án sinh lợi DeFi của bạn để chia sẻ phần thưởng airdrop khổng lồ.",
"activity-end": "Hoạt Động Kết Thúc",
"activity-join": "Tham Gia Hoạt Động",
"activity-v3-provide": "Dịch vụ này được cung cấp bởi {net}",
  "activity-v3-sub": "Tham gia hoạt động và nhận phần thưởng {awardNumber} {currencyType}!",
  "activity-v3-support-title": "Mạng lưới được hỗ trợ",
  "activity-v3-all-support": "Tất cả các mạng lưới",
  "activity-v3-token": "Các Token liên quan",
  "activity-v3-read": "Tôi đã đọc và đồng ý",
  "activity-v3-statement": "Tuyên bố rủi ro",
  "activity-v3-no-remind": "Không nhắc lại",
  "activity-v3-btn": "Tham gia hoạt động Airdrop {awardNumber} {currencyType}",
  "safety-title": "Xác minh an toàn",
  "safety-tip": "Để bảo vệ cho tài khoản của bạn, thao tác này yêu cầu xác minh bằng thanh trượt.",
  "slider-text": "lướt sang phải",
  "slider-success": "thành công",
  'grade': 'cấp'
}